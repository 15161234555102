import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { CadConfirmationService } from "@cad/services/confirmation";
import { CadValidators } from "@cad/validators";
import { content, health } from "@igniteui/material-icons-extended";
import {
  ButtonGroupAlignment,
  IComboSelectionChangingEventArgs,
  IgxBannerComponent,
  IgxComboComponent,
  IgxIconService,
  IgxSnackbarComponent,
  IgxTimePickerModule,
  IGX_INPUT_GROUP_TYPE,
} from "@infragistics/igniteui-angular";
import { AppConfigService } from "app/appconfig.service";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import {
  addendum as addendumData,
  notes as notesData,
} from "app/modules/provider/patient/composition/data";
import { DischargeService } from "app/modules/provider/patient/discharge.service";
import { PatientComponent } from "app/modules/provider/patient/patient.component";
import { PersonSearchService } from "app/modules/provider/person-search/person-search.service";
import { StoreService } from "app/shared/store.service";
import { ValueSet } from "app/shared/ValueSet";
import { TreemapHeaderDisplayMode } from "igniteui-angular-charts";
import { Subject, takeUntil } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { ComposeService } from "./compose.service";

export class Notes {
  category: {
    code: string;
    display: string;
    system: string;
    type: {
      code: string;
      display: string;
      system: string;
      event: {
        code: string;
        display: string;
        system: string;
        section: {
          code: string;
          display: string;
          input: string;
          system: string;
        }[];
      }[];
    }[];
  }[];
}

export class Category {
  code: string;
  display: string;
  system: string;
  type: Type[];

  constructor(category: Category) {
    Object.assign(this, category);
  }
}

export class Type {
  code: string;
  display: string;
  system: string;
  event: Event[];

  constructor(type: Type) {
    Object.assign(this, type);
  }
}

export class Event {
  code: string;
  display: string;
  system: string;
  section: Section[];

  constructor(event: Event) {
    Object.assign(this, event);
  }
}

export class Section {
  code: string;
  display: string;
  system: string;
  input: string;

  constructor(section: Section) {
    Object.assign(this, section);
  }
}

@Component({
  selector: "compose",
  templateUrl: "./compose.component.html",
  styleUrls: ["./compose.component.scss"],
  encapsulation: ViewEncapsulation.None,
  exportAs: "compose",
  providers: [{ provide: IGX_INPUT_GROUP_TYPE, useValue: "border" }],
})
export class ComposeComponent implements OnInit, OnDestroy {
  @ViewChild("snackbar", { static: true })
  public snackbar: IgxSnackbarComponent;
  @ViewChild("cbNoteCategories", { read: IgxComboComponent })
  public cbNoteCategories: IgxComboComponent;
  @ViewChild("cbNoteTypes", { read: IgxComboComponent })
  public cbNoteTypes: IgxComboComponent;
  @ViewChild("cbNoteEvents", { read: IgxComboComponent })
  public cbNoteEvents: IgxComboComponent;
  @ViewChild("cbNoteSections", { read: IgxComboComponent })
  public cbNoteSections: IgxComboComponent;
  @ViewChild("ICD11Banner", { read: IgxBannerComponent })
  public ICD11Banner: IgxBannerComponent;
  // @ViewChild("eventTime", { read: IgxTimePickerModule })
  // public timePicker: IgxTimePickerModule;
  @ViewChild("compositionNote") private myScrollContainer: ElementRef;

  public format = "hh:mm tt";
  public date: Date = new Date();

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _notes: Notes = notesData;
  private _addendumNotes: Notes = addendumData;

  public allIcons = [...health, ...content];
  public alignment = ButtonGroupAlignment.vertical;
  public rippleColor = "grey";

  private editMode: boolean = false;
  public enterNote: boolean = false;
  public opened: boolean = false;
  public ctlClinicalInformation: boolean = false;
  public ctlProblemList: boolean = false;
  public ctlVitalSign: boolean = false;
  public ctlAllergies: boolean = false;
  public ctlMedication: boolean = false;
  public ctlSocialHistory: boolean = false;
  public ctlFamilyHistory: boolean = false;
  public ctlImmunizationHistory: boolean = false;
  public ctlAssessment: boolean = false;
  public ctlCarePlan: boolean = false;

  public vtsRespiratoryRate: boolean = false;
  public vtsHeartRate: boolean = false;
  public vtsOxygenSaturation: boolean = false;
  public vtsBodyTemperature: boolean = false;
  public vtsSystolicPressure: boolean = false;
  public vtsDiastolicPressure: boolean = false;
  public vtsPainScore: boolean = false;

  public dntAlert: boolean = false;
  public dntChart: boolean = false;

  public adsEvent: boolean = false;

  public proDesc: boolean = false;
  public proFind: boolean = false;
  public proComp: boolean = false;

  public uuidRr: uuidv4;
  public uuidHr: uuidv4;
  public uuidOs: uuidv4;
  public uuidBt: uuidv4;
  public uuidSp: uuidv4;
  public uuidDp: uuidv4;
  public uuidAe: uuidv4;
  public uuidPs: uuidv4;

  private uuidVs: uuidv4;

  public noteCategories: Category[];
  private noteCategory: Category;
  public noteTypes: Type[];
  private noteType: Type;
  public noteEvents: Event[];
  private noteEvent: Event;
  public noteSections: Section[];
  public problemCategories: ValueSet[];
  public conditionCodes: ValueSet[];
  public bundle: fhir.r4.Bundle;
  public composition: fhir.r4.Composition;
  private vitalSign: fhir.r4.Observation;
  private respiratoryRate: fhir.r4.Observation;
  private heartRate: fhir.r4.Observation;
  private oxygenSaturation: fhir.r4.Observation;
  private bodyTemperature: fhir.r4.Observation;
  private systolicPressure: fhir.r4.Observation;
  private diastolicPressure: fhir.r4.Observation;
  private painScore: fhir.r4.Observation;
  public conditions: fhir.r4.Condition[];
  public encounter: any;
  private mandatorySection: Section[];
  public html: any;
  public ICD11InputIndex: number;
  public conditionCategory: string;
  public currentEncounter: any;

  public diagnosisRole: ValueSet[];

  public attender: fhir.r4.BundleEntry[];

  public compositionForm: FormGroup;
  public problemListForm: FormGroup;
  public diagnosisListForm: FormGroup;
  public problemList: FormArray;
  public diagnosisList: FormArray;

  public author: string;

  public cbNotesTypeDisable: boolean = false;
  public cbNoteEventsDisable: boolean = false;

  public abatementDateTimeValidity: boolean = false;
  public eventDateTimeValidity: boolean = false;

  consentStatus: string;
  status: boolean;

  compositionId: string;

  public performedDates: Date;

  get clinicalInformationForm() {
    return this.compositionForm.get("clinicalInformationForm");
  }

  get adverseReactionForm() {
    return this.compositionForm.get("adverseReactionForm");
  }

  get vtsRespiratoryRateForm() {
    return this.compositionForm.get("vtsRespiratoryRateForm");
  }

  get vtsHeartRateForm() {
    return this.compositionForm.get("vtsHeartRateForm");
  }

  get vtsOxygenSaturationForm() {
    return this.compositionForm.get("vtsOxygenSaturationForm");
  }

  get vtsBodyTemperatureForm() {
    return this.compositionForm.get("vtsBodyTemperatureForm");
  }

  get vtsSystolicPressureForm() {
    return this.compositionForm.get("vtsSystolicPressureForm");
  }

  get vtsDiastolicPressureForm() {
    return this.compositionForm.get("vtsDiastolicPressureForm");
  }

  get vtsPainScoreForm() {
    return this.compositionForm.get("vtsPainScoreForm");
  }

  get allergiesForm() {
    return this.compositionForm.get("allergiesForm");
  }

  get carePlanForm() {
    return this.compositionForm.get("carePlanForm");
  }

  get medicationForm() {
    return this.compositionForm.get("medicationForm");
  }

  get procedureDescriptionForm() {
    return this.compositionForm.get("procedureDescriptionForm");
  }

  get procedureFindingsForm() {
    return this.compositionForm.get("procedureFindingsForm");
  }

  get procedureComplicationsForm() {
    return this.compositionForm.get("procedureComplicationsForm");
  }

  get socialHistoryForm() {
    return this.compositionForm.get("socialHistoryForm");
  }

  get familyHistoryForm() {
    return this.compositionForm.get("familyHistoryForm");
  }

  get immunizationHistoryForm() {
    return this.compositionForm.get("immunizationHistoryForm");
  }

  get assessmentForm() {
    return this.compositionForm.get("assessmentForm");
  }

  get dentistryAlertForm() {
    return this.compositionForm.get("dentistryAlertForm");
  }

  get dentistryChartForm() {
    return this.compositionForm.get("dentistryChartForm");
  }

  get conditionCode() {
    return this.problemList.get("conditionCode");
  }

  get conditionDescription() {
    return this.problemList.get("conditionDescription");
  }

  get note() {
    return this.problemList.get("note");
  }

  get role() {
    return this.problemList.get("role");
  }

  get eventDate() {
    return this.compositionForm.get("eventDate");
  }

  get eventTime() {
    return this.compositionForm.get("eventTime");
  }

  public navItems = [
    // { name: "delete", icon: "delete", text: "Delete", hidden: true },
    { name: "cancel", icon: "not_interested", text: "Cancel", hidden: true },
    { name: "saveAs", icon: "save_as", text: "Save as draft", hidden: true },
    { name: "save", icon: "save", text: "Save as final", hidden: true },
    { name: "edit", icon: "edit", text: "Edit", hidden: true },
    { name: "delete", icon: "delete", text: "Delete", hidden: true },
    { name: "print", icon: "print", text: "Print", hidden: true },
  ];

  public navigate(item) {
    switch (item.name) {
      case "save": {
        this.saveFinalComposition();
        break;
      }
      case "saveAs": {
        this.saveDraftComposition();
        break;
      }
      case "cancel": {
        this.navItems[0].hidden = true;
        this.navItems[1].hidden = true;
        this.navItems[2].hidden = true;
        this.navItems[3].hidden = false;
        this.navItems[4].hidden = false;
        this.navItems[5].hidden = true;
        this.enterNote = false;
        this.clearForm();
        this.cbNoteSections === undefined
          ? this.clearForm()
          : this.cbNoteSections.deselectAllItems();
        this.setMandatory2();
        this.scrollToTop();
        this.checkRouting();
        this.checkCompositionStatus();
        this._appConfigService.changeCompositionMode("");
        break;
      }
      case "edit": {
        this.patientComponent.drawer.close();
        this.open();
        this.editNote();
        this._appConfigService.changeCompositionMode("edit");
        break;
      }
      case "delete": {
        this.deleteComposition();
        break;
      }
      case "print": {
        window.print();
        break;
      }
    }
  }

  constructor(
    public patientComponent: PatientComponent,
    private _iconService: IgxIconService,
    private _storeService: StoreService,
    private _cadConfirmationService: CadConfirmationService,
    private _appConfigService: AppConfigService,
    private _fhirPathService: FhirPathService,
    private _dischargeService: DischargeService,
    private _fb: FormBuilder,
    private _sanitizer: DomSanitizer,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fhirConfigService: FhirConfigService,
    private _personSearchService: PersonSearchService,
    public _composeService: ComposeService,
    private _router: Router
  ) {
    this.html = this._sanitizer.bypassSecurityTrustHtml(
      '<iframe src="./assets/ICD11.html" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen width="100%" height="300px"></iframe>'
    );

    // get status from consent resource
    this._dischargeService.consent$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((consent: fhir.r4.Consent) => {
        this.consentStatus = this._fhirPathService.evaluateToString(
          consent,
          "entry.resource.ofType(Consent).status.last()"
        );
      });

    // if consent rejected/inactive, include instCode in query
    if (this.consentStatus === "rejected" || this.consentStatus === "inactive") {
      this.status = true;
      this._dischargeService.getDischarges(0, 10, 1, true).subscribe({});
    } else {
      this.status = false;
      this._dischargeService.getDischarges(0, 10, 1, false).subscribe({});
    }
  }

  @HostBinding("class") get classList(): any {
    return {
      "compose-opened": this.opened,
    };
  }

  ngOnInit(): void {
    //close composition when click Medical Record

    if (this._appConfigService.getCompositionId() !== "") {
      this._composeService.getEventSubject().subscribe((param: any) => {
        this.closeToggle();
        this.navItems[0].hidden = true;
        this.navItems[1].hidden = true;
        this.navItems[2].hidden = true;
        this.navItems[3].hidden = true;
        this.navItems[4].hidden = true;
        this.navItems[5].hidden = true;
        this.enterNote = false;
        this.clearForm();
      });
    }

    for (let icon of this.allIcons) {
      this._iconService.addSvgIconFromText(icon.name, icon.value, "imx-icons");
    }

    this._storeService.diagnosisRole$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((diagnosisRole: fhir.r4.ValueSet) => {
        if (diagnosisRole !== null) {
          this.diagnosisRole = diagnosisRole.expansion.contains as ValueSet[];
        }
      });

    // ValueSet of problem categories
    this.problemCategories = [
      {
        system: "http://terminology.hl7.org/CodeSystem/condition-category",
        code: "problem-list-item",
        display: "Comorbid",
      },
      {
        system: "http://terminology.hl7.org/CodeSystem/condition-category",
        code: "encounter-diagnosis",
        display: "Encounter Diagnosis",
      },
    ];

    this.prepareForm();

    this._dischargeService.discharge$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((bundle: fhir.r4.Bundle) => {
        if (this.enterNote) {
          console.log("Console");
          this.compositionId = "";
          let compositionId = this._fhirPathService.evaluate(
            this.composition,
            "identifier.where(system ='http://fhir.hie.moh.gov.my/sid/composition-id')"
          );
          if (compositionId.length !== 0) {
            this.compositionId = compositionId[0].value;
          } else {
            this.compositionId = null;
          }
        } else {
          this.bundle = bundle;
          this.composition = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Composition)"
          )[0];

          this.compositionId = "";
          let compositionId = this._fhirPathService.evaluate(
            this.composition,
            "identifier.where(system ='http://fhir.hie.moh.gov.my/sid/composition-id')"
          );
          if (compositionId.length !== 0) {
            this.compositionId = compositionId[0].value;
          } else {
            this.compositionId = null;
          }

          this.conditions = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Condition)"
          );
          this.vitalSign = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Vital Signs')"
          );
          this.respiratoryRate = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Respiratory Rate')"
          );
          this.heartRate = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Heart Rate')"
          );
          this.oxygenSaturation = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Oxygen Saturation')"
          );
          this.bodyTemperature = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Body Temperature')"
          );
          this.systolicPressure = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Systolic Pressure')"
          );
          this.diastolicPressure = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Diastolic Pressure')"
          );
          this.painScore = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Observation).where(code.first().coding.first().display = 'Pain Score')"
          );
          this.encounter = this._fhirPathService.evaluate(
            this.bundle,
            "entry.resource.ofType(Encounter)"
          );

          //    this.checkEncounter(this._appConfigService.getEncounterId());

          if (
            this._appConfigService.getEncounterStatus() === "finished" ||
            this._appConfigService.getEncounterStatus() === "unknown"
          ) {
            // ValueSet of note categories
            this.noteCategories = this._addendumNotes.category as Category[];
          } else {
            this.noteCategories = this._notes.category as Category[];
          }

          // this._appConfigService.getCompositionMode() === "edit"
          //   ? (this.editMode = true)
          //   : (this.editMode = false);

          if (
            this._fhirPathService.evaluateToString(this.composition, "status") === "preliminary"
          ) {
            if (this.editMode) {
              this.navItems[0].hidden = false; //cancel button
              this.navItems[1].hidden = false; // save as draft
              this.navItems[2].hidden = false; // save final
              this.navItems[3].hidden = true; // edit button
              this.navItems[4].hidden = true; // delete button
              this.navItems[5].hidden = true; // print button
            } else {
              this.navItems[3].hidden = false; // edit button
              this.navItems[4].hidden = false; // delete button
              this.navItems[5].hidden = true; // print button
            }

            this.noteCategory = this._fhirPathService.evaluate(
              this.composition,
              "category.coding.first()"
            )[0];
            this.noteType = this._fhirPathService.evaluate(
              this.composition,
              "type.coding.first()"
            )[0];
            this.noteEvent = this._fhirPathService.evaluate(
              this.composition,
              "event.first().code.first().coding.first()"
            )[0];

            this.author = this._fhirPathService.evaluate(
              this.composition,
              "author.reference.first()"
            )[0];

            if (this.noteCategories === undefined) {
              this.navItems[3].hidden = true;
              this.navItems[4].hidden = true;
              this.navItems[5].hidden = true;
            } else {
              for (var i = 0; i < this.noteCategories.length; i++) {
                if (this.noteCategories[i].code === this.noteCategory.code) {
                  this.noteTypes = this.noteCategories[i].type;
                  for (var j = 0; j < this.noteTypes.length; j++) {
                    if (this.noteTypes[j].code === this.noteType.code) {
                      this.noteEvents = this.noteTypes[j].event;
                      for (var k = 0; k < this.noteEvents.length; k++) {
                        if (this.noteEvents[k].code === this.noteEvent.code) {
                          this.noteSections = this.noteEvents[k].section;
                        }
                      }
                    }
                  }
                }
              }
            }

            //         this.navigate({ name: "cancel", icon: "not_interested", text: "Cancel", hidden: false });

            this.navigate({
              name: "cancel",
              icon: "not_interested",
              text: "Cancel",
              hidden: false,
            });
            //Hide the edit and delete button when user is not match
            if (
              this.author.replace("PractitionerRole/", "") ===
              this._appConfigService.getPractitionerRoleId()
            ) {
              this.navItems[3].hidden = false; //edit
              this.navItems[4].hidden = false; //delete
              this.navItems[5].hidden = true; //print
            } else {
              this.navItems[3].hidden = true; //edit
              this.navItems[4].hidden = true; //delete
              this.navItems[5].hidden = true; //print
            }
          } else {
            //   this.editMode ? (this.navItems[0].hidden = false) : (this.navItems[0].hidden = true); //cancel;
            this.navItems[0].hidden = true; //save as draft
            this.navItems[1].hidden = true; //save as draft
            this.navItems[2].hidden = true; //save
            this.navItems[3].hidden = true; //edit
            this.navItems[4].hidden = true; //delete
            this.navItems[5].hidden = true; //print
            this.closeNote();
          }
        }
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  async checkEncounter(encounterId) {
    let g1 = new Promise((resolve, reject) => {
      this._dischargeService.getEncounter(encounterId).subscribe({
        next: (resp) => {
          this.encounter = null;
          this.encounter = [];
          this.encounter.push(resp);
          resolve(true);
        },
      });
    });
    await g1;

    if (this.encounter[0].status === "finished" || this.encounter[0].status === "unknown") {
      // ValueSet of note categories
      this.noteCategories = this._addendumNotes.category as Category[];
    } else {
      this.noteCategories = this._notes.category as Category[];
    }
  }

  checkCreation() {
    let practitioner = this._appConfigService.getPractitionerId();
    let practitionerRole = this._appConfigService.getPractitionerRoleId();
    let location = this._appConfigService.getLocationId();
    let encounter = this._appConfigService.getEncounterId();
    let encounterStatus = this._appConfigService.getEncounterStatus();

    if (practitioner === "null" || practitioner === undefined || practitioner === "undefined") {
      const confirmation = this._cadConfirmationService.openConfirm({
        title: "PRACTITIONER NOT FOUND",
        message: "Please create PRACTITIONER to create Composition Note",
        actions: {
          confirm: {
            label: "OK",
          },
        },
      });
    }

    if (
      practitionerRole === "null" ||
      practitionerRole === undefined ||
      practitionerRole === "undefined"
    ) {
      const confirmation = this._cadConfirmationService.openConfirm({
        title: "PRACTITIONER ROLE NOT FOUND",
        message: "Please create PRACTITIONER ROLE to create Composition Note",
        actions: {
          confirm: {
            label: "OK",
          },
        },
      });
    }

    if (location === "null" || location === undefined || location === "undefined") {
      const confirmation = this._cadConfirmationService.openConfirm({
        title: "LOCATION NOT FOUND",
        message: "Please set current LOCATION to create Composition Note",
        actions: {
          confirm: {
            label: "OK",
          },
        },
      });
    }

    if (encounter === "null" || encounter === undefined || encounter === "undefined") {
      const confirmation = this._cadConfirmationService.openConfirm({
        title: "PATIENT ENCOUNTER NOT FOUND",
        message: "Please create PATIENT'S ENCOUNTER to create Composition Note",
        actions: {
          confirm: {
            label: "OK",
          },
        },
      });
    }

    // if (encounterStatus === "arrived") {
    //   const confirmation = this._cadConfirmationService.openConfirm({
    //     title: "ENCOUNTER STATUS IS ARRIVED",
    //     message: "Encounter status need to be In-Progress to create Composition Note",
    //     actions: {
    //       confirm: {
    //         label: "OK",
    //       },
    //     },
    //   });
    // }

    if (
      practitioner !== "null" &&
      practitioner !== undefined &&
      practitioner !== "undefined" &&
      practitionerRole !== "null" &&
      practitionerRole !== undefined &&
      practitionerRole !== "undefined" &&
      location !== "null" &&
      location !== undefined &&
      location !== "undefined" &&
      encounter !== "null" &&
      encounter !== undefined &&
      encounter !== "undefined"
      // encounterStatus !== "arrived"
    ) {
      this.createNote();
    }
  }

  // create new note
  createNote() {
    this.cbNoteSections.deselectAllItems(true);
    this.compositionId = "NEW NOTE";
    this.editMode = false;
    this.enterNote = true;
    this.navItems[0].hidden = false;
    this.navItems[1].hidden = false;
    this.navItems[2].hidden = false;
    this.navItems[3].hidden = true;
    this.navItems[4].hidden = true;
    this.prepareForm();
    this.mandatorySection = null;
    this.setMandatory();
  }

  // edit existing note
  editNote() {
    this.prepareForm();

    this.editMode = true;
    this.enterNote = true;
    this.navItems[0].hidden = false;
    this.navItems[1].hidden = false;
    this.navItems[2].hidden = false;
    this.navItems[3].hidden = true;
    this.navItems[4].hidden = true;

    this.cbNoteSections.deselectAllItems(true);

    this.ctlClinicalInformation = false;
    this.ctlProblemList = false;
    this.ctlVitalSign = false;
    this.ctlAllergies = false;
    this.ctlMedication = false;
    this.ctlSocialHistory = false;
    this.ctlFamilyHistory = false;
    this.ctlImmunizationHistory = false;
    this.ctlAssessment = false;
    this.ctlCarePlan = false;

    this.vtsBodyTemperature = false;
    this.vtsDiastolicPressure = false;
    this.vtsHeartRate = false;
    this.vtsOxygenSaturation = false;
    this.vtsRespiratoryRate = false;
    this.vtsSystolicPressure = false;
    this.vtsPainScore = false;

    this.dntAlert = false;
    this.dntChart = false;

    this.adsEvent = false;

    this.proDesc = false;
    this.proFind = false;
    this.proComp = false;
    this.populateValues();
  }

  // reset everything once save edit
  closeNoteEdit() {
    this.cbNoteSections.deselectAllItems(true);
    this.navItems[0].hidden = true;
    this.navItems[1].hidden = true;
    this.navItems[2].hidden = true;
    this.navItems[3].hidden = false;
    this.navItems[4].hidden = true;
    this.enterNote = false;
    this.compositionForm.reset();
  }

  // reset everything once save
  closeNote() {
    this.cbNoteSections.deselectAllItems(true);
    this.navItems[0].hidden = true;
    this.navItems[1].hidden = true;
    this.navItems[2].hidden = true;
    this.navItems[3].hidden = true;
    this.navItems[4].hidden = true;
    this.enterNote = false;
    this.compositionForm.reset();
  }

  checkRouting() {
    let routerurl: string = this._router.url;
    if (routerurl !== null) {
      if (
        routerurl.includes("/apps/patient/dashboard") ||
        routerurl.includes("/apps/patient/problem") ||
        routerurl.includes("/apps/patient/medication") ||
        routerurl.includes("/apps/patient/diagnosis") ||
        routerurl.includes("/apps/patient/observation") ||
        routerurl.includes("/apps/patient/history") ||
        routerurl.includes("/apps/patient/family") ||
        routerurl.includes("/apps/patient/allergy") ||
        routerurl.includes("/apps/patient/procedure") ||
        routerurl.includes("/apps/patient/care") ||
        routerurl.includes("/apps/patient/transfusion") ||
        routerurl.includes("/apps/patient/appointment") ||
        routerurl.includes("/apps/patient/immunization") ||
        routerurl.includes("/apps/patient/donation") ||
        routerurl.includes("/apps/patient/blood")
      ) {
        if (this.editMode) {
          this._composeService.triggerCompositionPanel(false);
        } else {
          this._composeService.triggerCompositionPanel(true);
        }
      } else {
        this._composeService.triggerCompositionPanel(true);
      }
    }
  }

  checkCompositionStatus() {
    if (this.composition.status === "final") {
      this.navItems[0].hidden = true;
      this.navItems[1].hidden = true;
      this.navItems[2].hidden = true;
      this.navItems[3].hidden = true;
      this.navItems[4].hidden = true;
      this.navItems[5].hidden = true;
    }
  }

  // handle form creation
  prepareForm() {
    this.compositionForm = this._fb.group({
      clinicalInformationForm: [
        "",
        { validators: [this.isRequiredConditionalClinicalInformation], updateOn: "change" },
      ],
      problemList: this._fb.array([this.createProblemList()]),
      vitalSign: [""],
      allergiesForm: [
        "",
        { validators: [this.isRequiredConditionalAllergies], updateOn: "change" },
      ],
      medicationForm: [
        "",
        { validators: [this.isRequiredConditionalMedication], updateOn: "change" },
      ],
      socialHistoryForm: [
        "",
        { validators: [this.isRequiredConditionalSocialHistory], updateOn: "change" },
      ],
      familyHistoryForm: [
        "",
        { validators: [this.isRequiredConditionalFamilyHistory], updateOn: "change" },
      ],
      immunizationHistoryForm: [
        "",
        { validators: [this.isRequiredConditionalImmunizationHistory], updateOn: "change" },
      ],
      assessmentForm: [
        "",
        { validators: [this.isRequiredConditionalAssessment], updateOn: "change" },
      ],
      carePlanForm: ["", { validators: [this.isRequiredConditionalCarePlan], updateOn: "change" }],
      vtsRespiratoryRateForm: [
        "",
        {
          validators: [
            Validators.minLength(1),
            Validators.maxLength(3),
            Validators.min(1),
            Validators.max(100),
            CadValidators.number(),
          ],
          updateOn: "change",
        },
      ],
      vtsHeartRateForm: [
        "",
        {
          validators: [
            Validators.minLength(1),
            Validators.maxLength(3),
            Validators.min(1),
            Validators.max(250),
            CadValidators.number(),
          ],
          updateOn: "change",
        },
      ],
      vtsOxygenSaturationForm: [
        "",
        {
          validators: [
            Validators.minLength(1),
            Validators.maxLength(3),
            Validators.min(1),
            Validators.max(100),
            CadValidators.number(),
          ],
          updateOn: "change",
        },
      ],
      vtsBodyTemperatureForm: [
        "",
        {
          validators: [
            Validators.minLength(1),
            Validators.maxLength(5),
            Validators.min(1),
            Validators.max(100),
          ],
          updateOn: "change",
        },
      ],
      vtsSystolicPressureForm: [
        "",
        {
          validators: [
            Validators.minLength(1),
            Validators.maxLength(3),
            Validators.min(1),
            Validators.max(400),
            CadValidators.number(),
          ],
          updateOn: "change",
        },
      ],
      vtsDiastolicPressureForm: [
        "",
        {
          validators: [
            Validators.minLength(1),
            Validators.maxLength(3),
            Validators.min(1),
            Validators.max(200),
            CadValidators.number(),
          ],
          updateOn: "change",
        },
      ],
      vtsPainScoreForm: [
        "",
        {
          validators: [
            Validators.minLength(1),
            Validators.maxLength(2),
            Validators.min(0),
            Validators.max(10),
            CadValidators.number(),
          ],
          updateOn: "change",
        },
      ],
      dentistryAlertForm: [
        "",
        { validators: [this.isRequiredConditionalDentistryAlert], updateOn: "change" },
      ],
      dentistryChartForm: [
        "",
        { validators: [this.isRequiredConditionalDentistryChart], updateOn: "change" },
      ],
      adverseReactionForm: [
        "",
        { validators: [this.isRequiredConditionalAdverseEvent], updateOn: "change" },
      ],
      procedureDescriptionForm: [
        "",
        { validators: [this.isRequiredConditionalProDesc], updateOn: "change" },
      ],
      procedureFindingsForm: [
        "",
        { validators: [this.isRequiredConditionalProFind], updateOn: "change" },
      ],
      procedureComplicationsForm: [
        "",
        { validators: [this.isRequiredConditionalProComp], updateOn: "change" },
      ],
      eventDate: [
        "",
        { validators: [this.isRequiredConditionalEventDateTime], updateOn: "change" },
      ],
      eventTime: [
        new Date(),
        {
          validators: [Validators.required],
          updateOn: "change",
        },
      ],
    });
  }

  // handle problem list form array
  createProblemList() {
    return this._fb.group({
      conditionId: [""],
      conditionCode: [
        "",
        { validators: [this.isRequiredConditionalConditionCode], updateOn: "change" },
      ],
      conditionDescription: [
        "",
        { validators: [this.isRequiredConditionalConditionCode], updateOn: "change" },
      ],
      note: ["", { validators: [this.isRequiredConditionalConditionCode], updateOn: "change" }],
      role: ["", { validators: [this.isRequiredConditionalConditionCode], updateOn: "change" }],
      onsetDateTime: [""],
      abatementDateTime: [
        "",
        { validators: [this.isRequiredConditionalAbatementDateTime], updateOn: "change" },
      ],
    });
  }

  addProblem(): void {
    this.problemList = this.compositionForm.get("problemList") as FormArray;
    this.problemList.push(this.createProblemList());
  }

  removeProblemList(index) {
    let encounter = this.encounter[0];
    let condition = this.conditions;

    this.problemList = this.compositionForm.get("problemList") as FormArray;
    let formGroup = this.problemList.get([index]);
    let conditionId = formGroup.get("conditionId").value;
    if (conditionId !== "") {
      //find index of encounter and condition
      let indexEncounter = encounter.diagnosis.findIndex(
        (x) => x.condition.reference === "Condition/" + conditionId
      );
      let indexCondition = condition.findIndex((x) => x.id === conditionId);

      //remove list from array
      encounter.diagnosis.splice(indexEncounter, 1);
    }

    (<FormArray>this.compositionForm.get("problemList")).removeAt(index);
  }

  // handle diagnosis list form array
  createDiagnosisList() {
    return this._fb.group({
      conditionId: [""],
      conditionCode: [""],
      conditionDescription: [""],
      note: [""],
      role: [""],
      onsetDateTime: [""],
      abatementDateTime: [""],
    });
  }

  addDiagnosis(): void {
    this.diagnosisList = this.compositionForm.get("diagnosisList") as FormArray;
    this.diagnosisList.push(this.createDiagnosisList());
  }

  removeDiagnosisList(index) {
    (<FormArray>this.compositionForm.get("diagnosisList")).removeAt(index);
  }

  // select mandatory note section
  setMandatory() {
    this.compositionForm.get("eventDate").patchValue(this.dateString(new Date()));
    this.mandatorySection = [];
    this.noteSections.forEach((item) => {
      if (item.input === "mandatory") {
        this.mandatorySection.push(item);
      }
    });
    //  this.populateNoteSection(this.mandatorySection, []);
    this.mandatorySection.forEach((item) => {
      this.cbNoteSections.setSelectedItem(item, true);
    });
  }

  setMandatory2() {
    this.mandatorySection = [];
    if (this.noteSections !== undefined) {
      this.noteSections.forEach((item) => {
        if (item.input === "mandatory") {
          this.mandatorySection.push(item);
        }
      });
    }
    this.populateNoteSection([], this.mandatorySection);
    this.mandatorySection.forEach((item) => {
      if (this.cbNoteSections !== undefined) {
        this.cbNoteSections.setSelectedItem(item, true);
      }
    });
  }

  // populate composition values from existing data
  populateValues() {
    let sections = [];

    let eventDate = this._fhirPathService.evaluateToString(
      this.composition,
      "event.first().period.start"
    );

    if (eventDate === null || eventDate === undefined) {
      this.compositionForm.get("eventDate").patchValue(this.dateString(new Date()));
      this.compositionForm.get("eventTime").patchValue(this.dateString(new Date()));
    } else {
      this.compositionForm.get("eventDate").patchValue(eventDate);
      this.compositionForm.get("eventTime").patchValue(eventDate);
    }

    this.composition.section.forEach((item: fhir.r4.CompositionSection) => {
      switch (item.code.coding[0].code) {
        case "55752-0":
          this.ctlClinicalInformation = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("clinicalInformationForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "11450-4":
          this.ctlProblemList = true;
          sections.push(item.code.coding[0]);
          break;
        case "8716-3":
          this.ctlVitalSign = true;
          sections.push(item.code.coding[0]);
          this.compositionForm.get("vitalSign").patchValue(this.getContent(item.text.div));
          break;
        case "48765-2":
          this.ctlAllergies = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("allergiesForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "10160-0":
          this.ctlMedication = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("medicationForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "29762-2":
          this.ctlSocialHistory = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("socialHistoryForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "10157-6":
          this.ctlFamilyHistory = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("familyHistoryForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "11369-6":
          this.ctlImmunizationHistory = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("immunizationHistoryForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "51848-0":
          this.ctlAssessment = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("assessmentForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "18776-5":
          this.ctlCarePlan = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("carePlanForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "9279-1":
          this.vtsRespiratoryRate = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("vtsRespiratoryRateForm")
            .patchValue(this.getRespiratory(item.text.div));
          break;
        case "8867-4":
          this.vtsHeartRate = true;
          sections.push(item.code.coding[0]);
          this.compositionForm.get("vtsHeartRateForm").patchValue(this.getHeartRate(item.text.div));
          break;
        case "2708-6":
          this.vtsOxygenSaturation = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("vtsOxygenSaturationForm")
            .patchValue(this.getOxygenSaturation(item.text.div));
          break;
        case "8310-5":
          this.vtsBodyTemperature = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("vtsBodyTemperatureForm")
            .patchValue(this.getBodyTemperature(item.text.div));
          break;
        case "8480-6":
          this.vtsSystolicPressure = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("vtsSystolicPressureForm")
            .patchValue(this.getSystolicPressure(item.text.div));
          break;
        case "8462-4":
          this.vtsDiastolicPressure = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("vtsDiastolicPressureForm")
            .patchValue(this.getDiastolicPressure(item.text.div));
          break;
        case "72514-3":
          this.vtsPainScore = true;
          sections.push(item.code.coding[0]);
          this.compositionForm.get("vtsPainScoreForm").patchValue(this.getPainScore(item.text.div));
          break;
        case "83835-9":
          this.dntAlert = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("dentistryAlertForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "32885-6":
          this.dntChart = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("dentistryChartForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "85893-6":
          this.adsEvent = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("adverseReactionForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "29554-3":
          this.proDesc = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("procedureDescriptionForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "59776-5":
          this.proFind = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("procedureFindingsForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        case "55109-3":
          this.proFind = true;
          sections.push(item.code.coding[0]);
          this.compositionForm
            .get("procedureComplicationsForm")
            .patchValue(decodeURIComponent(this.getContent(item.text.div)));
          break;
        default:
          break;
      }
    });

    this.cbNoteSections.deselectAllItems();
    sections.forEach((item: Section) => {
      this.cbNoteSections.setSelectedItem(
        this.noteSections.find((x) => x.code === item.code),
        true
      );
    });
  }

  // populate condition values from existing data
  async populateCondition() {
    let problemCount = 0;

    if (!this.editMode) {
      if (this.enterNote) {
        let g1 = new Promise((resolve, reject) => {
          this._dischargeService.getEncounter(this._appConfigService.getEncounterId()).subscribe({
            next: (resp) => {
              this.encounter = null;
              this.encounter = [];
              this.encounter.push(resp);
              resolve(true);
            },
          });
        });

        await g1;

        let g2 = new Promise((resolve, reject) => {
          this._dischargeService.getObservation().subscribe({
            next: (obs) => {
              this.conditions = null;
              this.conditions = [];

              obs.entry.forEach((item) => {
                this.conditions.push(item);
              });
              resolve(true);

              this.encounter.forEach((encounters: any) => {
                this.conditions.forEach((item: any) => {
                  encounters.diagnosis.forEach((diagnosis: any) => {
                    let conditionId = diagnosis.condition.reference.replace("Condition/", "");
                    if (conditionId === item.resource.id) {
                      if (item.resource.category[0].coding[0].code !== null) {
                        this.problemList = this.compositionForm.get("problemList") as FormArray;
                        if (problemCount > 0) {
                          this.problemList.push(this.createProblemList());
                        }
                        let formGroup = this.problemList.get([problemCount]);
                        formGroup.get("conditionId").patchValue(item.resource.id);
                        formGroup
                          .get("conditionCode")
                          .patchValue(item.resource.code.coding[0].code);
                        formGroup
                          .get("conditionDescription")
                          .patchValue(item.resource.code.coding[0].display);
                        formGroup.get("note").patchValue(item.resource.code.text);
                        formGroup.get("onsetDateTime").patchValue(item.resource.onsetDateTime);
                        formGroup
                          .get("abatementDateTime")
                          .patchValue(item.resource.abatementDateTime);
                        formGroup.get("role").patchValue([item.resource.category[0].coding[0]]);
                        problemCount++;
                      }
                    }
                  });
                });
              });
            },
          });
        });

        await g2;
      } else {
        this.populateConditionEncounter();
      }
    } else {
      this.populateConditionEncounter();
    }
  }

  // populate condition values from existing data
  populateConditionEncounter() {
    let problemCount = 0;
    this.conditions.forEach((item: any) => {
      if (item.resource !== undefined) {
        if (item.resource.category[0].coding[0].code !== null) {
          this.problemList = this.compositionForm.get("problemList") as FormArray;
          if (problemCount > 0) {
            this.problemList.push(this.createProblemList());
          }
          let formGroup = this.problemList.get([problemCount]);
          formGroup.get("conditionId").patchValue(item.resource.id);
          formGroup.get("conditionCode").patchValue(item.resource.code.coding[0].code);
          formGroup.get("conditionDescription").patchValue(item.resource.code.coding[0].display);
          formGroup.get("note").patchValue(item.resource.code.text);
          formGroup.get("onsetDateTime").patchValue(item.resource.onsetDateTime);
          formGroup.get("abatementDateTime").patchValue(item.resource.abatementDateTime);
          formGroup.get("role").patchValue([item.resource.category[0].coding[0]]);
          problemCount++;
        }
      } else {
        if (item.category[0].coding[0].code !== null) {
          this.problemList = this.compositionForm.get("problemList") as FormArray;
          if (problemCount > 0) {
            this.problemList.push(this.createProblemList());
          }
          let formGroup = this.problemList.get([problemCount]);
          formGroup.get("conditionId").patchValue(item.id);
          formGroup.get("conditionCode").patchValue(item.code.coding[0].code);
          formGroup.get("conditionDescription").patchValue(item.code.coding[0].display);
          formGroup.get("note").patchValue(item.code.text);
          formGroup.get("onsetDateTime").patchValue(item.onsetDateTime);
          formGroup.get("abatementDateTime").patchValue(item.abatementDateTime);
          formGroup.get("role").patchValue([item.category[0].coding[0]]);
          problemCount++;
        }
      }
    });
  }

  // hide or display section based on user selection
  sectionSelection(e: IComboSelectionChangingEventArgs) {
    this.populateNoteSection(e.added, e.removed);
  }

  populateNoteSection(addItems: Section[], removedItems: Section[]) {
    removedItems.forEach((item) => {
      // keep mandatory note section selected
      setTimeout(() => {
        this.cbNoteSections.setSelectedItem(item, false);
      }, 0);

      switch (item.code) {
        case "55752-0":
          this.ctlClinicalInformation = false;

          this.clinicalInformationForm.updateValueAndValidity();
          break;
        case "11450-4":
          this.ctlProblemList = false;
          let problemCount = 0;
          if (this.compositionForm !== undefined) {
            this.problemList = this.compositionForm.get("problemList") as FormArray;
            let formGroup = this.problemList.get([problemCount]);
            formGroup.get("conditionId").updateValueAndValidity();
            formGroup.get("conditionCode").updateValueAndValidity();
            formGroup.get("conditionDescription").updateValueAndValidity();
            formGroup.get("note").updateValueAndValidity();
            formGroup.get("onsetDateTime").updateValueAndValidity();
            formGroup.get("abatementDateTime").updateValueAndValidity();
            formGroup.get("role").updateValueAndValidity();
            this.problemList.updateValueAndValidity();
          }

          break;
        case "8716-3":
          this.ctlVitalSign = false;
          this.vtsRespiratoryRate = false;
          this.vtsHeartRate = false;
          this.vtsOxygenSaturation = false;
          this.vtsBodyTemperature = false;
          this.vtsSystolicPressure = false;
          this.vtsDiastolicPressure = false;
          this.vtsPainScore = false;
          this.vtsRespiratoryRateForm.updateValueAndValidity();
          this.vtsHeartRateForm.updateValueAndValidity();
          this.vtsOxygenSaturationForm.updateValueAndValidity();
          this.vtsBodyTemperatureForm.updateValueAndValidity();
          this.vtsSystolicPressureForm.updateValueAndValidity();
          this.vtsDiastolicPressureForm.updateValueAndValidity();
          this.vtsPainScoreForm.updateValueAndValidity();
          break;
        case "48765-2":
          this.ctlAllergies = false;
          this.allergiesForm.updateValueAndValidity();
          break;
        case "10160-0":
          this.ctlMedication = false;
          this.medicationForm.updateValueAndValidity();
          break;
        case "29762-2":
          this.ctlSocialHistory = false;
          this.socialHistoryForm.updateValueAndValidity();
          break;
        case "10157-6":
          this.ctlFamilyHistory = false;
          this.familyHistoryForm.updateValueAndValidity();
          break;
        case "11369-6":
          this.ctlImmunizationHistory = false;
          this.immunizationHistoryForm.updateValueAndValidity();
          break;
        case "51848-0":
          this.ctlAssessment = false;
          this.assessmentForm.updateValueAndValidity();
          break;
        case "18776-5":
          this.ctlCarePlan = false;
          this.adverseReactionForm.removeValidators;
          if (this.carePlanForm !== undefined) {
            this.carePlanForm.updateValueAndValidity();
          }
          break;
        case "9279-1":
          this.vtsRespiratoryRate = false;
          break;
        case "8867-4":
          this.vtsHeartRate = false;
          break;
        case "2708-6":
          this.vtsOxygenSaturation = false;
          break;
        case "8310-5":
          this.vtsBodyTemperature = false;
          break;
        case "8480-6":
          this.vtsSystolicPressure = false;
          break;
        case "8462-4":
          this.vtsDiastolicPressure = false;
          break;
        case "83835-9":
          this.dntAlert = false;
          this.dentistryAlertForm.updateValueAndValidity();
          break;
        case "32885-6":
          this.dntChart = false;
          this.dentistryChartForm.updateValueAndValidity();
          break;
        case "85893-6":
          this.adsEvent = false;
          this.adverseReactionForm.updateValueAndValidity();
          this.adverseReactionForm.removeValidators;
          break;
        case "29554-3":
          this.proDesc = false;
          this.procedureDescriptionForm.updateValueAndValidity();
          break;
        case "59776-5":
          this.proFind = false;
          this.procedureFindingsForm.updateValueAndValidity();
          break;
        case "55109-3":
          this.proComp = false;
          this.procedureComplicationsForm.updateValueAndValidity();
          break;
      }
    });

    addItems.forEach((item) => {
      switch (item.code) {
        case "55752-0":
          this.ctlClinicalInformation = true;
          break;
        case "11450-4":
          this.ctlProblemList = true;
          this.populateCondition();
          break;
        case "8716-3":
          this.ctlVitalSign = true;
          this.vtsRespiratoryRate = true;
          this.vtsHeartRate = true;
          this.vtsOxygenSaturation = true;
          this.vtsBodyTemperature = true;
          this.vtsSystolicPressure = true;
          this.vtsDiastolicPressure = true;
          this.vtsPainScore = true;
          break;
        case "48765-2":
          this.ctlAllergies = true;
          break;
        case "10160-0":
          this.ctlMedication = true;
          break;
        case "29762-2":
          this.ctlSocialHistory = true;
          break;
        case "10157-6":
          this.ctlFamilyHistory = true;
          break;
        case "11369-6":
          this.ctlImmunizationHistory = true;
          break;
        case "51848-0":
          this.ctlAssessment = true;
          break;
        case "18776-5":
          this.ctlCarePlan = true;
          break;
        case "9279-1":
          this.vtsRespiratoryRate = true;
          break;
        case "8867-4":
          this.vtsHeartRate = true;
          break;
        case "2708-6":
          this.vtsOxygenSaturation = true;
          break;
        case "8310-5":
          this.vtsBodyTemperature = true;
          break;
        case "8480-6":
          this.vtsSystolicPressure = true;
          break;
        case "8462-4":
          this.vtsDiastolicPressure = true;
          break;
        case "83835-9":
          this.dntAlert = true;
          break;
        case "32885-6":
          this.dntChart = true;
          break;
        case "85893-6":
          this.adsEvent = true;
          break;
        case "29554-3":
          this.proDesc = true;
          break;
        case "59776-5":
          this.proFind = true;
          break;
        case "55109-3":
          this.proComp = true;
          break;
      }
    });
  }

  // generate composition bundle
  prepareCompositionBundle(operation) {
    let problemFormArray = <FormArray>this.compositionForm.get("problemList");
    let conditionReferences = [];
    let bundleEntries: fhir.r4.BundleEntry[] = [];
    let encounter = this.encounter[0];

    let date = new Date(this.compositionForm.get("eventDate").value);
    //Check if its edit Mode
    if (this.editMode) {
      let eventTime = this.compositionForm.get("eventTime").value.split(":");
      if (this.compositionForm.get("eventTime").touched) {
        this.performedDates = new Date(date);
        this.performedDates.setHours(+eventTime[0], +eventTime[1], +eventTime[2], 0);
      } else {
        let newTime = new Date(this.compositionForm.get("eventTime").value);
        this.performedDates = new Date(date);
        this.performedDates.setHours(
          newTime.getHours(),
          newTime.getMinutes(),
          newTime.getSeconds(),
          0
        );
      }
    } else {
      let eventTime = new Date(this.compositionForm.get("eventTime").value);
      if (this.compositionForm.get("eventTime").touched) {
        this.performedDates = new Date(date);
        this.performedDates.setHours(
          eventTime.getHours(),
          eventTime.getMinutes(),
          eventTime.getSeconds(),
          eventTime.getMilliseconds()
        );
      } else {
        let newTime = new Date(this.compositionForm.get("eventTime").value);
        this.performedDates = new Date(date);
        this.performedDates.setHours(
          newTime.getHours(),
          newTime.getMinutes(),
          newTime.getSeconds(),
          0
        );
      }
    }

    // Populate composition
    let composition = <fhir.r4.Composition>{
      resourceType: "Composition",
      meta: {
        source: "http://provider.hie.moh.gov.my",
        profile: ["http://fhir.hie.moh.gov.my/StructureDefinition/Composition-my-core"],
      },
      status: operation,
      type: {
        coding: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
            code: this.noteType.code,
            display: this.noteType.display,
          },
        ],
      },
      identifier: {
        system: "http://fhir.hie.moh.gov.my/sid/composition-id",
        value: this.compositionId !== null || this.compositionId !== "" ? this.compositionId : null,
      },
      event: [
        {
          code: [
            {
              coding: [
                {
                  system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
                  code: this.noteEvent.code,
                  display: this.noteEvent.display,
                },
              ],
            },
          ],
          period: {
            start:
              this.compositionForm.get("eventDate").value === null
                ? new Date()
                : this.dateString(this.performedDates),
            end: null,
          },
        },
      ],
      category: [
        {
          coding: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-category-my-core",
              code: this.noteCategory.code,
              display: this.noteCategory.display,
            },
          ],
        },
      ],
      subject: {
        reference: "Patient/" + this._appConfigService.getPatientResourceId(),
      },
      encounter: {
        reference: "Encounter/" + this._appConfigService.getEncounterId(),
      },
      date: this.editMode ? this.composition.date : this.dateString(new Date()),
      author: [
        {
          reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
        },
      ],
      title: this.noteCategory.display,
      confidentiality: "N",
      custodian: {
        reference: "Organization/" + this._appConfigService.getInstCode(),
        display: this._appConfigService.getInstName(),
      },
      extension: [
        {
          url: "http://fhir.hie.moh.gov.my/StructureDefinition/location-recorded-my-core",
          valueReference: {
            reference: "Location/" + this._appConfigService.getLocationId(),
          },
        },
      ],
    };

    if (this.compositionId === null || this.compositionId === undefined || this.compositionId === "NEW NOTE") {
      delete composition.identifier;
    }

    composition.section = [];

    if (this.ctlClinicalInformation) {
      composition.section.push({
        title: "Clinical Information",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "55752-0",
              display: "Clinical Information",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("clinicalInformationForm").value) +
            "</div>",
        },
      });
    }

    if (this.ctlProblemList) {
      // Create condition reference
      for (let i: number = 0; i < problemFormArray.length; i++) {
        let formGroup = problemFormArray.get([i]);

        //update Encounter entry
        let encounterProblemList = <any>{
          condition: {
            reference:
              formGroup.get("conditionId").value === ""
                ? "Condition/BP" + i
                : "Condition/" + formGroup.get("conditionId").value,
          },
          use: {
            coding: formGroup.get("role").value,
          },
        };

        if (formGroup.get("conditionId").value === "") {
          if (encounter.diagnosis !== undefined) {
            encounter.diagnosis.push(encounterProblemList);
          } else {
            let diagnosis = [];
            diagnosis.push(encounterProblemList);
            encounter.diagnosis = diagnosis;
          }
        } else {
          console.log(formGroup.get("conditionId").value);
          // this.conditions.forEach((item) => {
          //   if (encounter.diagnosis !== undefined) {
          //     let index = encounter.diagnosis.findIndex(
          //       (x) => x.condition.reference === "Condition/" + item.id
          //     );
          //     if(index === -1){
          //       console.log(encounterProblemList);
          //     } else {
          //       encounter.diagnosis[index] = encounterProblemList;
          //     }
          //   } else {
          // let diagnosis = [];
          // diagnosis.push(encounterProblemList);
          // if (encounter.diagnosis !== undefined) {
          //   encounter.diagnosis.push(encounterProblemList);
          // } else {
          //   encounter.diagnosis = diagnosis;
          // }
          //   }
          // });
        }

        conditionReferences.push({
          reference:
            formGroup.get("conditionId").value === ""
              ? "Condition/BP" + i
              : "Condition/" + formGroup.get("conditionId").value,
        });
      }

      bundleEntries.push({
        fullUrl: this._fhirConfigService.getFhirService() + "/Encounter/" + this.encounter[0].id,
        request: {
          method: "PUT",
          url: "Encounter/" + this.encounter[0].id,
        },
        resource: encounter,
      });

      composition.section.push({
        title: "Problem list",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "11450-4",
              display: "Problem List",
            },
          ],
        },
        orderedBy: {
          coding: [
            {
              system: "http://terminology.hl7.org/CodeSystem/list-order",
              code: "category",
              display: "Category",
            },
          ],
        },
        entry: conditionReferences,
      });
    }

    if (this.ctlVitalSign) {
      this.uuidVs = uuidv4();
      composition.section.push({
        title: "Vital Sign",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8716-3",
              display: "Vital Sign",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>Value: " +
            this.compositionForm.get("vitalSign").value +
            "</div>",
        },
        entry: [
          {
            reference: this.editMode
              ? "Observation/" + this.vitalSign[0].id
              : "Observation/" + this.uuidVs,
          },
        ],
      });
    }

    if (this.compositionForm.get("vtsRespiratoryRateForm").value !== "") {
      composition.section.push({
        title: "Respiratory Rate",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "9279-1",
              display: "Respiratory Rate",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml' >Respiratory Rate: " +
            this.compositionForm.get("vtsRespiratoryRateForm").value +
            "/min" +
            "</div>",
        },
      });
    }

    if (this.compositionForm.get("vtsHeartRateForm").value !== "") {
      composition.section.push({
        title: "Heart Rate",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8867-4",
              display: "Heart Rate",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>Heart Rate: " +
            this.compositionForm.get("vtsHeartRateForm").value +
            "/min" +
            "</div>",
        },
      });
    }

    if (this.compositionForm.get("vtsOxygenSaturationForm").value !== "") {
      composition.section.push({
        title: "Oxygen saturation",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "2708-6",
              display: "Oxygen Saturation",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>Oxygen Saturation: " +
            this.compositionForm.get("vtsOxygenSaturationForm").value +
            "%" +
            "</div>",
        },
      });
    }

    if (this.compositionForm.get("vtsBodyTemperatureForm").value !== "") {
      composition.section.push({
        title: "Body Temperature",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8310-5",
              display: "Body Temperature",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>Body Temperature: " +
            this.compositionForm.get("vtsBodyTemperatureForm").value +
            "'C" +
            "</div>",
        },
      });
    }

    if (this.compositionForm.get("vtsSystolicPressureForm").value !== "") {
      composition.section.push({
        title: "Systolic Pressure",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8480-6",
              display: "Systolic Pressure",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>Systolic Pressure: " +
            this.compositionForm.get("vtsSystolicPressureForm").value +
            "mmHg" +
            "</div>",
        },
      });
    }

    if (this.compositionForm.get("vtsDiastolicPressureForm").value !== "") {
      composition.section.push({
        title: "Diastolic Pressure",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8462-4",
              display: "Diastolic Pressure",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>Diastolic Pressure: " +
            this.compositionForm.get("vtsDiastolicPressureForm").value +
            "mmHg" +
            "</div>",
        },
      });
    }

    if (this.compositionForm.get("vtsPainScoreForm").value !== "") {
      composition.section.push({
        title: "Pain Score",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "72514-3",
              display: "Pain Score",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>Pain Score: " +
            this.compositionForm.get("vtsPainScoreForm").value +
            "</div>",
        },
      });
    }

    if (this.ctlAllergies) {
      composition.section.push({
        title: "Allergies And Intolerance",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "48765-2",
              display: "Allergies And Intolerance",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("allergiesForm").value) +
            "</div>",
        },
      });
    }

    if (this.ctlMedication) {
      composition.section.push({
        title: "Medication",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "10160-0",
              display: "Medication",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("medicationForm").value) +
            "</div>",
        },
      });
    }

    if (this.ctlSocialHistory) {
      composition.section.push({
        title: "Social History",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "29762-2",
              display: "Social History",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("socialHistoryForm").value) +
            "</div>",
        },
      });
    }

    if (this.ctlFamilyHistory) {
      composition.section.push({
        title: "Family History",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "10157-6",
              display: "Family History",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("familyHistoryForm").value) +
            "</div>",
        },
      });
    }

    if (this.ctlImmunizationHistory) {
      composition.section.push({
        title: "Immunization History",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "11369-6",
              display: "Immunization History",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("immunizationHistoryForm").value) +
            "</div>",
        },
      });
    }

    if (this.ctlAssessment) {
      composition.section.push({
        title: "Assessment",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "51848-0",
              display: "Assessment",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("assessmentForm").value) +
            "</div>",
        },
      });
    }

    if (this.ctlCarePlan) {
      composition.section.push({
        title: "Care Plan",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "18776-5",
              display: "Care Plan",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("carePlanForm").value) +
            "</div>",
        },
      });
    }

    if (this.dntAlert) {
      composition.section.push({
        title: "Dentistry Alert",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "83835-9",
              display: "Dentistry Alert",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("dentistryAlertForm").value) +
            "</div>",
        },
      });
    }

    if (this.dntChart) {
      composition.section.push({
        title: "Dentistry Chart",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "32885-6",
              display: "Dentistry Chart",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("dentistryChartForm").value) +
            "</div>",
        },
      });
    }

    if (this.adsEvent) {
      composition.section.push({
        title: "Adverse Event",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "85893-6",
              display: "Adverse Event",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("adverseReactionForm").value) +
            "</div>",
        },
      });
    }

    if (this.proDesc) {
      composition.section.push({
        title: "Procedure Description",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "29554-3",
              display: "Procedure Description",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("procedureDescriptionForm").value) +
            "</div>",
        },
      });
    }

    if (this.proFind) {
      composition.section.push({
        title: "Procedure Findings",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "59776-5",
              display: "Procedure Findings",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("procedureFindingsForm").value) +
            "</div>",
        },
      });
    }

    if (this.proComp) {
      composition.section.push({
        title: "Complications",
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "55109-3",
              display: "Complications",
            },
          ],
        },
        text: {
          status: "generated",
          div:
            "<div xmlns='http://www.w3.org/1999/xhtml'>" +
            encodeURIComponent(this.compositionForm.get("procedureComplicationsForm").value) +
            "</div>",
        },
      });
    }

    // Add composition to bundle entry
    bundleEntries.push({
      fullUrl: this.editMode
        ? this._fhirConfigService.getFhirService() + "/Composition/" + this.composition.id
        : "A0",
      request: {
        method: this.editMode ? "PUT" : "POST",
        url: this.editMode ? "Composition/" + this.composition.id : "Composition",
      },
      resource: composition,
    });

    // Generate condition
    if (this.ctlProblemList) {
      for (let i: number = 0; i < problemFormArray.length; i++) {
        let formGroup = problemFormArray.get([i]);

        //     if (formGroup.get("conditionId").value !== "") {
        let condition: fhir.r4.Condition = {
          resourceType: "Condition",
          meta: {
            profile: ["http://fhir.hie.moh.gov.my/StructureDefinition/Condition-my-core"],
          },
          category: [
            {
              coding: formGroup.get("role").value,
            },
          ],
          code: {
            coding: [
              {
                system: "http://hl7.org/fhir/sid/icd-11",
                code: formGroup.get("conditionCode").value,
                display: formGroup.get("conditionDescription").value,
              },
            ],
            text: formGroup.get("note").value,
          },
          subject: {
            reference: "Patient/" + this._appConfigService.getPatientResourceId(),
          },
          encounter: {
            reference: "Encounter/" + this._appConfigService.getEncounterId(),
          },
          onsetDateTime: formGroup.get("onsetDateTime").value,
          abatementDateTime: formGroup.get("abatementDateTime").value,
          clinicalStatus: {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/condition-clinical",
                code:
                  formGroup.get("abatementDateTime").value !== "" &&
                  formGroup.get("abatementDateTime").value !== undefined
                    ? "resolved"
                    : "active",
                display:
                  formGroup.get("abatementDateTime").value !== "" &&
                  formGroup.get("abatementDateTime").value !== undefined
                    ? "resolved"
                    : "active",
              },
            ],
          },
          recordedDate: this.dateString(new Date()),
          recorder: {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        };
        // Add condition to bundle entry
        bundleEntries.push({
          fullUrl:
            formGroup.get("conditionId").value === ""
              ? "BP" + i
              : this._fhirConfigService.getFhirService() +
                "/Condition/" +
                formGroup.get("conditionId").value,
          request: {
            method: formGroup.get("conditionId").value === "" ? "POST" : "PUT",
            url:
              formGroup.get("conditionId").value === ""
                ? "Condition"
                : "Condition/" + formGroup.get("conditionId").value,
          },
          resource: condition,
        });
        //       }
      }
    }

    // Populate RespiratoryRate
    if (this.compositionForm.get("vtsRespiratoryRateForm").value !== "") {
      this.uuidRr = uuidv4();
      let condition = {
        resourceType: "Observation",
        meta: {
          profile: [
            "http://fhir.hie.moh.gov.my/StructureDefinition/Observation-respi-rate-my-core",
          ],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "9279-1",
              display: "Respiratory Rate",
            },
          ],
        },
        valueQuantity: {
          value: isNaN(this.compositionForm.get("vtsRespiratoryRateForm").value)
            ? this.compositionForm.get("vtsRespiratoryRateForm").value
            : +this.compositionForm.get("vtsRespiratoryRateForm").value,
          unit: "/min",
          system: "http://unitsofmeasure.org",
          code: "/min",
        },
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "Respiratory Rate",
          },
        ],
        effectiveDateTime: new Date(),
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode
          ? this.respiratoryRate[0] !== undefined
            ? "Observation/" + this.respiratoryRate[0].id
            : "urn:uuid:" + this.uuidRr
          : "urn:uuid:" + this.uuidRr,
        request: {
          method: this.editMode ? (this.respiratoryRate[0] !== undefined ? "PUT" : "POST") : "POST",
          url: this.editMode
            ? this.respiratoryRate[0] !== undefined
              ? "Observation/" + this.respiratoryRate[0].id
              : "Observation"
            : "Observation",
        },
        resource: condition,
      });
    }

    // Populate Heart Rate
    if (this.compositionForm.get("vtsHeartRateForm").value !== "") {
      this.uuidHr = uuidv4();
      let condition = {
        resourceType: "Observation",
        meta: {
          profile: [
            "http://fhir.hie.moh.gov.my/StructureDefinition/Observation-heart-rate-my-core",
          ],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8867-4",
              display: "Heart Rate",
            },
          ],
        },
        valueQuantity: {
          value: isNaN(this.compositionForm.get("vtsHeartRateForm").value)
            ? this.compositionForm.get("vtsHeartRateForm").value
            : +this.compositionForm.get("vtsHeartRateForm").value,
          unit: "/min",
          system: "http://unitsofmeasure.org",
          code: "/min",
        },
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "Heart Rate",
          },
        ],
        effectiveDateTime: new Date(),
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode
          ? this.heartRate[0] !== undefined
            ? "Observation/" + this.heartRate[0].id
            : "urn:uuid:" + this.uuidHr
          : "urn:uuid:" + this.uuidHr,
        request: {
          method: this.editMode ? (this.heartRate[0] !== undefined ? "PUT" : "POST") : "POST",
          url: this.editMode
            ? this.heartRate[0] !== undefined
              ? "Observation/" + this.heartRate[0].id
              : "Observation"
            : "Observation",
        },
        resource: condition,
      });
    }

    // Populate 0xygen
    if (this.vtsOxygenSaturation) {
      this.uuidOs = uuidv4();
      let condition = {
        resourceType: "Observation",
        meta: {
          profile: [
            "http://fhir.hie.moh.gov.my/StructureDefinition/Observation-oxygen-sat-my-core",
          ],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "59408-5",
              display: "Oxygen Saturation",
            },
          ],
        },
        valueQuantity: {
          value: isNaN(this.compositionForm.get("vtsOxygenSaturationForm").value)
            ? this.compositionForm.get("vtsOxygenSaturationForm").value
            : +this.compositionForm.get("vtsOxygenSaturationForm").value,
          unit: "%",
          system: "http://unitsofmeasure.org",
          code: "%",
        },
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "Oxygen Saturation",
          },
        ],
        effectiveDateTime: new Date(),
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode
          ? this.oxygenSaturation[0] !== undefined
            ? "Observation/" + this.oxygenSaturation[0].id
            : "urn:uuid:" + this.uuidOs
          : "urn:uuid:" + this.uuidOs,
        request: {
          method: this.editMode
            ? this.oxygenSaturation[0] !== undefined
              ? "PUT"
              : "POST"
            : "POST",
          url: this.editMode
            ? this.oxygenSaturation[0] !== undefined
              ? "Observation/" + this.oxygenSaturation[0].id
              : "Observation"
            : "Observation",
        },
        resource: condition,
      });
    }

    // Populate Body Temp
    if (this.vtsBodyTemperature) {
      this.uuidBt = uuidv4();
      let condition = {
        resourceType: "Observation",
        meta: {
          profile: ["http://fhir.hie.moh.gov.my/StructureDefinition/Observation-body-temp-my-core"],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8310-5",
              display: "Body temperature",
            },
          ],
        },
        valueQuantity: {
          value: isNaN(this.compositionForm.get("vtsBodyTemperatureForm").value)
            ? this.compositionForm.get("vtsBodyTemperatureForm").value
            : +this.compositionForm.get("vtsBodyTemperatureForm").value,
          unit: "C",
          system: "http://unitsofmeasure.org",
          code: "C",
        },
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "Body Temperature",
          },
        ],
        effectiveDateTime: new Date(),
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode
          ? this.bodyTemperature[0] !== undefined
            ? "Observation/" + this.bodyTemperature[0].id
            : "urn:uuid:" + this.uuidBt
          : "urn:uuid:" + this.uuidBt,
        request: {
          method: this.editMode ? (this.bodyTemperature[0] !== undefined ? "PUT" : "POST") : "POST",
          url: this.editMode
            ? this.bodyTemperature[0] !== undefined
              ? "Observation/" + this.bodyTemperature[0].id
              : "Observation"
            : "Observation",
        },
        resource: condition,
      });
    }

    // Populate Systolic
    if (this.vtsSystolicPressure) {
      this.uuidSp = uuidv4();
      let condition = {
        resourceType: "Observation",
        meta: {
          profile: [
            "http://fhir.hie.moh.gov.my/StructureDefinition/Observation-systolic-bp-my-core",
          ],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8480-6",
              display: "Systolic blood pressure",
            },
          ],
        },
        valueQuantity: {
          value: isNaN(this.compositionForm.get("vtsSystolicPressureForm").value)
            ? this.compositionForm.get("vtsSystolicPressureForm").value
            : +this.compositionForm.get("vtsSystolicPressureForm").value,
          unit: "mmHg",
          system: "http://unitsofmeasure.org",
          code: "mmHg",
        },
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "Systolic Pressure",
          },
        ],
        effectiveDateTime: new Date(),
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode
          ? this.systolicPressure[0] !== undefined
            ? "Observation/" + this.systolicPressure[0].id
            : "urn:uuid:" + this.uuidSp
          : "urn:uuid:" + this.uuidSp,
        request: {
          method: this.editMode
            ? this.systolicPressure[0] !== undefined
              ? "PUT"
              : "POST"
            : "POST",
          url: this.editMode
            ? this.systolicPressure[0] !== undefined
              ? "Observation/" + this.systolicPressure[0].id
              : "Observation"
            : "Observation",
        },
        resource: condition,
      });
    }

    // Populate Diastolic
    if (this.vtsDiastolicPressure) {
      this.uuidDp = uuidv4();
      let condition = {
        resourceType: "Observation",
        meta: {
          profile: [
            "http://fhir.hie.moh.gov.my/StructureDefinition/Observation-diastolic-bp-my-core",
          ],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8462-4",
              display: "Diastolic blood pressure",
            },
          ],
        },
        valueQuantity: {
          value: isNaN(this.compositionForm.get("vtsDiastolicPressureForm").value)
            ? this.compositionForm.get("vtsDiastolicPressureForm").value
            : +this.compositionForm.get("vtsDiastolicPressureForm").value,
          unit: "mmHg",
          system: "http://unitsofmeasure.org",
          code: "mmHg",
        },
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "Diastolic Blood Pressure",
          },
        ],
        effectiveDateTime: new Date(),
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode
          ? this.systolicPressure[0] !== undefined
            ? "Observation/" + this.diastolicPressure[0].id
            : "urn:uuid:" + this.uuidDp
          : "urn:uuid:" + this.uuidDp,
        request: {
          method: this.editMode
            ? this.diastolicPressure[0] !== undefined
              ? "PUT"
              : "POST"
            : "POST",
          url: this.editMode
            ? this.diastolicPressure[0] !== undefined
              ? "Observation/" + this.diastolicPressure[0].id
              : "Observation"
            : "Observation",
        },
        resource: condition,
      });
    }

    // Populate Pain Score
    if (this.vtsPainScore) {
      this.uuidPs = uuidv4();
      let condition = {
        resourceType: "Observation",
        meta: {
          profile: [
            "http://fhir.hie.moh.gov.my/StructureDefinition/Observation-pain-score-my-core",
          ],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "72514-3",
              display: "Pain Score",
            },
          ],
        },
        valueQuantity: {
          value: isNaN(this.compositionForm.get("vtsPainScoreForm").value)
            ? this.compositionForm.get("vtsPainScoreForm").value
            : +this.compositionForm.get("vtsPainScoreForm").value,
        },
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "Pain Score",
          },
        ],
        effectiveDateTime: new Date(),
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode
          ? this.painScore[0] !== undefined
            ? "Observation/" + this.painScore[0].id
            : "urn:uuid:" + this.uuidPs
          : "urn:uuid:" + this.uuidPs,
        request: {
          method: this.editMode ? (this.painScore[0] !== undefined ? "PUT" : "POST") : "POST",
          url: this.editMode
            ? this.painScore[0] !== undefined
              ? "Observation/" + this.painScore[0].id
              : "Observation"
            : "Observation",
        },
        resource: condition,
      });
    }

    // Populate condition
    if (this.ctlVitalSign) {
      let hasMember = [];
      if (this.compositionForm.get("vtsRespiratoryRateForm").value !== "") {
        let HasMemberReference = {
          reference: this.editMode
            ? this.respiratoryRate[0] !== undefined
              ? "Observation/" + this.respiratoryRate[0].id
              : "Observation/" + "urn:uuid:" + this.uuidRr
            : "Observation/" + "urn:uuid:" + this.uuidRr,
        };
        hasMember.push(HasMemberReference);
      }
      if (this.compositionForm.get("vtsHeartRateForm").value !== "") {
        let HasMemberReference = {
          reference: this.editMode
            ? this.heartRate[0] !== undefined
              ? "Observation/" + this.heartRate[0].id
              : "Observation/" + "urn:uuid:" + this.uuidHr
            : "Observation/" + "urn:uuid:" + this.uuidHr,
        };
        hasMember.push(HasMemberReference);
      }
      if (this.compositionForm.get("vtsOxygenSaturationForm").value !== "") {
        let HasMemberReference = {
          reference: this.editMode
            ? this.oxygenSaturation[0] !== undefined
              ? "Observation/" + this.oxygenSaturation[0].id
              : "Observation/" + "urn:uuid:" + this.uuidOs
            : "Observation/" + "urn:uuid:" + this.uuidOs,
        };
        hasMember.push(HasMemberReference);
      }
      if (this.compositionForm.get("vtsBodyTemperatureForm").value !== "") {
        let HasMemberReference = {
          reference: this.editMode
            ? this.bodyTemperature[0] !== undefined
              ? "Observation/" + this.bodyTemperature[0].id
              : "Observation/" + "urn:uuid:" + this.uuidBt
            : "Observation/" + "urn:uuid:" + this.uuidBt,
        };
        hasMember.push(HasMemberReference);
      }
      if (this.compositionForm.get("vtsSystolicPressureForm").value !== "") {
        let HasMemberReference = {
          reference: this.editMode
            ? this.systolicPressure[0] !== undefined
              ? "Observation/" + this.systolicPressure[0].id
              : "Observation/" + "urn:uuid:" + this.uuidSp
            : "Observation/" + "urn:uuid:" + this.uuidSp,
        };
        hasMember.push(HasMemberReference);
      }
      if (this.compositionForm.get("vtsDiastolicPressureForm").value !== "") {
        let HasMemberReference = {
          reference: this.editMode
            ? this.diastolicPressure[0] !== undefined
              ? "Observation/" + this.diastolicPressure[0].id
              : "Observation/" + "urn:uuid:" + this.uuidDp
            : "Observation/" + "urn:uuid:" + this.uuidDp,
        };
        hasMember.push(HasMemberReference);
      }
      if (this.compositionForm.get("vtsPainScoreForm").value !== "") {
        let HasMemberReference = {
          reference: this.editMode
            ? this.painScore[0] !== undefined
              ? "Observation/" + this.painScore[0].id
              : "Observation/" + "urn:uuid:" + this.uuidPs
            : "Observation/" + "urn:uuid:" + this.uuidPs,
        };
        hasMember.push(HasMemberReference);
      }

      let condition = {
        resourceType: "Observation",
        meta: {
          profile: ["http://fhir.hie.moh.gov.my/StructureDefinition/Observation-panel-my-core"],
        },
        category: [
          {
            coding: [
              {
                system: "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-11",
              code: "85353-1",
              display: "Vital Signs",
            },
          ],
        },
        effectiveDateTime: new Date(),
        subject: {
          reference: "Patient/" + this._appConfigService.getPatientResourceId(),
        },
        encounter: {
          reference: "Encounter/" + this._appConfigService.getEncounterId(),
        },
        status: "final",
        note: [
          {
            text: "vitalSign",
          },
        ],
        hasMember: hasMember,
        performer: [
          {
            reference: "PractitionerRole/" + this._appConfigService.getPractitionerRoleId(),
          },
        ],
      };
      // Add condition to bundle entry
      bundleEntries.push({
        fullUrl: this.editMode ? this.vitalSign[0].id : this.uuidVs,
        request: {
          method: this.editMode ? "PUT" : "POST",
          url: this.editMode ? "Observation/" + this.vitalSign[0].id : "Observation",
        },
        resource: condition,
      });
    }

    // Create bundle
    let bundle = <fhir.r4.Bundle>{
      resourceType: "Bundle",
      type: "transaction",
      entry: bundleEntries,
    };
    return bundle;
  }

  // save as draft
  saveDraftComposition() {
    if (this.compositionForm.valid) {
      this._dischargeService
        .createComposition(this.prepareCompositionBundle("preliminary"))
        .subscribe({
          next: (data: any) => {
            let id = data.entry[0].response.location
              .replace("Composition/", "")
              .replace("/_history/1", "");
            this._appConfigService.changeCompositionMode("");
            //      this._appConfigService.changeCompositionId("");
            this._dischargeService.getDischarges(0, 100, 0, this.status).subscribe();
            this._dischargeService
              .getDischargeById(this.editMode ? this.composition.id : id)
              .subscribe();

            this.snackbar.open(
              this.editMode
                ? "The resource has been updated"
                : "The resource has been created as draft"
            );
            this.closeNoteEdit();
          },
          error: (error) => {
            this.snackbar.open(
              this._fhirPathService.evaluateToString(
                error.error as fhir.r4.OperationOutcome,
                "issue.where(severity='error').diagnostics.first()"
              )
            );
          },
        });
    } else {
      for (const key of Object.keys(this.compositionForm.controls)) {
        this.compositionForm.controls[key].markAsTouched({ onlySelf: true });
        this.compositionForm.controls[key].updateValueAndValidity({ onlySelf: true });
      }
      let pl: FormArray = this.compositionForm.get("problemList") as FormArray;
      for (const key1 of Object.keys(pl.controls)) {
        for (const key2 of Object.keys(pl.controls[key1].controls)) {
          pl.controls[key1].controls[key2].markAsTouched({ onlySelf: true });
          pl.controls[key1].controls[key2].updateValueAndValidity({ onlySelf: true });
        }
      }

      this.snackbar.open("Please fill in all the required fields");
    }
  }

  // save composition as final
  saveFinalComposition() {
    const confirmation = this._cadConfirmationService.open({
      title: "SAVE AS FINAL",
      message:
        "Do you want to save as final? <br> This composition will not be able to edit or delete.",
      actions: {
        confirm: {
          label: "Yes",
        },
      },
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        if (this.compositionForm.valid) {
          this._dischargeService
            .createComposition(this.prepareCompositionBundle("final"))
            .subscribe({
              next: (data: any) => {
                this._appConfigService.changeCompositionMode("");
                this._appConfigService.changeCompositionId("");
                this._dischargeService
                  .getDischargeById(this.editMode ? this.composition.id : data.id)
                  .subscribe();
                this._dischargeService.getDischarges(0, 100, 0, this.status).subscribe();
                this.snackbar.open("The resource has been updated");

                this._dischargeService.selectedDischargeChanged.next(
                  this.editMode ? this.composition : data
                );
                this.closeNote();
              },
              error: (error) => {
                this.snackbar.open(
                  this._fhirPathService.evaluateToString(
                    error.error as fhir.r4.OperationOutcome,
                    "issue.where(severity='error').diagnostics.first()"
                  )
                );
              },
            });
        } else {
          for (const key of Object.keys(this.compositionForm.controls)) {
            this.compositionForm.controls[key].markAsTouched({ onlySelf: true });
            this.compositionForm.controls[key].updateValueAndValidity({ onlySelf: true });
          }
          let pl: FormArray = this.compositionForm.get("problemList") as FormArray;
          for (const key1 of Object.keys(pl.controls)) {
            for (const key2 of Object.keys(pl.controls[key1].controls)) {
              pl.controls[key1].controls[key2].markAsTouched({ onlySelf: true });
              pl.controls[key1].controls[key2].updateValueAndValidity({ onlySelf: true });
            }
          }

          this.snackbar.open("Please fill in all the required fields");
        }
      }
    });
  }

  deleteComposition() {
    const confirmation = this._cadConfirmationService.open({
      title: "DELETE COMPOSITION",
      message: "Are you want to delete this composition?",
      actions: {
        confirm: {
          label: "Yes",
        },
      },
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        this.composition.status = "entered-in-error";
        this._dischargeService.deleteComposition(this.composition.id, this.composition).subscribe({
          next: () => {
            this._dischargeService.getDischarges(0, 100, 0, this.status).subscribe();
            this.snackbar.open("The resource has been deleted");
            this.closeNote();
          },
          error: (error) => {
            this.snackbar.open(
              this._fhirPathService.evaluateToString(
                error.error as fhir.r4.OperationOutcome,
                "issue.where(severity='error').diagnostics.first()"
              )
            );
          },
        });
      }
    });
  }

  // populate note combobox
  singleSelection(e: IComboSelectionChangingEventArgs, controlName) {
    if (e.newSelection.length > 1) {
      e.newSelection = e.added;
    }

    if (controlName === "noteCategories") {
      if (e.newSelection.length !== 0) {
        this.noteCategory = e.newSelection[0];
        // populate note types combo
        this.noteTypes = this.noteCategory.type;
        if (this.noteTypes.length === 1) {
          this.cbNotesTypeDisable = true;
        } else {
          this.cbNotesTypeDisable = false;
        }
        // Select type first item
        this.cbNoteTypes.setSelectedItem(this.noteTypes[0], true);
      }
      // remove note types selection when category deselect
      if (e.newSelection.length === 0) {
        this.cbNoteTypes.deselectAllItems(true);
      }
    }

    if (controlName === "noteTypes") {
      if (e.newSelection.length !== 0) {
        this.noteType = e.newSelection[0];
        // populate of note events combo
        this.noteEvents = this.noteType.event;
        if (this.noteEvents.length === 1) {
          this.cbNoteEventsDisable = true;
        } else {
          this.cbNoteEventsDisable = false;
        }
        // Select event first item
        this.cbNoteEvents.setSelectedItem(this.noteEvents[0], true);
      }
      // remove note events selection when type deselect
      if (e.newSelection.length === 0) {
        this.cbNoteEvents.deselectAllItems(true);
      }
    }

    if (controlName === "noteEvents") {
      if (e.newSelection.length !== 0) {
        this.noteEvent = e.newSelection[0];
        // populate of note sections combo
        this.noteSections = this.noteEvent.section;
      }
    }
  }

  // handle ICD 11
  searchICD11(category: string, index: number) {
    this.conditionCategory = category;
    this.ICD11InputIndex = index;
    this.ICD11Banner.open();
  }

  collectICD11() {
    let ICD11Code = window.localStorage.getItem("ICD11Code");
    let ICD11Desc = window.localStorage.getItem("ICD11Desc");
    let problemFormArray = <FormArray>this.compositionForm.get("problemList");

    for (let i: number = 0; i < problemFormArray.length; i++) {
      let formGroup = problemFormArray.get([i]);

      if (problemFormArray.controls.length !== 1) {
        if (i != this.ICD11InputIndex) {
          if (formGroup.get("conditionCode").value === ICD11Code) {
            this.snackbar.open("Same diagnosis found. Please select different diagnosis");
            var item = (<FormArray>this.compositionForm.get("problemList")).at(
              this.ICD11InputIndex
            );
            item.patchValue({
              conditionCode: null,
              conditionDescription: null,
              note: null,
            });
            break;
          } else {
            var item = (<FormArray>this.compositionForm.get("problemList")).at(
              this.ICD11InputIndex
            );
            item.patchValue({
              conditionCode: ICD11Code,
              conditionDescription: ICD11Desc,
              note: ICD11Desc,
            });
          }
        }
      } else {
        var item = (<FormArray>this.compositionForm.get("problemList")).at(this.ICD11InputIndex);
        item.patchValue({
          conditionCode: ICD11Code,
          conditionDescription: ICD11Desc,
          note: ICD11Desc,
        });
      }
    }

    this.ICD11Banner.close();
  }

  // handle note panel operation
  open(): void {
    // Return if the panel has already opened
    if (this.opened) {
      return;
    }
    // Open the panel
    this._toggleOpened(true);
  }

  close(): void {
    // Return if the panel has already closed
    if (!this.opened) {
      return;
    }
    // Close the panel
    this._toggleOpened(false);
  }

  toggle(): void {
    if (this.opened) {
      this.patientComponent.drawer.open();
      this.close();
    } else {
      this.patientComponent.drawer.close();
      this.open();
    }
  }

  closeToggle(): void {
    if (this.opened) {
      this.patientComponent.drawer.open();
      this.close();
    }
  }

  public _toggleOpened(open: boolean): void {
    // Set the opened
    this.opened = open;
  }

  // make textarea auto expand height
  autoGrowTextZone(e) {
    //when user click Enter -- expand <div></div>
    if (e.keyCode === 13) {
      e.target.style.height = e.target.scrollHeight + 25 + "px";
    }
  }

  dateString = function (date: Date) {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();
    var hh = date.getHours();
    var min = date.getMinutes();
    return (
      [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-") +
      "T" +
      (hh > 9 ? "" : "0") +
      hh +
      ":" +
      (min > 9 ? "" : "0") +
      min +
      ":00.000+08:00"
    );
  };

  closeSnackbar(element) {
    element.close();
  }

  // remove unnecessary note content
  getContent(text: string) {
    let content = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">', "")
      .replace("</div>", "");
    return content;
  }

  getRespiratory(text: string) {
    let rr = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">Respiratory Rate: ', "")
      .replace("</div>", "")
      .replace("/min", "");
    return rr;
  }

  getHeartRate(text: string) {
    let hr = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">Heart Rate: ', "")
      .replace("</div>", "")
      .replace("/min", "");
    return hr;
  }

  getOxygenSaturation(text: string) {
    let os = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">Oxygen Saturation: ', "")
      .replace("</div>", "")
      .replace("%", "");
    return os;
  }

  getBodyTemperature(text: string) {
    let os = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">Body Temperature: ', "")
      .replace("</div>", "")
      .replace("'C", "");
    return os;
  }

  getSystolicPressure(text: string) {
    let sp = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">Systolic Pressure: ', "")
      .replace("</div>", "")
      .replace("mmHg", "");
    return sp;
  }

  getDiastolicPressure(text: string) {
    let dp = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">Diastolic Pressure: ', "")
      .replace("</div>", "")
      .replace("mmHg", "");
    return dp;
  }

  getPainScore(text: string) {
    let dp = text
      .replace('<div xmlns="http://www.w3.org/1999/xhtml">Pain Score: ', "")
      .replace("</div>", "")
      .replace("mmHg", "");
    return dp;
  }

  clearForm() {
    this.ctlClinicalInformation = false;
    this.ctlProblemList = false;
    this.ctlVitalSign = false;
    this.ctlAllergies = false;
    this.ctlMedication = false;
    this.ctlSocialHistory = false;
    this.ctlFamilyHistory = false;
    this.ctlImmunizationHistory = false;
    this.ctlAssessment = false;
    this.ctlCarePlan = false;
    this.vtsBodyTemperature = false;
    this.vtsDiastolicPressure = false;
    this.vtsHeartRate = false;
    this.vtsOxygenSaturation = false;
    this.vtsRespiratoryRate = false;
    this.vtsSystolicPressure = false;
    this.dntAlert = false;
    this.dntChart = false;
    this.adsEvent = false;
    this.proDesc = false;
    this.proFind = false;
    this.proComp = false;
  }

  private readonly isRequiredConditionalClinicalInformation: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.ctlClinicalInformation) {
      return this.ctlClinicalInformation ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalSocialHistory: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.ctlSocialHistory) {
      return this.ctlSocialHistory ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalFamilyHistory: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.ctlFamilyHistory) {
      return this.ctlFamilyHistory ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalImmunizationHistory: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.ctlImmunizationHistory) {
      return this.ctlImmunizationHistory ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalAssessment: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlAssessment) {
      return this.ctlAssessment ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalDentistryAlert: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.dntAlert) {
      return this.dntAlert ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalDentistryChart: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.dntChart) {
      return this.dntChart ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalAdverseEvent: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.adsEvent) {
      return this.adsEvent ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalRR: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlVitalSign) {
      return this.ctlVitalSign ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalHR: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlVitalSign) {
      return this.ctlVitalSign ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalOS: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlVitalSign) {
      return this.ctlVitalSign ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalBT: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlVitalSign) {
      return this.ctlVitalSign ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalSP: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlVitalSign) {
      return this.ctlVitalSign ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalDP: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlVitalSign) {
      return this.ctlVitalSign ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalPS: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlVitalSign) {
      return this.ctlVitalSign ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalAllergies: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlAllergies) {
      return this.ctlAllergies ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalCarePlan: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlCarePlan) {
      return this.ctlCarePlan ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalMedication: ValidatorFn = (c): ValidationErrors | null => {
    if (this.ctlMedication) {
      return this.ctlMedication ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalProDesc: ValidatorFn = (c): ValidationErrors | null => {
    if (this.proDesc) {
      return this.proDesc ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalProFind: ValidatorFn = (c): ValidationErrors | null => {
    if (this.proFind) {
      return this.proFind ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalProComp: ValidatorFn = (c): ValidationErrors | null => {
    if (this.proComp) {
      return this.proComp ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalConditionCode: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.ctlProblemList) {
      return this.ctlProblemList ? Validators.required(c) : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalAbatementDateTime: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.abatementDateTimeValidity) {
      return this.abatementDateTimeValidity ? { notValid: true } : Validators.nullValidator(c);
    }
  };

  private readonly isRequiredConditionalEventDateTime: ValidatorFn = (
    c
  ): ValidationErrors | null => {
    if (this.eventDateTimeValidity) {
      return this.eventDateTimeValidity ? { notValid: true } : Validators.nullValidator(c);
    } else {
      return Validators.required(c);
    }
  };

  public checkIndex(index: any) {
    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }

  public iconChange() {
    if (this.enterNote) {
      return "edit";
    } else {
      return "heroicons_outline:document-text";
    }
  }

  public checkCuredDateValidity(onsetDateTime: Date, abatementDateTime: Date) {
    if (onsetDateTime !== null) {
      let onset = new Date(onsetDateTime);
      let curedDate = new Date(abatementDateTime);
      if (onset !== null) {
        if (onset < curedDate) {
          this.abatementDateTimeValidity = true;
          let pl: FormArray = this.compositionForm.get("problemList") as FormArray;
          for (const key1 of Object.keys(pl.controls)) {
            for (const key2 of Object.keys(pl.controls[key1].controls)) {
              if (pl.controls[key1].controls["abatementDateTime"].status === "INVALID") {
                pl.controls[key1].controls["abatementDateTime"].setErrors({ incorrect: true });
                pl.controls[key1].controls["abatementDateTime"].markAsTouched({ onlySelf: true });
                pl.controls[key1].controls["abatementDateTime"].updateValueAndValidity({
                  onlySelf: true,
                });
              }
            }
          }

          this.snackbar.open("Cured Date cannot less than Onset Date");
        } else {
          this.abatementDateTimeValidity = false;
        }
      } else {
        let pl: FormArray = this.compositionForm.get("problemList") as FormArray;
        for (const key1 of Object.keys(pl.controls)) {
          for (const key2 of Object.keys(pl.controls[key1].controls)) {
            pl.controls[key1].controls["abatementDateTime"].updateValueAndValidity({
              onlySelf: true,
            });
          }
        }
        this.abatementDateTimeValidity = false;
      }
    }
  }

  public checkEventDate(eventDates: Date) {
    if (eventDates !== null) {
      let eventDate = new Date(eventDates);
      let currentDate = new Date();
      if (eventDate !== null) {
        if (eventDate > currentDate) {
          this.eventDateTimeValidity = true;
          let p2 = this.compositionForm.get("eventDate");
          //    p2.setErrors({ notValid: true });
          p2.markAsTouched({ onlySelf: true });
          p2.updateValueAndValidity({
            onlySelf: true,
          });

          this.snackbar.open("Event Date cannot more than Current Date");
        } else {
          this.eventDateTimeValidity = false;
          let p2 = this.compositionForm.get("eventDate");
          console.log(p2);
        }
      }
    } else {
      this.eventDateTimeValidity = false;
      let p2 = this.compositionForm.get("eventDate");
      console.log(p2);
    }
  }

  public clearDate() {
    this.abatementDateTimeValidity = false;
    let pl: FormArray = this.compositionForm.get("problemList") as FormArray;
    for (const key1 of Object.keys(pl.controls)) {
      for (const key2 of Object.keys(pl.controls[key1].controls)) {
        if (pl.controls[key1].controls["abatementDateTime"].status === "INVALID") {
          pl.controls[key1].controls["abatementDateTime"].status = "VALID";
          pl.controls[key1].controls["abatementDateTime"].errors = null;
        }
      }
    }
  }

  scrollToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
