import {
  Component,
  TemplateRef,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { lastValueFrom, Subject, takeUntil } from "rxjs";
import { CadMediaWatcherService } from "@cad/services/media-watcher";
import { CadNavigationService, CadVerticalNavigationComponent } from "@cad/components/navigation";
import { Navigation } from "app/core/navigation/navigation.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import { MatDialog } from "@angular/material/dialog";
import { AppConfigService } from "app/appconfig.service";
import { PersonSearchService } from "app/modules/provider/person-search/person-search.service";
import { FhirPathService } from "app/fhirpath.service";
import moment from "moment";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { IgxDialogComponent, IgxIconService } from "@infragistics/igniteui-angular";
import { DefaultLocationService } from "app/modules/cad/auth/default-location/default-location.service";
import { forEach, result } from "lodash";
import { AuthService } from "app/core/auth/auth.service";
import { PatientService } from "app/modules/provider/patient/dashboard/dashboard.service";
import { ComposeComponent } from "app/modules/provider/patient/composition/compose/compose.component";
import { ComposeService } from "app/modules/provider/patient/composition/compose/compose.service";
import { CadConfirmationService } from "@cad/services/confirmation";
import { OutpatientService } from "app/modules/provider/outpatient/outpatient.service";
import { InpatientService } from "app/modules/provider/inpatient/inpatient.service";
import { EncounterQueueService } from "app/modules/provider/encounter-queue/encounter-queue.service";
import { VcService } from "app/shared/vc.service";
import { VcRoomService } from "app/modules/provider/vc-room/vc-room.service";
import { PatientFlagService } from "app/modules/provider/patient/flag/flag.service";
import { StoreService } from "app/shared/store.service";
import { AllergyService } from "app/modules/provider/patient/allergy.service";
import { environment } from "environments/environment";

@Component({
  selector: "modern-layout",
  templateUrl: "./modern.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
  @ViewChild("alert", { static: true })
  public alert: IgxDialogComponent;
  @ViewChild(ComposeComponent)
  _compose!: ComposeComponent;

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e) {
    if (this.sessionEndStart) {
      this.sessionEndStart = false;
      this.alert.close();
      this.resetTimer();
    } else {
      this.resetTimer();
    }
    //if use OnPush
    this._changeDetectorRef.detectChanges();
  }
  isScreenSmall: boolean;
  navigation: Navigation;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Output() selectEvent = new EventEmitter<any>();
  @Output() snackbarEvent = new EventEmitter<any>();

  //Related Person Start
  relatedPersons: fhir.r4.RelatedPerson[] = [];
  relatedPatients: fhir.r4.Patient[] = [];
  relatedPersonCombo: boolean = false;
  //Related Person End
  public patientId;

  public tempId: any;
  isPatientSelected: Boolean;
  selectedEncounter: string = "OUTPAT000002";

  isDefaultLocationSelected: Boolean;
  isDisplay: Boolean;

  patients: fhir.r4.Patient[];
  patient: fhir.r4.Patient;
  patientsLoading: boolean = false;
  totalCount: number;

  encounters: fhir.r4.Encounter[];
  encounter: fhir.r4.Encounter;
  encountersLoading: boolean = false;

  defaultLocation: any;
  public bundle$: any;
  public userId;
  public systemTask;
  id: any;

  @ViewChild("compositionSelectionRef")
  compositionSelectionRef!: TemplateRef<any>;

  @ViewChild("compositionRef")
  compositionRef!: TemplateRef<any>;

  formFieldHelpers: string[] = [""];

  compositionSelectionDialog;
  compositionDialog;
  instCode;
  instName: string;
  consent: string;

  min: number;
  sec: number;
  interval;
  subscribeTimer: any;
  sessionEndStart: boolean;

  public styleOne: boolean = false;
  public styleTwo: boolean = false;
  public viewMedicalRecord: boolean = false;
  public viewEndConsultation: boolean = false;
  public viewVcLink: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private __defaultLocationService: DefaultLocationService,
    private _navigationService: NavigationService,
    private _cadMediaWatcherService: CadMediaWatcherService,
    private _cadNavigationService: CadNavigationService,
    private _appConfigService: AppConfigService,
    private _personSearchService: PersonSearchService,
    private _fhirPathService: FhirPathService,
    private dialog: MatDialog,
    private _dbService: NgxIndexedDBService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _composeService: ComposeService,
    private _cadConfirmationService: CadConfirmationService,
    private _outpatientService: OutpatientService,
    private _inpatientService: InpatientService,
    private _encounterQueueService: EncounterQueueService,
    private _vcServis: VcService,
    private _vcRoomServis: VcRoomService,
    private _patientService: PatientService,
    private _patientFlagService: PatientFlagService,
    private _storeService: StoreService,
    private _allergyService: AllergyService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    //Related Person Start
    if (
      environment.environmentName === "LOCAL" ||
      environment.environmentName === "STAGING" ||
      environment.environmentName === "QA"
    )
      this._navigationService
        .getRelatedPersons(this._appConfigService.getPatientResourceId())
        .subscribe();

    this._navigationService.relatedpersons$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        this.relatedPersons = response as fhir.r4.RelatedPerson[];
      });
    this._navigationService.relatedpatients$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        this.relatedPatients = response as any[];
      });
    //Related Person End

    this.resetTimer();
    this.sessionEndStart = false;
    this.startTimer();

    this._navigationService.institutionSelected.subscribe((result) => {
      this.instName = result;
    });

    // default false
    if (this._appConfigService.getDisplayTag() !== "true") {
      this._appConfigService.changeDisplayTag("false");
    }

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._cadMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes("md");
      });

    this._navigationService.patientSelected.subscribe((result) => {
      this.isPatientSelected = result;
      this.patientId = this._appConfigService.getPatientResourceId();
      this.userId = this._appConfigService.getUserId();
      // this.isDisplay = result;
      if (this.patientId !== null && this.patientId !== "") {
        this._personSearchService.getPatientId(this.patientId).subscribe({
          next: (data) => {
            this.patient = data;

            // this._appConfigService.changePatientIcNo(this._fhirPathService.evaluateToString(data, "identifier.where(use='official').value"))

            this.viewMedicalRecord = this._appConfigService.checkSystemTask("accessMedicalRecord");

            if (this.patient.meta.source) {
              let x: string;

              x = this.patient.meta.source.substring(0, 30);

              if (x === "http://provider.hie.moh.gov.my") {
                this.styleOne = true;
                this.styleTwo = false;
              } else {
                this.styleTwo = true;
                this.styleOne = false;
              }
            } else {
              this.styleTwo = true;
              this.styleOne = false;
            }
          },
        });

        this._personSearchService.getEncounterById(this.patientId).subscribe({
          next: (data) => {
            this.encounter = data;
          },
        });
      }
    });

    if (this._appConfigService.getDisplayTag() === "true") {
      //if refresh page check if button end encounter display or not display
      if (this._appConfigService.getDisplayEndEncounter() === "Display") {
        this.viewEndConsultation = true;
      } else {
        this.viewEndConsultation = false;
      }

      // this._navigationService.patientSelected.subscribe((result) => {
      this.isPatientSelected = true;
      this.patientId = this._appConfigService.getPatientResourceId();
      this.userId = this._appConfigService.getUserId();
      // this.isDisplay = result;
      if (this.patientId !== null && this.patientId !== "") {
        this._personSearchService.getPatientId(this.patientId).subscribe({
          next: (data) => {
            this.patient = data;

            // this._appConfigService.changePatientIcNo(this._fhirPathService.evaluateToString(data, "identifier.where(use='official').value"))

            this.viewMedicalRecord = this._appConfigService.checkSystemTask("accessMedicalRecord");

            if (this.patient.meta.source) {
              let x: string;

              x = this.patient.meta.source.substring(0, 30);

              if (x === "http://provider.hie.moh.gov.my") {
                this.styleOne = true;
                this.styleTwo = false;
              } else {
                this.styleTwo = true;
                this.styleOne = false;
              }
            } else {
              this.styleTwo = true;
              this.styleOne = false;
            }
          },
        });

        this._personSearchService.getEncounterById(this.patientId).subscribe({
          next: (data) => {
            this.encounter = data;
          },
        });

        // this._patientService.consent$.pipe(takeUntil(this._unsubscribeAll)).subscribe((consent: fhir.r4.Consent) => {
        //   this.consent = this._fhirPathService.evaluateToString(consent, "entry.resource.ofType(Consent).status.last()");
        // });
        // this._appConfigService.changePatientConsentStatus(this.consent)
      }
      // });
    }
  }
  //Related Person Start
  relatedPersonSelect(e) {
    e.newSelection = e.added;
    console.log(e.newSelection);
    if (e.newSelection.length > 0) {
      this._navigationService
        .getPatient(e.newSelection[0])
        .subscribe((response: fhir.r4.Bundle) => {
          let patients: fhir.r4.Patient[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Patient)"
          );

          let patient: fhir.r4.Patient = patients.find((x) =>
            x.meta.source.includes("http://provider.hie.moh.gov.my")
          );
          if (response.total > 0) {
            this._appConfigService.changePatientResourceId(patient.id);
            this._appConfigService.changeDisplayTag("true");
            this._navigationService.changePatientSelection(true);
            this._patientService.getPatients().subscribe();
            this._patientService.getConsent().subscribe();
            // this._patientService.getProblems2(),
            this._patientService.getObservations().subscribe();
            this._patientService.getAllergys().subscribe();
            // this._medicationService.getMedications(0, 10, 1),
            this._storeService.getFlagCategory().subscribe();
            this._patientService.getVitalSigns().subscribe();
            this._patientService.getFlags().subscribe();
            this._allergyService.getAllergys(0, 10, 1).subscribe();
            this._router.navigate(["/apps/patient/dashboard"]);
          } else {
            console.log("This user is not registered in the system");
            const confirmation = this._cadConfirmationService.open({
              title: "Message",
              message: "This user is not registered in the system",
              actions: {
                confirm: {
                  label: "Close",
                },
                cancel: {
                  show: false,
                  label: "Close",
                },
              },
            });
          }
        });
    } else {
      this._appConfigService.changePatientResourceId(
        this._appConfigService.getDefaultPatientResourceId()
      );
      this._appConfigService.changeDisplayTag("true");
      this._navigationService.changePatientSelection(true);
      this._patientService.getPatients().subscribe();
      this._patientService.getConsent().subscribe();
      // this._patientService.getProblems2(),
      this._patientService.getObservations().subscribe();
      this._patientService.getAllergys().subscribe();
      // this._medicationService.getMedications(0, 10, 1),
      this._storeService.getFlagCategory().subscribe();
      this._patientService.getVitalSigns().subscribe();
      this._patientService.getFlags().subscribe();
      this._allergyService.getAllergys(0, 10, 1).subscribe();
      this._router.navigate(["/apps/patient/dashboard"]);
      this.relatedPersonCombo = false;
    }
  }
  //Related Person End
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this._appConfigService.changePatientResourceId("");
    this._navigationService.changePatientSelection(false);
    this._appConfigService.changeDisplayTag("");
    this._appConfigService.changeDisplayEndEncounter("");
    this.viewVcLink = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._cadNavigationService.getComponent<CadVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  closeTag() {
    let routerurl: string = this._router.url;
    this._appConfigService.changePatientResourceId("");
    this._appConfigService.changePatientIcNo("");
    this._appConfigService.changeEncounterId("");
    this._appConfigService.changePatientMrn("");
    this._appConfigService.changeCompositionId("");
    this._appConfigService.changeCompositionMode("");
    this._navigationService.changePatientSelection(false);
    this._appConfigService.changeDisplayTag("false");
    if (routerurl !== null) {
      if (routerurl.includes("/apps/patient")) {
        if (
          this._appConfigService.getVcUrl() != "null" &&
          this._appConfigService.getVcUrl() != null &&
          this._appConfigService.getVcUrl() != ""
        ) {
          this._router.navigate([this._appConfigService.getVcUrl()]);
          this._appConfigService.vcUrl("");
        } else {
          this._router.navigate(["/apps/person-search"]);
        }
      } else if (routerurl.includes("/frontdesk/related-person")){
        this._router.navigate(["/apps/person-search"]);
      } else {
        this._router.navigate([routerurl]);
      }
    }
    this.viewEndConsultation = false;
    this.viewVcLink = false;
    this.closeButton();
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/same-route"]);
  }

  reloadComponentEncounter() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/frontdesk/encounter-registration"]);
  }

  goMedicalRecord(event, id) {
    let encounterStatus = this._appConfigService.getEncounterStatus();
    this.triggerAnEvent();

    let routerurl: string = this._router.url;

    if (routerurl !== null) {
      if (routerurl === "/registeredpatient/outpatient/AMB") {
        if (encounterStatus === "in-progress") {
          this.viewEndConsultation = true;
          this.viewVcLink = false;
          this._appConfigService.changeDisplayEndEncounter("Display");
          this._router.navigate(["/apps/patient/dashboard"]);
        } else {
          this.viewEndConsultation = false;
          this.viewVcLink = false;
          this._router.navigate(["/apps/patient/dashboard"]);
        }
      } else if (routerurl === "/registeredpatient/inpatient/IMP") {
        if (encounterStatus === "in-progress") {
          this.viewEndConsultation = true;
          this.viewVcLink = false;
          this._appConfigService.changeDisplayEndEncounter("Display");
          this._router.navigate(["/apps/patient/dashboard"]);
        } else {
          this.viewEndConsultation = false;
          this.viewVcLink = false;
          this._router.navigate(["/apps/patient/dashboard"]);
        }
      } else if (routerurl === "/registeredpatient/emergency/EMER") {
        if (encounterStatus === "in-progress") {
          this.viewEndConsultation = true;
          this.viewVcLink = false;
          this._appConfigService.changeDisplayEndEncounter("Display");
          this._router.navigate(["/apps/patient/dashboard"]);
        } else {
          this.viewEndConsultation = false;
          this.viewVcLink = false;
          this._router.navigate(["/apps/patient/dashboard"]);
        }
      } else if (routerurl === "/registeredpatient/vc/VR") {
        if (encounterStatus === "in-progress") {
          this.viewVcLink = true;
          this.viewEndConsultation = true;
          this._appConfigService.changeDisplayEndEncounter("Display");
          this._router.navigate(["/apps/patient/dashboard"]);
        } else {
          this.viewEndConsultation = false;
          this.viewVcLink = false;
          this._router.navigate(["/apps/patient/dashboard"]);
        }
      } else {
        this.viewEndConsultation = false;
        this.viewVcLink = false;
        this._router.navigate(["/apps/patient/dashboard"]);
      }
    }
  }

  goVcLink(event, id) {
    let encounterId = id;

    this._encounterQueueService.readEncounterById(encounterId).subscribe({
      next: (data) => {
        let vcToken = this._fhirPathService.evaluateToString(
          data,
          "identifier.where(system='http://fhir.hie.moh.gov.my/sid/vc-token').value"
        );
        // console.log("item", item)

        this._vcServis.getShow(vcToken).subscribe({
          next: (data) => {
            // this._appConfigService.vcUrl("/registeredpatient/vc/VR");
            // this._vcRoomServis._vcData.next(data);
            // this._router.navigate(["/apps/vc-room"]);
            // this._router.navigateByUrl("/apps/vc-room", data);
            var moderatorUrl = data.data.moderator_jitsi_url;

            var popupWindow = null;
            // function centeredPopup(url,winName,w,h,scroll){
            let LeftPosition = screen.width ? (screen.width - 1400) / 2 : 0;
            let TopPosition = screen.height ? (screen.height - 900) / 2 : 0;
            let settings =
              "height=" +
              900 +
              ",width=" +
              1400 +
              ",top=" +
              TopPosition +
              ",left=" +
              LeftPosition +
              ",scrollbars=" +
              scroll +
              ",resizable";
            popupWindow = window.open(moderatorUrl, "myWindow", settings);
            // }

            // let fullUrl: string;
            //    fullUrl = moderatorUrl;
            //     window.open(fullUrl, 'popUpWindow','resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
          },
        });

        let notification = {
          title: "VC",
          body: "Virtual consultation session has started! Kindly join the link",
          patientId: this._appConfigService.getPatientIcNo(),
          status: "ongoing",
          vcToken: vcToken,
        };
        this._encounterQueueService.pushNotifications(notification).subscribe();
      },
    });
  }

  goEndConsultation(event, id) {
    let encounterId = this._appConfigService.getEncounterId();

    // Open the confirmation dialog
    const confirmation = this._cadConfirmationService.open({
      title: "End Consultation",
      message:
        "Are you sure you want to end consultation this patient? This action cannot be undone.",
      actions: {
        confirm: {
          label: "Yes",
        },
        cancel: {
          show: true,
          label: "No",
        },
      },
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        this._outpatientService.readEncounterById(encounterId).subscribe({
          next: (data) => {
            this._outpatientService.outpatient.next(data);
            this.selectEvent.emit();

            if (data.class.code === "AMB") {
              if (data.appointment) {
                let appointmentId = data.appointment[0].reference.split("/")[1];
                this._encounterQueueService.getAppointmentById(appointmentId).subscribe({
                  next: (appointmentData) => {
                    appointmentData.status = "fulfilled";

                    this._encounterQueueService
                      .updateAppointmentById(appointmentData.id, appointmentData)
                      .subscribe({
                        next: (response) => {
                          this.selectEvent.emit(response);
                        },
                        // error: (error) => {
                        //   this.snackbarEvent.emit(
                        //     this._fhirPathService.evaluateToString(
                        //       error.error as fhir.r4.OperationOutcome,
                        //       "issue.where(severity='error').diagnostics.first()"
                        //     )
                        //   );
                        // },
                      });
                  },
                });
              }

              let date = new Date();
              let currentDate = `${date.toISOString()}`;

              data.status = "finished";
              data.period = {
                start: data.statusHistory[0].period.start,
                end: currentDate,
              };

              data.statusHistory = [
                {
                  status: "arrived",
                  period: {
                    start: data.statusHistory[0].period.start,
                    end: data.statusHistory[0].period.end,
                  },
                },
                {
                  status: "in-progress",
                  period: {
                    start: data.statusHistory[0].period.end,
                    end: currentDate,
                  },
                },
              ];

              if (data.extension.length !== 1) {
                data.extension[1].extension[2].valueReference.display =
                  this._appConfigService.getPractitionerName();
                data.extension[1].extension[2].valueReference.reference =
                  "PractitionerRole/" + this._appConfigService.getPractitionerRoleId();
              } else {
                data.extension[0].extension[2].valueReference.display =
                  this._appConfigService.getPractitionerName();
                data.extension[0].extension[2].valueReference.reference =
                  "PractitionerRole/" + this._appConfigService.getPractitionerRoleId();
              }

              // (data.extension[0].extension[2].valueReference.display =
              //   this._appConfigService.getPractitionerName()),
              //   (data.extension[0].extension[2].valueReference.reference =
              //     "PractitionerRole/" + this._appConfigService.getPractitionerRoleId()),
              this._outpatientService
                .updateEncounter(this._appConfigService.getEncounterId(), data)
                .subscribe({
                  next: (response) => {
                    // this.snackbarEvent.emit("The resource has been updated");
                    this.selectEvent.emit(response);
                    // this._outpatientService.getData(null, null, null);
                    this.viewEndConsultation = false;
                    this._navigationService.changePatientSelection(true);
                    this._appConfigService.changeEncounterId("");
                    this._appConfigService.changeDisplayEndEncounter("");
                    this._router.navigate(["/registeredpatient/outpatient/AMB"]);
                  },
                  error: (error) => {
                    this.snackbarEvent.emit(
                      this._fhirPathService.evaluateToString(
                        error.error as fhir.r4.OperationOutcome,
                        "issue.where(severity='error').diagnostics.first()"
                      )
                    );
                  },
                });
            } else if (data.class.code === "EMER") {
              if (data.appointment) {
                let appointmentId = data.appointment[0].reference.split("/")[1];
                this._encounterQueueService.getAppointmentById(appointmentId).subscribe({
                  next: (appointmentData) => {
                    appointmentData.status = "fulfilled";

                    this._encounterQueueService
                      .updateAppointmentById(appointmentData.id, appointmentData)
                      .subscribe({
                        next: (response) => {
                          this.selectEvent.emit(response);
                        },
                        // error: (error) => {
                        //   this.snackbarEvent.emit(
                        //     this._fhirPathService.evaluateToString(
                        //       error.error as fhir.r4.OperationOutcome,
                        //       "issue.where(severity='error').diagnostics.first()"
                        //     )
                        //   );
                        // },
                      });
                  },
                });
              }
              let date = new Date();
              let currentDate = `${date.toISOString()}`;

              data.status = "finished";
              data.period = {
                start: data.statusHistory[0].period.start,
                end: currentDate,
              };

              data.statusHistory = [
                {
                  status: "arrived",
                  period: {
                    start: data.statusHistory[0].period.start,
                    end: data.statusHistory[0].period.end,
                  },
                },
                {
                  status: "in-progress",
                  period: {
                    start: data.statusHistory[0].period.end,
                    end: currentDate,
                  },
                },
              ];

              if (data.extension.length !== 1) {
                data.extension[1].extension[2].valueReference.display =
                  this._appConfigService.getPractitionerName();
                data.extension[1].extension[2].valueReference.reference =
                  "PractitionerRole/" + this._appConfigService.getPractitionerRoleId();
              } else {
                data.extension[0].extension[2].valueReference.display =
                  this._appConfigService.getPractitionerName();
                data.extension[0].extension[2].valueReference.reference =
                  "PractitionerRole/" + this._appConfigService.getPractitionerRoleId();
              }

              // (data.extension[0].extension[2].valueReference.display =
              //   this._appConfigService.getPractitionerName()),
              //   (data.extension[0].extension[2].valueReference.reference =
              //     "PractitionerRole/" + this._appConfigService.getPractitionerRoleId()),
              this._outpatientService
                .updateEncounter(this._appConfigService.getEncounterId(), data)
                .subscribe({
                  next: (response) => {
                    // this.snackbarEvent.emit("The resource has been updated");
                    this.selectEvent.emit(response);
                    // this._outpatientService.getData(null, null, null);
                    this.viewEndConsultation = false;
                    this._navigationService.changePatientSelection(true);
                    this._appConfigService.changeEncounterId("");
                    this._appConfigService.changeDisplayEndEncounter("");
                    this._router.navigate(["/registeredpatient/emergency/EMER"]);
                  },
                  error: (error) => {
                    this.snackbarEvent.emit(
                      this._fhirPathService.evaluateToString(
                        error.error as fhir.r4.OperationOutcome,
                        "issue.where(severity='error').diagnostics.first()"
                      )
                    );
                  },
                });
            } else if (data.class.code === "VR") {
              let vcToken = this._fhirPathService.evaluateToString(
                data,
                "identifier.where(system='http://fhir.hie.moh.gov.my/sid/vc-token').value"
              );

              if (data.appointment) {
                let appointmentId = data.appointment[0].reference.split("/")[1];
                this._encounterQueueService.getAppointmentById(appointmentId).subscribe({
                  next: (appointmentData) => {
                    appointmentData.status = "fulfilled";

                    this._encounterQueueService
                      .updateAppointmentById(appointmentData.id, appointmentData)
                      .subscribe({
                        next: (response) => {
                          this.selectEvent.emit(response);
                        },
                      });
                  },
                });
              }
              if (vcToken) {
                // let vcToken = data.identifier[1].value;
                this._vcServis.getShow(vcToken).subscribe({
                  next: (vcData) => {
                    vcData.status = "completed";

                    this._vcServis.postUpdate(vcToken, vcData).subscribe({});
                  },
                });
              }
              let date = new Date();
              let currentDate = `${date.toISOString()}`;

              data.status = "finished";
              data.period = {
                start: data.statusHistory[0].period.start,
                end: currentDate,
              };

              data.statusHistory = [
                {
                  status: "arrived",
                  period: {
                    start: data.statusHistory[0].period.start,
                    end: data.statusHistory[0].period.end,
                  },
                },
                {
                  status: "in-progress",
                  period: {
                    start: data.statusHistory[0].period.end,
                    end: currentDate,
                  },
                },
              ];

              if (data.extension.length !== 1) {
                data.extension[1].extension[2].valueReference.display =
                  this._appConfigService.getPractitionerName();
                data.extension[1].extension[2].valueReference.reference =
                  "PractitionerRole/" + this._appConfigService.getPractitionerRoleId();
              } else {
                data.extension[0].extension[2].valueReference.display =
                  this._appConfigService.getPractitionerName();
                data.extension[0].extension[2].valueReference.reference =
                  "PractitionerRole/" + this._appConfigService.getPractitionerRoleId();
              }

              // (data.extension[0].extension[2].valueReference.display =
              //   this._appConfigService.getPractitionerName()),
              //   (data.extension[0].extension[2].valueReference.reference =
              //     "PractitionerRole/" + this._appConfigService.getPractitionerRoleId()),
              this._outpatientService
                .updateEncounter(this._appConfigService.getEncounterId(), data)
                .subscribe({
                  next: (response) => {
                    // this.snackbarEvent.emit("The resource has been updated");
                    this.selectEvent.emit(response);
                    // this._outpatientService.getData(null, null, null);
                    this.viewEndConsultation = false;
                    this._navigationService.changePatientSelection(true);
                    this._appConfigService.changeEncounterId("");
                    this._appConfigService.changeDisplayEndEncounter("");
                    this._router.navigate(["/registeredpatient/vc/VR"]);
                    this.viewVcLink = false;
                  },
                  error: (error) => {
                    this.snackbarEvent.emit(
                      this._fhirPathService.evaluateToString(
                        error.error as fhir.r4.OperationOutcome,
                        "issue.where(severity='error').diagnostics.first()"
                      )
                    );
                  },
                });
            } else if (data.class.code === "IMP") {
              if (data.appointment) {
                let appointmentId = data.appointment[0].reference.split("/")[1];
                this._encounterQueueService.getAppointmentById(appointmentId).subscribe({
                  next: (appointmentData) => {
                    appointmentData.status = "fulfilled";

                    this._encounterQueueService
                      .updateAppointmentById(appointmentData.id, appointmentData)
                      .subscribe({
                        next: (response) => {
                          this.selectEvent.emit(response);
                        },
                        // error: (error) => {
                        //   this.snackbarEvent.emit(
                        //     this._fhirPathService.evaluateToString(
                        //       error.error as fhir.r4.OperationOutcome,
                        //       "issue.where(severity='error').diagnostics.first()"
                        //     )
                        //   );
                        // },
                      });
                  },
                });
              }
              this._inpatientService
                .getComposition(
                  this._appConfigService.getPatientResourceId(),
                  this._appConfigService.getEncounterId()
                )
                .subscribe({
                  next: (response) => {
                    if (response.total > 0) {
                      this._inpatientService
                        .readEncounterById(this._appConfigService.getEncounterId())
                        .subscribe({
                          next: (data) => {
                            let date = new Date();
                            let currentDate = `${date.toISOString()}`;

                            data.status = "finished";
                            data.period = {
                              start: data.statusHistory[0].period.start,
                              end: currentDate,
                            };

                            data.statusHistory = [
                              {
                                status: "arrived",
                                period: {
                                  start: data.statusHistory[0].period.start,
                                  end: data.statusHistory[0].period.end,
                                },
                              },
                              {
                                status: "in-progress",
                                period: {
                                  start: data.statusHistory[0].period.end,
                                  end: currentDate,
                                },
                              },
                            ];

                            if (data.extension.length !== 1) {
                              data.extension[1].extension[2].valueReference.display =
                                this._appConfigService.getPractitionerName();
                              data.extension[1].extension[2].valueReference.reference =
                                "PractitionerRole/" +
                                this._appConfigService.getPractitionerRoleId();
                            } else {
                              data.extension[0].extension[2].valueReference.display =
                                this._appConfigService.getPractitionerName();
                              data.extension[0].extension[2].valueReference.reference =
                                "PractitionerRole/" +
                                this._appConfigService.getPractitionerRoleId();
                            }

                            // (data.extension[0].extension[2].valueReference.display =
                            //   this._appConfigService.getPractitionerName()),
                            //   (data.extension[0].extension[2].valueReference.reference =
                            //     "PractitionerRole/" +
                            //     this._appConfigService.getPractitionerRoleId()),
                            this._outpatientService.updateEncounter(encounterId, data).subscribe({
                              next: (response) => {
                                // this.snackbarEvent.emit("The resource has been updated");
                                this.selectEvent.emit(response);
                                // this._outpatientService.getData(null, null, null);
                                this.viewEndConsultation = false;
                                this._navigationService.changePatientSelection(true);
                                this._appConfigService.changeEncounterId("");
                                this._appConfigService.changeDisplayEndEncounter("");
                                this._router.navigate(["/registeredpatient/inpatient/IMP"]);
                              },
                              error: (error) => {
                                this.snackbarEvent.emit(
                                  this._fhirPathService.evaluateToString(
                                    error.error as fhir.r4.OperationOutcome,
                                    "issue.where(severity='error').diagnostics.first()"
                                  )
                                );
                              },
                            });
                          },
                        });
                    } else {
                      const confirmation = this._cadConfirmationService.openConfirm({
                        title: "NO DISCHARGE SUMMARY DETECTED",
                        message:
                          "Kindly compose a discharge summary prior finishing an inpatient encounter",
                        actions: {
                          confirm: {
                            label: "Ok",
                          },
                          cancel: {
                            show: false,
                          },
                        },
                        dismissible: true,
                      });

                      confirmation.afterClosed().subscribe((result) => {
                        if (result === "confirmed") {
                          this._router.navigate(["/apps/patient/dashboard"]);
                        }
                      });
                    }
                  },
                });
            }
          },
          error: (error) => {
            this.snackbarEvent.emit(
              this._fhirPathService.evaluateToString(
                error.error as fhir.r4.OperationOutcome,
                "issue.where(severity='error').diagnostics"
              )
            );
          },
        });
      }
    });
    // this.ngOnInit();
  }

  triggerAnEvent() {
    this._composeService.triggerSomeEvent();
    this._composeService.triggerCompositionPanel(false);
    this._appConfigService.changeCompositionId("");
    this._appConfigService.changeCompositionMode("");
  }

  closeButton() {
    this._outpatientService.triggerSomeEvent();
  }

  //Get Name
  getNameText(patient: fhir.r4.Patient): string {
    let text = "";

    if (this._fhirPathService.evaluateToString(patient, "name.text")) {
      text = this._fhirPathService.evaluateToString(patient, "name.text");
    }

    return text;
  }

  //Get  ic number
  getIdentifierIc(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/my-kad-no').value"
    );
  }

  //Get  RN number
  getIdentifierRN(patient: fhir.r4.Patient): string {
    this._appConfigService.changePatientMrn(
      this._fhirPathService.evaluateToString(
        patient,
        "identifier.where(system='http://fhir.hie.moh.gov.my/sid/patient-mrn').value"
      )
    );
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/patient-mrn').value"
    );
  }
  /*    let passportNo = this._fhirPathService.evaluate(item, "resource.identifier.where(system='http://fhir.hie.moh.gov.my/sid/passport-no').value");
            let armyNo = this._fhirPathService.evaluate(item, "resource.identifier.where(system='http://fhir.hie.moh.gov.my/sid/army-no').value");
            let policeNo = this._fhirPathService.evaluate(item, "resource.identifier.where(system='http://fhir.hie.moh.gov.my/sid/police-no').value");
            let othersNo = this._fhirPathService.evaluate(item, "resource.identifier.where(system='http://fhir.hie.moh.gov.my/sid/others-no').value");
            let identifier = this._fhirPathService.evaluate(item, "resource.identifier.where(system='http://fhir.hie.moh.gov.my/sid/my-kad-no').value") + (passportNo.length > 0 ? "\n" + passportNo : "") + (armyNo.length > 0 ? "\n" + armyNo : "") + (policeNo.length > 0 ? "\n" + policeNo : "") + (othersNo.length > 0 ? "\n" + othersNo : "");
 */
  //Get passport number
  getIdentifierPassportNo(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/passport-no').value"
    );
  }

  //Get army number
  getIdentifierArmyNo(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/army-no').value"
    );
  }

  //Get police number
  getIdentifierPoliceNo(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/police-no').value"
    );
  }

  //Get  other number
  getIdentifierOtherNo(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/others-no').where(use='official').value"
    );
  }

  //Get  uknown number
  getIdentifierUknownNo(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/others-no').where(use='temp').value"
    );
  }

  //Get  Newborn No.
  getIdentifierNewbornNo(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(
      patient,
      "identifier.where(system='http://fhir.hie.moh.gov.my/sid/newborn-no').value"
    );
  }

  getBirthDate(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(patient, "birthDate");
  }

  //Get Patient Age
  public ageFromDateOfBirthday(calculateAge: fhir.r4.Patient): number {
    let birthdate = this.getBirthDate(calculateAge);
    return moment().diff(birthdate, "year");
  }

  getGender(patient: fhir.r4.Patient): string {
    return this._fhirPathService.evaluateToString(patient, "gender");
  }

  getMeta(patient: fhir.r4.Patient): string {
    //  console.log(this._fhirPathService.evaluateToString(patient, "meta.source"))
    return this._fhirPathService.evaluateToString(patient, "meta.source");
  }

  getId(encounter: fhir.r4.Encounter): string {
    return this._fhirPathService.evaluateToString(encounter, "entry.resource.ofType(Encounter).id");
  }

  getEncounterId(encounter: fhir.r4.Encounter): string {
    this._appConfigService.changeEncounterId(
      this._fhirPathService.evaluateToString(
        encounter,
        "entry.resource.ofType(Encounter).id.first()"
      )
    );
    this._appConfigService.changeEncounterIdentifier(
      this._fhirPathService.evaluateToString(
        encounter,
        "entry.resource.ofType(Encounter).identifier.where(system='http://fhir.hie.moh.gov.my/sid/encounter-id').value"
      )
    );
    return this._fhirPathService.evaluateToString(
      encounter,
      "entry.resource.ofType(Encounter).identifier.where(system='http://fhir.hie.moh.gov.my/sid/encounter-id').value"
    );
  }

  getEncounterStatus(encounter: fhir.r4.Encounter): string {
    this._appConfigService.changeEncounterStatus(
      this._fhirPathService.evaluateToString(
        encounter,
        "entry.resource.ofType(Encounter).status[0]"
      )
    );

    return this._fhirPathService.evaluateToString(
      encounter,
      "entry.resource.ofType(Encounter).status[0]"
    );
  }

  getLocationDisplay() {
    if (this._appConfigService.getLocationDisplay() !== "null")
      return this._appConfigService.getLocationDisplay();
  }

  getPractitionerDisplay() {
    if (this._appConfigService.getRoleDisplay() !== "null")
      return this._appConfigService.getRoleDisplay();
  }

  getPractitionerName() {
    if (this._appConfigService.getPractitionerName() !== "null")
      return this._appConfigService.getPractitionerName();
  }

  getPractitionerReference() {
    if (this.defaultLocation) return this.defaultLocation.data.attributes.practitionerReference;
  }

  // getColour(){
  //     return this.meta === ''
  // }

  startTimer() {
    this.interval = setInterval(() => {
      if (!this.sessionEndStart) {
        if ((this.min > 0 && this.sec > 0) || (this.min === 0 && this.sec > 15)) {
          this.sec--;
        } else if (this.min > 0) {
          this.sec = 59;
          this.min--;
        } else if (this.min === 0 && this.sec > 0 && this.sec <= 15) {
          this.alert.open();
          this.sessionEndStart = true;
        }
      } else {
        if (this.sec > 0) {
          this.sec--;
        } else {
          this.alert.close();
          this.stopTimer();

          localStorage.clear();
          this._authService.logout();
        }
      }
      //if use OnPush
      this._changeDetectorRef.detectChanges();
    }, 1000);
    this._authService.setInterval(this.interval);
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  resetTimer() {
    this.min = this._appConfigService.getMinSession() ? this._appConfigService.getMinSession() : 6;
    this.sec = this._appConfigService.getSecSession() ? this._appConfigService.getSecSession() : 59;
  }
}
