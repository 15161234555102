<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
	<!-- Flag -->
	<patient-notification></patient-notification>

	<!-- Order Management -->
	<!-- *ngIf="isOrderManagement; else medicalRecord" -->
	<mat-drawer-container #medicationOrdering class="flex-auto h-full" *ngIf="isOrderManagement">
		<patient-order-management></patient-order-management>
	</mat-drawer-container>

	<!-- Other medical record layout -->
	<!-- <ng-template #medicalRecord> -->
	<mat-drawer-container class="flex-auto h-full" *ngIf="!isOrderManagement">
		<!-- Drawer -->
		<mat-drawer class="w-72 dark:bg-gray-900" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
			<!-- Patient sidebar -->
			<patient-sidebar></patient-sidebar>
		</mat-drawer>

		<!-- Drawer content -->
		<mat-drawer-content class="flex flex-col overflow-hidden">
			<!-- Main -->
			<div class="flex flex-auto overflow-hidden">
				<router-outlet></router-outlet>
				<compose #compose *ngIf="compositionAllowed"></compose>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
	<!-- </ng-template> -->
</div>