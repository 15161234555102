<!-- Loading bar -->
<cad-loading-bar></cad-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <cad-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
  >
    <!-- Navigation header hook -->
    <ng-container cadVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-14 pt-6 px-8">
        <img class="w-12" src="assets/images/logo/jata.png" alt="Logo image" />
      </div>
    </ng-container>
  </cad-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <ng-container>
    <div
      [style.background-color]="styleOne ? '#5a2a63' : styleTwo ? '#89CFF0' : null"
      *ngIf="isPatientSelected"
    >
      <div class="max-w-7xl mx-auto py-2 px-2 sm:px-6 lg:px-8">
        <div class="sm:flex items-center justify-center">
          <!-- Related Person Start -->
          <div *ngIf="viewMedicalRecord" class="bg-white flex">
            <igx-combo
              *ngIf="relatedPersonCombo && relatedPersons?.length > 0"
              #relationship
              class="w-80"
              [displayKey]="'name'"
              [valueKey]="'id'"
              [groupKey]="'relationship'"
              [data]="relatedPatients"
              (selectionChanging)="relatedPersonSelect($event); relationship.close()"
            >
              <label igxLabel>Related Person</label>
            </igx-combo>
          </div>
          <!-- Related Person End -->
          <div class="p-2 sm:p-4 leading-none" *ngIf="!isScreenSmall">
            <div class="mt-2">
              <ng-container *ngIf="relatedPatients?.length > 0">
                <span>
                  <span
                    class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium"
                  >
                    {{ relatedPatients.length }}
                  </span>
                </span>
              </ng-container>
              <mat-icon
                #userIcon
                class="mat-icon-rtl-mirror"
                svgIcon="heroicons_outline:user"
                style="color: white"
                (click)="relatedPersonCombo = !relatedPersonCombo"
              >
              </mat-icon>
              <!-- <mat-icon class="mat-icon-rtl-mirror" svgIcon="heroicons_outline:user" style="color: white">
              </mat-icon> -->
            </div>
          </div>
          <div class="p-2 sm:p-4 leading-none">
            <div *ngIf="!isScreenSmall; else smallLayout">
              <div class="mt-2 font-normal text-white truncate">{{ getNameText(patient) }}</div>
              <div class="mt-2 font-normal text-white truncate">
                <span *ngIf="getIdentifierIc(patient) !== null">
                  {{ getIdentifierIc(patient) }}
                </span>
                <span *ngIf="getIdentifierPassportNo(patient) !== null">
                  {{ getIdentifierPassportNo(patient) }}
                </span>
                <span *ngIf="getIdentifierArmyNo(patient) !== null">
                  ({{ getIdentifierArmyNo(patient) }})
                </span>
                <span *ngIf="getIdentifierPoliceNo(patient) !== null">
                  ({{ getIdentifierPoliceNo(patient) }})
                </span>
                <span *ngIf="getIdentifierOtherNo(patient) !== null">
                  {{ getIdentifierOtherNo(patient) }}
                </span>
                <span *ngIf="getIdentifierNewbornNo(patient) !== null">
                  {{ getIdentifierNewbornNo(patient) }}
                </span>
                <span *ngIf="getIdentifierUknownNo(patient) !== null">
                  {{ getIdentifierUknownNo(patient) }}
                </span>
                <!-- <span *ngIf="getIdentifierUknownNo(patient) !== null">
                  {{ getIdentifierUknownNo(patient) }}
                </span> -->
              </div>
            </div>
            <div *ngIf="isScreenSmall" #smallLayout>
              <div class="layout">
                <!-- <div class="mt-2 font-normal text-white truncate"></div> -->
                <div class="mt-2 font-normal text-white truncate">
                  <p>
                    {{ getNameText(patient) }}
                  </p>
                  <span *ngIf="getIdentifierIc(patient) !== null">
                    {{ getIdentifierIc(patient) }}
                  </span>
                  <span *ngIf="getIdentifierPassportNo(patient) !== null">
                    {{ getIdentifierPassportNo(patient) }}
                  </span>
                  <span *ngIf="getIdentifierArmyNo(patient) !== null">
                    ({{ getIdentifierArmyNo(patient) }})
                  </span>
                  <span *ngIf="getIdentifierPoliceNo(patient) !== null">
                    ({{ getIdentifierPoliceNo(patient) }})
                  </span>
                  <span *ngIf="getIdentifierOtherNo(patient) !== null">
                    {{ getIdentifierOtherNo(patient) }}
                  </span>
                  <span *ngIf="getIdentifierNewbornNo(patient) !== null">
                    {{ getIdentifierNewbornNo(patient) }}
                  </span>
                  <span *ngIf="getIdentifierUknownNo(patient) !== null">
                    {{ getIdentifierUknownNo(patient) }}
                  </span>
                  <p>
                    {{ getIdentifierRN(patient) }}
                  </p>
                  <p>{{ getEncounterId(encounter) }} ( {{ getEncounterStatus(encounter) }} )</p>
                  <p>
                    <button mat-icon-button (click)="goMedicalRecord()" matTooltip="Medical Record">
                      <mat-icon [svgIcon]="'heroicons_outline:clipboard-list'"></mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matTooltip="Cancel Tag"
                      aria-label="close tag"
                      (click)="closeTag()"
                    >
                      <mat-icon [svgIcon]="'heroicons_outline:ban'"></mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matTooltip="End Consultation"
                      aria-label="end consultation"
                      *ngIf="viewEndConsultation"
                      (click)="goEndConsultation(); viewEndConsultation = true"
                    >
                      <mat-icon [svgIcon]="'heroicons_outline:badge-check'"></mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matTooltip="VC Link"
                      aria-label="VC Link"
                      *ngIf="viewVcLink"
                      (click)="goVcLink(); viewVcLink = true"
                    >
                      <mat-icon [svgIcon]="'heroicons_outline:video-camera'"></mat-icon>
                    </button>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 sm:p-4 leading-none" *ngIf="!isScreenSmall">
            <div class="mt-2 font-normal text-white truncate">
              {{ getGender(patient) | titlecase }}
            </div>
            <div class="mt-2 font-normal text-white truncate">
              {{ ageFromDateOfBirthday(patient) ? ageFromDateOfBirthday(patient) : "-" }}
              <span *ngIf="ageFromDateOfBirthday(patient)">Y</span>
            </div>
          </div>
          <div class="p-2 sm:p-4 leading-none hidden md:block" *ngIf="!isScreenSmall">
            <div class="mt-2 font-normal text-white truncate">
              RN : {{ getEncounterId(encounter) }}
              <span *ngIf="getEncounterStatus(encounter) !== null">(</span>
              {{ getEncounterStatus(encounter) }}
              <span *ngIf="getEncounterStatus(encounter) !== null">)</span>
            </div>

            <div class="mt-2 font-normal text-white truncate">
              MRN : {{ getIdentifierRN(patient) }}
            </div>
          </div>
          <div class="p-2 sm:p-4 leading-none" *ngIf="!isScreenSmall">
            <div class="mt-2">
              <mat-icon
                class="mat-icon-rtl-mirror"
                svgIcon="heroicons_outline:office-building"
                style="color: white"
              >
              </mat-icon>
            </div>
          </div>
          <div class="p-2 sm:p-4 leading-none" *ngIf="!isScreenSmall">
            <div class="mt-2 font-normal text-white truncate">
              {{ getPractitionerName() }}
              <span *ngIf="getPractitionerDisplay() !== null">(</span>
              {{ getPractitionerDisplay() }}
              <span *ngIf="getPractitionerDisplay() !== null">)</span>
            </div>
            <div class="mt-2 font-normal text-white truncate">{{ getLocationDisplay() }}</div>
          </div>
          <div class="p-2 md:p-3 leading-none" *ngIf="!isScreenSmall">
            <div *ngIf="viewMedicalRecord">
              <button
                mat-raised-button
                color="accent"
                matTooltip="Medical Record"
                (click)="goMedicalRecord(event, getEncounterStatus(encounter))"
              >
                Medical Record
              </button>
            </div>
          </div>
          <div class="p-2 md:p-3 leading-none" *ngIf="!isScreenSmall">
            <button mat-raised-button color="accent" matTooltip="Cancel" (click)="closeTag()">
              Cancel
            </button>
          </div>
          <div class="p-2 md:p-3 leading-none" *ngIf="!isScreenSmall">
            <div *ngIf="viewEndConsultation">
              <button
                mat-raised-button
                color="accent"
                matTooltip="End Encounter"
                (click)="goEndConsultation(event, getId(encounter))"
              >
                End Encounter
              </button>
            </div>
          </div>
          <div class="p-2 md:p-3 leading-none" *ngIf="!isScreenSmall">
            <div *ngIf="viewVcLink">
              <button
                mat-raised-button
                color="accent"
                matTooltip="VC Link"
                (click)="goVcLink(event, getId(encounter))"
              >
                <!-- <mat-icon [svgIcon]="'heroicons_outline:video-camera'"></mat-icon> -->
                VC Link
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Header -->
  <div class="relative flex flex-0 items-center w-full h-16 sm:h-14 px-4 bg-card print:hidden">
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <div class="flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex">
          <img class="dark:hidden w-12" src="assets/images/logo/jata.png" />
          <img class="hidden dark:flex w-12" src="assets/images/logo/jata.png" />
        </div>
        <img class="flex lg:hidden w-8" src="assets/images/logo/jata.png" />
      </div>
      <!-- <div class="flex items-center mx-2 lg:mr-8">
        <b>{{ instName | uppercase }}</b>
      </div> -->
      <!-- Horizontal navigation -->
      <cad-horizontal-navigation
        class="mr-2"
        [name]="'mainNavigation'"
        [navigation]="navigation.horizontal"
      >
      </cad-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    </ng-container>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <button [matTooltip]="'Person search'" [routerLink]="['apps/person-search']" mat-icon-button>
        <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
      </button> -->
      <!--
      <button [matTooltip]="'Info Center'" [routerLink]="['apps/info-center']" mat-icon-button>
        <mat-icon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
      </button>
      -->
      <!--<languages></languages>-->
      <cad-fullscreen class="hidden md:block"></cad-fullscreen>
      <!--<shortcuts></shortcuts>-->
      <!--<notifications></notifications>-->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <router-outlet></router-outlet>
    <igx-dialog
      #alert
      title="Are you still there?"
      message="If not, we'll close this session in 15 seconds"
      leftButtonLabel="OK"
      (leftButtonSelect)="alert.close()"
    >
    </igx-dialog>
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center w-full h-10 sm:h-10 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary pr-10" *ngIf="!isScreenSmall"
      >RekodPesakit &copy; {{ currentYear }}</span
    >

    <div class="rounded px-4" style="background-color: #8338ec; color: white">
      Session Timeout in {{ min }} Min {{ sec }} Sec
    </div>
    <!-- <session-timer></session-timer> -->
  </div>
</div>
