import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError } from "rxjs";
import { FhirConfigService } from "app/fhirconfig.service";
import {
	FilteringExpressionsTree,
	FilteringLogic,
	IForOfState,
} from "@infragistics/igniteui-angular";
import { formatDate } from "@angular/common";
import moment from "moment";
import { AppConfigService } from "app/appconfig.service";

const EMPTY_STRING = "";
const NULL_VALUE = null;

const fhirHttpOptions = {
	headers: new HttpHeaders({
		"cache-control": "no-cache",
		Accept: "application/fhir+json",
	}),
};

export enum FILTER_OPERATION {
	CONTAINS = "contains=",
	STARTS_WITH = "startswith",
	ENDS_WITH = "endswith",
	EQUALS = "=",
	DOES_NOT_EQUAL = "!=",
	DOES_NOT_CONTAIN = "not contains",
	GREATER_THAN = "gt",
	LESS_THAN = "lt",
	LESS_THAN_EQUAL = "le",
	GREATER_THAN_EQUAL = "ge",
}

@Injectable({
	providedIn: "root",
})
export class VcRoomService {

	private _bundle: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
	public bundle$: Observable<fhir.r4.Bundle> = this._bundle.asObservable();

	// Hold vc data
	public _vcData: BehaviorSubject<any> = new BehaviorSubject(null);

	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _fhirConfigService: FhirConfigService,
		private _appConfigService: AppConfigService
	) { }

	// -----------------------------------------------------------------------------------------------------
	// @ Public Methods
	// -----------------------------------------------------------------------------------------------------

	
}
