import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError } from "rxjs";
import { FhirConfigService } from "app/fhirconfig.service";
import { AppConfigService } from "app/appconfig.service";
import { FhirPathService } from "app/fhirpath.service";
const fhirHttpOptions = {
  headers: new HttpHeaders({
    "Cache-Control": "no-cache",
    Accept: "application/fhir+json",
  }),
};
@Injectable({
  providedIn: "root",
})
export class PatientService {
  //RESOURCE RelatedPerson
  private _relatedPersons: BehaviorSubject<fhir.r4.RelatedPerson[]> = new BehaviorSubject(null);
  private _relatedPatients: BehaviorSubject<any[]> = new BehaviorSubject(null);

  ////RESOURCE Patient
  private _patientsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _patient: BehaviorSubject<fhir.r4.Patient> = new BehaviorSubject(null);
  private _patients: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  // RESOURCE Encounter
  private _encountersLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _encounter: BehaviorSubject<fhir.r4.Encounter> = new BehaviorSubject(null);
  private _encounters: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  ////RESOURCE Problems
  private _problems: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
  private _problemsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ////CONSENT
  private _consent: BehaviorSubject<fhir.r4.Consent> = new BehaviorSubject(null);

  ////RESOURCE Observation
  private _observationsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _observation: BehaviorSubject<fhir.r4.Observation> = new BehaviorSubject(null);
  private _observations: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  // vital signs
  private _vitalSignsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _vitalSign: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
  private _vitalSigns: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  ////RESOURCE Allergys
  private _allergysLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _allergy: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
  private _allergys: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  // flags
  private _flags: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  // medications
  private _medications: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
  private _medicationsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _medication: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _appConfigService: AppConfigService,
    private _fhirPathService: FhirPathService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  get relatedpersons$(): Observable<fhir.r4.RelatedPerson[]> {
    return this._relatedPersons.asObservable();
  }
  get relatedpatients$(): Observable<any[]> {
    return this._relatedPatients.asObservable();
  }
  ////PATIENTS
  /**
   * Getter for patients loading
   */
  get patientsLoading$(): Observable<boolean> {
    return this._patientsLoading.asObservable();
  }

  /**
   * Getter for FHIR bundle of patient
   */
  get patients$(): Observable<fhir.r4.Bundle> {
    return this._patients.asObservable();
  }

  ////ENCOUNTERS
  /**
   * Getter for encounters loading
   */
  get encountersLoading$(): Observable<boolean> {
    return this._encountersLoading.asObservable();
  }

  /**
   * Getter for encounter
   */
  get encounter$(): Observable<fhir.r4.Encounter> {
    return this._encounter.asObservable();
  }

  /**
   * Getter for FHIR bundle of encounter
   */
  get encounters$(): Observable<fhir.r4.Bundle> {
    return this._encounters.asObservable();
  }

  setEncounters$(val) {
    this._encounters.next(val);
  }

  /**
   * Getter for problems loading
   */
  get problemsLoading$(): Observable<boolean> {
    return this._problemsLoading.asObservable();
  }

  ////CONSENT
  /**
   * Getter for observation
   */
  get consent$(): Observable<fhir.r4.Consent> {
    return this._consent.asObservable();
  }

  ////OBSERVATIONS
  /**
   * Getter for observations loading
   */
  get observationsLoading$(): Observable<boolean> {
    return this._observationsLoading.asObservable();
  }

  /**
   * Getter for FHIR bundle of observation
   */
  get observations$(): Observable<fhir.r4.Bundle> {
    return this._observations.asObservable();
  }

  /**
   * Getter for vital signs loading
   */
  get vitalSignsLoading$(): Observable<boolean> {
    return this._vitalSignsLoading.asObservable();
  }

  /**
   * Getter for FHIR bundle of vital signs
   */
  get vitalSigns$(): Observable<fhir.r4.Bundle> {
    return this._vitalSigns.asObservable();
  }

  ////ALLERGYS
  /**
   * Getter for allergys loading
   */
  get allergysLoading$(): Observable<boolean> {
    return this._allergysLoading.asObservable();
  }

  /**
   * Getter for allergys
   */
  get allergys$(): Observable<fhir.r4.Bundle> {
    return this._allergys.asObservable();
  }

  /**
   * Getter for allergys
   */
  get flags$(): Observable<fhir.r4.Bundle> {
    return this._flags.asObservable();
  }

  /**
   * Getter for medications
   */
  get medications$(): Observable<fhir.r4.Bundle> {
    return this._medications.asObservable();
  }

  /**
   * Getter for medication
   */
  get medication$(): Observable<fhir.r4.Bundle> {
    return this._medication.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  getPatient(id: string): Observable<fhir.r4.Patient> {
    return this._httpClient
      .get<fhir.r4.Patient>(this._fhirConfigService.getFhirService() + "/Patient/" + id)
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Patient = JSON.parse(letjstring);
          console.log(letjstring);
        })
      );
  }
  getRealatedPersons(id: string): Observable<fhir.r4.RelatedPerson[]> {
    return this._httpClient
      .get<fhir.r4.RelatedPerson[]>(
        this._fhirConfigService.getFhirService() +
          "/RelatedPerson?active=true" +
          "&_include=RelatedPerson:patient" +
          "&identifier=" +
          id
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);

          let relatedPersons: fhir.r4.RelatedPerson[] = this._fhirPathService.evaluate(
            jsonObject,
            "entry.resource.ofType(RelatedPerson)"
          );
          this._relatedPersons.next(relatedPersons);
          let patients: fhir.r4.Patient[] = this._fhirPathService.evaluate(
            jsonObject,
            "entry.resource.ofType(Patient)"
          );

          let relateds: any[] = [];
          for (let i = 0; i < patients.length; i++) {
            let related: any = <any>{
              id: patients[i].id,
              name: patients[i].name[0].text,
            };
            relateds.push(related);
          }
          this._relatedPatients.next(relateds);
        })
      );
  }
  /**
   * Get patients
   */
  getPatients(): Observable<any> {
    // Execute the loading with true
    this._patientsLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Patient>(
        this._fhirConfigService.getFhirService() +
          "/Patient?_id=" +
          this._appConfigService.getPatientResourceId() +
          ""
      )
      .pipe(
        tap((response: any) => {
          this._patients.next(response);
          this._patientsLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Reset the current patient
   */
  resetPatient(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this._patient.next(null);
      })
    );
  }

  /**
   * Get encounters
   */
  getEncounters(includeOrganization: boolean): Observable<any> {
    // Execute the loading with true
    this._encountersLoading.next(true);

    let query: string = "";

    // If consent status rejected/inactive, include instCode
    if (includeOrganization) {
      query += "&service-provider=" + this._appConfigService.getInstCode() + "";
    }

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
          "/Encounter?subject:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "&_include=Encounter:account" +
          "&_include=Encounter:appointment" +
          "&_include=Encounter:based-on" +
          "&_include=Encounter:diagnosis" +
          "&_include=Encounter:episode-of-care" +
          "&_include=Encounter:part-of" +
          "&_include=Encounter:location" +
          "&_include=Encounter:participant" +
          "&_include=Encounter:patient" +
          "&_include=Encounter:practitioner" +
          "&_include=Encounter:service-provider" +
          "&_include=Encounter:reason-reference" +
          "&_include=Encounter:subject&_sort=-date" +
          query,
        fhirHttpOptions
      )
      .pipe(
        tap((response: any) => {
          this._encounters.next(response);
          this._encountersLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Reset the current patient
   */
  resetEncounter(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this._encounter.next(null);
      })
    );
  }

  /**
   * Get observations
   */
  getObservations(): Observable<any> {
    // Execute the loading with true
    this._observationsLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
          "/Observation?subject:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "&code=29463-7,8302-2,LP17806-8&_sort=-date",
        fhirHttpOptions
      )
      .pipe(
        tap((response: any) => {
          this._observations.next(response);
          this._observationsLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Reset the current observation
   */
  resetObservation(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this._observation.next(null);
      })
    );
  }

  /**
   * Get vital signs
   */
  getVitalSigns(): Observable<any> {
    // Execute the loading with true
    this._vitalSignsLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
          "/Observation?subject:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "&code=85353-1" +
          "&_include=Observation:has-member" +
          "&_count=1&_sort=-_lastUpdated" +
          "&encounter=" +
          this._appConfigService.getEncounterId() +
          "",
        fhirHttpOptions
      )
      .pipe(
        tap((response: any) => {
          this._vitalSigns.next(response);
          this._vitalSignsLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Reset the current vital signs
   */
  resetVitalSigns(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this._vitalSign.next(null);
      })
    );
  }

  /**
   * Get consent status
   */
  getConsent(): Observable<any> {
    // Execute the problem loading with true
    this._problemsLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Consent>(
        this._fhirConfigService.getFhirService() +
          "/Consent?scope=patient-privacy" +
          "&patient:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "",
        fhirHttpOptions
      )
      .pipe(
        tap((response: any) => {
          this._consent.next(response);
          this._problemsLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Get allergys
   */
  getAllergys(): Observable<any> {
    // Execute the loading with true
    this._allergysLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
          "/AllergyIntolerance?patient:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "&_include=AllergyIntolerance:asserter" +
          "&_include=AllergyIntolerance:encounter" +
          "&_include=AllergyIntolerance:patient" +
          "&_include=AllergyIntolerance:recorder" +
          "&_include:iterate=PractitionerRole:practitioner" +
          "&_sort=-date",
        fhirHttpOptions
      )
      .pipe(
        tap((response: any) => {
          this._allergys.next(response);
          this._allergysLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Reset the current allergys
   */
  resetAllergys(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this._allergy.next(null);
      })
    );
  }

  /**
   * Get consent status
   */
  getFlags(): Observable<any> {
    // Execute the problem loading with true
    this._problemsLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Consent>(
        this._fhirConfigService.getFhirService() +
          "/Flag?subject:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "&_include=Flag:author&_include=Flag:encounter&_include=Flag:patient&_include=Flag:subject",
        fhirHttpOptions
      )
      .pipe(
        tap((response: any) => {
          this._flags.next(response);
          this._problemsLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Get medications (mdm record)
   */
  getMedications(includeOrganization: Boolean): Observable<any> {
    // Execute the medication loading with true
    this._medicationsLoading.next(true);

    let query: string = "";

    // If consent status rejected/inactive, include instCode
    if (includeOrganization) {
      query += "&encounter.service-provider=" + this._appConfigService.getInstCode() + "";
    }

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
          "/MedicationRequest?subject:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "&_include=MedicationRequest:encounter" +
          "&_include=MedicationRequest:intended-dispenser" +
          "&_include=MedicationRequest:intended-performer" +
          "&_include=MedicationRequest:medication" +
          "&_include=MedicationRequest:patient" +
          "&_include=MedicationRequest:recorder" +
          "&_include=MedicationRequest:requester" +
          "&_include=MedicationRequest:subject" +
          "&_include:iterate=PractitionerRole:practitioner" +
          "&_include:iterate=PractitionerRole:practitioner" +
          "&_sort=-authoredon" +
          query,
        fhirHttpOptions
      )
      .pipe(
        tap((response: any) => {
          this._medications.next(response);
          this._medicationsLoading.next(false);
        }),
        switchMap((response) => {
          if (response.medications === null) {
            return throwError({
              message: "Requested page is not available!",
              pagination: response.pagination,
            });
          }

          return of(response);
        })
      );
  }
}
