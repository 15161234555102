import { AppointmentMockApi } from 'app/mock-api/apps/appointment/api';
import { ProvidersMockApi } from 'app/mock-api/apps/facility/api';
import { HelpCenterMockApi } from 'app/mock-api/apps/help/api';
import { PatientMockApi } from 'app/mock-api/apps/patient/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { UserMockApi } from 'app/mock-api/common/user/api';

export const mockApiServices = [
    AppointmentMockApi,
    ProvidersMockApi,
    HelpCenterMockApi,
    PatientMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    ShortcutsMockApi,
    UserMockApi,
];
