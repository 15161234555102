import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver } from "app/app.resolvers";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboards/project'
  { path: "", pathMatch: "full", redirectTo: "/dashboard/console/ssvc" },

  // Redirect signed in user to the '/dashboards/project'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: "signed-in-redirect",
    pathMatch: "full",
    redirectTo: "/dashboard/console/ssvc",
  },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("app/modules/cad/landing/home/home.module").then((m) => m.LandingHomeModule),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboards
      {
        path: "dashboard",
        children: [
          {
            path: "console",
            loadChildren: () =>
              import("app/modules/dashboard/console.module").then((m) => m.ConsoleModule),
          },
        ],
      },

      {
        path: "user-profile",
        loadChildren: () =>
          import("app/modules/cad/auth/user-profile/user-profile.module").then(
            (m) => m.UserProfileModule
          ),
      },

      // Apps
      {
        path: "apps",
        children: [
          {
            path: "appointment",
            loadChildren: () =>
              import("app/modules/provider/appointment/appointment.module").then(
                (m) => m.AppointmentModule
              ),
          },
          {
            path: "providers",
            loadChildren: () =>
              import("app/modules/provider/facility/providers.module").then(
                (m) => m.ProvidersModule
              ),
          },
          {
            path: "person-search",
            loadChildren: () =>
              import("app/modules/provider/person-search/person-search.module").then(
                (m) => m.PersonSearchModule
              ),
          },
          {
            path: "patient",
            loadChildren: () =>
              import("app/modules/provider/patient/patient.module").then((m) => m.PatientModule),
          },
          {
            path: "scheduling",
            loadChildren: () =>
              import("app/modules/provider/scheduling/scheduling.module").then(
                (m) => m.SchedulingModule
              ),
          },
          {
            path: "patient-appointment",
            loadChildren: () =>
              import("app/modules/provider/appointment/appointment.module").then(
                (m) => m.AppointmentModule
              ),
          },
          {
            path: "patient-appointment-vc",
            loadChildren: () =>
              import("app/modules/provider/appointment-vc/appointment-vc.module").then(
                (m) => m.AppointmentVcModule
              ),
          },
          {
            path: "laboratory-console",
            loadChildren: () =>
              import("app/modules/provider/laboratory-console/laboratory-console.module").then(
                (m) => m.LaboratoryConsoleModule
              ),
          },
          {
            path: "phlebotomy-station",
            loadChildren: () =>
              import("app/modules/provider/phlebotomy-station/phlebotomy-station.module").then(
                (m) => m.PhlebotomyStationModule
              ),
          },
          {
            path: "vc-room",
            loadChildren: () =>
              import("app/modules/provider/vc-room/vc-room.module").then((m) => m.VcRoomModule),
          },
          {
            path: "pharmacy-console",
            loadChildren: () =>
              import("app/modules/provider/pharmacy-console/pharmacy-console.module").then((m) => m.PharmacyConsoleModule),
          },
        ],
      },

      // Admin
      {
        path: "settings",
        children: [
          {
            path: "organization",
            loadChildren: () =>
              import("app/modules/provider/organization/organization.module").then(
                (m) => m.OrganizationModule
              ),
          },
          {
            path: "system-admin",
            loadChildren: () =>
              import("app/modules/cad/system-admin.module").then((m) => m.SystemAdminModule),
          },
          {
            path: "reference-group",
            loadChildren: () =>
              import("app/modules/cad/reference-group/reference-group.module").then(
                (m) => m.ReferenceGroupModule
              ),
          },
          {
            path: "reference-code",
            loadChildren: () =>
              import("app/modules/cad/reference-code/reference-code.module").then(
                (m) => m.ReferenceCodeModule
              ),
          },
          {
            path: "system-module",
            loadChildren: () =>
              import("app/modules/cad/system-module/system-module.module").then(
                (m) => m.SystemModuleModule
              ),
          },
          {
            path: "country",
            loadChildren: () =>
              import("app/modules/cad/country/country.module").then((m) => m.CountryModule),
          },
          {
            path: "region",
            loadChildren: () =>
              import("app/modules/cad/region/region.module").then((m) => m.RegionModule),
          },
          {
            path: "state",
            loadChildren: () =>
              import("app/modules/cad/state/state.module").then((m) => m.StateModule),
          },
          {
            path: "district",
            loadChildren: () =>
              import("app/modules/cad/district/district.module").then((m) => m.DistrictModule),
          },
          {
            path: "change-log",
            loadChildren: () =>
              import("app/modules/cad/change-log/change-log.module").then((m) => m.ChangeLogModule),
          },
          {
            path: "database-job-log",
            loadChildren: () =>
              import("app/modules/cad/database-job-log/database-job-log.module").then(
                (m) => m.DatabaseJobLogModule
              ),
          },
          {
            path: "event-log",
            loadChildren: () =>
              import("app/modules/cad/event-log/event-log.module").then((m) => m.EventLogModule),
          },
          {
            path: "attachment-log",
            loadChildren: () =>
              import("app/modules/cad/attachment-log/attachment-log.module").then(
                (m) => m.AttachmentLogModule
              ),
          },
          {
            path: "output-template",
            loadChildren: () =>
              import("app/modules/cad/output-template/output-template.module").then(
                (m) => m.OutputTemplateModule
              ),
          },
          {
            path: "outbox",
            loadChildren: () =>
              import("app/modules/cad/outbox/outbox.module").then((m) => m.OutboxModule),
          },
          {
            path: "user-admin",
            loadChildren: () =>
              import("app/modules/cad/user-admin.module").then((m) => m.UserAdminModule),
          },
          {
            path: "drug-master",
            loadChildren: () =>
              import("app/modules/pharmacy/drug-master/drug-master.module").then(
                (m) => m.DrugMasterModule
              ),
          },
          {
            path: "drug-master-list",
            loadChildren: () =>
              import("app/modules/pharmacy/drug-master-list/drug-master.module").then(
                (m) => m.DrugMasterModule
              ),
          },
          {
            path: "medication",
            loadChildren: () =>
              import("app/modules/pharmacy/drug-facility/drug-facility.module").then(
                (m) => m.DrugFacilityModule
              ),
          },
          {
            path: "facility360",
            loadChildren: () =>
              import("app/modules/facility/facility360/facility360.module").then(
                (m) => m.Facility360Module
              ),
          },
          {
            path: "practitioner",
            loadChildren: () =>
              import("app/modules/provider/practitioner/practitioner.module").then(
                (m) => m.PractitionerModule
              ),
          },
          {
            path: "location",
            loadChildren: () =>
              import("app/modules/provider/location/location.module").then((m) => m.LocationModule),
          },
          {
            path: "practitioner-role",
            loadChildren: () =>
              import("app/modules/provider/practitioner-role/practitioner-role.module").then(
                (m) => m.PractitionerRoleModule
              ),
          },
          {
            path: "healthcare-service",
            loadChildren: () =>
              import("app/modules/provider/healthcare-service/healthcare-service.module").then(
                (m) => m.HealthcareModule
              ),
          },
          {
            path: "healthcare-service-lab",
            loadChildren: () =>
              import(
                "app/modules/provider/healthcare-service-lab/healthcare-service-lab.module"
              ).then((m) => m.HealthcareLabModule),
          },
          {
            path: "healthcare-service-imaging",
            loadChildren: () =>
              import(
                "app/modules/provider/healthcare-imaging/healthcare-imaging.module"
              ).then((m) => m.HealthcareImagingModule),
          },
          {
            path: "menu-set",
            loadChildren: () =>
              import("app/modules/cad/menu-set/menu-set.module").then((m) => m.MenuSetModule),
          },
          {
            path: "email-template",
            loadChildren: () =>
              import("app/modules/cad/email-template/email-template.module").then(
                (m) => m.EmailTemplateModule
              ),
          },
          {
            path: "sent-box",
            loadChildren: () =>
              import("app/modules/cad/sent-box/sent-box.module").then((m) => m.SentBoxModule),
          },
          {
            path: "designation-grade",
            loadChildren: () =>
              import("app/modules/cad/designation-grade/designation-grade.module").then(
                (m) => m.DesignationGradeModule
              ),
          },
          {
            path: "affiliation",
            loadChildren: () =>
              import("app/modules/provider/affiliation/affiliation.module").then(
                (m) => m.AffiliationModule
              ),
          },
          {
            path: "integration-code-mapping",
            loadChildren: () =>
              import(
                "app/modules/cad/integration-code-mapping/integration-code-mapping.module"
              ).then((m) => m.IntegrationCodeMappingModule),
          },
          {
            path: "inbox",
            loadChildren: () =>
              import("app/modules/cad/inbox/inbox.module").then((m) => m.InboxModule),
          },
          {
            path: "public-holiday",
            loadChildren: () =>
              import("app/modules/cad/public-holiday/public-holiday.module").then(
                (m) => m.PublicHolidayModule
              ),
          },
          {
            path: "tenant-access-rights",
            loadChildren: () =>
              import("app/modules/cad/tenant-access-right/tenant-access-right.module").then(
                (m) => m.TenantAccessRightModule
              ),
          },
          {
            path: "facility-event",
            loadChildren: () =>
              import("app/modules/provider/facility-event/facility-event.module").then(
                (m) => m.FacilityEventModule
              ),
          },
          // {
          //   path: "referral",
          //   loadChildren: () =>
          //     import("app/modules/referral/referral.module").then((m) => m.ReferralModule),
          // },
        ],
      },

      // System Config
      {
        path: "system-config",
        children: [
          {
            path: "output-template",
            loadChildren: () =>
              import("app/modules/cad/output-template/output-template.module").then(
                (m) => m.OutputTemplateModule
              ),
          },
          {
            path: "system-module",
            loadChildren: () =>
              import("app/modules/cad/system-module/system-module.module").then(
                (m) => m.SystemModuleModule
              ),
          },
          {
            path: "record-autono",
            loadChildren: () =>
              import("app/modules/cad/record-auto-no/record-autono.module").then(
                (m) => m.RecordAutonoModule
              ),
          },
        ],
      },

      // System Admin
      {
        path: "system-admin",
        children: [
          {
            path: "settings/intergration-code-mapping",
            loadChildren: () =>
              import(
                "app/modules/cad/integration-code-mapping/integration-code-mapping.module"
              ).then((m) => m.IntegrationCodeMappingModule),
          },
          {
            path: "settings/output-template",
            loadChildren: () =>
              import("app/modules/cad/output-template/output-template.module").then(
                (m) => m.OutputTemplateModule
              ),
          },
          {
            path: "settings/notification-log",
            loadChildren: () =>
              import("app/modules/cad/notification-log/notification-log.module").then(
                (m) => m.NotificationLogModule
              ),
          },
          {
            path: "settings/inst-system-parameter",
            loadChildren: () =>
              import("app/modules/cad/inst-system-parameter/inst-system-parameter.module").then(
                (m) => m.InstSystemParameterModule
              ),
          },
          {
            path: "settings/inst-record-autono",
            loadChildren: () =>
              import("app/modules/cad/inst-record-auto-no/record-autono.module").then(
                (m) => m.InstRecordAutonoModule
              ),
          },
        ],
      },

      // User Admin
      {
        path: "user-admin",
        children: [
          {
            path: "settings/intergration-code-mapping",
            loadChildren: () =>
              import(
                "app/modules/cad/integration-code-mapping/integration-code-mapping.module"
              ).then((m) => m.IntegrationCodeMappingModule),
          },
          {
            path: "settings/inst-output-template",
            loadChildren: () =>
              import("app/modules/cad/inst-output-template/output-template.module").then(
                (m) => m.OutputTemplateModule
              ),
          },
          {
            path: "settings/access-log",
            loadChildren: () =>
              import("app/modules/cad/access-log/access-log.module").then((m) => m.AccessLogModule),
          },
          {
            path: "settings/inst-record-autono",
            loadChildren: () =>
              import("app/modules/cad/inst-record-auto-no/record-autono.module").then(
                (m) => m.InstRecordAutonoModule
              ),
          },
          {
            path: "settings/attachment-log",
            loadChildren: () =>
              import("app/modules/cad/attachment-log/attachment-log.module").then(
                (m) => m.AttachmentLogModule
              ),
          },
          {
            path: "settings/inst-system-parameter",
            loadChildren: () =>
              import("app/modules/cad/inst-system-parameter/inst-system-parameter.module").then(
                (m) => m.InstSystemParameterModule
              ),
          },
        ],
      },

      // Frontdesk
      {
        path: "frontdesk",
        children: [
          {
            path: "appointment-book",
            loadChildren: () =>
              import("app/modules/provider/appointment-book/appointment-book.module").then(
                (m) => m.AppointmentBookModule
              ),
          },
          {
            path: "patient-registration",
            loadChildren: () =>
              import("app/modules/provider/patient-registration/patient-registration.module").then(
                (m) => m.PatientRegistrationModule
              ),
          },
          {
            path: "related-person",
            loadChildren: () =>
              import("app/modules/provider/related-person/related-person.module").then(
                (m) => m.RelatedPersonModule
              ),
          },
          {
            path: "encounter-registration",
            loadChildren: () =>
              import(
                "app/modules/provider/encounter-registration/encounter-registration.module"
              ).then((m) => m.EncounterRegistrationModule),
          },
          {
            path: "phr-acc-activation",
            loadChildren: () =>
              import("app/modules/provider/phr-acc-activation/phr-activation.module").then(
                (m) => m.PhrActivationModule
              ),
          },
        ],
      },

      // Helpdesk
      {
        path: "helpdesk",
        children: [
          {
            path: "service-category",
            loadChildren: () =>
              import("app/modules/helpdesk/service-category/service-category.module").then(
                (m) => m.ServiceCategoryModule
              ),
          },
          {
            path: "ticket-console",
            loadChildren: () =>
              import("app/modules/helpdesk/ticket-console/ticket-console.module").then(
                (m) => m.TicketConsoleModule
              ),
          },
          {
            path: "ticket-console-attender",
            loadChildren: () =>
              import(
                "app/modules/helpdesk/ticket-console-attender/ticket-console-attender.module"
              ).then((m) => m.TicketConsoleAttenderModule),
          },
          {
            path: "new-ticket",
            loadChildren: () =>
              import("app/modules/helpdesk/new-ticket/new-ticket.module").then(
                (m) => m.NewTicketModule
              ),
          },
          {
            path: "SLA",
            loadChildren: () =>
              import("app/modules/helpdesk/SLA/SLA.module").then((m) => m.SLAModule),
          },
          {
            path: "ticket-action",
            loadChildren: () =>
              import("app/modules/helpdesk/ticket-action/ticket-action.module").then(
                (m) => m.TicketActionModule
              ),
          },
          {
            path: "service-assignment",
            loadChildren: () =>
              import("app/modules/helpdesk/service-assignment/service-assignment.module").then(
                (m) => m.ServiceAssignmentModule
              ),
          },
        ],
      },

      // Registered Patient
      {
        path: "registeredpatient",
        children: [
          {
            path: "outpatient",
            loadChildren: () =>
              import("app/modules/provider/encounter-queue/encounter-queue.module").then(
                (m) => m.EncounterQueueModule
              ),
          },
          {
            path: "inpatient",
            loadChildren: () =>
              import("app/modules/provider/encounter-queue/encounter-queue.module").then(
                (m) => m.EncounterQueueModule
              ),
          },
          {
            path: "emergency",
            loadChildren: () =>
              import("app/modules/provider/encounter-queue/encounter-queue.module").then(
                (m) => m.EncounterQueueModule
              ),
          },
          {
            path: "mortuary",
            loadChildren: () =>
              import("app/modules/provider/encounter-queue/encounter-queue.module").then(
                (m) => m.EncounterQueueModule
              ),
          },
          {
            path: "vc",
            loadChildren: () =>
              import("app/modules/provider/encounter-queue/encounter-queue.module").then(
                (m) => m.EncounterQueueModule
              ),
          },
        ],
      },

      // Pages
      {
        path: "pages",
        children: [
          // Coming Soon
          {
            path: "coming-soon",
            loadChildren: () =>
              import("app/modules/cad/pages/coming-soon/coming-soon.module").then(
                (m) => m.ComingSoonModule
              ),
          },

          // Error
          {
            path: "error",
            children: [
              {
                path: "404",
                loadChildren: () =>
                  import("app/modules/cad/pages/error/error-404/error-404.module").then(
                    (m) => m.Error404Module
                  ),
              },
              {
                path: "500",
                loadChildren: () =>
                  import("app/modules/cad/pages/error/error-500/error-500.module").then(
                    (m) => m.Error500Module
                  ),
              },
            ],
          },

          // Maintenance
          {
            path: "maintenance",
            loadChildren: () =>
              import("app/modules/cad/pages/maintenance/maintenance.module").then(
                (m) => m.MaintenanceModule
              ),
          },
          // Validation
          {
            path: "validation",
            loadChildren: () =>
              import("app/modules/cad/pages/validation/validation.module").then(
                (m) => m.ValidationModule
              ),
          },
          // Push Notification
          {
            path: "push-notification",
            loadChildren: () =>
              import("app/modules/cad/pages/push-notification/push-notification.module").then(
                (m) => m.PushNotificationModule
              ),
          },
          // Storage
          {
            path: "storage",
            loadChildren: () =>
              import("app/modules/cad/pages/storage/storage.module").then((m) => m.StorageModule),
          },
          // Storage
          {
            path: "virtual-clinic",
            loadChildren: () =>
              import("app/modules/cad/pages/virtual-clinic/virtual-clinic.module").then(
                (m) => m.VirtualClinicModule
              ),
          },
        ],
      },

      //Default Location
      {
        path: "default-location",
        loadChildren: () =>
          import("app/modules/cad/auth/default-location/default-location.module").then(
            (m) => m.DefaultLocationModule
          ),
      },

      //Switch Tenant
      {
        path: "switch-tenant",
        loadChildren: () =>
          import("app/modules/cad/auth/switch-tenant/switch-tenant.module").then(
            (m) => m.SwitchTenantModule
          ),
      },

      //Switch Tenant Location
      {
        path: "switch-tenant-location",
        loadChildren: () =>
          import("app/modules/cad/auth/switch-tenant-location/switch-tenant-location.module").then(
            (m) => m.SwitchTenantLocationModule
          ),
      },

      // Utilities
      {
        path: "utilities",
        children: [
          {
            path: "merge-patient",
            loadChildren: () =>
              import("app/modules/provider/merge-patient/merge-patient.module").then(
                (m) => m.MergePatientModule
              ),
          },
          {
            path: "unmerge-patient",
            loadChildren: () =>
              import("app/modules/provider/unmerge-patient/unmerge-patient.module").then(
                (m) => m.UnMergePatientModule
              ),
          },
        ],
      },

      // 404 & Catch all
      {
        path: "404-not-found",
        pathMatch: "full",
        loadChildren: () =>
          import("app/modules/cad/pages/error/error-404/error-404.module").then(
            (m) => m.Error404Module
          ),
      },
      { path: "**", redirectTo: "404-not-found" },
    ],
  },
];
