import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError } from "rxjs";
import { FhirConfigService } from "app/fhirconfig.service";
import { AppConfigService } from "app/appconfig.service";

const fhirHttpOptions = {
  headers: new HttpHeaders({
    "Cache-Control": "no-cache",
    Accept: "application/fhir+json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class DischargeService {
  selectedDischargeChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public _discharges: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
  private _dischargesLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public _discharge: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
  private _practitioner: BehaviorSubject<fhir.r4.Practitioner> = new BehaviorSubject(null);
  private _adverseEvent: BehaviorSubject<fhir.r4.AdverseEvent> = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<any> = new BehaviorSubject(null);

  ////CONSENT
  private _consent: BehaviorSubject<fhir.r4.Consent> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfigService: AppConfigService,
    private _fhirConfigService: FhirConfigService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for discharges
   */
  get discharges$(): Observable<fhir.r4.Bundle> {
    return this._discharges.asObservable();
  }

  get compositionList(): Observable<any> {
    return this.selectedDischargeChanged.asObservable();
  }

  /**
   * Getter for discharges loading
   */
  get dischargesLoading$(): Observable<boolean> {
    return this._dischargesLoading.asObservable();
  }

  /**
   * Getter for discharge
   */
  get discharge$(): Observable<fhir.r4.Bundle> {
    return this._discharge.asObservable();
  }

  /**
   * Getter for pagination
   */
  get pagination$(): Observable<any> {
    return this._pagination.asObservable();
  }

  ////CONSENT
  /**
   * Getter for cares
   */
  get consent$(): Observable<fhir.r4.Consent> {
    return this._consent.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get discharges
   */
  getDischarges(
    skip: number,
    top: number,
    currentPage: number,
    includeOrganization: Boolean
  ): Observable<any> {
    // Execute the mails loading with true
    this._dischargesLoading.next(true);

    let query: string = "";

    // If consent status rejected/inactive, include instCode
    if (includeOrganization) {
      query += "&encounter.service-provider=" + this._appConfigService.getInstCode() + "";
    }

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
          "/Composition?subject:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          "&_include:iterate=Composition:author" +
          "&_include:iterate=Composition:encounter" +
          "&_include:iterate=Composition:entry" +
          "&_include:iterate=PractitionerRole:practitioner" +
          "&_include:iterate=PractitionerRole:organization" +
          "&_sort=-date" +
          "&status=preliminary,final,amended" +
          query,
        fhirHttpOptions
      )
      .pipe(
        take(1),
        map((composition) => {
          // Update the observation
          //   this._discharge.next(composition);
          this._discharges.next(composition);
          this._pagination.next(this.Pagination(composition, skip, top, currentPage));
          this._dischargesLoading.next(false);
          // Return the observation
          return composition;
        }),
        switchMap((composition) => {
          if (!composition) {
            return throwError(
              () => new Error("Could not found composition with id of ' + id + '!")
            );
          }
          return of(composition);
        })
      );
  }

  // map numbering page
  Pagination(res: any, skip: number, top: number, currentPage: number): any {
    let pagination: any;
    let lastpage = skip + top;
    if (lastpage > res.total) lastpage = res.total;
    pagination = {
      totalResults: res.total,
      currentPage: skip + 1,
      lastPage: lastpage,
    };
    return pagination;
  }

  /**
   * Get discharge by id
   */
  getDischargeById(id: string): Observable<any> {
    //  Execute the loading with true
    this._dischargesLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
          "/Composition?_id=" +
          id +
          "&_include:iterate=Composition:encounter&_include:iterate=Composition:entry&_include=Composition:custodian&_include:iterate=Composition:author&_include:iterate=PractitionerRole:practitioner&_include:iterate=PractitionerRole:organization&_include:iterate=Observation:has-member&_include:iterate=Composition:subject",
        fhirHttpOptions
      )
      .pipe(
        take(1),
        map((composition) => {
          // Update the observation
          this._discharge.next(composition);
          this._dischargesLoading.next(false);
          // Return the observation
          return composition;
        }),
        switchMap((composition) => {
          if (!composition) {
            return throwError(
              () => new Error("Could not found composition with id of ' + id + '!")
            );
          }
          return of(composition);
        })
      );
  }

  /**
   * Get discharge by id
   */
  getDischargeByIdAndVersionId(id: string, versionId: string): Observable<any> {
    //  Execute the loading with true
    this._dischargesLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() + "/Composition/" + id + "/_history/" + versionId,
        fhirHttpOptions
      )
      .pipe(
        take(1),
        map((composition) => {
          // Update the observation
          this._discharge.next(composition);
          this._dischargesLoading.next(false);
          // Return the observation
          return composition;
        }),
        switchMap((composition) => {
          if (!composition) {
            return throwError(
              () => new Error("Could not found composition with id of ' + id + '!")
            );
          }
          return of(composition);
        })
      );
  }

  getPractitioner(url: string) {
    //  Execute the loading with true
    this._dischargesLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Practitioner>(this._fhirConfigService.getFhirService() + url)
      .pipe(
        take(1),
        map((practitioner) => {
          // Update the observation
          this._practitioner.next(practitioner);
          this._dischargesLoading.next(false);
          // Return the observation
          return practitioner;
        }),
        switchMap((practitioner) => {
          if (!practitioner) {
            return throwError(
              () => new Error("Could not found practitioner with id of ' + id + '!")
            );
          }
          return of(practitioner);
        })
      );
  }

  /**
   * Reset the current discharge
   */
  resetDischarge(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this._discharge.next(null);
      })
    );
  }

  createComposition(compositionBundle: fhir.r4.Bundle) {
    return this._httpClient.post(this._fhirConfigService.getFhirService(), compositionBundle);
  }

  updateComposition(compositionBundle: fhir.r4.Bundle) {
    return this._httpClient.put(this._fhirConfigService.getFhirService(), compositionBundle);
  }

  deleteComposition(compositionId: string, composition) {
    return this._httpClient.put(
      this._fhirConfigService.getFhirService() + "/Composition/" + compositionId,
      composition
    );
  }

  getAdverseEvent(id: string) {
    //  Execute the loading with true
    this._dischargesLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/AdverseEvent?_id=" + id)
      .pipe(
        take(1),
        map((adverseEvent) => {
          // Update the observation
          //      this._adverseEvent.next(adverseEvent);
          this._dischargesLoading.next(false);
          // Return the observation
          return adverseEvent;
        }),
        switchMap((adverseEvent) => {
          if (!adverseEvent) {
            return throwError(
              () => new Error("Could not found Adverse Event with id of ' + id + '!")
            );
          }
          return of(adverseEvent);
        })
      );
  }

  getEncounter(encounterId: string) {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/Encounter/" + encounterId)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getObservation() {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Condition/?subject=" +
          this._appConfigService.getPatientResourceId()
      )
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getCondition(id: string) {
    return this._httpClient.get(this._fhirConfigService.getFhirService() + "/Condition/" + id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  /**
   * Get consent status
   */
  getConsent(): Observable<any> {
    // Execute the appointment loading with true
    this._dischargesLoading.next(true);

    // call and store patientId

    return this._httpClient
      .get<fhir.r4.Consent>(
        this._fhirConfigService.getFhirService() +
          "/Consent?scope=patient-privacy&patient:mdm=Patient/" +
          this._appConfigService.getPatientResourceId() +
          ""
      )
      .pipe(
        tap((response: any) => {
          this._consent.next(response);
          this._dischargesLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Get discharges
   */
  // getDischarges2(skip: number, top: number, currentPage: number): Observable<any> {
  //   // Execute the mails loading with true
  //   this._dischargesLoading.next(true);

  //   return this._httpClient
  //     .get<fhir.r4.Bundle>(
  //       this._fhirConfigService.getFhirService() +
  //         "/Composition?subject:mdm=Patient/" +
  //         this._appConfigService.getPatientResourceId() +
  //         "&_include:iterate=Composition:author&_include:iterate=PractitionerRole:practitioner&_sort=-date&status=preliminary,final,amended&encounter.service-provider=" +
  //         this._appConfigService.getInstCode() +
  //         " ",
  //       {
  //         // params: {
  //         //     page
  //         // }
  //       }
  //     )
  //     .pipe(
  //       tap((response: any) => {
  //         this._discharges.next(response);
  //         //   this._pagination.next(this.Pagination(response, skip, top, currentPage));
  //         this._dischargesLoading.next(false);
  //       }),
  //       switchMap((response) => {
  //         if (response.discharges === null) {
  //           return throwError({
  //             message: "Requested page is not available!",
  //             pagination: response.pagination,
  //           });
  //         }

  //         return of(response);
  //       })
  //     );
  // }
}
