import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  constructor() {}

  public changeDateTimeFormat(format: string) {
    localStorage.setItem("dateTimeFormat", format);
  }

  getDateTimeFormat(): string {
    return localStorage.getItem("dateTimeFormat");
  }

  public changeDateFormat(format: string) {
    localStorage.setItem("dateFormat", format);
  }

  getDateFormat(): string {
    return localStorage.getItem("dateFormat");
  }

  public changeTimezone(timezone: string) {
    localStorage.setItem("timezone", timezone);
  }

  getTimezone(): string {
    return localStorage.getItem("timezone");
  }

  public changeLoginId(loginId: string) {
    localStorage.setItem("loginId", loginId);
  }

  getLoginId(): string {
    return localStorage.getItem("loginId");
  }

  public changeUserId(userId: string) {
    localStorage.setItem("userId", userId);
  }

  getUserId(): string {
    return localStorage.getItem("userId");
  }

  public changeUserIdentifier(userIdentifier: string) {
    localStorage.setItem("userIdentifier", userIdentifier);
  }

  getUserIdentifier(): string {
    return localStorage.getItem("userIdentifier");
  }

  public changeUserName(userName: string) {
    localStorage.setItem("userName", userName);
  }

  getUserName(): string {
    return localStorage.getItem("userName");
  }

  public changeInstCode(instCode: string) {
    localStorage.setItem("instCode", instCode);
  }

  getInstCode(): string {
    return localStorage.getItem("instCode");
  }

  public changeInstName(instName: string) {
    localStorage.setItem("instName", instName);
  }

  getInstName(): string {
    return localStorage.getItem("instName");
  }

  public changeAuthorizationLevel(authorizationLevel: string) {
    localStorage.setItem("authorizationLevel", authorizationLevel);
  }

  getAuthorizationLevel(): string {
    return localStorage.getItem("authorizationLevel");
  }

  public changePatientResourceId(patientResourceId: string) {
    localStorage.setItem("patientResourceId", patientResourceId);
  }

  getPatientResourceId(): string {
    return localStorage.getItem("patientResourceId");
  }

  public changeEncounterId(encounterId: string) {
    localStorage.setItem("encounterId", encounterId);
  }

  getEncounterId(): string {
    return localStorage.getItem("encounterId");
  }

  public changeEncounterIdentifier(encounterIdentifier: string) {
    localStorage.setItem("encounterIdentifier", encounterIdentifier);
  }

  getEncounterIdentifier(): string {
    return localStorage.getItem("encounterIdentifier");
  }

  public changePractitionerRoleId(practitionerRoleId: string) {
    localStorage.setItem("practitionerRoleId", practitionerRoleId);
  }

  getPractitionerRoleId(): string {
    return localStorage.getItem("practitionerRoleId");
  }

  public changeAutoNoPrefix(format: string) {
    localStorage.setItem("autoNoPrefix", format);
  }

  getAutoNoPrefix(): string {
    return localStorage.getItem("autoNoPrefix");
  }

  public changePractitionerId(practitionerId: string) {
    localStorage.setItem("practitionerId", practitionerId);
  }

  getPractitionerId(): string {
    return localStorage.getItem("practitionerId");
  }

  public changePractitionerName(practitionerName: string) {
    localStorage.setItem("practitionerName", practitionerName);
  }

  getPractitionerName(): string {
    return localStorage.getItem("practitionerName");
  }

  public changeLocationId(locationId: string) {
    localStorage.setItem("locationId", locationId);
  }

  getLocationId(): string {
    return localStorage.getItem("locationId");
  }

  checkSystemTask(task: string) {
    const element = this.getSystemTask().find((x) => {
      return x === task;
    });
    return typeof element === "undefined" ? false : true;
  }

  public changeSystemTasks(systemTasks: string) {
    localStorage.setItem("systemTasks", systemTasks);
  }

  getSystemTask(): string[] {
    return JSON.parse(localStorage.getItem("systemTasks"));
  }

  public changeBranchCode(branchCode: string) {
    localStorage.setItem("branchCode", branchCode);
  }

  getBranchCode(): string {
    return localStorage.getItem("branchCode");
  }

  public changeDisplayTag(displayTag: string) {
    localStorage.setItem("displayTag", displayTag);
  }

  getDisplayTag(): string {
    return localStorage.getItem("displayTag");
  }

  getRoleDisplay(): string {
    return localStorage.getItem("roleDisplay");
  }

  public changeRoleDisplay(data: string) {
    localStorage.setItem("roleDisplay", data);
  }

  getLocationDisplay(): string {
    return localStorage.getItem("locationDisplay");
  }

  public changeLocationDisplay(data: string) {
    localStorage.setItem("locationDisplay", data);
  }

  getProgramRoleId(): string {
    return localStorage.getItem("programRoleId");
  }

  public changeProgramRoleId(data: string) {
    localStorage.setItem("programRoleId", data);
  }

  public changePatientId(data: string) {
    localStorage.setItem("patientId", data);
  }

  getPatientId(): string {
    return localStorage.hasOwnProperty("patientId") ? localStorage.getItem("patientId") : null;
  }

  getPrimaryProgramRoleId(): string {
    return localStorage.getItem("primaryProgramRoleId");
  }

  public changePrimaryProgramRoleId(data: string) {
    localStorage.setItem("primaryProgramRoleId", data);
  }

  public changePatientIcNo(patientIcNo: string) {
    localStorage.setItem("patientIcNo", patientIcNo);
  }

  getPatientIcNo(): string {
    return localStorage.getItem("patientIcNo");
  }

  public changeMinSession(min: string) {
    localStorage.setItem("minSession", min);
  }

  getMinSession(): number {
    return parseInt(localStorage.getItem("minSession"));
  }

  public changeSecSession(sec: string) {
    localStorage.setItem("secSession", sec);
  }

  getSecSession(): number {
    return parseInt(localStorage.getItem("secSession"));
  }

  public changeRoleCode(roleCode: string) {
    localStorage.setItem("roleCode", roleCode);
  }

  getRoleCode(): string {
    return localStorage.getItem("roleCode");
  }

  public changeServiceCode(serviceCode: string) {
    localStorage.setItem("serviceCode", serviceCode);
  }

  getServiceCode(): string {
    return localStorage.getItem("serviceCode");
  }

  public changeServiceDisplay(serviceDisplay: string) {
    localStorage.setItem("serviceDisplay", serviceDisplay);
  }

  getServiceDisplay(): string {
    return localStorage.getItem("serviceDisplay");
  }

  public changeFhirEnable(fhirEnable: string) {
    localStorage.setItem("fhirEnable", fhirEnable);
  }

  getFhirEnable(): string {
    return localStorage.getItem("fhirEnable");
  }

  checkFhirEnable() {
    return this.getFhirEnable() && this.getFhirEnable() === "Y" ? true : false;
  }

  public changeUserEmail(userEmail: string) {
    localStorage.setItem("userEmail", userEmail);
  }

  getUserEmail(): string {
    return localStorage.getItem("userEmail");
  }

  public changePatientMrn(patientMrn: string) {
    localStorage.setItem("patientMrn", patientMrn);
  }

  getPatientMrn(): string {
    return localStorage.getItem("patientMrn");
  }

  public changeCompositionId(compositionId: string) {
    localStorage.setItem("compositionId", compositionId);
  }

  getCompositionId(): string {
    return localStorage.getItem("compositionId");
  }

  public changeCompositionMode(compositionMode: string) {
    localStorage.setItem("compositionMode", compositionMode);
  }

  getCompositionMode(): string {
    return localStorage.getItem("compositionMode");
  }

  public changeEncounterStatus(encounterStatus: string) {
    localStorage.setItem("encounterStatus", encounterStatus);
  }

  getEncounterStatus(): string {
    return localStorage.getItem("encounterStatus");
  }

  public changeDisplayEndEncounter(displayEndEncounter: string) {
    localStorage.setItem("displayEndEncounter", displayEndEncounter);
  }

  getDisplayEndEncounter(): string {
    return localStorage.getItem("displayEndEncounter");
  }

  public changePreferencePanel(preferencePanel: string) {
    localStorage.setItem("preferencePanel", preferencePanel);
  }

  getPreferencePanel(): string {
    return localStorage.getItem("preferencePanel");
  }

  public vcUrl(vcUrl: string) {
    localStorage.setItem("vcUrl", vcUrl);
  }

  getVcUrl(): string {
    return localStorage.getItem("vcUrl");
  }

  public changeDefaultPatientResourceId(defaultPatientResourceId: string) {
    localStorage.setItem("Default Patient ID", defaultPatientResourceId);
  }

  getDefaultPatientResourceId(): string {
    return localStorage.getItem("Default Patient ID");
  }

  public changeDahsboardAccess(accessDashboard: string) {
    localStorage.setItem("accessDashboard", accessDashboard);
  }

  getDashboardAccess(): string {
    return localStorage.getItem("accessDashboard");
  }
}
