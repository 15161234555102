import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CadDrawerModule } from '@cad/components/drawer';
import { CadScrollbarModule } from '@cad/directives/scrollbar';
import { SharedModule } from 'app/shared/shared.module';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import {
    IgxIconModule,
    IgxInputGroupModule,
    IgxSwitchModule,
    IgxButtonGroupModule,
    IgxComboModule,
    IgxRippleModule,
    IgxToggleModule,
    IgxTooltipModule,
    IgxSnackbarModule,
    IgxNavigationDrawerModule,
    IgxCardModule,
  } from "@infragistics/igniteui-angular";

@NgModule({
    declarations: [
        QuickChatComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        IgxIconModule,
        IgxInputGroupModule,
        IgxSwitchModule,
        IgxButtonGroupModule,
        IgxComboModule,
        IgxRippleModule,
        IgxToggleModule,
        IgxTooltipModule,
        IgxSnackbarModule,
        IgxNavigationDrawerModule,
        IgxCardModule,
        CadDrawerModule,
        CadScrollbarModule,
        SharedModule
    ],
    exports     : [
        QuickChatComponent
    ]
})
export class QuickChatModule
{
}
