import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, map, Observable, of, switchMap, tap, throwError } from "rxjs";
import { FhirConfigService } from "app/fhirconfig.service";
import { AppConfigService } from "app/appconfig.service";

@Injectable({
  providedIn: "root",
})
export class ComposeService {
  private _composition: BehaviorSubject<fhir.r4.Composition> = new BehaviorSubject(null);
  private _compositions: BehaviorSubject<fhir.r4.Composition[]> = new BehaviorSubject<
    fhir.r4.Composition[]
  >(null);
  private eventSubject = new BehaviorSubject<any>(undefined);
  private compositionPanel = new BehaviorSubject<any>(undefined);
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _appConfigService: AppConfigService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for composition
   */
  get composition$(): Observable<fhir.r4.Composition> {
    return this._composition.asObservable();
  }

  /**
   * Getter for composition
   */
  get compositions$(): Observable<fhir.r4.Composition[]> {
    return this._compositions.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get compositions
   */
  getCompositions(): Observable<any> {
    return this._httpClient.get<fhir.r4.Composition[]>("api/apps/chat/chats").pipe(
      tap((response: fhir.r4.Composition[]) => {
        this._compositions.next(response);
      })
    );
  }

  /**
   * Get composition
   *
   * @param id
   */
  getCompositionById(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Composition>("api/apps/chat/chat", { params: { id } }).pipe(
      map((composition) => {
        // Update the chat
        this._composition.next(composition);

        // Return the chat
        return composition;
      }),
      switchMap((composition) => {
        if (!composition) {
          return throwError("Could not found composition with id of " + id + "!");
        }

        return of(composition);
      })
    );
  }

  triggerSomeEvent() {
    this.eventSubject.next("emit");
  }

  getEventSubject(): BehaviorSubject<any> {
    return this.eventSubject;
  }

  triggerCompositionPanel(item) {
    this.compositionPanel.next(item);
  }

  getCompositionPanel() {
    return this.compositionPanel;
  }
}
