import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CadConfirmationConfig } from '@cad/services/confirmation/confirmation.types';
import { IComboSelectionChangingEventArgs, IGX_INPUT_GROUP_TYPE } from '@infragistics/igniteui-angular';

@Component({
    selector: 'cad-confirmation-dialog',
    templateUrl: './dialog.component.html',
    styles: [
        /* language=SCSS */
        `
            .cad-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-dialog-container {
                    padding: 0 !important;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: IGX_INPUT_GROUP_TYPE, useValue: "line" }],
})
export class CadConfirmationDialogComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CadConfirmationConfig,
        public matDialogRef: MatDialogRef<CadConfirmationDialogComponent>,
		// private _changeDetectorRef: ChangeDetectorRef,
    ) {
    }
	
	// comboboxData: any[] = this.data.body? this.data.body.data : [];
	bodyValue: any;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
		
	}
	
	// ngAfterContentInit(): void {
	// 	if(this.data.body) { this._changeDetectorRef.detectChanges() }	
	// }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    
	close(): void {
        // console.log("mimi")
        this.matDialogRef.close(true);
    }
	
	// --- When clicked confirm button, pass 'confirmed' and form value
	confirmed(): void {
        this.matDialogRef.close({status: "confirmed", data: this.bodyValue});
    }
	
	singleSelection(e: IComboSelectionChangingEventArgs, fieldName: string) {
		if (e.newSelection.length > 0) {
			e.newSelection = e.added;
			this.bodyValue = e.added;
		}
	}
}
