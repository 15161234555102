import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError } from "rxjs";
import {
  FilteringLogic,
  IForOfState,
  FilteringExpressionsTree,
} from "@infragistics/igniteui-angular";
import { FhirConfigService } from "app/fhirconfig.service";
import { formatDate } from "@angular/common";
import { AppConfigService } from "app/appconfig.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { NcrConfigService } from "app/ncrconfig.service";

const EMPTY_STRING = "";
const NULL_VALUE = null;
export enum FILTER_OPERATION {
  CONTAINS = "_contains=",
  STARTS_WITH = "startswith",
  ENDS_WITH = "endswith",
  EQUALS = "=",
  DOES_NOT_EQUAL = "!=",
  DOES_NOT_CONTAIN = "not contains",
  GREATER_THAN = "gt",
  LESS_THAN = "lt",
  LESS_THAN_EQUAL = "le",
  GREATER_THAN_EQUAL = "ge",
}
const fhirHttpOptions = {
  headers: new HttpHeaders({
    "Cache-Control": "no-cache",
    Accept: "application/fhir+json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class EncounterQueueService {
  public bundle$: Observable<fhir.r4.Bundle>;
  private _bundle: BehaviorSubject<fhir.r4.Bundle>;

  private _encounterQueuesLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public encounterQueue: BehaviorSubject<fhir.r4.Encounter> = new BehaviorSubject(null);

  private _bundleLocation: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _bundleHealthcare: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _bundlePatient: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);

  private eventSubject = new BehaviorSubject<any>(undefined);

  private virtualizationArgsCache: any;
  private filteringArgsCache: any;
  private sortingArgsCache: any;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _appConfigService: AppConfigService,
    private _route: ActivatedRoute,
    private _ncrConfigService: NcrConfigService
  ) {
    this._bundle = new BehaviorSubject(null);
    this.bundle$ = this._bundle.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for encounters loading
   */
  get encounterQueuesLoading$(): Observable<boolean> {
    return this._encounterQueuesLoading.asObservable();
  }

  /**
   * Getter for encounter
   */
  get encounterQueue$(): Observable<fhir.r4.Encounter> {
    return this.encounterQueue.asObservable();
  }

  /*** Getter for FHIR bundle*/
  get bundlePatient$(): Observable<fhir.r4.BundleEntry[]> {
    return this._bundlePatient.asObservable();
  }

  /*** Getter for FHIR bundle*/
  get bundleLocation$(): Observable<fhir.r4.BundleEntry[]> {
    return this._bundleLocation.asObservable();
  }

  /*** Getter for FHIR bundle*/
  get bundleHealthcare$(): Observable<fhir.r4.BundleEntry[]> {
    return this._bundleHealthcare.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  /**
   * Get encounters
   */
  getEncounterQueues(skip: number, top: number, patientId: string): Observable<any> {
    const query = {
      _count: top,
      _getpagesoffset: skip,
      _summary: "false",
      _total: "accurate",
      subject: patientId,
    };

    // Execute the loading with true
    this._encounterQueuesLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Encounter[]>(
        this._fhirConfigService.getFhirService() +
        "/Encounter?service-provider=11-05060009&date=ge2022-01-01&date=le2022-04-30&status=arrived,in-progress,completed,finished&_include:iterate=Encounter:subject",
        {
          params: query,
        }
      )
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
          this._encounterQueuesLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  getEncounterQueue(skip: number): Observable<any> {
    const query = {
      _count: top,
      _getpagesoffset: skip,
      _total: "accurate",
    };

    // Execute the loading with true
    this._encounterQueuesLoading.next(true);

    return this._httpClient
      .get<fhir.r4.Encounter[]>(
        this._fhirConfigService.getFhirService() +
        "/Encounter",
        {
          // params: query,
        }
      )
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
          this._encounterQueuesLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  /**
   * Read encounter by id
   */
  readEncounterById(id: string): Observable<any> {
    return this._httpClient
      .get<fhir.r4.Encounter>(this._fhirConfigService.getFhirService() + "/Encounter/" + id)
      .pipe(take(1));
  }

  /**
   * Update encounter by id
   */
  updateEncounter(id: string, encounter: any) {
    return this._httpClient.put(
      this._fhirConfigService.getFhirService() + "/Encounter/" + id,
      encounter, fhirHttpOptions
    );
  }

  /**
   * Reset the current encounter
   */
  resetEncounter(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this.encounterQueue.next(null);
      })
    );
  }

  // Get location by role id
  getLocationByRoleId(id): Observable<any> {
    return this._httpClient
      .get<fhir.r4.PractitionerRole>(this._fhirConfigService.getFhirService() + "/PractitionerRole/" + id)
      .pipe(take(1));
  }

  getLocationByInstCode(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
        "/Location?organization=" +
        this._appConfigService.getInstCode()
      )
      .pipe(
        map((response: any) => {
          this._bundleLocation.next(response);
          return response;
        })
      );
  }

  /**
   * get healthcare service based on organization and practitioner role
   */
  getHealthcareServiceByPractitionerRole(id): Observable<any> {
    return (
      this._httpClient
        //   .get(this._fhirConfigService.getFhirService() + "/HealthcareService?active=true")
        .get(
          this._fhirConfigService.getFhirService()
          + "/HealthcareService?organization=" + this._appConfigService.getInstCode()
          + "&service-category=" + id
          // + "&active=true&appointment-required=true&_sort=name"
          + "&_has:PractitionerRole:service:practitioner=" + this._appConfigService.getPractitionerId()
        )
        .pipe(take(1))
    );
  }

  /**
   * get healthcare service based on filter service
   */
  getHealthcareService(id): Observable<any> {
    return (
      this._httpClient
        //   .get(this._fhirConfigService.getFhirService() + "/HealthcareService?active=true")
        .get(
          this._fhirConfigService.getFhirService()
          + "/HealthcareService/" + id
        )
        .pipe(take(1))
    );
  }

  /**
   * get appointment by id
   */
  getAppointmentById(id): Observable<any> {
    return (
      this._httpClient
        //   .get(this._fhirConfigService.getFhirService() + "/HealthcareService?active=true")
        .get(
          this._fhirConfigService.getFhirService()
          + "/Appointment/" + id
        )
        .pipe(take(1))
    );
  }

  /**
   * Update appointment by id
   */
  updateAppointmentById(id: string, data: any) {
    return this._httpClient.put(
      this._fhirConfigService.getFhirService() + "/Appointment/" + id,
      data, fhirHttpOptions
    );
  }

  //get encounter by service

  getEncounterByService(serviceCode, classCode, typeCode) {
    return this._httpClient.get(
      this._fhirConfigService.getFhirService()
      + "/Encounter?type=" + serviceCode
      + "&class=" + classCode
      + "&service-type=" + typeCode
      + "&service-provider=Organization/" + this._appConfigService.getInstCode()
      + "&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Encounter-my-core"
      + "&_include=Encounter:patient&_include=Encounter:location&status=arrived,in-progress"
      + "&service-provider=Organization/" + this._appConfigService.getInstCode()
    )
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
          this._encounterQueuesLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(() => new Error("Requested page is not available!"));
          }
          return of(response);
        })
      );
  }

  // Push Noti
  pushNotifications(body: any) : Observable<any> {
    const opts: any = {
      responseType: "text",
    };
    return this._httpClient
      .post<any>( 
        this._ncrConfigService.getNcrService() +
        "/api/v1/notification/vc", body, opts);
  }

  public getData(
    virtualizationArgs?: IForOfState,
    filteringArgs?: any,
    sortingArgs?: any,
    classTypeArgs?: any,
    specialtyCode?: any,
    typeCode?: any,

    cb?: (any) => void
  ): any {
    return this._httpClient
      .get(this.buildDataUrl(virtualizationArgs, filteringArgs, sortingArgs, classTypeArgs, specialtyCode, typeCode), fhirHttpOptions)
      .subscribe((data: fhir.r4.Bundle) => {
        this._bundle.next(data);
        if (cb) {
          cb(data);
        }
      });
  }

  private buildDataUrl(virtualizationArgs: any, filteringArgs: any, sortingArgs: any, classTypeArgs: any, specialtyCode: any, typeCode: any): string {
    let baseQueryString = this._fhirConfigService.getFhirService() + "/Encounter?";
    let scrollingQuery = EMPTY_STRING;
    let orderQuery = EMPTY_STRING;
    let filterQuery = EMPTY_STRING;
    let query = EMPTY_STRING;
    let order = EMPTY_STRING;
    let filter = EMPTY_STRING;

    if (virtualizationArgs === null) {
      virtualizationArgs = this.virtualizationArgsCache;
    }
    this.virtualizationArgsCache = virtualizationArgs;

    if (filteringArgs === null) {
      filteringArgs = this.filteringArgsCache;
    }
    this.filteringArgsCache = filteringArgs;

    if (sortingArgs === null) {
      sortingArgs = this.sortingArgsCache;
    }
    this.sortingArgsCache = sortingArgs;

    if (sortingArgs && sortingArgs.length > 0) {
      sortingArgs.forEach((arg) => {
        if (order !== EMPTY_STRING) {
          order += `,`;
        }
        order += this._buildSortExpression(arg);
      });
      orderQuery = `_sort=${order}`;
    }

    if (filteringArgs && filteringArgs.length > 0) {
      filteringArgs.forEach((columnFilter) => {
        if (filter !== EMPTY_STRING) {
          filter += `&`;
        }

        filter += this._buildAdvancedFilterExpression(
          columnFilter.filteringOperands,
          columnFilter.operator
        );
      });
      filterQuery = `${filter}`;
    }

    if (virtualizationArgs) {
      scrollingQuery = this._buildScrollExpression(virtualizationArgs);
    }

    if (classTypeArgs !== "VR") {
      query += orderQuery !== EMPTY_STRING ? `&${orderQuery}` : EMPTY_STRING;
      query += filterQuery !== EMPTY_STRING ? `&${filterQuery}` : EMPTY_STRING;
      // query += `&date=ge` + moment().format("YYYY-MM-DD") + `T00:00:00+08:00` + `&date=le` + moment().format("YYYY-MM-DD") + `T23:59:59+08:00`;
      query += `&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Encounter-my-core`;
      query += `&_include=Encounter:patient`;
      query += `&_include=Encounter:location`;
      query += `&class=` + classTypeArgs;
      query += `&service-type=` + typeCode;
      query += `&type=` + specialtyCode;
      query += `&status=arrived,in-progress`;
      query += `&service-provider=Organization/` + this._appConfigService.getInstCode()
      // query += `&type=` + this._appConfigService.getServiceCode();
      // query += `&location=` + this._appConfigService.getLocationId()
      // if (filter !== "_filter=(location eq \"11-05060009-000008\" or location.partof eq \"11-05060009-000008\")") {
      //   query += `&_filter=(location eq "` + this._appConfigService.getLocationId() + '" or location.partof eq "' + this._appConfigService.getLocationId() + '")'
      // }
      query += scrollingQuery !== EMPTY_STRING ? `&${scrollingQuery}` : EMPTY_STRING;
    }
    else if (classTypeArgs === "VR") {
      query += orderQuery !== EMPTY_STRING ? `&${orderQuery}` : EMPTY_STRING;
      query += filterQuery !== EMPTY_STRING ? `&${filterQuery}` : EMPTY_STRING;
      // query += `&date=ge` + moment().format("YYYY-MM-DD") + `T00:00:00+08:00` + `&date=le` + moment().format("YYYY-MM-DD") + `T23:59:59+08:00`;
      query += `&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Encounter-my-core`;
      query += `&_include=Encounter:patient`;
      query += `&_include=Encounter:location`;
      query += `&class=VR`;
      query += `&type=` + specialtyCode;
      query += `&status=arrived,in-progress`;
      query += `&service-type=` + typeCode;
      query += `&service-provider=Organization/` + this._appConfigService.getInstCode()
      // query += `&type=` + this._appConfigService.getServiceCode();
      // query += `&actor=` + this._appConfigService.getServiceCode();
      // query += `&location=` + this._appConfigService.getLocationId()
      // if (filter !== "_filter=(location eq \"11-05060009-000008\" or location.partof eq \"11-05060009-000008\")") {
      //   query += `&_filter=(location eq "` + this._appConfigService.getLocationId() + '" or location.partof eq "' + this._appConfigService.getLocationId() + '")'
      // }
      query += scrollingQuery !== EMPTY_STRING ? `&${scrollingQuery}` : EMPTY_STRING;
    }



    baseQueryString += query;

    return baseQueryString;
  }

  private _buildAdvancedFilterExpression(operands, operator): string {
    let filterExpression = EMPTY_STRING;
    operands.forEach((operand, index) => {
      if (operand instanceof FilteringExpressionsTree) {
        if (index > 0) {
          filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
        }
        filterExpression += this._buildAdvancedFilterExpression(
          operand.filteringOperands,
          operand.operator
        );
        return filterExpression;
      }

      const value = operand.searchVal;
      let filterValue = value;
      let fieldNameCol = operand.fieldName;
      let fieldName = fieldNameCol;
      let filterString;

      if (filterExpression !== EMPTY_STRING) {
        filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
      }

      switch (operand.condition.name) {
        case "contains": {
          if (fieldName === "patientName") {
            fieldName = "patient.name";
          } else if (fieldName === "identifier") {
            fieldName = "identifier";
            filterValue = filterValue;
          } else if (fieldName === "idNo") {
            fieldName = "patient.identifier";
            filterValue = filterValue;
          } else if (fieldName === "icNo") {
            fieldName = "patient.identifier";
            filterValue = "http://fhir.hie.moh.gov.my/sid/my-kad-no|" + filterValue;
          } else if (fieldName === "passportNo") {
            fieldName = "patient.identifier";
            filterValue = "http://fhir.hie.moh.gov.my/sid/passport-no|" + filterValue;
          } else if (fieldName === "policeNo") {
            fieldName = "patient.identifier";
            filterValue = "http://fhir.hie.moh.gov.my/sid/police-no|" + filterValue;
          } else if (fieldName === "armyNo") {
            fieldName = "patient.identifier";
            filterValue = "http://fhir.hie.moh.gov.my/sid/army-no|" + filterValue;
          } else if (fieldName === "othersNo") {
            fieldName = "patient.identifier";
            filterValue = "http://fhir.hie.moh.gov.my/sid/others-no|" + filterValue;
          }

          filterString = `${fieldName}:${FILTER_OPERATION.CONTAINS}${filterValue}`;
          break;
        }
        case "in": {
          let startDate;
          let endDate;

          if (fieldName === "lastUpdated") {
            fieldName = "_lastUpdated";
            startDate = formatDate(filterValue.start, "yyyy-MM-dd", "en") + "T00:00:00+08:00";
            endDate = formatDate(filterValue.end, "yyyy-MM-dd", "en") + "T23:59:59+00:00";
          } else if (fieldName === "birthDate") {
            fieldName = "_patient.birthDate";
            startDate = formatDate(filterValue.start, "yyyy-MM-dd", "en") + "T00:00:00+08:00";
            endDate = formatDate(filterValue.end, "yyyy-MM-dd", "en") + "T23:59:59+00:00";
          }
          if (fieldName === "periodStart") {
            fieldName = "date";
            startDate = formatDate(filterValue.start, "yyyy-MM-dd", "en") + "T00:00:00+08:00";
            endDate = formatDate(filterValue.end, "yyyy-MM-dd", "en") + "T23:59:59+00:00";
          }
          filterString = `${fieldName}=ge${startDate}&${fieldName}=le${endDate}`;
          break;
        }
        case "startsWith": {
          filterString = `${FILTER_OPERATION.STARTS_WITH}(${fieldName},${filterValue})`;
          break;
        }
        case "endsWith": {
          filterString = `${FILTER_OPERATION.ENDS_WITH}(${fieldName},${filterValue})`;
          break;
        }
        case "equals": {
          if (fieldName === "identifier") {
            filterValue = "http://fhir.hie.moh.gov.my/sid/encounter-id|" + filterValue;
          } else if (fieldName === "status") {
            fieldName = "status";
          } else if (fieldName === "location") {
            fieldName = "location";
          } else if (fieldName === "gender") {
            fieldName = "patient.gender";
          } else if (fieldName === "classDisplay") {
            fieldName = "class";
          } else if (fieldName === "typeDisplay") {
            fieldName = "specialty";
          } else if (fieldName === "serviceTypeDisplay") {
            fieldName = "service-type";
          } else if (fieldName === "priority") {
            fieldName = "priority";
          }

          //Filter location with part of
          // if (fieldName === 'location') {
          //   filterString = `${'_filter'}${FILTER_OPERATION.EQUALS}${'(location eq "'}${filterValue}${'" or location.partof eq "'}${filterValue}${'")'}`;
          // } else {
          //   filterString = `${fieldName}${FILTER_OPERATION.EQUALS}${filterValue}`;
          // }

          filterString = `${fieldName}${FILTER_OPERATION.EQUALS}${filterValue}`;

          break;
        }
        case "doesNotEqual": {
          filterString = `${fieldName}${FILTER_OPERATION.DOES_NOT_EQUAL}${filterValue} `;
          break;
        }
        case "doesNotContain": {
          filterString = `${FILTER_OPERATION.DOES_NOT_CONTAIN}(${fieldName},${filterValue})`;
          break;
        }
        case "greaterThan": {
          filterString = `${fieldName}${FILTER_OPERATION.GREATER_THAN}${filterValue} `;
          break;
        }
        case "greaterThanOrEqualTo": {
          filterString = `${fieldName}${FILTER_OPERATION.GREATER_THAN_EQUAL}${filterValue} `;
          break;
        }
        case "lessThan": {
          filterString = `${fieldName}${FILTER_OPERATION.LESS_THAN}${filterValue} `;
          break;
        }
        case "lessThanOrEqualTo": {
          filterString = `${fieldName}${FILTER_OPERATION.LESS_THAN_EQUAL}${filterValue} `;
          break;
        }
        case "empty": {
          filterString = `length(${fieldName})${FILTER_OPERATION.EQUALS}0`;
          break;
        }
        case "notEmpty": {
          filterString = `length(${fieldName})${FILTER_OPERATION.GREATER_THAN}0`;
          break;
        }
        case "null": {
          filterString = `${fieldName}${FILTER_OPERATION.EQUALS}${NULL_VALUE}`;
          break;
        }
        case "notNull": {
          filterString = `${fieldName}${FILTER_OPERATION.DOES_NOT_EQUAL}${NULL_VALUE}`;
          break;
        }
      }

      filterExpression += filterString;
    });

    return filterExpression;
  }

  private _buildSortExpression(sortingArgs): string {
    let sortingDirection: string;
    switch (sortingArgs.dir) {
      case 1: {
        sortingDirection = EMPTY_STRING;
        break;
      }
      case 2: {
        sortingDirection = "-";
        break;
      }
      default: {
        sortingDirection = EMPTY_STRING;
        break;
      }
    }

    let fieldName = sortingArgs.fieldName;
    if (fieldName === "lastUpdated") {
      fieldName = "_lastUpdated";
    } else if (fieldName === "patientName") {
      fieldName = "patient.name";
    } else if (fieldName === "idNo") {
      fieldName = "patient.identifier";
    } else if (fieldName === "passportNo") {
      fieldName = "patient.identifier";
    } else if (fieldName === "status") {
      fieldName = "status";
    } else if (fieldName === "birthDate") {
      fieldName = "birthDate";
    } else if (fieldName === "location") {
      fieldName = "location";
    } else if (fieldName === "identifier") {
      fieldName = "identifier";
    } else if (fieldName === "gender") {
      fieldName = "gender";
    } else if (fieldName === "classDisplay") {
      fieldName = "classification";
    } else if (fieldName === "typeDisplay") {
      fieldName = "type";
    } else if (fieldName === "serviceTypeDisplay") {
      fieldName = "service-category";
    } else if (fieldName === "periodStart") {
      fieldName = "date";
    } else if (fieldName === "priority") {
      fieldName = "priority";
    }

    return `${sortingDirection}${fieldName}`;
  }

  private _buildScrollExpression(virtualizationArgs): string {
    let requiredChunkSize: number;
    const skip = virtualizationArgs.startIndex;
    requiredChunkSize = virtualizationArgs.chunkSize === 0 ? 25 : virtualizationArgs.chunkSize;
    const top = requiredChunkSize;

    return `_getpagesoffset=${skip}&_count=${top}&_total=accurate`;
  }

  getPatient(): Observable<any> {
    return this._httpClient.get(this._fhirConfigService.getFhirService() + "/Patient").pipe(
      map((response: any) => {
        this._bundlePatient.next(response);
        return response;
      })
    );
  }

  getLocation(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
        "/Location?organization=" +
        this._appConfigService.getInstCode() +
        "&type=AMB&" +
        "_hasPractitionerRole:location:practitioner" +
        this._appConfigService.getPractitionerId()
      )
      .pipe(
        map((response: any) => {
          this._bundleLocation.next(response);
          return response;
        })
      );
  }

  getServiceTypeByClassCode(classCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetServiceTypeMyCore",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/service-type-my-core",
            filter: [
              {
                property: "classCode",
                op: "=",
                value: classCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(take(1));
  }

  // getHealthcareService(): Observable<any> {
  //   return this._httpClient
  //     .get(this._fhirConfigService.getFhirService() + "/HealthcareService")
  //     .pipe(
  //       map((response: any) => {
  //         this._bundleHealthcare.next(response);
  //         return response;
  //       })
  //     );
  // }

  triggerSomeEvent() {
    this.eventSubject.next("emit");
  }

  getEventSubject(): BehaviorSubject<any> {
    return this.eventSubject;
  }
}
