import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { CadMockApiService, CadMockApiUtils } from '@cad/lib/mock-api';
import {  medications as medicationsData, allergys as allergysData, historys as historysData, devices as devicesData, discharges as dischargesData,
         cares as caresData, diagnoses as diagnosesData, referrals as referralsData, immunizations as immunizationsData, observations as observationsData, familys as familysData,
         appointments as appointmentsData, filters as filtersData, folders as foldersData, labels as labelsData, mails as mailsData, settings as settingsData } from 'app/mock-api/apps/patient/data';


@Injectable({
    providedIn: 'root'
})
export class PatientMockApi
{
    private _filters: any[] = filtersData;
    private _folders: any[] = foldersData;
    private _mails: any[] = mailsData;
    private _labels: any[] = labelsData;
    private _settings: any = settingsData;

    // private _problems: any[] = problemsData;
    private _medications: any[] = medicationsData;
    private _allergys: any[] = allergysData;
    private _historys: any[] = historysData;
    private _devices: any[] = devicesData;
    private _discharges: any[] = dischargesData;
    private _cares: any[] = caresData;
    private _diagnoses: any[] = diagnosesData;
    private _referrals: any[] = referralsData;
    private _immunizations: any[] = immunizationsData;
    private _observations: any[] = observationsData;
    private _familys: any[] = familysData;
    private _appointments: any[] = appointmentsData;

    /**
     * Constructor
     */
    constructor(private _cadMockApiService: CadMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Settings - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/patient/settings')
            .reply(() => [200, cloneDeep(this._settings)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Settings - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPatch('api/apps/patient/settings')
            .reply(({request}) => {

                // Get the settings
                const settings = cloneDeep(request.body.settings);

                // Update the settings
                this._settings = assign({}, this._settings, settings);

                // Return the response
                return [200, cloneDeep(this._settings)];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Folders - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/patient/folders')
            .reply(() => {

                let count = 0;

                // Iterate through the folders
                this._folders.forEach((folder) => {

                    // Get the mails of this folder
                    const mails = this._mails.filter(mail => mail.folder === folder.id);

                    // If we are counting the 'sent' or the 'trash' folder...
                    if ( folder.slug === 'sent' || folder.slug === 'trash' )
                    {
                        // Always set the count to 0
                        count = 0;
                    }
                    // If we are counting the 'drafts' or the 'spam' folder...
                    else if ( folder.slug === 'drafts' || folder.slug === 'trash' || folder.slug === 'spam' )
                    {
                        // Set the count to the count of all mails
                        count = mails.length;
                    }
                    // Otherwise ('inbox')...
                    else
                    {
                        // Go through the mails and count the unread ones
                        mails.forEach((mail) => {

                            if ( mail.unread )
                            {
                                count++;
                            }
                        });
                    }

                    // Append the count to the folder mock-api
                    folder.count = count;

                    // Reset the count
                    count = 0;
                });

                // Return the response
                return [200, cloneDeep(this._folders)];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Filters - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/patient/filters')
            .reply(() => [200, cloneDeep(this._filters)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Labels - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/patient/labels')
            .reply(() => [200, cloneDeep(this._labels)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Labels - POST
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPost('api/apps/patient/label')
            .reply(({request}) => {

                // Get the label
                const label = cloneDeep(request.body.label);

                // Generate an id
                label.id = CadMockApiUtils.guid();

                // Generate a slug
                label.slug = label.title.toLowerCase()
                                  .replace(/ /g, '-')
                                  .replace(/[-]+/g, '-')
                                  .replace(/[^\w-]+/g, '');

                // Check if the slug is being used and update it if necessary
                const originalSlug = label.slug;

                let sameSlug;
                let slugSuffix = 1;

                do
                {
                    sameSlug = this._labels.filter(item => item.slug === label.slug);

                    if ( sameSlug.length > 0 )
                    {
                        label.slug = originalSlug + '-' + slugSuffix;
                        slugSuffix++;
                    }
                }
                while ( sameSlug.length > 0 );

                // Add the label
                this._labels.push(label);

                // Return the response
                return [200, label];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Labels - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPatch('api/apps/patient/label')
            .reply(({request}) => {

                // Get the id and label
                const id = request.body.id;
                const label = cloneDeep(request.body.label);

                // Prepare the updated label
                let updatedLabel = null;

                // Find the label and update it
                this._labels.forEach((item, index, labels) => {

                    if ( item.id === id )
                    {
                        // Update the slug
                        label.slug = label.title.toLowerCase()
                                          .replace(/ /g, '-')
                                          .replace(/[-]+/g, '-')
                                          .replace(/[^\w-]+/g, '');

                        // Update the label
                        labels[index] = assign({}, labels[index], label);

                        // Store the updated label
                        updatedLabel = labels[index];
                    }
                });

                // Return the response
                return [200, updatedLabel];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Labels - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onDelete('api/apps/patient/label')
            .reply(({request}) => {

                // Get the id
                const id = request.params.get('id');

                // Find the label and delete it
                const index = this._labels.findIndex(item => item.id === id);
                this._labels.splice(index, 1);

                // Get all the mails that have the label
                const mailsWithLabel = this._mails.filter(mail => mail.labels.indexOf(id) > -1);

                // Iterate through them and remove the label
                mailsWithLabel.forEach((mail) => {
                    mail.labels.splice(mail.labels.indexOf(id), 1);
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Mails - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/patient/mails', 625)
            .reply(({request}) => {

                // First, decide if mails are requested by folder, filter or label
                const byFolder = request.params.get('folder');
                const byFilter = request.params.get('filter');
                const byLabel = request.params.get('label');

                // Clone the mails mock-api to prevent accidental mock-api updates
                let mails: any[] | null = cloneDeep(this._mails);

                // Filter the mails depending on the requested by type
                mails = mails.filter((mail) => {

                    if ( byFolder )
                    {
                        return mail.folder === this._folders.find(folder => folder.slug === byFolder).id;
                    }

                    if ( byFilter )
                    {
                        return mail[byFilter] === true;
                    }

                    if ( byLabel )
                    {
                        return mail.labels.includes(this._labels.find(label => label.slug === byLabel).id);
                    }
                });

                // Sort by date - descending
                mails.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

                // Figure out the cc and bcc counts
                mails.forEach((mail) => {
                    mail.ccCount = mail.cc ? mail.cc.length : 0;
                    mail.bccCount = mail.bcc ? mail.bcc.length : 0;
                });

                // Paginate - Start
                const mailsLength = mails.length;
                const resultsPerPage = 10;

                // Get the requested page number
                const page = parseInt(request.params.get('page') ?? '1', 10);

                // Calculate pagination details
                const begin = (page - 1) * resultsPerPage;
                const end = Math.min((resultsPerPage * page), mailsLength);
                const lastPage = Math.max(Math.ceil(mailsLength / resultsPerPage), 1);

                // Prepare the pagination object
                let pagination = {};

                // If the requested page number is bigger than
                // the last possible page number, return null for
                // mails but also send the last possible page so
                // the app can navigate to there
                if ( page > lastPage )
                {
                    mails = null;
                    pagination = {
                        lastPage
                    };
                }
                else
                {
                    // Paginate the results by 10
                    mails = mails.slice(begin, end);

                    // Prepare the pagination mock-api
                    pagination = {
                        totalResults  : mailsLength,
                        resultsPerPage: resultsPerPage,
                        currentPage   : page,
                        lastPage      : lastPage,
                        startIndex    : begin,
                        endIndex      : end - 1
                    };
                }

                // Return the response
                return [
                    200,
                    {
                        mails,
                        pagination
                    }
                ];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Mail - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/patient/mail')
            .reply(({request}) => {

                // Get the id from the params
                const id = request.params.get('id');

                // Clone the mails mock-api to prevent accidental mock-api updates
                const mails = cloneDeep(this._mails);

                // Find the mail
                const mail = mails.find(item => item.id === id);

                return [
                    200,
                    mail
                ];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Mail - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPatch('api/apps/patient/mail')
            .reply(({request}) => {

                // Get the id and mail
                const id = request.body.id;
                const mail = cloneDeep(request.body.mail);

                // Prepare the updated mail
                let updatedMail = null;

                // Find the mail and update it
                this._mails.forEach((item, index, mails) => {

                    if ( item.id === id )
                    {
                        // Update the mail
                        mails[index] = assign({}, mails[index], mail);

                        // Store the updated mail
                        updatedMail = mails[index];
                    }
                });

                // Return the response
                return [200, updatedMail];
            });



        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------
        // New mock API put below here
        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------

        // -----------------------------------------------------------------------------------------------------
        // @ Problems - GET
        // -----------------------------------------------------------------------------------------------------
        // problems
        // this._cadMockApiService
        // .onGet('api/apps/patient/problems', 625)
        // .reply(({request}) => {

        //     // Clone the problems mock-api to prevent accidental mock-api updates
        //     let problems: any[] | null = cloneDeep(this._problems);

        //     // Paginate - Start
        //     const problemsLength = problems.length;
        //     const resultsPerPage = 10;

        //     // Get the requested page number
        //     const page = parseInt(request.params.get('page') ?? '1', 10);

        //     // Calculate pagination details
        //     const begin = (page - 1) * resultsPerPage;
        //     const end = Math.min((resultsPerPage * page), problemsLength);
        //     const lastPage = Math.max(Math.ceil(problemsLength / resultsPerPage), 1);

        //     // Prepare the pagination object
        //     let pagination = {};

        //     // If the requested page number is bigger than
        //     // the last possible page number, return null for
        //     // problems but also send the last possible page so
        //     // the app can navigate to there
        //     if ( page > lastPage )
        //     {
        //         problems = null;
        //         pagination = {
        //             lastPage
        //         };
        //     }
        //     else
        //     {
        //         // Paginate the results by 10
        //         problems = problems.slice(begin, end);

        //         // Prepare the pagination mock-api
        //         pagination = {
        //             totalResults  : problemsLength,
        //             resultsPerPage: resultsPerPage,
        //             currentPage   : page,
        //             lastPage      : lastPage,
        //             startIndex    : begin,
        //             endIndex      : end - 1
        //         };
        //     }

        //     // Return the response
        //     return [
        //         200,
        //         {
        //             problems,
        //             pagination
        //         }
        //     ];
        // });

        // medications
        this._cadMockApiService
        .onGet('api/apps/patient/medications',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let medications: any[] | null = cloneDeep(this._medications);

            // Paginate - Start
            const medicationsLength = medications.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), medicationsLength);
            const lastPage = Math.max(Math.ceil(medicationsLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                medications = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                medications = medications.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : medicationsLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    medications,
                    pagination
                }
            ];
        });

        // allergy
        this._cadMockApiService
        .onGet('api/apps/patient/allergys',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let allergys: any[] | null = cloneDeep(this._allergys);

            // Paginate - Start
            const allergysLength = allergys.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), allergysLength);
            const lastPage = Math.max(Math.ceil(allergysLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                allergys = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                allergys = allergys.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : allergysLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    allergys,
                    pagination
                }
            ];
        });

        // personal history
        this._cadMockApiService
        .onGet('api/apps/patient/historys',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let historys: any[] | null = cloneDeep(this._historys);

            // Paginate - Start
            const historysLength = historys.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), historysLength);
            const lastPage = Math.max(Math.ceil(historysLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                historys = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                historys = historys.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : historysLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    historys,
                    pagination
                }
            ];
        });

        // devices
        this._cadMockApiService
        .onGet('api/apps/patient/devices',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let devices: any[] | null = cloneDeep(this._devices);

            // Paginate - Start
            const devicesLength = devices.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), devicesLength);
            const lastPage = Math.max(Math.ceil(devicesLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                devices = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                devices = devices.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : devicesLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    devices,
                    pagination
                }
            ];
        });

        // discharge summary
        this._cadMockApiService
        .onGet('api/apps/patient/discharges',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let discharges: any[] | null = cloneDeep(this._discharges);

            // Paginate - Start
            const dischargesLength = discharges.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), dischargesLength);
            const lastPage = Math.max(Math.ceil(dischargesLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                discharges = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                discharges = discharges.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : dischargesLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    discharges,
                    pagination
                }
            ];
        });

        // care plan
        this._cadMockApiService
        .onGet('api/apps/patient/cares',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let cares: any[] | null = cloneDeep(this._cares);

            // Paginate - Start
            const caresLength = cares.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), caresLength);
            const lastPage = Math.max(Math.ceil(caresLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                cares = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                cares = cares.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : caresLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    cares,
                    pagination
                }
            ];
        });

        // appointment
        this._cadMockApiService
        .onGet('api/apps/patient/appointments',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let appointments: any[] | null = cloneDeep(this._appointments);

            // Paginate - Start
            const appointmentsLength = appointments.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), appointmentsLength);
            const lastPage = Math.max(Math.ceil(appointmentsLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                appointments = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                appointments = appointments.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : appointmentsLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    appointments,
                    pagination
                }
            ];
        });

        // diagnosis
        this._cadMockApiService
        .onGet('api/apps/patient/diagnoses',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let diagnoses: any[] | null = cloneDeep(this._diagnoses);

            // Paginate - Start
            const diagnosesLength = diagnoses.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), diagnosesLength);
            const lastPage = Math.max(Math.ceil(diagnosesLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                diagnoses = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                diagnoses = diagnoses.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : diagnosesLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    diagnoses,
                    pagination
                }
            ];
        });

        // referrals
        this._cadMockApiService
        .onGet('api/apps/patient/referrals',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let referrals: any[] | null = cloneDeep(this._referrals);

            // Paginate - Start
            const referralsLength = referrals.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), referralsLength);
            const lastPage = Math.max(Math.ceil(referralsLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                referrals = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                referrals = referrals.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : referralsLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    referrals,
                    pagination
                }
            ];
        });

        // immunizations
        this._cadMockApiService
        .onGet('api/apps/patient/immunizations',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let immunizations: any[] | null = cloneDeep(this._immunizations);

            // Paginate - Start
            const immunizationsLength = immunizations.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), immunizationsLength);
            const lastPage = Math.max(Math.ceil(immunizationsLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                immunizations = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                immunizations = immunizations.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : immunizationsLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    immunizations,
                    pagination
                }
            ];
        });

        // observation
        this._cadMockApiService
        .onGet('api/apps/patient/observations',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let observations: any[] | null = cloneDeep(this._observations);

            // Paginate - Start
            const observationsLength = observations.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), observationsLength);
            const lastPage = Math.max(Math.ceil(observationsLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                observations = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                observations = observations.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : observationsLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    observations,
                    pagination
                }
            ];
        });

        // familys
        this._cadMockApiService
        .onGet('api/apps/patient/familys',625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let familys: any[] | null = cloneDeep(this._familys);

            // Paginate - Start
            const familysLength = familys.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), familysLength);
            const lastPage = Math.max(Math.ceil(familysLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                familys = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                familys = familys.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : familysLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    familys,
                    pagination
                }
            ];
        });


    // -----------------------------------------------------------------------------------------------------
    // @ Problem - GET
    // -----------------------------------------------------------------------------------------------------
    // problems
    // this._cadMockApiService
    //     .onGet('api/apps/patient/problem')
    //     .reply(({request}) => {

    //         // Get the id from the params
    //         const id = request.params.get('id');

    //         // Clone the problems mock-api to prevent accidental mock-api updates
    //         const problems = cloneDeep(this._problems);

    //         // Find the problem
    //         const problem = problems.find(item => item.id === id);

    //         return [
    //             200,
    //             problem
    //         ];
    //     });

        // medication
        this._cadMockApiService
        .onGet('api/apps/patient/medication')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const medications = cloneDeep(this._medications);

            // Find the problem
            const medication = medications.find(item => item.id === id);

            return [
                200,
                medication
            ];
        });

        // appointment
        this._cadMockApiService
        .onGet('api/apps/patient/appointment')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const appointments = cloneDeep(this._appointments);

            // Find the problem
            const appointment = appointments.find(item => item.id === id);

            return [
                200,
                appointment
            ];
        });


        // allergy
        this._cadMockApiService
        .onGet('api/apps/patient/allergy')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const allergys = cloneDeep(this._allergys);

            // Find the problem
            const allergy = allergys.find(item => item.id === id);

            return [
                200,
                allergy
            ];
        });

        // personal history
        this._cadMockApiService
        .onGet('api/apps/patient/history')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const historys = cloneDeep(this._allergys);

            // Find the problem
            const history = historys.find(item => item.id === id);

            return [
                200,
                history
            ];
        });

        // device
        this._cadMockApiService
        .onGet('api/apps/patient/device')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const devices = cloneDeep(this._devices);

            // Find the problem
            const device = devices.find(item => item.id === id);

            return [
                200,
                device
            ];
        });

        // discharge summary
        this._cadMockApiService
        .onGet('api/apps/patient/discharge')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const discharges = cloneDeep(this._discharges);

            // Find the problem
            const discharge = discharges.find(item => item.id === id);

            return [
                200,
                discharge
            ];
        });

        // care plan
        this._cadMockApiService
        .onGet('api/apps/patient/care')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const cares = cloneDeep(this._cares);

            // Find the problem
            const care = cares.find(item => item.id === id);

            return [
                200,
                care
            ];
        });

        // diagnosis
        this._cadMockApiService
        .onGet('api/apps/patient/diagnosis')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const diagnoses = cloneDeep(this._diagnoses);

            // Find the problem
            const diagnosis = diagnoses.find(item => item.id === id);

            return [
                200,
                diagnosis
            ];
        });

        // referral
        this._cadMockApiService
        .onGet('api/apps/patient/referral')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const referrals = cloneDeep(this._referrals);

            // Find the problem
            const referral = referrals.find(item => item.id === id);

            return [
                200,
                referral
            ];
        });

        // immunization
        this._cadMockApiService
        .onGet('api/apps/patient/immunization')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const immunizations = cloneDeep(this._immunizations);

            // Find the problem
            const immunization = immunizations.find(item => item.id === id);

            return [
                200,
                immunization
            ];
        });

        // observation
        this._cadMockApiService
        .onGet('api/apps/patient/observation')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const observations = cloneDeep(this._observations);

            // Find the problem
            const observation = observations.find(item => item.id === id);

            return [
                200,
                observation
            ];
        });

        // family
        this._cadMockApiService
        .onGet('api/apps/patient/family')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const familys = cloneDeep(this._familys);

            // Find the problem
            const family = familys.find(item => item.id === id);

            return [
                200,
                family
            ];
        });
        

    }

}
