import { KeycloakOptions, KeycloakService } from "keycloak-angular";
import { environment } from "environments/environment";

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
  const options: KeycloakOptions = {
    config: environment.keycloak,
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: "login-required",
      //silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
    enableBearerInterceptor: true,
    bearerExcludedUrls: ["/assets", "/styles", "/verify_email"],
  };

  return () => keycloak.init(options);
}
