import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, map, take, Observable, ReplaySubject, tap } from "rxjs";
import { NcrConfigService } from "app/ncrconfig.service";
import { FhirConfigService } from "app/fhirconfig.service";
import { AppConfigService } from "app/appconfig.service";
import {
  FilteringLogic,
  IForOfState,
  FilteringExpressionsTree,
} from "@infragistics/igniteui-angular";

const EMPTY_STRING = "";
const NULL_VALUE = null;
export enum FILTER_OPERATION {
  CONTAINS = "contain=",
  STARTS_WITH = "startswith",
  ENDS_WITH = "endswith",
  EQUALS = "=",
  DOES_NOT_EQUAL = "!=",
  DOES_NOT_CONTAIN = "not contains",
  GREATER_THAN = "gt",
  LESS_THAN = "lt",
  LESS_THAN_EQUAL = "le",
  GREATER_THAN_EQUAL = "ge",
}

const restHttpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/vnd.api+json",
  }),
};

const graphqlHttpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class DefaultLocationService {
  graphqlURL: string;
  restURL: string;

  public bundle$: Observable<any>;
  private _bundle: BehaviorSubject<fhir.r4.Bundle>;
  private _bundleLocation: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _bundlePractitionerRole: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(
    null
  );

  private virtualizationArgsCache: any;
  private filteringArgsCache: any;
  private sortingArgsCache: any;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _fhirConfigService: FhirConfigService,
    private _appConfigService: AppConfigService
  ) {
    this.restURL = this._ncrConfigService.getNcrService() + "/api/v1";
    this.graphqlURL = this._ncrConfigService.getNcrService() + "/graphql/api/v1";
    this._bundle = new BehaviorSubject(null);
    this.bundle$ = this._bundle.asObservable();
  }

  /*** Getter for FHIR bundle*/
  get bundleLocation$(): Observable<fhir.r4.BundleEntry[]> {
    return this._bundleLocation.asObservable();
  }

  /*** Getter for FHIR bundle*/
  get bundlePractitionerRole$(): Observable<fhir.r4.BundleEntry[]> {
    return this._bundlePractitionerRole.asObservable();
  }

  getPractitionerRole(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
        "/PractitionerRole?practitioner=" +
        this._appConfigService.getPractitionerId() +
        "&organization=" +
        this._appConfigService.getInstCode()
      )
      .pipe(
        map((response: any) => {
          this._bundlePractitionerRole.next(response);
          return response;
        })
      );
  }

  getLocationByInstCode(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
        "/Location?organization=" +
        this._appConfigService.getInstCode()
      )
      .pipe(
        map((response: any) => {
          this._bundleLocation.next(response);
          return response;
        })
      );
  }

  getPractitionerById(): Observable<any> {
    return this._httpClient
      .get<fhir.r4.Patient>(
        this._fhirConfigService.getFhirService() + "/Practitioner/" + this._appConfigService.getPractitionerId()
      )
      .pipe(take(1));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create PrmUsrloc
   */
  createDefaultLocation(systemModule: any) {
    return this._httpClient.post(
      this._ncrConfigService.getNcrService() + "/api/v1/PRMUsrloc",
      JSON.stringify({ data: systemModule }),
      restHttpOptions
    );
  }

  /**
   * Update PrmUsrloc by id
   */
  updateDefaultLocation(id: string, systemModule: any) {
    return this._httpClient.patch(
      this._ncrConfigService.getNcrService() + "/api/v1/PRMUsrloc/" + id,
      JSON.stringify({ data: systemModule }),
      restHttpOptions
    );
  }

  /**
   * Read PrmUsrloc by user id
   */
  readDefaultLocationById(id, id2): Observable<any> {
    return this._httpClient
      .get<any>(
        this._ncrConfigService.getNcrService() + "/api/v1/PRMUsrloc?filter=instCode==" + id + ";userId==" + id2
      )
      .pipe(take(1));
  }

  readDefaultLocationByUniqueId(id: string): Observable<any> {
    return this._httpClient
      .get<any>(
        this._ncrConfigService.getNcrService() + "/api/v1/PRMUsrloc/" + id
      )
      .pipe(take(1));
  }

  readDefaultLocation(): Observable<any> {
    return this._httpClient
      .get<any>(this._ncrConfigService.getNcrService() + "/api/v1/PRMUsrloc")
      .pipe();
  }

  public getData(
    virtualizationArgs?: IForOfState,
    filteringArgs?: any,
    sortingArgs?: any,
    cb?: (any) => void
  ): any {
    let queryString = `
    {
      PRMUsrloc {
        edges {
          node {
            id
            insertBy
            insertDate
            updateBy
            updateDate
            userId
            instCode
            branchCode
            practitionerRoleReference
            roleDisplay
            roleCode
            serviceCode
            ServiceDisplay
            locationReference
            locationDisplay
          }
        }
      }
    }`;
    return this._httpClient
      .post(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1/",
        JSON.stringify({
          query: this.buildDataUrl(queryString, virtualizationArgs, filteringArgs, sortingArgs),
        }),
        graphqlHttpOptions
      )
      .subscribe((response: any) => {
        this._bundle.next(response.data.PRMUsrLoc);
        if (cb) {
          cb(response.data.PRMUsrLoc);
        }
      });
  }

  private buildDataUrl(
    queryString: string,
    virtualizationArgs: any,
    filteringArgs: any,
    sortingArgs: any
  ): string {
    let baseQueryString = queryString;
    let scrollingQuery = EMPTY_STRING;
    let orderQuery = EMPTY_STRING;
    let filterQuery = EMPTY_STRING;
    let query = EMPTY_STRING;
    let order = EMPTY_STRING;
    let filter = EMPTY_STRING;

    if (virtualizationArgs === null) {
      virtualizationArgs = this.virtualizationArgsCache;
    }
    this.virtualizationArgsCache = virtualizationArgs;

    if (filteringArgs === null) {
      filteringArgs = this.filteringArgsCache;
    }
    this.filteringArgsCache = filteringArgs;

    if (sortingArgs === null) {
      sortingArgs = this.sortingArgsCache;
    }
    this.sortingArgsCache = sortingArgs;

    if (sortingArgs && sortingArgs.length > 0) {
      sortingArgs.forEach((arg) => {
        if (order !== EMPTY_STRING) {
          order += `,`;
        }
        order += this._buildSortExpression(arg);
      });
      orderQuery = `, sort:"${order}"`;
    }

    if (filteringArgs && filteringArgs.length > 0) {
      filteringArgs.forEach((columnFilter) => {
        if (filter !== EMPTY_STRING) {
          filter += ` ${FilteringLogic[columnFilter.operator].toLowerCase()} `;
        }

        filter += this._buildAdvancedFilterExpression(
          columnFilter.filteringOperands,
          columnFilter.operator
        );
      });
      filterQuery = `, filter:"${filter}"`;
    }

    if (virtualizationArgs) {
      scrollingQuery = this._buildScrollExpression(virtualizationArgs);
    }

    query = scrollingQuery !== EMPTY_STRING ? `${scrollingQuery}` : EMPTY_STRING;
    query += orderQuery !== EMPTY_STRING ? `${orderQuery}` : EMPTY_STRING;
    query += filterQuery !== EMPTY_STRING ? `${filterQuery}` : EMPTY_STRING;

    return baseQueryString.replace("$CONDITION", query);
  }

  private _buildAdvancedFilterExpression(operands, operator): string {
    let filterExpression = EMPTY_STRING;
    operands.forEach((operand, index) => {
      if (operand instanceof FilteringExpressionsTree) {
        if (index > 0) {
          filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
        }
        filterExpression += this._buildAdvancedFilterExpression(
          operand.filteringOperands,
          operand.operator
        );
        return filterExpression;
      }

      const value = operand.searchVal;
      let filterValue = value;
      const fieldName = operand.fieldName;
      let filterString;

      if (filterExpression !== EMPTY_STRING) {
        filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
      }

      switch (operand.condition.name) {
        case "Contains (case sensitive)": {
          filterString = `${fieldName}==*${filterValue}*`;
          break;
        }
        case "startsWith": {
          filterString = `${fieldName}==*${filterValue}`;
          break;
        }
        case "endsWith": {
          filterString = `${fieldName}==${filterValue}*`;
          break;
        }
        case "equals": {
          filterString = `${fieldName}=='${filterValue}'`;
          break;
        }
        case "doesNotEqual": {
          filterString = `${fieldName}!=='${filterValue}'`;
          break;
        }
        case "doesNotContain": {
          filterString = `${FILTER_OPERATION.DOES_NOT_CONTAIN}(${fieldName},${filterValue})`;
          break;
        }
        case "greaterThan": {
          filterString = `${fieldName}${FILTER_OPERATION.GREATER_THAN}${filterValue} `;
          break;
        }
        case "greaterThanOrEqualTo": {
          filterString = `${fieldName}${FILTER_OPERATION.GREATER_THAN_EQUAL}${filterValue} `;
          break;
        }
        case "lessThan": {
          filterString = `${fieldName}${FILTER_OPERATION.LESS_THAN}${filterValue} `;
          break;
        }
        case "lessThanOrEqualTo": {
          filterString = `${fieldName}${FILTER_OPERATION.LESS_THAN_EQUAL}${filterValue} `;
          break;
        }
        case "empty": {
          filterString = `length(${fieldName})${FILTER_OPERATION.EQUALS}0`;
          break;
        }
        case "notEmpty": {
          filterString = `length(${fieldName})${FILTER_OPERATION.GREATER_THAN}0`;
          break;
        }
        case "null": {
          filterString = `${fieldName}${FILTER_OPERATION.EQUALS}${NULL_VALUE}`;
          break;
        }
        case "notNull": {
          filterString = `${fieldName}${FILTER_OPERATION.DOES_NOT_EQUAL}${NULL_VALUE}`;
          break;
        }
      }

      filterExpression += filterString;
    });

    return filterExpression;
  }

  private _buildSortExpression(sortingArgs): string {
    let sortingDirection: string;
    switch (sortingArgs.dir) {
      case 1: {
        sortingDirection = EMPTY_STRING;
        break;
      }
      case 2: {
        sortingDirection = "-";
        break;
      }
      default: {
        sortingDirection = EMPTY_STRING;
        break;
      }
    }

    let fieldName = sortingArgs.fieldName;
    if (fieldName === "updateDate") {
      fieldName = "updateDate";
    }
    return `${sortingDirection}${fieldName}`;
  }

  private _buildScrollExpression(virtualizationArgs): string {
    let requiredChunkSize: number;
    const skip = virtualizationArgs.startIndex;
    requiredChunkSize = virtualizationArgs.chunkSize === 0 ? 11 : virtualizationArgs.chunkSize;
    const top = requiredChunkSize;

    return `first:"${top}", after:"${skip}"`;
  }
}
