import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { BooleanInput } from "@angular/cdk/coercion";
import { User } from "app/core/user/user.types";
import { AuthService } from "app/core/auth/auth.service";
import { AppConfigService } from "app/appconfig.service";
import { KeycloakProfile } from "keycloak-js";
import { lastValueFrom } from "rxjs";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { NavigationService } from "app/core/navigation/navigation.service";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { FhirPathService } from "app/fhirpath.service";
import * as Sentry from "@sentry/angular";
import { environment } from "environments/environment";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "user",
})
export class UserComponent implements OnInit {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = false;

  user: User;
  ncrUser: any;
  fhirEnable: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _keycloakService: KeycloakService,
    private _appConfigService: AppConfigService,
    private _navigationService: NavigationService,
    private _dbService: NgxIndexedDBService,
    private _fhirPathService: FhirPathService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    // const userProfile: KeycloakProfile = await this._authService.loadUserProfile();

    const userProfile: any = await this._authService.loadUserProfile();
    if (userProfile?.attributes.dashboard != null) {
      if (userProfile?.attributes.dashboard.length > 0)
        this._appConfigService.changeDahsboardAccess(userProfile?.attributes.dashboard[0]);
    }

    let ncrUser$;
    if (localStorage.hasOwnProperty("instCode")) {
      ncrUser$ = this._authService.switchInst(userProfile.id, this._appConfigService.getInstCode());
      this.ncrUser = await lastValueFrom(ncrUser$);
    } else {
      ncrUser$ = this._authService.getNcrUserProfile(userProfile.id);
      this.ncrUser = await lastValueFrom(ncrUser$);
      // Store default programroleid
      this._appConfigService.changePrimaryProgramRoleId(this.ncrUser.programRoleId);
    }

    this.user = {
      avatar: "",
      email: userProfile.email,
      name: this.ncrUser.fullName,
      id: userProfile.id,
      status: "online",
    };

    if (environment.environmentName === "QA" || environment.environmentName === "STAGING") {
      Sentry.setUser({ username: userProfile.username });
    }

    // if (this.ncrUser.programRoleId) {
    //   this._navigationService.getTreeMenu().subscribe();
    // }
    this._appConfigService.changeUserId(this.ncrUser.userId);
    this._appConfigService.changeUserName(this.ncrUser.fullName);
    this._appConfigService.changeInstCode(this.ncrUser.instCode);
    this._appConfigService.changeInstName(this.ncrUser.instName);
    this._appConfigService.changeAutoNoPrefix(this.ncrUser.autoNoPrefix);
    this._appConfigService.changeLocationId(this.ncrUser.locationId);
    this._appConfigService.changeUserIdentifier(this.ncrUser.idNo);
    this._appConfigService.changeAuthorizationLevel(this.ncrUser.authorizationLevel);
    //first time login practitionerId not store in NCR db yet.. need to call from FHIR
    if (this.ncrUser.practitionerId) {
      this._appConfigService.changePractitionerId(this.ncrUser.practitionerId);
    } else {
      this._authService.getPractitioner(userProfile.username).subscribe((res) => {
        this._appConfigService.changePractitionerId(this._authService.getPractitionerId(res));
      });
    }
    this._appConfigService.changePractitionerRoleId(this.ncrUser.practitionerRoleId);
    this._appConfigService.changePractitionerName(this.ncrUser.practitionerName);
    this._appConfigService.changeRoleDisplay(this.ncrUser.roleDisplay);
    this._appConfigService.changeRoleCode(this.ncrUser.roleCode);
    this._appConfigService.changeLocationDisplay(this.ncrUser.locationDisplay);
    this._appConfigService.changeServiceCode(this.ncrUser.serviceCode);
    this._appConfigService.changeServiceDisplay(this.ncrUser.serviceDisplay);
    this._appConfigService.changePreferencePanel(this.ncrUser.panelPreference);
    this._appConfigService.changeProgramRoleId(this.ncrUser.programRoleId);
    if (this.ncrUser.programRoleId) {
      this._navigationService.getTreeMenu().subscribe();
    }
    this._appConfigService.changeUserEmail(this.user.email);
    this._appConfigService.changeBranchCode(this.ncrUser.branchCode);
    this._appConfigService.changeSystemTasks(JSON.stringify(this.ncrUser.systemTasks));
    this._navigationService.changeInstitution(this.ncrUser.instName);
    this._dbService.bulkAdd("parameters", this.ncrUser.parameters).subscribe();
    this._dbService
      .getByIndex("parameters", "parameterId", "HIE_SESSION_MIN")
      .subscribe((min: any) => {
        this._appConfigService.changeMinSession(min.parameterValue);
      });
    this._dbService
      .getByIndex("parameters", "parameterId", "HIE_SESSION_SEC")
      .subscribe((sec: any) => {
        this._appConfigService.changeSecSession(sec.parameterValue);
      });
    this._dbService
      .getByIndex("parameters", "parameterId", "FHIR_ENABLED")
      .subscribe((fhir: any) => {
        this._appConfigService.changeFhirEnable(fhir.parameterValue);
        if (fhir.parameterValue === "Y") {
          this.fhirEnable = true;
        } else {
          this.fhirEnable = false;
        }
      });

    this._keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type === KeycloakEventType.OnTokenExpired) {
          this._keycloakService
            .updateToken(10)
            .then(function (refreshed) {
              if (refreshed) {
                console.log("Token was successfully refreshed");
              } else {
                console.log("Token is still valid");
              }
            })
            .catch(async function () {
              console.log("Failed to refresh the token, or the session has expired");
              await this._keycloakService.login({
                redirectUri: window.location.origin,
              });
            });
        }
      },
    });

    this._keycloakService.keycloakEvents$.subscribe({
      next: async (e) => {
        if (
          e.type === KeycloakEventType.OnAuthRefreshError ||
          e.type === KeycloakEventType.OnAuthLogout ||
          e.type === KeycloakEventType.OnAuthError
        ) {
          console.log("Failed to refresh the token, or the session has expired");
          await this._keycloakService.login({
            redirectUri: window.location.origin,
          });
        }
      },
    });
  }

  getPractitionerId(bundle: fhir.r4.Bundle) {
    let practitioner = this._fhirPathService.evaluate(
      bundle,
      "entry.resource.ofType(Practitioner)"
    );
    return this._fhirPathService.evaluateToString(practitioner[0], "id");
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  signOut(): void {
    this._authService.logout();
    localStorage.clear();
    this._dbService.deleteDatabase().subscribe();
  }

  changePassword(): void {
    this._router.navigate(["/change-password"]);
  }

  goToProfile(): void {
    this._router.navigate(["/user-profile"]);
  }

  defaultLocation(): void {
    this._router.navigate(["/default-location"]);
  }

  switchTenant(): void {
    this._router.navigate(["/switch-tenant"]);
  }

  switchTenantAndDefaultLocation(): void {
    this._router.navigate(["/switch-tenant-location"]);
  }

  goToAccount(): void {
    this._authService.redirectToProfile();
  }
}
