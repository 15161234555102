import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { CadMockApiService } from '@cad/lib/mock-api';
import { patients as patientsData, faqCategories as faqCategoriesData, faqs as faqsData, guideCategories as guideCategoriesData, guideContent as guideContentData, guides as guidesData } from 'app/mock-api/apps/help/data';

@Injectable({
    providedIn: 'root'
})
export class HelpCenterMockApi
{
    private _faqCategories: any[] = faqCategoriesData;
    private _faqs: any[] = faqsData;
    private _guideCategories: any[] = guideCategoriesData;
    private _guides: any[] = guidesData;
    private _guideContent: string = guideContentData;
    private _patients: any[] = patientsData;

    /**
     * Constructor
     */
    constructor(private _cadMockApiService: CadMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ FAQs - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/help-center/faqs')
            .reply(({request}) => {

                // Get the category slug
                const slug = request.params.get('slug');

                // Prepare the results
                const results = [];

                // Get FAQs
                const faqs = cloneDeep(this._faqs);

                // Get FAQ Categories
                const categories = cloneDeep(this._faqCategories);

                // If slug is not provided...
                if ( !slug )
                {
                    // Go through each category and set the results
                    categories.forEach((category) => {

                        results.push(
                            {
                                ...category,
                                faqs: faqs.filter(faq => faq.categoryId === category.id)
                            }
                        );
                    });
                }
                // Otherwise...
                else
                {
                    // Find the category by the slug
                    const category = categories.find(item => item.slug === slug);

                    // Set the results
                    results.push(
                        {
                            ...category,
                            faqs: faqs.filter(faq => faq.categoryId === category.id)
                        }
                    );
                }

                // Return the response
                return [200, results];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Guides - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/help-center/guides')
            .reply(({request}) => {

                // Get the slug & limit
                const slug = request.params.get('slug');
                const limit = request.params.get('limit');

                // Prepare the results
                const results = [];

                // Get all Guides
                const guides = cloneDeep(this._guides);

                // Get Guide categories
                const categories = cloneDeep(this._guideCategories);

                // If slug is not provided...
                if ( !slug )
                {
                    // Parse the limit as an integer
                    const limitNum = parseInt(limit ?? '5', 10);

                    // Go through each category and set the results
                    categories.forEach((category) => {

                        results.push(
                            {
                                ...category,
                                visibleGuides: limitNum,
                                totalGuides  : guides.filter(guide => guide.categoryId === category.id).length,
                                guides       : guides.filter(guide => guide.categoryId === category.id).slice(0, limitNum)
                            }
                        );
                    });
                }
                // Otherwise...
                else
                {
                    // Find the category by the slug
                    const category = categories.find(item => item.slug === slug);

                    // Set the results
                    results.push(
                        {
                            ...category,
                            guides: guides.filter(guide => guide.categoryId === category.id)
                        }
                    );
                }

                // Return the response
                return [200, results];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Guide - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/help-center/guide')
            .reply(({request}) => {

                // Get the slugs
                const categorySlug = request.params.get('categorySlug');
                const guideSlug = request.params.get('guideSlug');

                // Get all Guides and Guide Categories
                const guides = cloneDeep(this._guides);
                const categories = cloneDeep(this._guideCategories);

                // Prepare the result
                const result = {
                    ...categories.find(category => category.slug === categorySlug),
                    guides: [guides.find(guide => guide.slug === guideSlug)]
                };

                // Add the content to the guide
                result.guides[0]['content'] = this._guideContent;

                // Return the response
                return [200, result];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Products - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/help-center/patients', 300)
            .reply(({request}) => {

                // Get available queries
                const search = request.params.get('search');
                const sort = request.params.get('sort') || 'name';
                const order = request.params.get('order') || 'asc';
                const page = parseInt(request.params.get('page') ?? '1', 10);
                const size = parseInt(request.params.get('size') ?? '10', 10);

                // Clone the products
                let patients: any[] | null = cloneDeep(this._patients);

                // Sort the products
                if ( sort === 'id' )
                {
                    patients.sort((a, b) => {
                        const fieldA = a[sort].toString().toUpperCase();
                        const fieldB = b[sort].toString().toUpperCase();
                        return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
                    });
                }
                else
                {
                    patients.sort((a, b) => order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]);
                }

                // Paginate - Start
                const patientsLength = patients.length;

                // Calculate pagination details
                const begin = page * size;
                const end = Math.min((size * (page + 1)), patientsLength);
                const lastPage = Math.max(Math.ceil(patientsLength / size), 1);

                // Prepare the pagination object
                let pagination = {};

                // If the requested page number is bigger than
                // the last possible page number, return null for
                // products but also send the last possible page so
                // the app can navigate to there
                if ( page > lastPage )
                {
                    patients = null;
                    pagination = {
                        lastPage
                    };
                }
                else
                {
                    // Paginate the results by size
                    patients = patients.slice(begin, end);

                    // Prepare the pagination mock-api
                    pagination = {
                        length    : patientsLength,
                        size      : size,
                        page      : page,
                        lastPage  : lastPage,
                        startIndex: begin,
                        endIndex  : end - 1
                    };
                }

                // Return the response
                return [
                    200,
                    {
                        patients,
                        pagination
                    }
                ];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Product - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/help-center/patient')
            .reply(({request}) => {

                // Get the id from the params
                const id = request.params.get('id');

                // Clone the products
                const patients = cloneDeep(this._patients);

                // Find the product
                const patient = patients.find(item => item.id === id);

                // Return the response
                return [200, patient];
            });


    }
}
