<div
  class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-[40rem] lg:w-16 lg:h-screen lg:shadow"
>
  <div
    class="fixed flex flex-col w-full sm:w-[40rem] overflow-y-auto h-full transition-transform duration-400 ease-drawer bg-card"
    [ngClass]="{
      '-translate-x-full sm:-translate-x-[40rem] lg:-translate-x-[36rem] shadow': opened,
      'translate-x-0': !opened
    }"
  >
    <!-- Header -->
    <div
      class="compose-header flex flex-auto items-center justify-start cursor-pointer overflow-y-auto"
      (click)="toggle()"
    >
      <!-- Toggle -->
      <ng-container>
        <div class="flex flex-auto items-center justify-center">
          <div class="flex flex-0 items-center justify-center w-16">
            <mat-icon
              class="icon-size-6"
              [svgIcon]="iconChange()"
              matTooltip="Composition Note"
              matTooltipPosition="left"
            ></mat-icon>
          </div>
          <div class="text-lg font-medium text-secondary">
            Composition Note {{ compositionId !== null ? "(" + compositionId + ")" : null }}
          </div>
          <!-- ({{ compositionIndex + ". " + composition.type.coding[0].display }}) -->
          <button class="ml-auto mr-4" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <!-- Content -->
    <div class="flex flex-auto h-full border-t overflow-hidden overflow-y-auto">
      <!-- Chat list -->
      <div
        class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overscroll-auto"
        cadScrollbar
        [cadScrollbarOptions]="{ wheelPropagation: true }"
      >
        <div class="flex-auto">
          <igx-nav-drawer id="navigation" [pin]="true" [pinThreshold]="0">
            <ng-template igxDrawerMini>
              <div *ngFor="let item of navItems">
                <span [hidden]="item.hidden" igxDrawerItem igxRipple (click)="navigate(item)">
                  <igx-icon igxTooltipTarget tooltip="{{ item.text }}" family="material">{{
                    item.icon
                  }}</igx-icon>
                </span>
              </div>
            </ng-template>
          </igx-nav-drawer>
        </div>
      </div>

      <!-- Conversation -->
      <div
        class="flex h-full flex-col flex-auto border-l overflow-y-auto overscroll-y-auto bg-gray-50 dark:bg-transparent"
        cadScrollbar
        [cadScrollbarOptions]="{ wheelPropagation: true }"
      >
        <button id="bannerClose" [hidden]="true" (click)="collectICD11()"></button>
        <igx-banner id="ICD11Banner" #ICD11Banner style="width: 100%">
          <div [innerHTML]="html"></div>
        </igx-banner>

        <igx-combo
          #cbNoteSections
          [hidden]="!enterNote"
          [groupKey]="'input'"
          placeholder="Add more note section below"
          [data]="noteSections"
          [displayKey]="'display'"
          (selectionChanging)="sectionSelection($event)"
        >
        </igx-combo>

        <!-- <igx-combo #cbNoteSections [hidden]="!enterNote" placeholder="Assetter" [data]="attender"
          [displayKey]="'display'" (selectionChanging)="sectionSelection($event)">
        </igx-combo> -->

        <div
          class="flex h-full flex-col flex-auto border-l overflow-y-auto overscroll-y-contain bg-gray-50 dark:bg-transparent"
        >
          <ng-container *ngIf="!enterNote">
            <div class="grid grid-cols-1 gap-y-2 p-4">
              <div>
                <igx-combo
                  #cbNoteCategories
                  [data]="noteCategories"
                  [displayKey]="'display'"
                  (selectionChanging)="
                    singleSelection($event, 'noteCategories'); cbNoteCategories.close()
                  "
                >
                  <label igxLabel>Category</label>
                </igx-combo>
              </div>
              <div>
                <igx-combo
                  #cbNoteTypes
                  [disabled]="disabled"
                  [data]="noteTypes"
                  [displayKey]="'display'"
                  [disabled]="cbNotesTypeDisable"
                  (selectionChanging)="singleSelection($event, 'noteTypes'); cbNoteTypes.close()"
                >
                  <label igxLabel>Type</label>
                </igx-combo>
              </div>
              <div>
                <igx-combo
                  #cbNoteEvents
                  [data]="noteEvents"
                  [displayKey]="'display'"
                  [disabled]="cbNoteEventsDisable"
                  (selectionChanging)="singleSelection($event, 'noteEvents'); cbNoteEvents.close()"
                >
                  <label igxLabel>Event</label>
                </igx-combo>
              </div>
              <div>
                <button
                  igxButton="outlined"
                  igxRipple
                  (click)="checkCreation()"
                  [disabled]="
                    cbNoteCategories.selection.length === 0 ||
                    cbNoteTypes.selection.length === 0 ||
                    cbNoteEvents.selection.length === 0
                  "
                >
                  <igx-icon>create_new_folder</igx-icon>
                  <span>Create</span>
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="enterNote">
            <form [formGroup]="compositionForm" class="grid grid-cols-1 pr-4">
              <igx-card class="card">
                <igx-card-header>
                  <h3 igxCardHeaderTitle>
                    Event Date
                    <igx-icon igxTooltipTarget tooltip="Period when practitioner attended patient"
                      >info_outline</igx-icon
                    >
                  </h3>
                </igx-card-header>
                <igx-card-content>
                  <div>
                    <igx-date-picker
                      #eventDate
                      formControlName="eventDate"
                      inputFormat="dd/MM/yyyy"
                      (valueChange)="checkEventDate($event)"
                    >
                      <label igxLabel>Event Date</label>
                    </igx-date-picker>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="eventDate.errors?.['notValid']"
                    >
                      Cannot more than current Date
                    </span>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="eventDate.invalid && (eventDate.dirty || eventDate.touched)"
                      >This is required field</span
                    >
                  </div>
                </igx-card-content>
                <igx-card-content>
                  <div>
                    <igx-time-picker
                      formControlName="eventTime"
                      inputFormat="hh:mm tt"
                      (valueChange)="checkEventDate($event)"
                    >
                      <label igxLabel>Event Time</label>
                    </igx-time-picker>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="eventTime.invalid && (eventTime.dirty || eventTime.touched)"
                      >This is required field</span
                    >
                  </div>
                </igx-card-content>
              </igx-card>

              <div *ngIf="ctlAllergies">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Allergies and intolerance</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="allergiesForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        allergiesForm.invalid && (allergiesForm.dirty || allergiesForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlProblemList">
                <igx-card class="card">
                  <igx-card-header>
                    <button igxButton="outlined" (click)="addProblem()">
                      <igx-icon family="imx-icons" name="add-row"></igx-icon>
                      <span>Add</span>
                    </button>
                  </igx-card-header>
                  <igx-card-content>
                    <ng-container
                      formArrayName="problemList"
                      *ngFor="
                        let problem of compositionForm.get('problemList').controls;
                        let i = index
                      "
                    >
                      <igx-card [formGroupName]="i" class="card">
                        <igx-card-header>
                          <h3 igxCardHeaderTitle>Diagnosis #{{ i + 1 }}</h3>
                          <button
                            type="button"
                            [hidden]="checkIndex(i)"
                            (click)="removeProblemList(i)"
                          >
                            Remove
                          </button>
                        </igx-card-header>
                        <igx-card-content>
                          <div class="grid grid-cols-1 gap-y-2 pr-4">
                            <input type="hidden" formControlName="conditionId" />
                            <div class="col-span-1 sm:col-span-2">
                              <igx-combo
                                #role
                                [data]="diagnosisRole"
                                [displayKey]="'display'"
                                formControlName="role"
                                (selectionChanging)="singleSelection($event, 'role'); role.close()"
                              >
                                <label igxLabel>Role</label>
                              </igx-combo>
                              <span
                                class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                                *ngIf="role.invalid && (role.dirty || role.touched)"
                              >
                                This is required field
                              </span>
                            </div>
                            <div>
                              <igx-input-group>
                                <input
                                  #conditionDescription
                                  igxInput
                                  readonly
                                  formControlName="conditionDescription"
                                  placeholder="Click icon to search ICD11"
                                />
                                <label igxLabel>ICD 11 Description</label>
                                <igx-prefix>
                                  <igx-icon (click)="searchICD11('P', i)">search</igx-icon>
                                </igx-prefix>
                              </igx-input-group>
                              <span
                                class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                                *ngIf="
                                  conditionDescription.invalid &&
                                  (conditionDescription.dirty || conditionDescription.touched)
                                "
                              >
                                This is required field
                              </span>
                            </div>
                            <div>
                              <igx-input-group>
                                <input #icdCode readonly igxInput formControlName="conditionCode" />
                                <label igxLabel>ICD11 Codes</label>
                                <igx-suffix
                                  *ngIf="icdCode.value.length > 0"
                                  (click)="icdCode.value = ''"
                                >
                                  <igx-icon>clear</igx-icon>
                                </igx-suffix>
                              </igx-input-group>
                            </div>
                            <div class="col-span-1 sm:col-span-2">
                              <igx-input-group>
                                <input #note igxInput formControlName="note" />
                                <label igxLabel>Description</label>
                              </igx-input-group>
                              <!-- <span class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                                *ngIf="note.invalid && (note.dirty || note.touched)">
                                This is required field
                              </span> -->
                            </div>
                            <div class="col-span-1 sm:col-span-2">
                              <igx-date-picker
                                #onsetDateTime
                                formControlName="onsetDateTime"
                                inputFormat="dd/MM/yyyy"
                                (valueChange)="
                                  checkCuredDateValidity(abatementDateTime.value, $event)
                                "
                              >
                                <label igxLabel>Onset Date</label>
                              </igx-date-picker>
                              <span
                                class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                                *ngIf="
                                  onsetDateTime.invalid &&
                                  (onsetDateTime.dirty || onsetDateTime.touched)
                                "
                                >This is required field</span
                              >
                            </div>
                            <div class="col-span-1 sm:col-span-2">
                              <igx-date-picker
                                #abatementDateTime
                                formControlName="abatementDateTime"
                                inputFormat="dd/MM/yyyy"
                                (valueChange)="checkCuredDateValidity($event, onsetDateTime.value)"
                              >
                                <label igxLabel>Cured Date</label>
                                <igx-picker-clear igxSuffix>
                                  <igx-icon (click)="clearDate()">clear</igx-icon>
                                </igx-picker-clear>
                              </igx-date-picker>
                              <span
                                class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                                *ngIf="
                                  abatementDateTime.invalid &&
                                  (abatementDateTime.dirty || abatementDateTime.touched)
                                "
                                >This is required field</span
                              >
                              <span
                                class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                                *ngIf="abatementDateTime.errors?.['incorrect']"
                                >Cured Date must more thatn On Set Date</span
                              >
                            </div>
                          </div>
                        </igx-card-content>
                      </igx-card>
                    </ng-container>
                    <igx-divider></igx-divider>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlClinicalInformation">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Clinical Information</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        igxInput
                        content-height
                        (keyup)="autoGrowTextZone($event)"
                        formControlName="clinicalInformationForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        clinicalInformationForm.invalid &&
                        (clinicalInformationForm.dirty || clinicalInformationForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlVitalSign">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Vital Signs</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <div class="grid grid-cols-1 gap-y-2 pr-4">
                      <div>
                        <igx-input-group>
                          <input
                            type="text"
                            igxInput
                            formControlName="vtsRespiratoryRateForm"
                            maxlength="3"
                          />
                          <label igxLabel>Respiratory Rate(/min)</label>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsRespiratoryRateForm.errors?.['number']"
                        >
                          Only number are allowed
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsRespiratoryRateForm.errors?.['max']"
                        >
                          Maximum value is 100
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            vtsRespiratoryRateForm.invalid &&
                            (vtsRespiratoryRateForm.dirty || vtsRespiratoryRateForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                      <div>
                        <igx-input-group>
                          <input
                            type="text"
                            igxInput
                            formControlName="vtsHeartRateForm"
                            maxlength="3"
                          />
                          <label igxLabel>Heart Rate(/min)</label>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsHeartRateForm.errors?.['number']"
                        >
                          Only number are allowed
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsHeartRateForm.errors?.['max']"
                        >
                          Maximum value is 250
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            vtsHeartRateForm.invalid &&
                            (vtsHeartRateForm.dirty || vtsHeartRateForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                      <div>
                        <igx-input-group>
                          <input
                            type="text"
                            igxInput
                            formControlName="vtsOxygenSaturationForm"
                            maxlength="3"
                          />
                          <label igxLabel>Oxygen Saturation(%)</label>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsOxygenSaturationForm.errors?.['number']"
                        >
                          Only number are allowed
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsOxygenSaturationForm.errors?.['max']"
                        >
                          Maximum value is 100
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            vtsOxygenSaturationForm.invalid &&
                            (vtsOxygenSaturationForm.dirty || vtsOxygenSaturationForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                      <div>
                        <igx-input-group>
                          <input
                            type="text"
                            igxInput
                            formControlName="vtsBodyTemperatureForm"
                            maxlength="5"
                          />
                          <label igxLabel>Body Temperature('C)</label>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsBodyTemperatureForm.errors?.['number']"
                        >
                          Only number are allowed
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsBodyTemperatureForm.errors?.['max']"
                        >
                          Maximum value is 100
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            vtsBodyTemperatureForm.invalid &&
                            (vtsBodyTemperatureForm.dirty || vtsBodyTemperatureForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                      <div>
                        <igx-input-group>
                          <input
                            type="text"
                            igxInput
                            formControlName="vtsSystolicPressureForm"
                            maxlength="3"
                          />
                          <label igxLabel>Systolic Blood Pressure(mmHg)</label>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsSystolicPressureForm.errors?.['number']"
                        >
                          Only number are allowed
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsSystolicPressureForm.errors?.['max']"
                        >
                          Maximum value is 400
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            vtsSystolicPressureForm.invalid &&
                            (vtsSystolicPressureForm.dirty || vtsSystolicPressureForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                      <div>
                        <igx-input-group>
                          <input
                            type="text"
                            igxInput
                            formControlName="vtsDiastolicPressureForm"
                            maxlength="3"
                          />
                          <label igxLabel>Diastolic Blood Pressure(mmHg)</label>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsDiastolicPressureForm.errors?.['number']"
                        >
                          Only number are allowed
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsDiastolicPressureForm.errors?.['max']"
                        >
                          Maximum value is 200
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            vtsDiastolicPressureForm.invalid &&
                            (vtsDiastolicPressureForm.dirty || vtsDiastolicPressureForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                      <div>
                        <igx-input-group>
                          <input
                            type="text"
                            igxInput
                            formControlName="vtsPainScoreForm"
                            maxlength="2"
                          />
                          <label igxLabel>Pain Score</label>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsPainScoreForm.errors?.['number']"
                        >
                          Only number are allowed
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="vtsPainScoreForm.errors?.['max']"
                        >
                          Maximum value is 10
                        </span>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            vtsPainScoreForm.invalid &&
                            (vtsPainScoreForm.dirty || vtsPainScoreForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                    </div>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlMedication">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Medication section</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="medicationForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        medicationForm.invalid && (medicationForm.dirty || medicationForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlSocialHistory">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Social history</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="socialHistoryForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        socialHistoryForm.invalid &&
                        (socialHistoryForm.dirty || socialHistoryForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlFamilyHistory">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Family history</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="familyHistoryForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        familyHistoryForm.invalid &&
                        (familyHistoryForm.dirty || familyHistoryForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlImmunizationHistory">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Immunization history</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="immunizationHistoryForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        immunizationHistoryForm.invalid &&
                        (immunizationHistoryForm.dirty || immunizationHistoryForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlAssessment">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Assessment</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="assessmentForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        assessmentForm.invalid && (assessmentForm.dirty || assessmentForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="dntAlert">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Dentistry Alert</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="dentistryAlertForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        dentistryAlertForm.invalid &&
                        (dentistryAlertForm.dirty || dentistryAlertForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="dntChart">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Dentistry Chart</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="dentistryChartForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        dentistryChartForm.invalid &&
                        (dentistryChartForm.dirty || dentistryChartForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="adsEvent">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Adverse Event</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <div class="grid grid-cols-1 gap-y-2 pr-4">
                      <div>
                        <igx-input-group>
                          <textarea
                            (keyup)="autoGrowTextZone($event)"
                            content-height
                            igxInput
                            formControlName="adverseReactionForm"
                          ></textarea>
                        </igx-input-group>
                        <span
                          class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                          *ngIf="
                            adverseReactionForm.invalid &&
                            (adverseReactionForm.dirty || adverseReactionForm.touched)
                          "
                        >
                          This is required field
                        </span>
                      </div>
                    </div>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="proDesc">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Procedure Description</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        igxInput
                        content-height
                        (keyup)="autoGrowTextZone($event)"
                        formControlName="procedureDescriptionForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        procedureDescriptionForm.invalid &&
                        (procedureDescriptionForm.dirty || procedureDescriptionForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="proFind">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Procedure Findings</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="procedureFindingsForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        procedureFindingsForm.invalid &&
                        (procedureFindingsForm.dirty || procedureFindingsForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="proComp">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Complications</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="procedureComplicationsForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="
                        procedureComplicationsForm.invalid &&
                        (procedureComplicationsForm.dirty || procedureComplicationsForm.touched)
                      "
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div *ngIf="ctlCarePlan">
                <igx-card class="card">
                  <igx-card-header>
                    <h3 igxCardHeaderTitle>Care Plan</h3>
                  </igx-card-header>
                  <igx-card-content>
                    <igx-input-group>
                      <textarea
                        (keyup)="autoGrowTextZone($event)"
                        content-height
                        igxInput
                        formControlName="carePlanForm"
                      ></textarea>
                    </igx-input-group>
                    <span
                      class="flex items-center tracking-wide text-red-500 mt-1 ml-1"
                      *ngIf="carePlanForm.invalid && (carePlanForm.dirty || carePlanForm.touched)"
                    >
                      This is required field
                    </span>
                  </igx-card-content>
                </igx-card>
              </div>
              <div style="padding-top: 200px"></div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<igx-snackbar
  #snackbar
  [autoHide]="true"
  actionText="Close"
  (clicked)="closeSnackbar(snackbar)"
></igx-snackbar>
