import { Component, HostListener } from "@angular/core";
import { environment } from "environments/environment";
import { FhirConfigService } from "./fhirconfig.service";
import { AppConfigService } from "./appconfig.service";
import { NcrConfigService } from "./ncrconfig.service";
import { AuthService } from "./core/auth/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {

  //private tabWasClosed = false;//
  private accessToken: string;
  private refreshToken: string;

  //@HostListener('window:beforeunload', ['$event'])
  doBeforeUnload() {
    //if (document.visibilityState === 'hidden') {
    this.accessToken = this._authService.accessToken();
    this.refreshToken = this._authService.refreshToken();

    //this.tabWasClosed = true;
    //}
    //return false;
  }

  //@HostListener('window:unload', ['$event'])
  doUnload() {
    //if (this.tabWasClosed) {
    if (environment.production) {
      this.checkHiddenDocument();
    }
    //}
  }

  private checkHiddenDocument() {
    if (!navigator.sendBeacon) return true;

    var data = {
      "id": environment.keycloak.clientId,
      "accessToken": this.accessToken,
      "refreshToken": this.refreshToken
    };
    let headers = {
      type: "application/json"
    };
    let blob = new Blob([JSON.stringify(data)], headers);
    this._authService.clearToken();

    navigator.sendBeacon(this._ncrConfigService.getNcrService() + "/api/v1/auth/logout", blob);
  }

  /**
   * Constructor
   */
  constructor(
    private _appConfigService: AppConfigService,
    private _fhirConfigService: FhirConfigService,
    private _ncrConfigService: NcrConfigService,
    private _authService: AuthService,
  ) {
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
    }

    this._fhirConfigService.changeFhirService(environment.fhirURL);
    this._ncrConfigService.changeNcrService(environment.apiURL);

    window.localStorage.setItem("read_url", environment.apiURL);

    this._appConfigService.changeDateFormat("dd-MMM-yyyy");
    this._appConfigService.changeDateTimeFormat("dd-MMM-yyyy HH:mm:ss");
    this._appConfigService.changeTimezone("UTC+8");
  }
}
