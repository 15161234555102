import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { merge } from "lodash-es";
import { CadConfirmationDialogComponent } from "@cad/services/confirmation/dialog/dialog.component";
import { CadConfirmationConfig } from "@cad/services/confirmation/confirmation.types";

@Injectable()
export class CadConfirmationService {
  private _defaultConfig: CadConfirmationConfig = {
    title: "Confirm action",
    message: "Are you sure you want to confirm this action?",
    icon: {
      show: true,
      name: "heroicons_outline:exclamation",
      color: "warn",
    },
    actions: {
      confirm: {
        show: true,
        label: "Confirm",
        color: "warn",
      },
      cancel: {
        show: true,
        label: "Cancel",
      },
      back: {
        show: false,
        label: "Back",
      },
    },
    dismissible: false,
  };

  private _defaultConfig2: CadConfirmationConfig = {
    title: "Confirm action",
    message: "Are you sure you want to confirm this action?",
    icon: {
      show: true,
      name: "heroicons_outline:exclamation",
      color: "warn",
    },
    actions: {
      confirm: {
        show: true,
        label: "Confirm",
        color: "warn",
      },
      cancel: {
        show: false,
        label: "Cancel",
      },
      back: {
        show: false,
        label: "Back",
      },
    },
    dismissible: false,
  };

  private _defaultConfig3: CadConfirmationConfig = {
    title: "Confirm action",
    message: "Are you sure you want to confirm this action?",
    icon: {
      show: true,
      name: "heroicons_outline:exclamation",
      color: "warn",
    },
    actions: {
      confirm: {
        show: true,
        label: "Confirm",
        color: "warn",
      },
      cancel: {
        show: true,
        label: "Cancel",
      },
      back: {
        show: true,
        label: "Back",
      },
    },
    dismissible: false,
  };
  
  private _defaultConfig4: CadConfirmationConfig = {
    title: "Confirm action",
    message: "Are you sure you want to confirm this action?",
    icon: {
      show: true,
      name: "heroicons_outline:exclamation",
      color: "warn",
    },
    actions: {
      confirm: {
        show: true,
        label: "Confirm",
        color: "warn",
      },
      cancel: {
        show: true,
        label: "Cancel",
      },
      back: {
        show: false,
        label: "Back",
      },
    },
	body: {
		component: "combobox"
	},
    dismissible: false,
  };

  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(config: CadConfirmationConfig = {}): MatDialogRef<CadConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(CadConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "cad-confirmation-dialog-panel",
    });
  }

  openConfirm(config: CadConfirmationConfig = {}): MatDialogRef<CadConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig2, config);

    // Open the dialog
    return this._matDialog.open(CadConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "cad-confirmation-dialog-panel",
    });
  }

  openDialog(config: CadConfirmationConfig = {}): MatDialogRef<CadConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig3, config);

    // Open the dialog
    return this._matDialog.open(CadConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "cad-confirmation-dialog-panel",
    });
  }
  
  openDialogBody(config: CadConfirmationConfig = {}): MatDialogRef<CadConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig4, config);

    // Open the dialog
    return this._matDialog.open(CadConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "cad-confirmation-dialog-panel",
    });
  }
}
