
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, of, switchMap, take, throwError, Observable, ReplaySubject, tap } from 'rxjs';
import { FaqCategory, Guide, GuideCategory, RecordsPagination, Patient } from 'app/modules/provider/person-search/person-search.type';
import { FhirConfigService } from "app/fhirconfig.service";
import { AppConfigService } from 'app/appconfig.service';
import { NcrConfigService } from "app/ncrconfig.service";

@Injectable({
  providedIn: 'root'
})
export class PersonSearchService {
  private _faqs: ReplaySubject<FaqCategory[]> = new ReplaySubject<FaqCategory[]>(1);
  private _guides: ReplaySubject<GuideCategory[]> = new ReplaySubject<GuideCategory[]>(1);
  private _guide: ReplaySubject<Guide> = new ReplaySubject<Guide>(1);
  private _pagination: BehaviorSubject<RecordsPagination | null> = new BehaviorSubject(null);
  // private _patient: BehaviorSubject<Patient | null> = new BehaviorSubject(null);
  // private _patients: BehaviorSubject<Patient[] | null> = new BehaviorSubject(null);

  // selectedOrganizationChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  // private _organizations: BehaviorSubject<fhir.r4.Organization[]> = new BehaviorSubject(null);
  // private _organizationsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private _organization: BehaviorSubject<fhir.r4.Organization> = new BehaviorSubject(null);

  // patient
  private _patientsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _patientsLoading2: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _patient: BehaviorSubject<fhir.r4.Patient> = new BehaviorSubject(null);
  private _bundle: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);

  // encounter
  private _encountersLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _encounter: BehaviorSubject<fhir.r4.Encounter> = new BehaviorSubject(null);
  private _encounters: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);


  /**
* Constructor
*/
  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _appConfigService: AppConfigService,
    private _ncrConfigService: NcrConfigService,
  ) { }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
* Getter for patient
*/
  get patient$(): Observable<fhir.r4.Patient> {
    return this._patient.asObservable();
  }

  /**
 * Getter for patients loading
 */
  get patientsLoading$(): Observable<boolean> {
    return this._patientsLoading.asObservable();
  }

  get patientsLoading2$(): Observable<boolean> {
    return this._patientsLoading2.asObservable();
  }

  /**
   * Getter for FHIR bundle
   */
  get bundle$(): Observable<fhir.r4.Bundle> {
    return this._bundle.asObservable();
  }

  // encounter\\
  /**
   * Getter for encounters loading
   */
  get encountersLoading$(): Observable<boolean> {
    return this._encountersLoading.asObservable();
  }

  /**
   * Getter for encounter
   */
  get encounter$(): Observable<fhir.r4.Encounter> {
    return this._encounter.asObservable();
  }

  /**
   * Getter for FHIR bundle of patient
   */
  get encounters$(): Observable<fhir.r4.Bundle> {
    return this._encounters.asObservable();
  }

  /**
   * Constructor
   */

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for FAQs
   */
  get faqs$(): Observable<FaqCategory[]> {
    return this._faqs.asObservable();
  }

  /**
   * Getter for guides
   */
  get guides$(): Observable<GuideCategory[]> {
    return this._guides.asObservable();
  }

  /**
   * Getter for guide
   */
  get guide$(): Observable<GuideCategory> {
    return this._guide.asObservable();
  }

  /**
   * Getter for pagination
   */
  get pagination$(): Observable<RecordsPagination> {
    return this._pagination.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  /**
  * Get patients
  */
  getPatients(skip: number, top: number): Observable<any> {
    const query = {
      _count: top,
      _getpagesoffset: skip,
      _summary: "false",
      _sort: "name",
      _total: "accurate",
    };

    // Execute the loading with true
    this._patientsLoading.next(true);

    return this._httpClient.get<fhir.r4.Patient[]>(this._fhirConfigService.getFhirService() + "/Patient",
      {
        params: query,
      }
    )
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
          this._patientsLoading.next(false);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () => new Error("Requested page is not available!")
            );
          }
          return of(response);
        })
      );
  }

  /**
 * Read patient by id
 */
  getPatientId(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Patient>(this._fhirConfigService.getFhirService() + "/Patient/" + id)
      .pipe(take(1));

  }

  getEncounterById(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Encounter>(this._fhirConfigService.getFhirService() + "/Encounter?subject:mdm=Patient/" + id + "&_sort=-_lastUpdated&_count=1")
      .pipe(take(1));

  }

  /**
 * Reset the current organization
 */
  resetPatient(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this._patient.next(null);
      })
    );
  }

  /**
   * Get product by id
   */
  getPatientById(id: string): Observable<Patient> {
    return this._patient.pipe(
      take(1),
      map((patient) => {

        // Find the product
        //const patient = patients.find(item => item.id === id) || null;

        // Update the product
        //this._patient.next(patient);

        // Return the product
        //return patient;
        return null;
      }),
      switchMap((patient) => {

        if (!patient) {
          return throwError('Could not found patient with id of ' + id + '!');
        }

        return of(patient);
      })
    );
  }

  readPatientById(id: string): Observable<any> {
    if (id.includes("/") == true) {
      return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content=" + id
        + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization")
        .pipe(
          tap((response: any) => {
            this._bundle.next(response);
          }),
          switchMap((response) => {
            if (response.problems === null) {
              return throwError(
                () =>
                  new Error("Could not found patient with id of " + id + "!")
              );
            }
            return of(response);
          })
        );
    } else if (id.includes("#")) {
      return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content="
        + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization")
        .pipe(
          tap((response: any) => {
            this._bundle.next(response);
          }),
          switchMap((response) => {
            if (response.problems === null) {
              return throwError(
                () =>
                  new Error("Could not found patient with id of " + id + "!")
              );
            }
            return of(response);
          })
        );
    } else if (id.includes("-")) {
      return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content=" + id
        + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization")
        .pipe(
          tap((response: any) => {
            this._bundle.next(response);
          }),
          switchMap((response) => {
            if (response.problems === null) {
              return throwError(
                () =>
                  new Error("Could not found patient with id of " + id + "!")
              );
            }
            return of(response);
          })
        );
    } else {
      return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content=" + id
        + "*&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization")
        .pipe(
          tap((response: any) => {
            this._bundle.next(response);
          }),
          switchMap((response) => {
            if (response.problems === null) {
              return throwError(
                () =>
                  new Error("Could not found patient with id of " + id + "!")
              );
            }
            return of(response);
          })
        );
    }

  }

  getPatientByOrganization(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content=" + id + "*&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization" + "&organization=" + this._appConfigService.getInstCode())
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getPatientByName(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?name:contains=" + id + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Patient-my-core&_count=500&active=true")
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getPatientByNameAndFacility(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?name:contains=" + id + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Patient-my-core&_count=500&active=true" + "&organization=" + this._appConfigService.getInstCode())
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getSearchAll(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?identifier=" + id + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization")
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getSearchAllByFacility(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content=" + id + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization" + "&organization=" + this._appConfigService.getInstCode())
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getPatientByDefault(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content=" + id + "*&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization")
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getPatientByDefaultAndFacility(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?_content=" + id + "*&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization" + "&organization=" + this._appConfigService.getInstCode())
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getPatientByIdNo(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?identifier=" + id + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Patient-my-core&_count=500&active=true")
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  getPatientByIdNoAndFacility(id: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Patient?identifier=" + id + "&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&_include=Patient:organization&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Patient-my-core&_count=500&active=true" + "&organization=" + this._appConfigService.getInstCode())
      .pipe(
        tap((response: any) => {
          this._bundle.next(response);
        }),
        switchMap((response) => {
          if (response.problems === null) {
            return throwError(
              () =>
                new Error("Could not found patient with id of " + id + "!")
            );
          }
          return of(response);
        })
      );
  }

  readSwitchTenantLocationById(id: string, id2: string): Observable<any> {
    return this._httpClient
      .get<any>(
        this._ncrConfigService.getNcrService() + "/api/v1/PRMUsrloc?filter=instCode==" + id + ";userId==" + id2
      )
      .pipe(take(1));
  }

}
