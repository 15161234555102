export const environment = {
    production: true,
    environmentName: 'QA',
    fhirURL: "https://veinscdr.mhnexus.com/baseR4",
    apiURL: "https://veinsinsight.mhnexus.com",
    keycloak: {
        url: "https://veinssso.mhnexus.com",
        realm: "provider",
        clientId: "dashboard"
    },
    default: "P@ssw0rd"
};
