import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { FhirConfigService } from "app/fhirconfig.service";
import { AppConfigService } from "app/appconfig.service";
import { RightCurriedFunction4 } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AllergyService {
    selectedAllergyChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    private _allergys: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
    private _allergysLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _allergy: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<any> = new BehaviorSubject(null);

    public patientId;

    /**
     * Constructor
     */
    constructor
        (
            private _httpClient: HttpClient,
            private _fhirConfigService: FhirConfigService,
            private _appConfigService: AppConfigService
        ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for allergys
     */
    get allergys$(): Observable<fhir.r4.Bundle> {
        return this._allergys.asObservable();
    }

    /**
     * Getter for allergys loading
     */
    get allergysLoading$(): Observable<boolean> {
        return this._allergysLoading.asObservable();
    }

    /**
     * Getter for allergy
     */
    get allergy$(): Observable<fhir.r4.Bundle> {
        return this._allergy.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<any> {
        return this._pagination.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get allergys (mdm record)
     */
    getAllergys(skip: number, top: number, currentPage: number): Observable<any> {
        this._allergysLoading.next(true);

        // call and store patientId
        this.patientId = this._appConfigService.getPatientResourceId();

        return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService()
            + "/AllergyIntolerance?patient:mdm=Patient/" + this.patientId
            + "&_include=AllergyIntolerance:asserter"
            + "&_include=AllergyIntolerance:encounter"
            + "&_include=AllergyIntolerance:patient"
            + "&_include=AllergyIntolerance:recorder"
            + "&_include:iterate=PractitionerRole:practitioner"
            + "&_sort=-date",
        ).pipe(
            tap((response: any) => {
                this._allergys.next(response);
                this._pagination.next(this.Pagination(response, skip, top, currentPage));
                this._allergysLoading.next(false);
            }),
            switchMap((response) => {
                if (response.allergys === null) {
                    return throwError(
                        () => new Error("Requested page is not available!")
                    );
                }
                return of(response);
            })
        );
    }

    // map numbering page
    Pagination(res: any, skip: number, top: number, currentPage: number): any {
        let pagination: any;
        let lastpage = skip + top;
        if (lastpage > res.total) lastpage = res.total
        pagination = {
            totalResults: res.total,
            currentPage: skip + 1,
            lastPage: lastpage
        };
        return pagination
    }

    /**
     * Get allergy by id
     */
    getAllergyById(id: string): Observable<any> {
        // Execute the loading with true
        this._allergysLoading.next(true);

        return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/AllergyIntolerance?_id=" + id + "&_include=AllergyIntolerance:asserter&_include=AllergyIntolerance:encounter&_include=AllergyIntolerance:patient&_include=AllergyIntolerance:recorder&_include:iterate=PractitionerRole:practitioner")
            .pipe(
                take(1),
                map((allergy) => {
                    // Update the allergy
                    this._allergy.next(allergy);
                    this._allergysLoading.next(false);
                    // Return the allergy
                    return allergy;
                }),
                switchMap((allergy) => {
                    if (!allergy) {
                        return throwError(
                            () =>
                                new Error("Could not found immunization with id of ' + id + '!")
                        );
                    }
                    return of(allergy);
                })
            );
    }

    /**
     * Reset the current allergy
     */
    resetAllergy(): Observable<boolean> {
        return of(true).pipe(
            take(1),
            tap(() => {
                this._allergy.next(null);
            })
        );
    }

    createAllergy(data: any) {
        return this._httpClient.post(
          this._fhirConfigService.getFhirService() + "/AllergyIntolerance",
          data
        )
      }

      updateAllergy(id: string, data: any) {
        return this._httpClient.put(
          this._fhirConfigService.getFhirService() + "/AllergyIntolerance/" + id,
          data
        )
      }

      deleteById(id: string) {
        return this._httpClient.delete(
          this._fhirConfigService.getFhirService() + "/AllergyIntolerance/" + id
        );
      }


}
