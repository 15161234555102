import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { CadFullscreenModule } from "@cad/components/fullscreen";
import { CadLoadingBarModule } from "@cad/components/loading-bar";
import { CadNavigationModule } from "@cad/components/navigation";
import { LanguagesModule } from "app/layout/common/languages/languages.module";
import { MessagesModule } from "app/layout/common/messages/messages.module";
import { NotificationsModule } from "app/layout/common/notifications/notifications.module";
import { QuickChatModule } from "app/layout/common/quick-chat/quick-chat.module";
import { SearchModule } from "app/layout/common/search/search.module";
import { ShortcutsModule } from "app/layout/common/shortcuts/shortcuts.module";
import { UserModule } from "app/layout/common/user/user.module";
import { SharedModule } from "app/shared/shared.module";
import { ModernLayoutComponent } from "app/layout/layouts/horizontal/modern/modern.component";

import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  IgxButtonModule,
  IgxDialogModule,
  IgxRippleModule,
  IgxComboModule,
} from "@infragistics/igniteui-angular";

@NgModule({
  declarations: [ModernLayoutComponent],
  imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    CadFullscreenModule,
    CadLoadingBarModule,
    CadNavigationModule,
    LanguagesModule,
    MessagesModule,
    NotificationsModule,
    QuickChatModule,
    SearchModule,
    ShortcutsModule,
    UserModule,
    SharedModule,
    MatTooltipModule,
    IgxButtonModule,
    IgxDialogModule,
    IgxRippleModule,
    IgxComboModule,
  ],
  exports: [ModernLayoutComponent],
})
export class ModernLayoutModule {}
