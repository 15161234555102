<button mat-icon-button matTooltip="User Profile" [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img class="w-7 h-7 rounded-full" *ngIf="showAvatar" [src]="user.avatar">
    <mat-icon *ngIf="!showAvatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span *ngIf="user" class="mt-2 font-medium">{{ user.name }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="goToAccount()">
    <mat-icon [svgIcon]="'heroicons_outline:shield-check'"></mat-icon>
    <span>KKMOneLogin Console</span>
  </button>
  <button mat-menu-item (click)="goToProfile()">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>User Profile</span>
  </button>
  <!-- <button *ngIf="!fhirEnable" mat-menu-item (click)="switchTenant()">
    <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
    <span>Switch Tenant</span>
  </button> -->
  <!-- <button mat-menu-item (click)="defaultLocation()">
    <mat-icon [svgIcon]="'heroicons_outline:location-marker'"></mat-icon>
    <span>Default Location</span>
  </button> -->
  <!-- <button *ngIf="fhirEnable" mat-menu-item (click)="switchTenantAndDefaultLocation()">
    <mat-icon [svgIcon]="'heroicons_outline:location-marker'"></mat-icon>
    <span>Switch Tenant Location</span>
  </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>