import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { NcrConfigService } from 'app/ncrconfig.service';
import { FhirConfigService } from 'app/fhirconfig.service';
import { FhirPathService } from 'app/fhirpath.service';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class AuthService {

  restURL: string;
  graphqlURL: string;
  intervalAuth: any;

  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _fhirConfigService: FhirConfigService,
    private _fhirPathService: FhirPathService,
    private keycloakService: KeycloakService) {
    this.restURL = this._ncrConfigService.getNcrService() + "/api/v1";
    this.graphqlURL = this._ncrConfigService.getNcrService() + "/graphql/api/v1";
  }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance().idTokenParsed;
      return userDetails;
    } catch (e) {
      console.error("Exception", e);
      return undefined;
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public login(): void {
    this.keycloakService.login();
  }

  public logout(): void {
    this.keycloakService.logout(window.location.origin);
  }

  public accessToken(): string {
    return this.keycloakService.getKeycloakInstance().token;
  }

  public refreshToken(): string {
    return this.keycloakService.getKeycloakInstance().refreshToken;
  }

  public clearToken(): void {
    return this.keycloakService.clearToken();
  }

  public redirectToProfile(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  getNcrUserProfile(keycloakId): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("keycloakId", keycloakId);
    return this._httpClient.post(this.restURL + `/user/ncr-user-profile`, httpParams);
  }

  switchInst(keycloakId, instCode): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("keycloakId", keycloakId);
    httpParams = httpParams.set("instCode", instCode);
    return this._httpClient.post(this.restURL + `/user/ncr-user-switch-profile`, httpParams);
  }

  getPractitioner(icNo: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(
      this._fhirConfigService.getFhirService() + "/Practitioner?identifier=http://fhir.hie.moh.gov.my/sid/my-kad-no|" + icNo
    );
  }

  getPractitionerId(bundle: fhir.r4.Bundle) {
    let practitioner = this._fhirPathService.evaluate(
      bundle,
      "entry.resource.ofType(Practitioner)"
    );
    return this._fhirPathService.evaluateToString(practitioner[0], "id");
  }

  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post(this.restURL + "/user/create", user);
  }

  setInterval(interval) {
    this.intervalAuth = interval;
  }

  getInterval(): any {
    return this.intervalAuth;
  }
}