import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { Subject, takeUntil } from "rxjs";
import { CadMediaWatcherService } from "@cad/services/media-watcher";
import { AppConfig } from "app/core/config/app.config";
import { NavigationService } from "app/core/navigation/navigation.service";
import { AppConfigService } from "app/appconfig.service";
import { ComposeService } from "./composition/compose/compose.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
	selector: "patient",
	templateUrl: "./patient.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class PatientComponent implements OnInit, OnDestroy {
	@ViewChild("drawer") drawer: MatDrawer;

	config: AppConfig;
	drawerMode: "over" | "side" | "push" = "push";
	drawerOpened: boolean = true;
	compositionAllowed: Boolean = false;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	isOrderManagement: boolean = false;

	/**
	 * Constructor
	 */
	constructor(
		private _cadMediaWatcherService: CadMediaWatcherService,
		private _navigationService: NavigationService,
		private _appConfigService: AppConfigService,
		private _composeService: ComposeService,
		private router: Router
	) { }

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.checkUrl(window.location.pathname);

		// Subscribe to media changes
		this._cadMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Set the drawerMode and drawerOpened if the given breakpoint is active
				if (matchingAliases.includes("md")) {
					this.drawerMode = "side";
					this.drawerOpened = true;
				} else {
					this.drawerMode = "over";
					this.drawerOpened = false;
				}
			});

		// this._navigationService.patientSelected.subscribe((result) => {
		//   if (this._appConfigService.getPatientResourceId().indexOf("HIE") > -1) {
		//     this.compositionAllowed = false;
		//   }
		// });

		this._composeService.getCompositionPanel().subscribe((param: any) => {
			this.compositionAllowed = param;
		});

		// monitor current url changes
		this.router.events.subscribe((route: NavigationEnd) => {
			if (route.url) {
				this.checkUrl(route.url);
			}
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	checkUrl(url: string) {
		url.includes("/order-management")
			? (this.isOrderManagement = true)
			: (this.isOrderManagement = false);
	}
}
