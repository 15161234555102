import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, take } from "rxjs";
import { NcrConfigService } from "app/ncrconfig.service";

const headers = {
  // headers: {
  "content-type": "application/json",
  // }
};

@Injectable({
  providedIn: "root",
})
export class VcService {
  private _vcToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public vcToken$: Observable<string> = this._vcToken.asObservable();

  constructor(private _httpClient: HttpClient, private _ncrConfigService: NcrConfigService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getTokenTest(): any {
    return this._httpClient
      .get(this._ncrConfigService.getNcrService() + "/api/v1/jitsi/getTokenTest")
      .pipe(take(1));
  }

  // Generate token
  postStore(query: any): any {
    let param = {
      ...(query.name && { name: query.name }),
      ...(query.moderatorName && { moderatorName: query.moderatorName }),
      ...(query.moderatorEmail && { moderatorEmail: query.moderatorEmail }),
      ...(query.participantName && { participantName: query.participantName }),
      ...(query.participantEmail && { participantEmail: query.participantEmail }),
      ...(query.status && { status: query.status }),
    };

    return this._httpClient
      .post<any>(this._ncrConfigService.getNcrService() + "/api/v1/jitsi/postStore", param)
      .pipe(take(1));
  }

  getShow(id): any {
    return this._httpClient
      .get(this._ncrConfigService.getNcrService() + "/api/v1/jitsi/getShow/" + id)
      .pipe(take(1));
  }

  postUpdate(token, body): any {
    return this._httpClient
      .post(this._ncrConfigService.getNcrService() + "/api/v1/jitsi/postUpdate/" + token, body)
      .pipe(take(1));
  }
}
