/* eslint-disable */
import { CadNavigationItem } from "@cad/components/navigation";

export const defaultNavigation: CadNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboards",
    subtitle: "National dashboard",
    type: "group",
    icon: "heroicons_outline:home",
    children: [
      {
        id: "dashboards.project",
        title: "National Health Status",
        type: "basic",
        icon: "heroicons_outline:clipboard-check",
        link: "/dashboards/project",
      },
      {
        id: "dashboards.analytics",
        title: "National Blood Stock",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/dashboards/analytics",
      },
    ],
  },
  {
    id: "apps",
    title: "Applications",
    subtitle: "Application main menu",
    type: "group",
    icon: "heroicons_outline:home",
    children: [
      {
        id: "apps.help-center",
        title: "Patient Search",
        type: "basic",
        icon: "heroicons_outline:user-group",
        link: "/apps/help-center",
      },
      {
        id: "frontdesk",
        title: "Frontdesk",
        type: "collapsable",
        icon: "heroicons_outline:calendar",
        children: [
          {
            id: "apps.register",
            title: "Patient Registration",
            type: "basic",
            icon: "heroicons_outline:user-group",
            link: "/apps/help-center",
          },
        ],
      },
      {
        id: "apps.patient",
        title: "Medical Record",
        type: "basic",
        icon: "heroicons_outline:user-group",
        link: "/apps/patient",
      },
      {
        id: "apps.appointment",
        title: "Appointments",
        type: "collapsable",
        icon: "heroicons_outline:calendar",
        link: "/apps/appointment",
        children: [
          {
            id: "apps.appointment",
            title: "Scheduler",
            type: "basic",
            link: "/apps/appointment",
          },
        ],
      },
      {
        id: "apps.scheduling",
        title: "Scheduling",
        type: "collapsable",
        icon: "heroicons_outline:calendar",
        link: "/apps/scheduling",
        children: [
          {
            id: "apps.scheduling",
            title: "Scheduler",
            type: "basic",
            link: "/apps/scheduling",
          },
        ],
      },
      {
        id: "apps.providers",
        title: "Facility Directory",
        type: "basic",
        icon: "heroicons_outline:office-building",
        link: "/apps/providers",
      },
      {
        id: "apps.help-center",
        title: "Help Center",
        type: "collapsable",
        icon: "heroicons_outline:support",
        children: [
          {
            id: "apps.help-center.faqs",
            title: "FAQs",
            type: "basic",
            link: "/apps/help-center/faqs",
          },
          {
            id: "apps.help-center.guides",
            title: "Guides",
            type: "basic",
            link: "/apps/help-center/guides",
          },
          {
            id: "apps.help-center.support",
            title: "Support",
            type: "basic",
            link: "/apps/help-center/support",
          },
        ],
      },
    ],
  },
  {
    id: "settings",
    title: "Administration",
    subtitle: "Admin",
    type: "group",
    icon: "heroicons_outline:adjustments",
    children: [
      {
        id: "settings.organization",
        title: "Organization",
        type: "basic",
        icon: "heroicons_outline:office-building",
        link: "/settings/organization",
      },
      {
        id: "settings.medication",
        title: "Medication",
        type: "basic",
        icon: "heroicons_outline:office-building",
        link: "/settings/medication",
      },
      {
        id: "settings.practitioner",
        title: "Practitioner",
        type: "basic",
        icon: "heroicons_outline:office-building",
        link: "/settings/practitioner",
      },
      {
        id: "settings.location",
        title: "Practitioner",
        type: "basic",
        icon: "heroicons_outline:location-marker",
        link: "/settings/location",
      },
      {
        id: "settings.practitioner-role",
        title: "Practitioner Role",
        type: "basic",
        icon: "heroicons_outline:office-building",
        link: "/settings/practitioner-role",
      },
      {
        id: "settings.healthcare-service",
        title: "Healthcare Service",
        type: "basic",
        icon: "heroicons_outline:office-building",
        link: "/settings/healthcare-service",
      },
      // {
      //     id   : 'settings.medication',
      //     title: 'MedicationForm',
      //     type : 'collapsable',
      //     icon : 'heroicons_outline:medicationform',
      //     children: [
      //         {
      //             id   : 'settings.medication.medicationform',
      //             title: 'medicationform',
      //             type : 'basic',
      //             link : '/settings/medication/medicationform'
      //         }
      //     ]
      // }
    ],
  },
];
export const compactNavigation: CadNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboards",
    tooltip: "Dashboards",
    type: "aside",
    icon: "heroicons_outline:home",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "apps",
    title: "Apps",
    tooltip: "Apps",
    type: "aside",
    icon: "heroicons_outline:qrcode",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "settings",
    title: "Administration",
    tooltip: "Admin",
    type: "aside",
    icon: "heroicons_outline:adjustments",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const futuristicNavigation: CadNavigationItem[] = [
  {
    id: "dashboards",
    title: "DASHBOARDS",
    type: "group",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "apps",
    title: "APPS",
    type: "group",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "settings",
    title: "ADMINS",
    type: "group",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const horizontalNavigation: CadNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboards",
    type: "group",
    icon: "heroicons_outline:home",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "apps",
    title: "Apps",
    type: "group",
    icon: "heroicons_outline:qrcode",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "settings",
    title: "Administration",
    type: "group",
    icon: "heroicons_outline:adjustments",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
