/* eslint-disable */
export const notes = {
  category: [
    {
      system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-category-my-core",
      code: "LP72467-1",
      display: "Discharge Summary Note",
      type: [
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "18842-5",
          display: "Discharge Summary Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "18842-5",
              display: "Discharge Summary Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Discharge Diagnosis",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Discharge Plan",
                  input: "mandatory",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-category-my-core",
      code: "LP73428-2",
      display: "Summary Note",
      type: [
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "68608-9",
          display: "Summary Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "68608-9",
              display: "Summary Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Summary Diagnosis",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "mandatory",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
      code: "LP173418-7",
      display: "Note",
      type: [
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "34109-9",
          display: "Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "34109-9",
              display: "Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "optional",
                },
              ],
            },
          ],
        },
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "75476-2",
          display: "Physician Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "75476-2",
              display: "Physician Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "optional",
                },
              ],
            },
          ],
        },
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "34746-8",
          display: "Nurse Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "34746-8",
              display: "Nurse Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "optional",
                },
              ],
            },
          ],
        },
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "85443-0",
          display: "Hematology Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "85443-0",
              display: "Hematology Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "optional",
                },
              ],
            },
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "0001-0001",
              display: "Transfusion Adverse Reaction Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "optional",
                },
              ],
            },
          ],
        },
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "28618-7",
          display: "Dentistry Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "28618-7",
              display: "Dentistry Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "83835-9",
                  display: "Dentistry Alert",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "32885-6",
                  display: "Dentistry Chart",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
              ],
            },
          ],
        },
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "34821-9",
          display: "Pharmacology Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "34821-9",
              display: "Pharmacology Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "optional",
                },
              ],
            },
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "0002-0001",
              display: "MTAC (Medical therapy adherance clinic)",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "11450-4",
                  display: "Problem List",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "48765-2",
                  display: "Allergies And Intolerance",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "85893-6",
                  display: "Adverse Event",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10160-0",
                  display: "Medication",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29762-2",
                  display: "Social History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "10157-6",
                  display: "Family History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "11369-6",
                  display: "Immunization History",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "51848-0",
                  display: "Assessment",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Care Plan",
                  input: "optional",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-category-my-core",
      code: "LP74249-1",
      display: "Procedure Note",
      type: [
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "28570-0",
          display: "Procedure Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "28570-0",
              display: "Procedure Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "8716-3",
                  display: "Vital Sign",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "18776-5",
                  display: "Plan of Care",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "29554-3",
                  display: "Procedure Description",
                  input: "mandatory",
                },
                {
                  system: "http://loinc.org",
                  code: "59776-5",
                  display: "Procedure Findings",
                  input: "optional",
                },
                {
                  system: "http://loinc.org",
                  code: "55109-3",
                  display: "Complications",
                  input: "optional",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const addendum = {
  category: [
    {
      system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-category-my-core",
      code: "LP173418-7",
      display: "Note",
      type: [
        {
          system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
          code: "55107-7",
          display: "Addendum Note",
          event: [
            {
              system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-event-my-core",
              code: "55107-7",
              display: "Addendum Note",
              section: [
                {
                  system: "http://loinc.org",
                  code: "55752-0",
                  display: "Clinical Information",
                  input: "mandatory",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
