import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of, switchMap, take, tap, throwError } from "rxjs";
import { FhirConfigService } from "app/fhirconfig.service";
import {
    FilteringExpressionsTree,
    FilteringLogic,
    IForOfState,
} from "@infragistics/igniteui-angular";
import { formatDate } from "@angular/common";
import { AppConfigService } from "app/appconfig.service";

const EMPTY_STRING = "";
const NULL_VALUE = null;
export enum FILTER_OPERATION {
    CONTAINS = "contains=",
    STARTS_WITH = "startswith",
    ENDS_WITH = "endswith",
    EQUALS = "=",
    DOES_NOT_EQUAL = "!=",
    DOES_NOT_CONTAIN = "not contains",
    GREATER_THAN = "gt",
    LESS_THAN = "lt",
    LESS_THAN_EQUAL = "le",
    GREATER_THAN_EQUAL = "ge",
}

const fhirHttpOptions = {
    headers: new HttpHeaders({
        "Cache-Control": "no-cache",
        Accept: "application/fhir+json",
    }),
};

@Injectable({
    providedIn: "root",
})

export class PatientFlagService {

    private _bundle: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
    public bundle$: Observable<fhir.r4.Bundle> = this._bundle.asObservable();

    private _patientFlagsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public patientFlag: BehaviorSubject<fhir.r4.Flag> = new BehaviorSubject(null);

    private virtualizationArgsCache: any;
    private filteringArgsCache: any;
    private sortingArgsCache: any;

    public patientId

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _fhirConfigService: FhirConfigService,
        private _appConfigService: AppConfigService) {
        this._bundle = new BehaviorSubject(null);
        this.bundle$ = this._bundle.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for flags loading
     */
    get patientFlagsLoading$(): Observable<boolean> {
        return this._patientFlagsLoading.asObservable();
    }

    /**
     * Getter for flag
     */
    get patientFlags$(): Observable<fhir.r4.Flag> {
        return this.patientFlag.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get flags
     */
    getFlags(
        skip: number,
        top: number,
        patientId: string,
        include: string
    ): Observable<any> {
        const query = {
            _count: top,
            _getpagesoffset: skip,
            _summary: "false",
            _total: "accurate",
            subject: patientId,
            _include: include,
            //   organization: this._appConfigService.getInstCode(),
        };

        // Execute the loading with true
        this._patientFlagsLoading.next(true);

        return this._httpClient
            .get<fhir.r4.Flag[]>(this._fhirConfigService.getFhirService() + "/Flag" + fhirHttpOptions, {
                params: query
            }
            )
            .pipe(
                tap((response: any) => {
                    this._bundle.next(response);
                    this._patientFlagsLoading.next(false);
                }),
                switchMap((response) => {
                    if (response.problems === null) {
                        return throwError(() => new Error("Requested page is not available!"));
                    }
                    return of(response);
                })
            );
    }

    // flag status = active
    getFlagsActive(): Observable<any> {
        return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Flag?subject:mdm=Patient/" + this._appConfigService.getPatientResourceId()
            + "&_include=Flag:author"
            + "&_include=Flag:encounter"
            + "&_include=Flag:patient"
            + "&_include=Flag:subject"
            + "&status=active"
            , fhirHttpOptions)
            .pipe(
                tap((response: any) => {
                    this._bundle.next(response);
                }),
            );
    }

    // flag status = inactive
    getFlagsInactive(): Observable<any> {
        return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Flag?subject:mdm=Patient/" + this._appConfigService.getPatientResourceId()
            + "&_include=Flag:author"
            + "&_include=Flag:encounter"
            + "&_include=Flag:patient"
            + "&_include=Flag:subject"
            + "&status=inactive"
            , fhirHttpOptions)
            .pipe(
                tap((response: any) => {
                    this._bundle.next(response);
                }),
            );
    }

    getAllFlags(): Observable<any> {
        return this._httpClient.get<fhir.r4.Bundle>(this._fhirConfigService.getFhirService() + "/Flag?subject:mdm=Patient/" + this._appConfigService.getPatientResourceId()
            + "&_include=Flag:author"
            + "&_include=Flag:encounter"
            + "&_include=Flag:patient"
            + "&_include=Flag:subject"
            , fhirHttpOptions)
            .pipe(
                tap((response: any) => {
                    this._bundle.next(response);
                }),
            );
    }

    /**
     * Get flag by Id
     */
    getFlagById(id: string): Observable<any> {
        return this._httpClient.get(
            this._fhirConfigService.getFhirService() + "/Flag/" + id
        );
    }

    /**
     * Create flag
     */
    createFlag(flag: any) {
        return this._httpClient.post(
            this._fhirConfigService.getFhirService() + "/Flag",
            flag
        );
    }

    /**
     * Update encounter by id
     */
    updateFlag(id: string, flag: any) {
        return this._httpClient.put(
            this._fhirConfigService.getFhirService() + "/Flag/" + id,
            flag, fhirHttpOptions
        );
    }

    /**
     * Reset the current encounter
     */
    resetFlag(): Observable<boolean> {
        return of(true).pipe(
            take(1),
            tap(() => {
                this.patientFlag.next(null);
            })
        );
    }

    public getData(
        virtualizationArgs?: IForOfState,
        filteringArgs?: any,
        sortingArgs?: any,
        cb?: (any) => void
    ): any {
        return this._httpClient
            .get(this.buildDataUrl(virtualizationArgs, filteringArgs, sortingArgs), fhirHttpOptions)
            .subscribe((data: fhir.r4.Bundle) => {
                this._bundle.next(data);
                if (cb) {
                    cb(data);
                }
            });
    }

    private buildDataUrl(virtualizationArgs: any, filteringArgs: any, sortingArgs: any): string {
        let baseQueryString = this._fhirConfigService.getFhirService() + "/Flag?subject:mdm=Patient/" + this._appConfigService.getPatientResourceId(); +" &_include=Flag:author&_include=Flag:encounter&_include=Flag:patient&_include=Flag:subject";
        let scrollingQuery = EMPTY_STRING;
        let orderQuery = EMPTY_STRING;
        let filterQuery = EMPTY_STRING;
        let query = EMPTY_STRING;
        let order = EMPTY_STRING;
        let filter = EMPTY_STRING;

        if (virtualizationArgs === null) {
            virtualizationArgs = this.virtualizationArgsCache;
        }
        this.virtualizationArgsCache = virtualizationArgs;

        if (filteringArgs === null) {
            filteringArgs = this.filteringArgsCache;
        }
        this.filteringArgsCache = filteringArgs;

        if (sortingArgs === null) {
            sortingArgs = this.sortingArgsCache;
        }
        this.sortingArgsCache = sortingArgs;

        if (sortingArgs && sortingArgs.length > 0) {
            sortingArgs.forEach((arg) => {
                if (order !== EMPTY_STRING) {
                    order += ",";
                }
                order += this._buildSortExpression(arg);
            });
            orderQuery = `_sort=${order}`;
        }

        if (filteringArgs && filteringArgs.length > 0) {
            filteringArgs.forEach((columnFilter) => {
                if (filter !== EMPTY_STRING) {
                    // filter += `${FilteringLogic[columnFilter.operator].toLowerCase()}`;
                    filter += `&`;
                }

                if (columnFilter.fieldName == "healthcare-service") {
                    // filter += "HealthcareService:identifier=" + columnFilter.filteringOperands[0].searchVal[0];
                    // } else if(columnFilter.fieldName == "location") {
                    // filter += "actor=Location/" + columnFilter.filteringOperands[0].searchVal[0];
                } else {
                    filter += this._buildAdvancedFilterExpression(
                        columnFilter.filteringOperands,
                        columnFilter.operator
                    );
                }
            });

            filterQuery = `${filter}`;
        }

        if (virtualizationArgs) {
            scrollingQuery = this._buildScrollExpression(virtualizationArgs);
        }

        query += orderQuery !== EMPTY_STRING ? `&${orderQuery}` : EMPTY_STRING;
        query += filterQuery !== EMPTY_STRING ? `&${filterQuery}` : EMPTY_STRING;
        // query += `&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Flag-my-core`;
        // query += `&_include=Encounter:patient`;
        // query += `&_source:=http://provider.hie.moh.gov.my`;
        // query += "&service-provider=Organization/" + this._appConfigService.getInstCode();
        query += scrollingQuery !== EMPTY_STRING ? `&${scrollingQuery}` : EMPTY_STRING;

        baseQueryString += query;
        return baseQueryString;
    }

    private _buildAdvancedFilterExpression(operands, operator): string {
        let filterExpression = EMPTY_STRING;
        operands.forEach((operand, index) => {
            if (operand instanceof FilteringExpressionsTree) {
                if (index > 0) {
                    filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
                }
                filterExpression += this._buildAdvancedFilterExpression(
                    operand.filteringOperands,
                    operand.operator
                );
                return filterExpression;
            }

            const value = operand.searchVal;
            let filterValue = value;
            let fieldNameCol = operand.fieldName;
            let fieldName = fieldNameCol;
            let filterString;

            if (filterExpression !== EMPTY_STRING) {
                filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
            }
            switch (operand.condition.name) {
                case "contains": {
                    // if (fieldName === "identifier") {
                    //     filterValue = "http://fhir.hie.moh.gov.my/sid/organization-id|" + filterValue;
                    // }
                    filterString = `${fieldName}:${FILTER_OPERATION.CONTAINS}${filterValue}`;
                    break;
                }
                case "startsWith": {
                    filterString = `${FILTER_OPERATION.STARTS_WITH}(${fieldName},${filterValue})`;
                    break;
                }
                case "endsWith": {
                    filterString = `${FILTER_OPERATION.ENDS_WITH}(${fieldName},${filterValue})`;
                    break;
                }
                case "equals": {
                    // if (fieldName === "state") {
                    //     fieldName = "address-state";
                    // } else if (fieldName === "type") {
                    //     fieldName = "cluster-facility";
                    // } else if (fieldName === "category") {
                    //     fieldName = "organization-category";
                    // }
                    filterString = `${fieldName}${FILTER_OPERATION.EQUALS}${filterValue}`;
                    break;
                }
                case "doesNotEqual": {
                    filterString = `${fieldName}${FILTER_OPERATION.DOES_NOT_EQUAL}${filterValue}`;
                    break;
                }
                case "doesNotContain": {
                    filterString = `${FILTER_OPERATION.DOES_NOT_CONTAIN}(${fieldName},${filterValue})`;
                    break;
                }
                case "greaterThan": {
                    filterString = `${fieldName}${FILTER_OPERATION.GREATER_THAN}${filterValue}`;
                    break;
                }
                case "in": {
                    let startDate;
                    let endDate;

                    if (fieldName === "date") {
                        // fieldName = "date";
                        startDate =
                            formatDate(filterValue.start, "yyyy-MM-dd", "en") + "T00:00:00+08:00";
                        endDate =
                            formatDate(filterValue.end, "yyyy-MM-dd", "en") + "T23:59:59+08:00";
                    }
                    filterString = `${fieldName}=ge${startDate}&${fieldName}=le${endDate}`;
                    break;
                }
                case "greaterThanOrEqualTo": {
                    filterString = `${fieldName}${FILTER_OPERATION.GREATER_THAN_EQUAL}${filterValue}`;
                    break;
                }
                case "lessThan": {
                    filterString = `${fieldName}${FILTER_OPERATION.LESS_THAN}${filterValue}`;
                    break;
                }
                case "lessThanOrEqualTo": {
                    filterString = `${fieldName}${FILTER_OPERATION.LESS_THAN_EQUAL}${filterValue}`;
                    break;
                }
                case "empty": {
                    filterString = `length(${fieldName})${FILTER_OPERATION.EQUALS}0`;
                    break;
                }
                case "notEmpty": {
                    filterString = `length(${fieldName})${FILTER_OPERATION.GREATER_THAN}0`;
                    break;
                }
                case "null": {
                    filterString = `${fieldName}${FILTER_OPERATION.EQUALS}${NULL_VALUE}`;
                    break;
                }
                case "notNull": {
                    filterString = `${fieldName}${FILTER_OPERATION.DOES_NOT_EQUAL}${NULL_VALUE}`;
                    break;
                }
                // case "after" : {
                //     filterString = `date${FILTER_OPERATION.EQUALS}${FILTER_OPERATION.GREATER_THAN_EQUAL}${filterValue}`;
                //     break;
                // }
                // case "before" : {
                //     filterString = `&date${FILTER_OPERATION.EQUALS}${FILTER_OPERATION.LESS_THAN_EQUAL}${filterValue}`;
                //     break;
                // }
                case "before": {
                    if (fieldName === "date") {
                        let startDate = formatDate(filterValue.start, "yyyy-MM-dd", "en");
                        filterString = `${fieldName}=${FILTER_OPERATION.LESS_THAN_EQUAL}${startDate}`;
                    } else {
                        filterString = `${fieldName}=${FILTER_OPERATION.LESS_THAN_EQUAL}${filterValue}`;
                    }
                    break;
                }
            }
            filterExpression += filterString;
        });

        return filterExpression;
    }

    private _buildSortExpression(sortingArgs): string {
        let sortingDirection: string;
        switch (sortingArgs.dir) {
            case 1: {
                sortingDirection = EMPTY_STRING;
                break;
            }
            case 2: {
                sortingDirection = "-";
                break;
            }
            default: {
                sortingDirection = EMPTY_STRING;
                break;
            }
        }

        let fieldName = sortingArgs.fieldName;
        if (fieldName === "lastUpdated") {
            fieldName = "_lastUpdated";
        } else if (fieldName === "appointmentStart") {
            fieldName = "date";
        }

        return `${sortingDirection}${fieldName}`;
    }

    private _buildScrollExpression(virtualizationArgs): string {
        let requiredChunkSize: number;
        const skip = virtualizationArgs.startIndex;
        requiredChunkSize = virtualizationArgs.chunkSize === 0 ? 11 : virtualizationArgs.chunkSize;
        const top = requiredChunkSize;

        return `_getpagesoffset=${skip}&_count=${top}&_total=accurate`;
    }



}