import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "app/appconfig.service";
import { FhirConfigService } from "app/fhirconfig.service";
import { NcrConfigService } from "app/ncrconfig.service";
import { BehaviorSubject, Observable, take } from "rxjs";
import { map } from "rxjs/operators";
import { ReferenceCode } from "./ReferenceCode";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};
const fhirHttpOptions = {
  headers: new HttpHeaders({
    "Cache-Control": "no-cache",
    Accept: "application/fhir+json",
    Authorization: "a262d915-15bc-417c-afeb-842c63b54154",
  }),
};
@Injectable({
  providedIn: "root",
})
export class StoreService {
  private _states: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _states2: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _districts: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _districts2: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _personTitles: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _ethnics: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _educationLevels: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _nationality: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _postcodes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _serviceCategory: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _encounterClass: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _serviceType: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _serviceTypeList: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _appointmentType: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _flagCategory: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _imagingCode: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _imagingModality: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _imagingRegion: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _specialty: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _specialtyMyCore: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _serviceProvisionCode: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _program: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _refferalMethod: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _locationTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _genders: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _religions: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _organizationTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _clusterFacilities: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _organizationCategories: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _countries: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _encounterStatus: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _encounterClassification: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _encounterType: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _priorities: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _bundleOrganization: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _organizations: BehaviorSubject<fhir.r4.Organization> = new BehaviorSubject(null);
  private _allorganizations: BehaviorSubject<fhir.r4.Organization> = new BehaviorSubject(null);
  private _organizationPKDs: BehaviorSubject<fhir.r4.Organization> = new BehaviorSubject(null);
  private _reasonCodes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _appointmentStatus: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _admitSources: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _locationStatuses: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _locationPhysicalTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _locations: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _locationsByInst: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _serviceProviders: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _drugCategories: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugFormularies: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _tableName: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugSchedules: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _activeIngredients: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugTypes: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _itemSubgroups: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _dosageForms: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugLocations: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _strengthUoms: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _bundlePractitioner: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _practitioners: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _healthcareServices: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _healthcareServicesByInst: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(
    null
  );
  private _healthcareServicesByRcvInst: BehaviorSubject<fhir.r4.BundleEntry[]> =
    new BehaviorSubject(null);
  private _skuUoms: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _skuSizeUoms: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _instructions: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _specialInstructions: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _cautionaries: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _recommendDoseUomes: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _recommendFrequencies: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _careAdmins: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _module: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _noteCategories: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _noteTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _noteEvents: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _typeOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _parentInstOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _departmentOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _countryOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _stateOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _districtOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _menusetOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _designationOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _accountStatusOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _authorizationInternalTypeOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _authorizationLevelOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _branchOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _mobileAccessOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _bcpAccessOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _remarkOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _supervisorOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _accessRightOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _institutionOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _statesOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _relationships: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _relationshiptypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _currencyOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _referenceGroup: BehaviorSubject<any> = new BehaviorSubject(null);
  private _moduleId: BehaviorSubject<any> = new BehaviorSubject(null);
  private _regionOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _conditonCode: BehaviorSubject<any> = new BehaviorSubject(null);
  private _designationGradeOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _menuSetTypeOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _functionOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _objectTypeOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _affiliations: BehaviorSubject<any> = new BehaviorSubject(null);
  private _parents: BehaviorSubject<any> = new BehaviorSubject(null);
  private _practitionerRoles: BehaviorSubject<any> = new BehaviorSubject(null);
  private _eligibilityOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _communication: BehaviorSubject<any> = new BehaviorSubject(null);
  private _mode: BehaviorSubject<any> = new BehaviorSubject(null);
  private _templateType: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _locationRole: BehaviorSubject<any> = new BehaviorSubject(null);
  private _statusLocation: BehaviorSubject<any> = new BehaviorSubject(null);
  private _operationStatus: BehaviorSubject<any> = new BehaviorSubject(null);
  private _userInstitution: BehaviorSubject<any> = new BehaviorSubject(null);
  private _facilityTypeOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _facilityCategoryOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _facilityLocationOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _facilityProgramOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _pkdCodeOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _leadFacilityOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _divisionOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _subdistrictOption: BehaviorSubject<any> = new BehaviorSubject(null);
  private _roles: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _services: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _specialities: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _bundleHealthcare: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _childIdentificationTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _schedulerTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _serviceRequestCategories: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _laboratoryTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _primaryCareTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _secondaryCareTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _tertiaryCareTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _laboratoryCodes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _primaryCareCodes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _secondaryCareCodes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _tertiaryCareCodes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _adverseEventCategory: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _adverseEventEvent: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _adverseEventOutcome: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _ticketTypeOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _ticketStatusOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _ticketStatusByReferenceCodeOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(
    null
  );
  private _supportTypeOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _serviceTypeOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _severityLevelOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _diagnosisRole: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _serviceCategoryOptions: BehaviorSubject<any> = new BehaviorSubject(null);
  private _attenderOptions: BehaviorSubject<any> = new BehaviorSubject(null);
  private _specialArrangements: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _visitTypes: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _compositionAttender: BehaviorSubject<fhir.r4.BundleEntry[]> = new BehaviorSubject(null);
  private _ticketModeOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _allergyActiveIngredientOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(
    null
  );
  private _allergyCategoryOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _allergyFormularyOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _allergyStatusOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _allergyReactionExposureRouteOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _allergyReactionSubstanceCodedOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _allergyReactionSeverityOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(
    null
  );
  private _allergyReactionManifestationOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _allergyActiveIngredientMedicationOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _allergyActiveIngredientFoodOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _allergyActiveIngredientEnvironmentOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _allergyActiveIngredientBiologyOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _allergyAsserterOption: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
  private _socialHistoryCategoryOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(
    null
  );
  private _familyRelationshipOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _familyGenderOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _drugCategory: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _drugType: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _drugSubGroup: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _activeIngredientMedicationOption: BehaviorSubject<fhir.r4.ValueSet> =
    new BehaviorSubject(null);
  private _drugDoseFormOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugScheduleOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugNumeratorUnitOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugSpecialInstructionOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugCautionaryOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugFrequencyOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);
  private _drugRouteOption: BehaviorSubject<ReferenceCode> = new BehaviorSubject(null);

  private _procedureCategoryOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _procedureNameOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _procedureOutcomeOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _procedureStatusOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _procedurePerformerRoleOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(
    null
  );

  private _timingAbbreviation: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _durationUnits: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _requestPriority: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _drugSpecialInstruction: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _drugCautionary: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _drugForm: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _requestStatusOption: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);

  private _labTest: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _interpretations: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);

  private _dentition: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _dentitionCode: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _surface: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _surfaceCode: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);

  private _modalitys: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _regions: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);
  private _orderables: BehaviorSubject<fhir.r4.ValueSet> = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _ncrConfigService: NcrConfigService,
    private _appConfigService: AppConfigService
  ) {}

  get states$(): Observable<fhir.r4.ValueSet> {
    return this._states.asObservable();
  }

  get states2$(): Observable<fhir.r4.ValueSet> {
    return this._states2.asObservable();
  }

  get locationTypes$(): Observable<fhir.r4.ValueSet> {
    return this._locationTypes.asObservable();
  }

  get serviceCategory$(): Observable<fhir.r4.ValueSet> {
    return this._serviceCategory.asObservable();
  }

  get encounterClass$(): Observable<fhir.r4.ValueSet> {
    return this._encounterClass.asObservable();
  }

  get serviceType$(): Observable<fhir.r4.ValueSet> {
    return this._serviceType.asObservable();
  }

  get serviceTypeList$(): Observable<fhir.r4.ValueSet> {
    return this._serviceTypeList.asObservable();
  }

  get specialty$(): Observable<fhir.r4.ValueSet> {
    return this._specialty.asObservable();
  }

  get specialtyMyCore$(): Observable<fhir.r4.ValueSet> {
    return this._specialtyMyCore.asObservable();
  }

  get serviceProvisionCode$(): Observable<fhir.r4.ValueSet> {
    return this._serviceProvisionCode.asObservable();
  }

  get program$(): Observable<fhir.r4.ValueSet> {
    return this._program.asObservable();
  }

  get refferalMethod$(): Observable<fhir.r4.ValueSet> {
    return this._refferalMethod.asObservable();
  }

  get appointmentType$(): Observable<fhir.r4.ValueSet> {
    return this._appointmentType.asObservable();
  }

  get flagCategory$(): Observable<fhir.r4.ValueSet> {
    return this._flagCategory.asObservable();
  }

  get imagingCode$(): Observable<fhir.r4.ValueSet> {
    return this._imagingCode.asObservable();
  }

  get imagingModality$(): Observable<fhir.r4.ValueSet> {
    return this._imagingModality.asObservable();
  }

  get imagingRegion$(): Observable<fhir.r4.ValueSet> {
    return this._imagingRegion.asObservable();
  }

  get districts$(): Observable<fhir.r4.ValueSet> {
    return this._districts.asObservable();
  }

  get districts2$(): Observable<fhir.r4.ValueSet> {
    return this._districts2.asObservable();
  }

  get personTitles$(): Observable<fhir.r4.ValueSet> {
    return this._personTitles.asObservable();
  }

  get ethnics$(): Observable<fhir.r4.ValueSet> {
    return this._ethnics.asObservable();
  }

  get educationLevels$(): Observable<fhir.r4.ValueSet> {
    return this._educationLevels.asObservable();
  }

  get nationality$(): Observable<fhir.r4.ValueSet> {
    return this._nationality.asObservable();
  }

  get postcodes$(): Observable<fhir.r4.ValueSet> {
    return this._postcodes.asObservable();
  }

  get genders$(): Observable<fhir.r4.ValueSet> {
    return this._genders.asObservable();
  }

  get religions$(): Observable<fhir.r4.ValueSet> {
    return this._religions.asObservable();
  }

  get organizationTypes$(): Observable<fhir.r4.ValueSet> {
    return this._organizationTypes.asObservable();
  }

  get clusterFacilities$(): Observable<fhir.r4.ValueSet> {
    return this._clusterFacilities.asObservable();
  }

  get organizationCategories$(): Observable<fhir.r4.ValueSet> {
    return this._organizationCategories.asObservable();
  }

  get affiliationCategories$(): Observable<fhir.r4.ValueSet> {
    return this._organizationCategories.asObservable();
  }

  get parentCategories$(): Observable<fhir.r4.ValueSet> {
    return this._organizationCategories.asObservable();
  }

  get countries$(): Observable<fhir.r4.ValueSet> {
    return this._countries.asObservable();
  }

  get encounterStatus$(): Observable<fhir.r4.ValueSet> {
    return this._encounterStatus.asObservable();
  }

  get encounterClassification$(): Observable<fhir.r4.ValueSet> {
    return this._encounterClassification.asObservable();
  }

  get encounterType$(): Observable<fhir.r4.ValueSet> {
    return this._encounterType.asObservable();
  }

  get priorities$(): Observable<fhir.r4.ValueSet> {
    return this._priorities.asObservable();
  }

  get bundleOrganization$(): Observable<fhir.r4.BundleEntry[]> {
    return this._bundleOrganization.asObservable();
  }

  get organizations$(): Observable<fhir.r4.Organization> {
    return this._organizations.asObservable();
  }

  get allorganizations$(): Observable<fhir.r4.Organization> {
    return this._allorganizations.asObservable();
  }

  get organizationPKDs$(): Observable<fhir.r4.Organization> {
    return this._organizationPKDs.asObservable();
  }

  get bundlePractitioner$(): Observable<fhir.r4.BundleEntry[]> {
    return this._bundlePractitioner.asObservable();
  }

  get practitioners$(): Observable<fhir.r4.BundleEntry[]> {
    return this._practitioners.asObservable();
  }

  get reasonCodes$(): Observable<fhir.r4.ValueSet> {
    return this._reasonCodes.asObservable();
  }

  get appointmentStatus$(): Observable<fhir.r4.ValueSet> {
    return this._appointmentStatus.asObservable();
  }

  get admitSources$(): Observable<fhir.r4.ValueSet> {
    return this._admitSources.asObservable();
  }

  get locationStatuses$(): Observable<fhir.r4.ValueSet> {
    return this._locationStatuses.asObservable();
  }

  get locationPhysicalTypes$(): Observable<fhir.r4.ValueSet> {
    return this._locationPhysicalTypes.asObservable();
  }

  get locations$(): Observable<fhir.r4.BundleEntry[]> {
    return this._locations.asObservable();
  }

  get locationsByInst$(): Observable<fhir.r4.BundleEntry[]> {
    return this._locationsByInst.asObservable();
  }

  get serviceProviders$(): Observable<fhir.r4.BundleEntry[]> {
    return this._serviceProviders.asObservable();
  }

  get healthcareServices$(): Observable<fhir.r4.BundleEntry[]> {
    return this._healthcareServices.asObservable();
  }

  get healthcareServicesByInst$(): Observable<fhir.r4.BundleEntry[]> {
    return this._healthcareServicesByInst.asObservable();
  }

  get healthcareServicesByRcvInst$(): Observable<fhir.r4.BundleEntry[]> {
    return this._healthcareServicesByRcvInst.asObservable();
  }

  get drugCategories$(): Observable<any> {
    return this._drugCategories.asObservable();
  }

  get drugFormularies$(): Observable<any> {
    return this._drugFormularies.asObservable();
  }

  get drugSchedules$(): Observable<any> {
    return this._drugSchedules.asObservable();
  }

  get activeIngredients$(): Observable<any> {
    return this._activeIngredients.asObservable();
  }

  get drugTypes$(): Observable<any> {
    return this._drugTypes.asObservable();
  }

  get itemSubgroups$(): Observable<any> {
    return this._itemSubgroups.asObservable();
  }

  get dosageForms$(): Observable<any> {
    return this._dosageForms.asObservable();
  }

  get drugLocations$(): Observable<any> {
    return this._drugLocations.asObservable();
  }

  get strengthUoms$(): Observable<any> {
    return this._strengthUoms.asObservable();
  }

  get skuUoms$(): Observable<any> {
    return this._skuUoms.asObservable();
  }

  get skuSizeUoms$(): Observable<any> {
    return this._skuSizeUoms.asObservable();
  }

  get instructions$(): Observable<any> {
    return this._instructions.asObservable();
  }

  get specialInstructions$(): Observable<any> {
    return this._specialInstructions.asObservable();
  }

  get cautionaries$(): Observable<any> {
    return this._cautionaries.asObservable();
  }

  get recommendDoseUomes$(): Observable<any> {
    return this._recommendDoseUomes.asObservable();
  }

  get recommendFrequencies$(): Observable<any> {
    return this._recommendFrequencies.asObservable();
  }

  get careAdmins$(): Observable<any> {
    return this._careAdmins.asObservable();
  }

  get tableName$(): Observable<any> {
    return this._tableName.asObservable();
  }

  get module$(): Observable<any> {
    return this._module.asObservable();
  }

  get noteCategories$(): Observable<any> {
    return this._noteCategories.asObservable();
  }

  get noteTypes$(): Observable<any> {
    return this._noteTypes.asObservable();
  }

  get noteEvents$(): Observable<any> {
    return this._noteEvents.asObservable();
  }

  get typeOption$(): Observable<any> {
    return this._typeOption.asObservable();
  }

  get parentInstOption$(): Observable<any> {
    return this._parentInstOption.asObservable();
  }

  get countryOption$(): Observable<any> {
    return this._countryOption.asObservable();
  }

  get stateOption$(): Observable<any> {
    return this._stateOption.asObservable();
  }

  get districtOption$(): Observable<any> {
    return this._districtOption.asObservable();
  }

  get departmentOption$(): Observable<any> {
    return this._departmentOption.asObservable();
  }

  get menusetOption$(): Observable<any> {
    return this._menusetOption.asObservable();
  }

  get accessRightOption$(): Observable<any> {
    return this._accessRightOption.asObservable();
  }

  get branchOption$(): Observable<any> {
    return this._branchOption.asObservable();
  }

  get authorizationLevelOption$(): Observable<any> {
    return this._authorizationLevelOption.asObservable();
  }

  get authorizationInternalTypeOption$(): Observable<any> {
    return this._authorizationInternalTypeOption.asObservable();
  }

  get accountStatusOption$(): Observable<any> {
    return this._accountStatusOption.asObservable();
  }

  get designationOption$(): Observable<any> {
    return this._designationOption.asObservable();
  }

  get supervisorOption$(): Observable<any> {
    return this._supervisorOption.asObservable();
  }

  get remarkOption$(): Observable<any> {
    return this._remarkOption.asObservable();
  }

  get mobileAccessOption$(): Observable<any> {
    return this._mobileAccessOption.asObservable();
  }

  get bcpAccessOption$(): Observable<any> {
    return this._bcpAccessOption.asObservable();
  }

  get institutionOption$(): Observable<any> {
    return this._institutionOption.asObservable();
  }

  get statesOption$(): Observable<any> {
    return this._statesOption.asObservable();
  }

  get relationships$(): Observable<fhir.r4.ValueSet> {
    return this._relationships.asObservable();
  }

  get relationshiptypes$(): Observable<fhir.r4.ValueSet> {
    return this._relationshiptypes.asObservable();
  }

  get currencyOption$(): Observable<any> {
    return this._currencyOption.asObservable();
  }

  get referenceGroup$(): Observable<any> {
    return this._referenceGroup.asObservable();
  }

  get moduleId$(): Observable<any> {
    return this._moduleId.asObservable();
  }

  get regionOption$(): Observable<any> {
    return this._regionOption.asObservable();
  }

  get conditionCode$(): Observable<any> {
    return this._conditonCode.asObservable();
  }

  get designationGradeOption$(): Observable<any> {
    return this._designationGradeOption.asObservable();
  }

  get menuSetTypeOption$(): Observable<any> {
    return this._menuSetTypeOption.asObservable();
  }

  get functionOption$(): Observable<any> {
    return this._functionOption.asObservable();
  }

  get objectTypeOption$(): Observable<any> {
    return this._objectTypeOption.asObservable();
  }

  get practitionerRoles$(): Observable<any> {
    return this._practitionerRoles.asObservable();
  }

  get eligibilityOption$(): Observable<any> {
    return this._eligibilityOption.asObservable();
  }

  get mode$(): Observable<fhir.r4.ValueSet> {
    return this._mode.asObservable();
  }

  get locationRole$(): Observable<fhir.r4.ValueSet> {
    return this._locationRole.asObservable();
  }

  get templateType$(): Observable<any> {
    return this._templateType.asObservable();
  }

  get statusLocation$(): Observable<fhir.r4.ValueSet> {
    return this._statusLocation.asObservable();
  }

  get operationStatus$(): Observable<fhir.r4.ValueSet> {
    return this._operationStatus.asObservable();
  }

  get userInstitution$(): Observable<fhir.r4.ValueSet> {
    return this._userInstitution.asObservable();
  }

  get communication$(): Observable<fhir.r4.ValueSet> {
    return this._communication.asObservable();
  }

  get facilityTypeOption$(): Observable<any> {
    return this._facilityTypeOption.asObservable();
  }

  get facilityCategoryOption$(): Observable<any> {
    return this._facilityCategoryOption.asObservable();
  }

  get facilityLocationOption$(): Observable<any> {
    return this._facilityLocationOption.asObservable();
  }

  get facilityProgramOption$(): Observable<any> {
    return this._facilityProgramOption.asObservable();
  }

  get pkdCodeOption$(): Observable<any> {
    return this._pkdCodeOption.asObservable();
  }

  get roles$(): Observable<fhir.r4.ValueSet> {
    return this._roles.asObservable();
  }

  get services$(): Observable<fhir.r4.ValueSet> {
    return this._services.asObservable();
  }

  get specialities$(): Observable<fhir.r4.ValueSet> {
    return this._specialities.asObservable();
  }

  get childIdentificationTypes$(): Observable<fhir.r4.ValueSet> {
    return this._childIdentificationTypes.asObservable();
  }

  get scheduleTypes$(): Observable<fhir.r4.ValueSet> {
    return this._schedulerTypes.asObservable();
  }

  get serviceRequestCategories$(): Observable<fhir.r4.ValueSet> {
    return this._serviceRequestCategories.asObservable();
  }

  get laboratoryTypes$(): Observable<fhir.r4.ValueSet> {
    return this._laboratoryTypes.asObservable();
  }

  get primaryCareTypes$(): Observable<fhir.r4.ValueSet> {
    return this._primaryCareTypes.asObservable();
  }

  get secondaryCareTypes$(): Observable<fhir.r4.ValueSet> {
    return this._secondaryCareTypes.asObservable();
  }

  get tertiaryCareTypes$(): Observable<fhir.r4.ValueSet> {
    return this._tertiaryCareTypes.asObservable();
  }

  get laboratoryCodes$(): Observable<fhir.r4.ValueSet> {
    return this._laboratoryCodes.asObservable();
  }

  get primaryCareCodes$(): Observable<fhir.r4.ValueSet> {
    return this._primaryCareCodes.asObservable();
  }

  get secondaryCareCodes$(): Observable<fhir.r4.ValueSet> {
    return this._secondaryCareCodes.asObservable();
  }

  get tertiaryCareCodes$(): Observable<fhir.r4.ValueSet> {
    return this._tertiaryCareCodes.asObservable();
  }

  get ticketTypeOption$(): Observable<any> {
    return this._ticketTypeOption.asObservable();
  }

  get ticketStatusOption$(): Observable<any> {
    return this._ticketStatusOption.asObservable();
  }

  get ticketStatusByReferenceCodeOption$(): Observable<any> {
    return this._ticketStatusByReferenceCodeOption.asObservable();
  }

  get ticketModeOption$(): Observable<any> {
    return this._ticketModeOption.asObservable();
  }

  get supportTypeOption$(): Observable<any> {
    return this._supportTypeOption.asObservable();
  }

  get serviceTypeOption$(): Observable<any> {
    return this._serviceTypeOption.asObservable();
  }

  get severityLevelOption$(): Observable<any> {
    return this._severityLevelOption.asObservable();
  }

  get adverseEventCategory$(): Observable<fhir.r4.ValueSet> {
    return this._adverseEventCategory.asObservable();
  }

  get adverseEventEvent$(): Observable<fhir.r4.ValueSet> {
    return this._adverseEventEvent.asObservable();
  }

  get adverseEventOutcome$(): Observable<fhir.r4.ValueSet> {
    return this._adverseEventOutcome.asObservable();
  }

  get diagnosisRole$(): Observable<fhir.r4.ValueSet> {
    return this._diagnosisRole.asObservable();
  }

  get serviceCategoryOptions$(): Observable<any> {
    return this._serviceCategoryOptions.asObservable();
  }
  get attenderOptions$(): Observable<any> {
    return this._attenderOptions.asObservable();
  }

  get specialArrangements$(): Observable<fhir.r4.ValueSet> {
    return this._specialArrangements.asObservable();
  }

  get visitTypes$(): Observable<fhir.r4.ValueSet> {
    return this._visitTypes.asObservable();
  }

  get compositionAttender$(): Observable<fhir.r4.BundleEntry[]> {
    return this._compositionAttender.asObservable();
  }

  get allergyActiveIngredientOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyActiveIngredientOption.asObservable();
  }
  get allergyCategoryOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyCategoryOption.asObservable();
  }
  get allergyFormularyOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyFormularyOption.asObservable();
  }
  get allergyStatusOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyStatusOption.asObservable();
  }
  get allergyReactionExposureRouteOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyReactionExposureRouteOption.asObservable();
  }
  get allergyReactionSubstanceCodedOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyReactionSubstanceCodedOption.asObservable();
  }
  get allergyReactionSeverityOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyReactionSeverityOption.asObservable();
  }
  get allergyReactionManifestationOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyReactionManifestationOption.asObservable();
  }
  get allergyActiveIngredientMedicationOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyActiveIngredientMedicationOption.asObservable();
  }
  get allergyActiveIngredientFoodOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyActiveIngredientFoodOption.asObservable();
  }
  get allergyActiveIngredientEnvironmentOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyActiveIngredientEnvironmentOption.asObservable();
  }
  get allergyActiveIngredientBiologyOption$(): Observable<fhir.r4.ValueSet> {
    return this._allergyActiveIngredientBiologyOption.asObservable();
  }
  get allergyAsserterOption$(): Observable<fhir.r4.Bundle> {
    return this._allergyAsserterOption.asObservable();
  }
  get socialHistoryCategoryOption$(): Observable<fhir.r4.ValueSet> {
    return this._socialHistoryCategoryOption.asObservable();
  }
  get familyRelationshipOption$(): Observable<fhir.r4.ValueSet> {
    return this._familyRelationshipOption.asObservable();
  }

  get familyGenderOption$(): Observable<fhir.r4.ValueSet> {
    return this._familyGenderOption.asObservable();
  }

  get drugCategory$(): Observable<fhir.r4.ValueSet> {
    return this._drugCategory.asObservable();
  }

  get drugType$(): Observable<fhir.r4.ValueSet> {
    return this._drugType.asObservable();
  }

  get drugSubGroup$(): Observable<fhir.r4.ValueSet> {
    return this._drugSubGroup.asObservable();
  }

  get activeIngredientMedicationOption$(): Observable<fhir.r4.ValueSet> {
    return this._activeIngredientMedicationOption.asObservable();
  }
  get drugDoseFormOption$(): Observable<any> {
    return this._drugDoseFormOption.asObservable();
  }
  get drugScheduleOption$(): Observable<any> {
    return this._drugScheduleOption.asObservable();
  }
  get drugNumeratorUnitOption$(): Observable<any> {
    return this._drugNumeratorUnitOption.asObservable();
  }
  get drugSpecialInstructionOption$(): Observable<any> {
    return this._drugSpecialInstructionOption.asObservable();
  }
  get drugCautionaryOption$(): Observable<any> {
    return this._drugCautionaryOption.asObservable();
  }
  get drugFrequencyOption$(): Observable<any> {
    return this._drugFrequencyOption.asObservable();
  }
  get drugRouteOption$(): Observable<any> {
    return this._drugRouteOption.asObservable();
  }

  get procedureCategoryOption$(): Observable<fhir.r4.ValueSet> {
    return this._procedureCategoryOption.asObservable();
  }
  get procedureNameOption$(): Observable<fhir.r4.ValueSet> {
    return this._procedureNameOption.asObservable();
  }
  get procedureOutcomeOption$(): Observable<fhir.r4.ValueSet> {
    return this._procedureOutcomeOption.asObservable();
  }
  get procedureStatusOption$(): Observable<fhir.r4.ValueSet> {
    return this._procedureStatusOption.asObservable();
  }
  get procedurePerformerRoleOption$(): Observable<fhir.r4.ValueSet> {
    return this._procedurePerformerRoleOption.asObservable();
  }

  get timingAbbreviation$(): Observable<fhir.r4.ValueSet> {
    return this._timingAbbreviation.asObservable();
  }

  get durationUnits$(): Observable<fhir.r4.ValueSet> {
    return this._durationUnits.asObservable();
  }

  get requestPriority$(): Observable<fhir.r4.ValueSet> {
    return this._requestPriority.asObservable();
  }

  get drugSpecialInstruction$(): Observable<fhir.r4.ValueSet> {
    return this._drugSpecialInstruction.asObservable();
  }

  get dentition$(): Observable<fhir.r4.ValueSet> {
    return this._dentition.asObservable();
  }

  get dentitionCode$(): Observable<fhir.r4.ValueSet> {
    return this._dentitionCode.asObservable();
  }

  get surface$(): Observable<fhir.r4.ValueSet> {
    return this._surface.asObservable();
  }

  get surfaceCode$(): Observable<fhir.r4.ValueSet> {
    return this._surfaceCode.asObservable();
  }

  get drugCautionary$(): Observable<fhir.r4.ValueSet> {
    return this._drugCautionary.asObservable();
  }

  get drugForm$(): Observable<fhir.r4.ValueSet> {
    return this._drugForm.asObservable();
  }

  get requestStatusOption$(): Observable<fhir.r4.ValueSet> {
    return this._requestStatusOption.asObservable();
  }

  get labTest$(): Observable<fhir.r4.ValueSet> {
    return this._labTest.asObservable();
  }

  get interpretations$(): Observable<fhir.r4.ValueSet> {
    return this._interpretations.asObservable();
  }

  get modalitys$(): Observable<fhir.r4.ValueSet> {
    return this._modalitys.asObservable();
  }

  get regions$(): Observable<fhir.r4.ValueSet> {
    return this._regions.asObservable();
  }

  get orderables$(): Observable<fhir.r4.ValueSet> {
    return this._orderables.asObservable();
  }

  getStates(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "state",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/state-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._states.next(response);
          return response;
        })
      );
  }

  getStates2(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "state",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/state-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._states2.next(response);
          return response;
        })
      );
  }

  getAllergyActiveIngredientOption(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "allergy-active-ingredient-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/ValueSet/allergy-active-ingredient-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._allergyActiveIngredientOption.next(response);
          return response;
        })
      );
  }

  getAllergyFormularyOption(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "state",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/ValueSet/allergy-code-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._allergyFormularyOption.next(response);
          return response;
        })
      );
  }

  getAllergyStatusOption(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "allergyintolerance-verification",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/allergyintolerance-verification",
          },
        ],
      },
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/allergyintolerance-verification"
      )
      .pipe(
        map((response: any) => {
          this._allergyStatusOption.next(response);
          return response;
        })
      );
  }

  getAllergyReactionExposureRouteOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/route-codes"
      )
      .pipe(
        map((response: any) => {
          this._allergyReactionExposureRouteOption.next(response);
          return response;
        })
      );
  }

  getAllergyCategoryOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/allergy-intolerance-category"
      )
      .pipe(
        map((response: any) => {
          this._allergyCategoryOption.next(response);
          return response;
        })
      );
  }

  getSocialHistoryCategoryOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/observation-social-history-my-core"
      )
      .pipe(
        map((response: any) => {
          this._socialHistoryCategoryOption.next(response);
          return response;
        })
      );
  }
  getAllergyReactionSubstanceCodedOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-drug-allergy-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._allergyReactionSubstanceCodedOption.next(response);
          return response;
        })
      );
  }
  getAllergyReactionSeverityOption(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/reaction-event-severity/$expand")
      .pipe(
        map((response: any) => {
          this._allergyReactionSeverityOption.next(response);
          return response;
        })
      );
  }
  getAllergyReactionManifestationOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/allergy-manifestation-my-core"
      )
      .pipe(
        map((response: any) => {
          this._allergyReactionManifestationOption.next(response);
          return response;
        })
      );
  }
  getAllergyActiveIngredientMedicationOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/allergy-active-ingredient-my-core"
      )
      .pipe(
        map((response: any) => {
          this._allergyActiveIngredientMedicationOption.next(response);
          return response;
        })
      );
  }
  getAllergyActiveIngredientFoodOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/allergy-food-my-core"
      )
      .pipe(
        map((response: any) => {
          this._allergyActiveIngredientFoodOption.next(response);
          return response;
        })
      );
  }
  getAllergyActiveIngredientEnvironmentOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/allergy-environment-my-core"
      )
      .pipe(
        map((response: any) => {
          this._allergyActiveIngredientEnvironmentOption.next(response);
          return response;
        })
      );
  }
  getAllergyActiveIngredientBiologyOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/allergy-biology-my-core"
      )
      .pipe(
        map((response: any) => {
          this._allergyActiveIngredientBiologyOption.next(response);
          return response;
        })
      );
  }

  getLocationTypes(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "LocationType",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/encoutner-class",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._locationTypes.next(response);
          return response;
        })
      );
  }

  getServiceCategory(): Observable<any> {
    // const query = {
    //   resourceType: "ValueSet",
    //   name: "service-request-category-my-core",
    //   status: "active",
    //   compose: {
    //     include: [
    //       {
    //         system: "http://fhir.hie.moh.gov.my/CodeSystem/service-request-category-my-core",
    //       },
    //     ],
    //   },
    // };

    // return this._httpClient
    //   .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
    //   .pipe(
    //     map((response: any) => {
    //       this._serviceCategory.next(response);
    //       return response;
    //     })
    //   );

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-service-request-category-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._healthcareServices.next(response);
          return response;
        })
      );
  }

  getEncounterClass(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/encounter-class-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._encounterClass.next(response);
          return response;
        })
      );
  }

  // getEncounterClassMyCore(): Observable<any> {
  //   const query = {
  //     resourceType: "ValueSet",
  //     status: "active",
  //     compose: {
  //       include: [
  //         {
  //           system: "http://fhir.hie.moh.gov.my/ValueSet/value-set-encounter-class-my-core",
  //         },
  //       ],
  //     },
  //   };

  //   return this._httpClient
  //     .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService(), query)
  //     .pipe(
  //       map((response: any) => {
  //         this._encounterClass.next(response);
  //         return response;
  //       })
  //     );
  // }

  getEncounterClassMyCore(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-encounter-class-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._encounterClass.next(response);
          return response;
        })
      );
  }

  getPersonTitles(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "personTitle",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/person-title-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._personTitles.next(response);
          return response;
        })
      );
  }

  getDistricts(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "district",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/district-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._districts.next(response);
          return response;
        })
      );
  }

  getDistricts2(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "district",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/district-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._districts2.next(response);
          return response;
        })
      );
  }

  getPostcodes(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "postcode",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/postcode-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._postcodes.next(response);
          return response;
        })
      );
  }

  getServiceType(): Observable<any> {
    // const query = {
    //   resourceType: "ValueSet",
    //   name: "value-set-service-request-type-my-core",
    //   status: "draft",
    //   compose: {
    //     include: [
    //       {
    //         system: "http://fhir.hie.moh.gov.my/CodeSystem/service-request-type-my-core",
    //       },
    //     ],
    //   },
    // };

    // return this._httpClient
    //   .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
    //   .pipe(
    //     map((response: any) => {
    //       this._serviceType.next(response);
    //       return response;
    //     })
    //   );
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-service-request-type-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._healthcareServices.next(response);
          return response;
        })
      );
  }

  getServiceTypeList(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-service-type-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._serviceTypeList.next(response);
          return response;
        })
      );
  }

  getAppointmentType(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "v2-0276",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v2-0276&_pretty=true",
          },
        ],
      },
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://terminology.hl7.org/ValueSet/v2-0276"
      )
      .pipe(
        map((response: any) => {
          this._appointmentType.next(response);
          return response;
        })
      );
  }

  getFlagCategory(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "flag-category",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/flag-category",
          },
        ],
      },
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/flag-category"
      )
      .pipe(
        map((response: any) => {
          this._flagCategory.next(response);
          return response;
        })
      );
  }

  getImagingCode(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/value-set-imaging-my-core/$expand")
      .pipe(
        map((response: any) => {
          this._imagingCode.next(response);
          return response;
        })
      );
  }

  getImagingModality(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-imaging-modality-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._imagingModality.next(response);
          return response;
        })
      );
  }

  getImagingRegion(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-imaging-region-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._imagingRegion.next(response);
          return response;
        })
      );
  }

  getOperationStatus(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "v2-0116",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v2-0116",
          },
        ],
      },
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://terminology.hl7.org/ValueSet/v2-0116"
      )
      .pipe(
        map((response: any) => {
          this._operationStatus.next(response);
          return response;
        })
      );
  }

  getSpecialty(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "specialty",
      status: "draft",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/specialty-my-core"
      )
      .pipe(
        map((response: any) => {
          this._specialty.next(response);
          return response;
        })
      );
  }

  getSpecialtyMyCore(group: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "specialty-my-core",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/specialty-my-core",
            filter: [
              {
                property: group,
                op: "=",
                value: "Y",
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._specialtyMyCore.next(response);
          return response;
        })
      );
  }

  getServiceProvisionCode(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ServiceProvisonCode",
      status: "draft",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/service-provision-conditions"
      )
      .pipe(
        map((response: any) => {
          this._serviceProvisionCode.next(response);
          return response;
        })
      );
  }

  getProgram(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "program",
      status: "draft",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/program-my-core"
      )
      .pipe(
        map((response: any) => {
          this._program.next(response);
          return response;
        })
      );
  }

  getRefferalMethod(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "refferalMethod",
      status: "draft",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/service-referral-method"
      )
      .pipe(
        map((response: any) => {
          this._refferalMethod.next(response);
          return response;
        })
      );
  }

  getCommunication(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "communication",
      status: "draft",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/languages"
      )
      .pipe(
        map((response: any) => {
          this._communication.next(response);
          return response;
        })
      );
  }

  getMode(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "mode",
      status: "active",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/location-mode"
      )
      .pipe(
        map((response: any) => {
          this._mode.next(response);
          return response;
        })
      );
  }

  getStatusLocation(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "status",
      status: "active",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/location-status"
      )
      .pipe(
        map((response: any) => {
          this._statusLocation.next(response);
          return response;
        })
      );
  }

  getLocationRole(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "locationRole",
      status: "active",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/encounter-class-my-core"
      )
      .pipe(
        map((response: any) => {
          this._locationRole.next(response);
          return response;
        })
      );
  }

  getGenders(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-administrative-gender-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._genders.next(response);
          return response;
        })
      );
  }

  getEthnics(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "value-set-ethnic-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/ethnic-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._ethnics.next(response);
          return response;
        })
      );
  }

  getReligions(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "value-set-religion-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/religion-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._religions.next(response);
          return response;
        })
      );
  }

  getOrganizationTypes(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "value-set-organization-type-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/organization-type-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._organizationTypes.next(response);
          return response;
        })
      );
  }

  getClusterFacilities(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "value-set-cluster-facility-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/cluster-facility-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._clusterFacilities.next(response);
          return response;
        })
      );
  }

  getOrganizationCategories(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "value-set-organization-category-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/organization-category-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._organizationCategories.next(response);
          return response;
        })
      );
  }

  getCountries(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "iso3166-1-3",
      meta: {
        lastUpdated: "2019-11-01T09:29:23.356+11:00",
        profile: ["http://hl7.org/fhir/StructureDefinition/shareablevalueset"],
      },
      url: "http://hl7.org/fhir/ValueSet/iso3166-1-3",
      version: "4.0.1",
      name: "Iso3166-1-3",
      title: "Iso 3166 Part 1: 3 Letter Codes",
      status: "active",
      experimental: false,
      publisher: "FHIR Project team",
      contact: [
        {
          telecom: [
            {
              system: "url",
              value: "http://hl7.org/fhir",
            },
          ],
        },
      ],
      description: "This value set defines the ISO 3166 Part 1 3-letter codes",
      compose: {
        include: [
          {
            system: "urn:iso:std:iso:3166",
            filter: [
              {
                property: "code",
                op: "regex",
                value: "[A-Z]{3}",
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._countries.next(response);
          return response;
        })
      );
  }

  getDistrictsByStateCode(stateCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetDistrictMyCore",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/district-my-core",
            filter: [
              {
                property: "stateCode",
                op: "=",
                value: stateCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._districts.next(response);
          return response;
        })
      );
  }

  getDistrictsByStateCode2(stateCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetDistrictMyCore",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/district-my-core",
            filter: [
              {
                property: "stateCode",
                op: "=",
                value: stateCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._districts2.next(response);
          return response;
        })
      );
  }

  getServiceTypeByClassCode(classCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetServiceTypeMyCore",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/service-type-my-core",
            filter: [
              {
                property: "classCode",
                op: "=",
                value: classCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._serviceType.next(response);
          return response;
        })
      );
  }

  getEncounterStatus(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/encounter-status-my-core"
      )
      .pipe(
        map((response: any) => {
          this._encounterStatus.next(response);
          return response;
        })
      );
  }

  getEncounterClassification(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/encounter-status"
      )
      .pipe(
        map((response: any) => {
          this._encounterClassification.next(response);
          return response;
        })
      );
  }

  getEncounterType(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/encounter-type"
      )
      .pipe(
        map((response: any) => {
          this._encounterType.next(response);
          return response;
        })
      );
  }

  getPriorities(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/encounter-priority-my-core"
      )
      .pipe(
        map((response: any) => {
          this._priorities.next(response);
          return response;
        })
      );
  }

  getPractitionerRoles(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/practitioner-role-code-my-core"
      )
      .pipe(
        map((response: any) => {
          this._practitionerRoles.next(response);
          return response;
        })
      );
  }

  getBundleOrganization(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/Organization?active=true")
      .pipe(
        map((response: any) => {
          this._bundleOrganization.next(response);
          return response;
        })
      );
  }

  getOrganizations(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Organization?_elements=name&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core&_count=1000"
      )
      .pipe(
        map((response: any) => {
          this._organizations.next(response);
          return response;
        })
      );
  }

  getAllOrganizations(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Organization?_elements=name&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core&_count=500"
      )
      .pipe(
        map((response: any) => {
          this._allorganizations.next(response);
          return response;
        })
      );
  }

  getOrganizationByPKDs(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Organization?organization-category=PKD&_elements=name&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core&_count=5000&_sort=name"
      )
      .pipe(
        map((response: any) => {
          this._organizationPKDs.next(response);
          return response;
        })
      );
  }

  getBundlePractitioner(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/Practitioner?active=true")
      .pipe(
        map((response: any) => {
          this._bundleOrganization.next(response);
          return response;
        })
      );
  }

  getPractitioners(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Practitioner?active=true&_count=5000&_sort=name"
      )
      .pipe(
        map((response: any) => {
          this._practitioners.next(response);
          return response;
        })
      );
  }

  getReasonCodes(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/reason-code-my-core"
      )
      .pipe(
        map((response: any) => {
          this._reasonCodes.next(response);
          return response;
        })
      );
  }

  getappointmentStatus(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/appointmentstatus"
      )
      .pipe(
        map((response: any) => {
          this._appointmentStatus.next(response);
          return response;
        })
      );
  }

  getAdmitSources(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/encounter-admit-source"
      )
      .pipe(
        map((response: any) => {
          this._admitSources.next(response);
          return response;
        })
      );
  }

  getLocationStatuses(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/encounter-location-status"
      )
      .pipe(
        map((response: any) => {
          this._locationStatuses.next(response);
          return response;
        })
      );
  }

  getLocationPhysicalTypes(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/location-physical-type"
      )
      .pipe(
        map((response: any) => {
          this._locationPhysicalTypes.next(response);
          return response;
        })
      );
  }

  getLocations(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Location?_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Location-my-core&organization=" +
          this._appConfigService.getInstCode() +
          "&status=active&physicalType=ro,wa,bu,si,area,wi&_sort=name"
      )
      .pipe(
        map((response: any) => {
          this._locations.next(response);
          return response;
        })
      );
  }

  getLocationsByInst(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Location?_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Location-my-core&status=active&organization=" +
          this._appConfigService.getInstCode()
      )
      .pipe(
        map((response: any) => {
          this._locationsByInst.next(response);
          return response;
        })
      );
  }

  getLocationsByPhsyicalTypeInst(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Location?_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Location-my-core&status=active&organization=" +
          this._appConfigService.getInstCode() +
          "&physicalType=ro,si,bu,wi,wa,lvl,area"
      )
      .pipe(
        map((response: any) => {
          this._locationsByInst.next(response);
          return response;
        })
      );
  }

  getLocationByClassCode(id): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Location?_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Location-my-core&status=active&organization=" +
          this._appConfigService.getInstCode() +
          "&physicalType=ro,si,bu,wi,wa,area" +
          "&type=" +
          id
      )
      .pipe(
        map((response: any) => {
          this._locations.next(response);
          return response;
        })
      );
  }

  getHealthcareServices(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/HealthcareService?active=true")
      .pipe(
        map((response: any) => {
          this._healthcareServices.next(response);
          return response;
        })
      );
  }

  getHealthcareServicesByInst(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/HealthcareService?active=true&organization=" +
          this._appConfigService.getInstCode()
      )
      .pipe(
        map((response: any) => {
          this._healthcareServicesByInst.next(response);
          return response;
        })
      );
  }

  getHealthcareServicesByReceivingInst(instCode): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/HealthcareService?active=true&organization=" +
          instCode
      )
      .pipe(
        map((response: any) => {
          this._healthcareServicesByRcvInst.next(response);
          return response;
        })
      );
  }

  getHealthcareServicesNonLabRadByInst(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/HealthcareService?active=true&organization=" +
          this._appConfigService.getInstCode() +
          "&service-category:not=108252007,363679005&_sort=service-category,name"
      )
      .pipe(
        map((response: any) => {
          this._healthcareServicesByInst.next(response);
          return response;
        })
      );
  }

  getHealthcareServicesByInstAndServiceType(id): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/HealthcareService?active=true&organization=" +
          this._appConfigService.getInstCode() +
          "&service-type=" +
          id
      )
      .pipe(
        map((response: any) => {
          this._healthcareServicesByInst.next(response);
          return response;
        })
      );
  }

  getServiceProviders(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Organization?_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core"
      )
      .pipe(
        map((response: any) => {
          this._serviceProviders.next(response);
          return response;
        })
      );
  }

  getDrugCategories(): Observable<any> {
    const query = `
      {
        drugCategories:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='DRUG_CATEGORY'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._drugCategories.next(response.data.drugCategories.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getDrugFormularies(): Observable<any> {
    const query = `
      {
        drugformularies:AFCRefcd(filter:"moduleId=='AF';referenceGroup=='YES_OR_NO'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._drugFormularies.next(response.data.drugformularies.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getTableName(): Observable<any> {
    const query = `
      {
        tableName: AFCTabaud {
          edges {
            node {
              tableName
              fullDesc
            }
          }
        } 
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._tableName.next(response.data.tableName.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getDrugSchedules(): Observable<any> {
    const query = `
      {
        drugSchedules:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='DRUG_SCHEDULE'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._drugSchedules.next(response.data.drugSchedules.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getActiveIngredients(): Observable<any> {
    const query = `
      {
        activeIngredients:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='ACTIVE_INGREDIENT'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._activeIngredients.next(
            response.data.activeIngredients.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getDrugTypes(): Observable<any> {
    const query = `
      {
        drugTypes:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='DRUG_TYPE'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._drugTypes.next(response.data.drugTypes.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getItemSubgroups(): Observable<any> {
    const query = `
      {
        itemSubgroups:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='ITEM_SUBGROUP'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._itemSubgroups.next(response.data.itemSubgroups.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getDosageForms(): Observable<any> {
    const query = `
      {
        dosageForms:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='DOSAGE_FORM’'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._dosageForms.next(response.data.dosageForms.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getDrugLocations(): Observable<any> {
    const query = `
      {
        drugLocations:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='DRUG_LOCATION'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._drugLocations.next(response.data.drugLocations.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getStrengthUoms(): Observable<any> {
    const query = `
      {
        strengthUoms:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='STRENGTH_UOM'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._strengthUoms.next(response.data.strengthUoms.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getSkuUoms(): Observable<any> {
    const query = `
      {
        skuUoms:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='SKU_UOM'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._skuUoms.next(response.data.skuUoms.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getSkuSizeUoms(): Observable<any> {
    const query = `
      {
        skuSizeUoms:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='SKU_UOM'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._skuSizeUoms.next(response.data.skuSizeUoms.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getInstructions(): Observable<any> {
    const query = `
      {
        instructions:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='INSTRUCTOIN’'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._instructions.next(response.data.instructions.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getSpecialInstructions(): Observable<any> {
    const query = `
      {
        specialInstructions:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='SPECIAL_INSTRUCTOIN’'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._specialInstructions.next(
            response.data.specialInstructions.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getCautionaries(): Observable<any> {
    const query = `
      {
        cautionaries:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='CAUTIONARY’'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._cautionaries.next(response.data.cautionaries.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getRecommendDoseUomes(): Observable<any> {
    const query = `
      {
        recommendDoseUomes:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='DOSE_UOM'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._recommendDoseUomes.next(
            response.data.recommendDoseUomes.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getRecommendFrequencies(): Observable<any> {
    const query = `
      {
        recommendFrequencies:AFCRefcd(filter:"moduleId=='PH';referenceGroup=='CARE_ADMIN'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._recommendFrequencies.next(
            response.data.recommendFrequencies.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getCareAdmins(): Observable<any> {
    const query = `
      {
        careAdmins:AFCRefcd(filter:"moduleId=='AF';referenceGroup=='YES_OR_NO'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._careAdmins.next(response.data.careAdmins.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getmodule(): Observable<any> {
    const query = `
      {
        module: AFCMod {
          edges {
            node {
              moduleId
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._module.next(response.data.module.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getNoteCategories(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/document-classcodes"
      )
      .pipe(
        map((response: any) => {
          this._noteCategories.next(response);
          return response;
        })
      );
  }

  getNoteTypes(category: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetCompositionTypeMyCore",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/composition-type-my-core",
            filter: [
              {
                property: "category",
                op: "=",
                value: category,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._noteTypes.next(response);
          return response;
        })
      );
  }

  getTypeOption(): Observable<any> {
    const query = `
      {
        institutionType: AFCRefcd(filter:"moduleId == 'AF';referenceGroup=='INST_TYPE'"){
          edges{
            node{
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._typeOption.next(response.data.institutionType.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getNoteEvents(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://terminology.hl7.org/ValueSet/v3-ActCode"
      )
      .pipe(
        map((response: any) => {
          this._reasonCodes.next(response);
          return response;
        })
      );
  }

  getParentInstOption(): Observable<any> {
    const query = `
      {
        parentInstCode: AFMInst {
          edges {
            node {
              instCode
              fullName
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._parentInstOption.next(response.data.parentInstCode.edges.map((edge) => edge.node));
          return response.data.parentInstCode.edges.map((edge) => edge.node);
        })
      );
  }

  getCountryOption(): Observable<any> {
    const query = `
      {
        countryCode: AFCCtry{
          edges{
            node{
              countryCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._countryOption.next(response.data.countryCode.edges.map((edge) => edge.node));
          return response.data.countryCode.edges.map((edge) => edge.node);
        })
      );
  }

  getStateOption(): Observable<any> {
    const query = `
      {
        stateCode: AFCState(filter:"countryCode=='MYS'"){
          edges{
            node{
              stateCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._stateOption.next(response.data.stateCode.edges.map((edge) => edge.node));
          return response.data.stateCode.edges.map((edge) => edge.node);
        })
      );
  }

  getDistrictOption(): Observable<any> {
    const query = `
      {
        districtCode: AFCDist(filter: "countryCode=='MYS'") {
          edges {
            node {
              districtCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._districtOption.next(response.data.districtCode.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getDepartmentOptions(): Observable<any> {
    const query = `
      {
        departmentOption:AFCRefcd(filter:"moduleId=='AF';referenceGroup=='DEPARTMENT_CATEGORY'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._departmentOption.next(
            response.data.departmentOption.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getMenuSetOption(): Observable<any> {
    let instCode = this._appConfigService.getInstCode();
    var query = `
    {
      menuSet: AFMInstmenuset {
        edges {
          node {
            AFCObjHasOne {
              edges {
                node {
                  objectId
                  fullDesc
                }
              }
            }
          }
        }
      }
    }
    `;

    query = query.replace("$INSTCODE", instCode);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._menusetOption.next(
            response.data.menuSet.edges.map((edge) => edge.node.AFCObjHasOne.edges[0].node)
          );
          return response;
        })
      );
  }

  getAccessRightOption(): Observable<any> {
    let instCode = this._appConfigService.getInstCode();
    var query = `
      {
        accessRight: AFMPrgrole(filter: "departmentCode=='IT' ; instCode =='$INST_CODE'") {
          edges {
            node {
              fullDesc
              programRoleId
            }
          }
        }
      }`;

    query = query.replace("$INST_CODE", instCode);
    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._accessRightOption.next(response.data.accessRight.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getBranchOption(): Observable<any> {
    let instCode = this._appConfigService.getInstCode();
    var query = `
      {
        branch: AFMInstbrh(filter: "instCode == '$INST_CODE'") {
          edges {
            node {
              fullName
              branchCode
            }
          }
        }
      }`;

    query = query.replace("$INST_CODE", instCode);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._branchOption.next(response.data.branch.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getAuthorizationLevelOption(): Observable<any> {
    const query = `
      {
        authorizationLevel: AFCRefcd(filter: "referenceGroup == 'AUTHORIZATION_LEVEL'") {
          edges {
            node {
              fullDesc
              referenceCode
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._authorizationLevelOption.next(
            response.data.authorizationLevel.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getAuthorizationInternalTypeOption(): Observable<any> {
    const query = `
      {
        authorizationInternalType: AFCRefcd(filter: "referenceGroup == 'USER_TYPE'; moduleId=='AF';referenceCode=in=('0','1','2','3','4','6')") {
          edges {
            node {
              fullDesc
              referenceCode
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._authorizationInternalTypeOption.next(
            response.data.authorizationInternalType.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getAccountStatusOption(): Observable<any> {
    const query = `
    {
      accountStatus: AFCRefcd(filter: "referenceGroup == 'ACCOUNT_STATUS'") {
        edges {
          node {
            fullDesc
            referenceCode
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._accountStatusOption.next(
            response.data.accountStatus.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getDesignationOption(): Observable<any> {
    const query = `
      {
        designation: AFCDsgn {
          edges {
            node {
              fullDesc
              designationCode
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._designationOption.next(response.data.designation.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getSupervisorOption(): Observable<any> {
    const query = `
      {
        supervisor: AFMUsr(filter: "userType=in=('2','3') ; instCode=='$INSTCODE' ; selectBranchCode=='001'") {
          edges {
            node {
              fullName
              userId
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._supervisorOption.next(response.data.supervisor.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getRemarkOption(): Observable<any> {
    const query = `
      {
        remark: AFCRefcd(filter: "referenceGroup == 'USER_REMARK' ; referenceCode =out= ('1','2')") {
          edges {
            node {
              fullDesc
              referenceCode
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._remarkOption.next(response.data.remark.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getMobileAccessOption(): Observable<any> {
    const query = `
      {
        mobileAccess:AFMPrgrole(filter: "menuSetObjectIdHasOne.menuSetType == 'MOB'; instCode=='$INSTCODE' ; share=='Y'") {
          edges {
            node {
              programRoleId
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._mobileAccessOption.next(response.data.mobileAccess.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getBcpAccessOption(): Observable<any> {
    const query = `
      {
        bcpAccess:AFMPrgrole(filter: "menuSetObjectIdHasOne.menuSetType == 'BCP'; instCode=='$INSTCODE' ; share=='Y'") {
          edges {
            node {
              programRoleId
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._bcpAccessOption.next(response.data.bcpAccess.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getInstitution(): Observable<any> {
    let instCode = this._appConfigService.getInstCode();
    var query = `
      {
        institution: AFMInst(filter: "instCode == '$INST_CODE'") {
          edges {
            node {
              fullName
              instCode
            }
          }
        }
      }`;

    query = query.replace("$INST_CODE", instCode);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._institutionOption.next(response.data.institution.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getRelationships(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/patient-contactrelationship"
      )
      .pipe(
        map((response: any) => {
          this._relationships.next(response);
          return response;
        })
      );
  }

  getRelationshiptypes(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/relatedperson-relationship-my-core"
      )
      .pipe(
        map((response: any) => {
          this._relationshiptypes.next(response);
          return response;
        })
      );
  }

  getBranchOptionById(id: string): Observable<any> {
    var query = `
      {
        branch: AFMInstbrh(filter: "instCode == '$INST_CODE'") {
          edges {
            node {           
              fullName
              branchCode
            }
          }
        }
      }`;

    query = query.replace("$INST_CODE", id);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._branchOption.next(response.data.branch.edges.map((edge) => edge.node));
          return response.data.branch.edges.map((edge) => edge.node);
        })
      );
  }

  getState(): Observable<any> {
    let instCode = this._appConfigService.getInstCode();
    var query = `
    {
      state: AFCState(filter:"countryCode=='$COUNTRY_CODE'"){
        edges{
          node{
            stateCode
            fullDesc
          }
        }
      }
    }`;

    query = query.replace("$COUNTRY_CODE", instCode);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._statesOption.next(response.data.state.edges.map((edge) => edge.node));
          return response.data.state.edges.map((edge) => edge.node);
        })
      );
  }

  getCurrencyOption(): Observable<any> {
    var query = `
      {
      AFCCurr{
        edges {
          node {
            updateDate
            fullDesc
            currencyCode
            currencyRate
            recordStatus
            shortDesc
          }
        }
        pageInfo {
          totalRecords
          startCursor
          hasNextPage
          endCursor
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._currencyOption.next(response.data.AFCCurr.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getDistrictOptionByState(id: string): Observable<any> {
    var query = `
    {
      state: AFCState(filter:"countryCode=='$COUNTRY_CODE'"){
        edges{
          node{
            stateCode
            fullDesc
          }
        }
      }
    }`;

    query = query.replace("$STATE_CODE", id);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._districtOption.next(response.data.district.edges.map((edge) => edge.node));
          return response.data.district.edges.map((edge) => edge.node);
        })
      );
  }

  getDepartmentOptionsByInstCodeandBranchCode(instCode, branchCode): Observable<any> {
    var query = `
    {
      departmentOption: AFMInstbrhdept(filter:"instCode=='$INST_CODE' and branchCode =='$BRANCH_CODE'"){
        edges{
          node{
            departmentCode,
            fullDesc
          }
        }
      }
    }`;

    query = query.replace("$INST_CODE", instCode).replace("$BRANCH_CODE", branchCode);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._departmentOption.next(
            response.data.departmentOption.edges.map((edge) => edge.node)
          );
          return response.data.departmentOption.edges.map((edge) => edge.node);
        })
      );
  }

  getBcpAccessOptionByInstCode(instCode: string): Observable<any> {
    var query = `
      {
        bcpAccess:AFMPrgrole(filter: "menuSetObjectIdHasOne.menuSetType == 'BCP'; instCode=='$INSTCODE' or share=='Y'") {
          edges {
            node {
              programRoleId
              fullDesc
            }
          }
        }
      }`;

    query = query.replace("$INST_CODE", instCode);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._bcpAccessOption.next(response.data.bcpAccess.edges.map((edge) => edge.node));
          return response.data.bcpAccess.edges.map((edge) => edge.node);
        })
      );
  }

  getMobileAccessOptionByInstCode(instCode: string): Observable<any> {
    var query = `
      {
        mobileAccess:AFMPrgrole(filter: "menuSetObjectIdHasOne.menuSetType == 'MOB'; instCode=='$INSTCODE' or share=='Y'") {
          edges {
            node {
              programRoleId
              fullDesc
            }
          }
        }
      }`;

    query = query.replace("$INST_CODE", instCode);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._mobileAccessOption.next(response.data.mobileAccess.edges.map((edge) => edge.node));
          return response.data.mobileAccess.edges.map((edge) => edge.node);
        })
      );
  }

  getAccessRightOptionByInstCode(instCode: string): Observable<any> {
    var query = `
      {
        accessRight: AFMPrgrole(filter:"instCode =='$INST_CODE'") {
          edges {
            node {
              fullDesc
              programRoleId
            }
          }
        }
      }`;

    query = query.replace("$INST_CODE", instCode);
    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._accessRightOption.next(response.data.accessRight.edges.map((edge) => edge.node));
          return response.data.accessRight.edges.map((edge) => edge.node);
        })
      );
  }

  getDesgngrdbydesignationCode(code: string): Observable<any> {
    var query = `
    {
      grade: AFCDsgngrd(filter:"designationCode.designationCode=='$CODE'"){
        edges{
          node{
            designationGrade
          }
        }
      }
    }`;

    query = query.replace("$CODE", code);
    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._designationGradeOption.next(response.data.grade.edges.map((edge) => edge.node));
          return response.data.grade.edges.map((edge) => edge.node);
        })
      );
  }

  getSupervisorOptionByInstCodeBranchCodeDepartmentCode(): Observable<any> {
    const query = `
      {
        supervisor: AFMUsr(filter: "userType=in=('2','3') ; instCode=='$INSTCODE' ; selectBranchCode=='001'") {
          edges {
            node {
              fullName
              userId
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._supervisorOption.next(response.data.supervisor.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getReferenceGroupOption(): Observable<any> {
    var query = `
    {
      AFCRefgrp {
        edges {
          node {
            fullDesc
            shortDesc
            updateDate
            referenceGroup
            recordStatus
            remark
          }
        }
        pageInfo {
          totalRecords
          startCursor
          endCursor
          startCursor
          hasNextPage
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._referenceGroup.next(response.data.AFCRefgrp.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getModuleId(): Observable<any> {
    var query = `
    {
      AFCMod {
        edges {
          node {
            fullDesc
            moduleId
            shortDesc
            updateDate
            recordStatus
            remark
          }
        }
        pageInfo {
          totalRecords
          startCursor
          endCursor
          startCursor
          hasNextPage
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._moduleId.next(response.data.AFCMod.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getRegionOption(): Observable<any> {
    const query = `
    {
      regionCode: AFCRgn(filter: "countryCode == 'MYS'") {
        edges {
          node {
            regionCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._regionOption.next(response.data.regionCode.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getStateOptionByCounstryCode(code: string): Observable<any> {
    var query = `
      {
        stateCode: AFCState(filter:"countryCode=='$CODE'"){
          edges{
            node{
              stateCode
              fullDesc
            }
          }
        }
      }`;

    query = query.replace("$CODE", code);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._stateOption.next(response.data.stateCode.edges.map((edge) => edge.node));
          return response.data.stateCode.edges.map((edge) => edge.node);
        })
      );
  }

  getDistrictOptionByStateCode(code: string): Observable<any> {
    var query = `
    {
      districtCode: AFCDist(filter: "stateCodeHasOne.stateCode=='$CODE'") {
        edges {
          node {
            districtCode
            fullDesc
          }
        }
      }
    }`;

    query = query.replace("$CODE", code);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._districtOption.next(response.data.districtCode.edges.map((edge) => edge.node));
          return response.data.districtCode.edges.map((edge) => edge.node);
        })
      );
  }

  getMenuSetBOption(): Observable<any> {
    let instCode = this._appConfigService.getInstCode();
    var query = `
    {
      menuSet:AFCObj(filter:"objectType == 'B'",sort:"fullDesc"){
        edges{
          node{
            objectId
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._menusetOption.next(response.data.menuSet.edges.map((edge) => edge.node));
          return response.data.menuSet.edges.map((edge) => edge.node);
        })
      );
  }

  getMenuSetTypeOption(): Observable<any> {
    const query = `
    {
      menuSetType: AFCRefcd(filter: "moduleId =='AF' and referenceGroup=='MENU_SET_TYPE'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._menuSetTypeOption.next(response.data.menuSetType.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getObjectTypeOption(): Observable<any> {
    const query = `
    {
      objectType: AFCRefcd(filter: "moduleId =='AF' and referenceGroup=='OBJECT_TYPE'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._objectTypeOption.next(response.data.objectType.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getAllInstitutionOption(): Observable<any> {
    const query = `
      {
        parentInstCode: AFMInst {
          edges {
            node {
              instCode
              fullName
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._institutionOption.next(response.data.parentInstCode.edges.map((edge) => edge.node));
          return response.data.parentInstCode.edges.map((edge) => edge.node);
        })
      );
  }

  getBundleAffiliation(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/Organization?active=true")
      .pipe(
        map((response: any) => {
          this._bundleOrganization.next(response);
          return response;
        })
      );
  }

  getAffiliations(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Organization?_elements=name&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core"
      )
      .pipe(
        map((response: any) => {
          this._affiliations.next(response);
          return response;
        })
      );
  }

  getAffiliationCategories(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "affiliationService",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._affiliations.next(response);
          return response;
        })
      );
  }

  getAffiliationTypes(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "affiliationService",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._affiliations.next(response);
          return response;
        })
      );
  }

  getBundleParent(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/Organization?active=true")
      .pipe(
        map((response: any) => {
          this._bundleOrganization.next(response);
          return response;
        })
      );
  }

  getParents(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/Organization?_elements=name&_profile=http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core"
      )
      .pipe(
        map((response: any) => {
          this._parents.next(response);
          return response;
        })
      );
  }

  getParentCategories(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "parentService",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._parents.next(response);
          return response;
        })
      );
  }

  getEligibilityOption(): Observable<any> {
    const query = `
    {
      eligibility: AFCRefcd(filter: "moduleId=='AF';referenceGroup=='HOLIDAY_ELIGIBILITY'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._eligibilityOption.next(response.data.eligibility.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getParentTypes(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "affiliationService",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/StructureDefinition/Organization-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._affiliations.next(response);
          return response;
        })
      );
  }

  getTemplateType(): Observable<any> {
    const query = `
      {
        templateType:AFCRefcd(filter: "moduleId=='OD';referenceGroup=='TEMPLATE_TYPE'") {
          edges {
            node {
              referenceCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._templateType.next(response.data.templateType.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getFunctionTypeOption(): Observable<any> {
    const query = `
    {
      functionList: AFCRefcd(filter: "moduleId =='AF' and referenceGroup=='SCREEN_FUNCTION'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._functionOption.next(response.data.functionList.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getUserInstitution(): Observable<any> {
    let userId = this._appConfigService.getUserId();
    var query = `
      {
        AFMUsrinstQ(filter: "AFMUsrHasOne.userId=='$USER_ID'") {
          edges {
            node {
              AFMInst {
                edges {
                  node {
                    instCode
                    fullName
                  }
                }
              }
            }
          }
        }
      }`;

    query = query.replace("$USER_ID", userId);

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._userInstitution.next(
            response.data.AFMUsrinstQ.edges.flatMap((usrinst) =>
              usrinst.node.AFMInst.edges.flatMap((inst) => inst.node)
            )
          );
          return response;
        })
      );
  }

  getFacilityTypeOption(): Observable<any> {
    const query = `
    {
      facilityType: AFCRefcd(filter: "moduleId=='PR';referenceGroup=='FACILITY_TYPE'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._facilityTypeOption.next(response.data.facilityType.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getFacilityCategoryOption(): Observable<any> {
    const query = `
    {
      facilityCategory: AFCRefcd(filter: "moduleId=='PR';referenceGroup=='FACILITY_CATEGORY'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._facilityCategoryOption.next(
            response.data.facilityCategory.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getFacilityLocationOption(): Observable<any> {
    const query = `
    {
      facilityLocation: AFCRefcd(filter: "moduleId=='PR';referenceGroup=='FACILITY_LOCATION'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._facilityLocationOption.next(
            response.data.facilityLocation.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getFacilityProgramOption(): Observable<any> {
    const query = `
    {
      facilityProgram: AFCRefcd(filter: "moduleId=='PR';referenceGroup=='FACILITY_PROGRAM'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._facilityProgramOption.next(
            response.data.facilityProgram.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getPkdCodeOption(): Observable<any> {
    const query = `
    {
      pkdCode: PRMFac(filter: "facilityCategory=='PKD';recordStatus=='1'") {
        edges {
          node {
            pkdCode
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._facilityLocationOption.next(response.data.pkdCode.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getLeadFacilityOption(): Observable<any> {
    const query = `
    {
      leadFacility: AFCRefcd(filter: "moduleId=='PR';referenceGroup=='LEAD_FACILITY'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }
    `;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._leadFacilityOption.next(response.data.leadFacility.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getDivisionOption(): Observable<any> {
    const query = `
      {
        divisionCode: AFCDist(filter: "countryCode=='MYS'"; "districtType=='B'") {
          edges {
            node {
              districtCode
              fullDesc
            }
          }
        }
      }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._divisionOption.next(response.data.division.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getSubDistrictOption(): Observable<any> {
    const query = `
    {
      districtCode: AFCDist(filter: "countryCode=='MYS'"; "districtType=='B'") {
        edges {
          node {
            districtCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._subdistrictOption.next(response.data.subdistrict.edges.map((edge) => edge.node));
          return response;
        })
      );
  }

  getRoles(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "roles",
      status: "draft",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/organization-role"
      )
      .pipe(
        map((response: any) => {
          this._roles.next(response);
          return response;
        })
      );
  }

  getHealthcareService(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/HealthcareService")
      .pipe(
        map((response: any) => {
          this._bundleHealthcare.next(response);
          return response;
        })
      );
  }

  getSpecialities(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "specialities",
      status: "draft",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/organization-role"
      )
      .pipe(
        map((response: any) => {
          this._specialities.next(response);
          return response;
        })
      );
  }

  getChildIdentificationTypes(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/child-identification-type-my-core"
      )
      .pipe(
        map((response: any) => {
          this._childIdentificationTypes.next(response);
          return response;
        })
      );
  }

  getScheduleTypes(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-schedule-type-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._schedulerTypes.next(response);
          return response;
        })
      );
  }

  getServiceRequestCategory(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetServiceCategoryMyCore",
      status: "active",
    };

    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/encounter-class-my-core"
      )
      .pipe(
        map((response: any) => {
          this._serviceRequestCategories.next(response);
          return response;
        })
      );
  }

  getLaboratoryType(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-laboratory-type-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._laboratoryTypes.next(response);
          return response;
        })
      );
  }

  getPrimaryCareType(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-primary-care-type-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._primaryCareTypes.next(response);
          return response;
        })
      );
  }

  getSecondaryCareType(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-secondary-care-type-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._secondaryCareTypes.next(response);
          return response;
        })
      );
  }

  getTertiaryCareType(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-tertiary-care-type-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._tertiaryCareTypes.next(response);
          return response;
        })
      );
  }

  getLaboratoryCode(typeCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "value-set-laboratory-code-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/laboratory-code-my-core",
            filter: [
              {
                property: "typeCode",
                op: "=",
                value: typeCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._laboratoryCodes.next(response);
          return response;
        })
      );
  }

  getPrimaryCareCode(typeCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetPrimaryCareCodeMyCore",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/primary-care-code-my-core",
            filter: [
              {
                property: "typeCode",
                op: "=",
                value: typeCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._primaryCareCodes.next(response);
          return response;
        })
      );
  }

  getSecondaryCareCode(typeCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "value-set-secondary-care-code-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/secondary-care-code-my-core",
            filter: [
              {
                property: "typeCode",
                op: "=",
                value: typeCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._secondaryCareCodes.next(response);
          return response;
        })
      );
  }

  getTertiaryCareCode(typeCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "ValueSetTertiaryCareCodeMyCore",
      status: "draft",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/tertiary-care-code-my-core",
            filter: [
              {
                property: "typeCode",
                op: "=",
                value: typeCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._tertiaryCareCodes.next(response);
          return response;
        })
      );
  }

  getTicketTypeOptions(): Observable<any> {
    const query = `
    {
      ticketTypeOption:AFCRefcd(filter:"moduleId=='ST';referenceGroup=='TICKET_TYPE'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._ticketTypeOption.next(
            response.data.ticketTypeOption.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getSupportTypeOptions(): Observable<any> {
    const query = `
    {
      supportTypeOption:AFCRefcd(filter:"moduleId=='ST';referenceGroup=='SUPPORT_TYPE'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._supportTypeOption.next(
            response.data.supportTypeOption.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getServiceTypeOptions(): Observable<any> {
    const query = `
    {
      serviceTypeOption:AFCRefcd(filter:"moduleId=='ST';referenceGroup=='SERVICE_TYPE'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._serviceTypeOption.next(
            response.data.serviceTypeOption.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getSeverityLevelOptions(): Observable<any> {
    const query = `
    {
      severityLevelOption:AFCRefcd(filter:"moduleId=='ST';referenceGroup=='SEVERITY_LEVEL'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._severityLevelOption.next(
            response.data.severityLevelOption.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getAdverseEventCategory(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "adverse-event-category",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/adverse-event-category",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._adverseEventCategory.next(response);
          return response;
        })
      );
  }

  getAdverseEventEvent(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "adverse-event-event",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/adverse-event-event",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._adverseEventEvent.next(response);
          return response;
        })
      );
  }

  getAdverseEventOutcome(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "adverse-event-outcome",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/adverse-event-outcome",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._adverseEventOutcome.next(response);
          return response;
        })
      );
  }

  getDiagnosisRole(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      id: "value-set-diagnosis-role-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/diagnosis-role",
          },
        ],
      },
    };

    return this._httpClient
      .get<fhir.r4.ValueSet>(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-diagnosis-role-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._diagnosisRole.next(response);
          return response;
        })
      );
  }

  getTicketStatusOptions(): Observable<any> {
    const query = `
    {
      ticketStatusOption:AFCRefcd(filter:"moduleId=='HD';referenceGroup=='TICKET_STATUS'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._ticketStatusOption.next(
            response.data.ticketStatusOption.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getTicketStatusOptions2(item): Observable<any> {
    var query = `
    {
      ticketStatusOption:AFCRefcd(filter:"moduleId=='HD';referenceGroup=='TICKET_STATUS'; referenceCode=in=(items)") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }`;

    // item = item.replace("[", "");
    // item = item.replace("]", "");
    query = query.replace("items", item.toString());

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._ticketStatusOption.next(
            response.data.ticketStatusOption.edges.map((edge) => edge.node)
          );
          return response.data.ticketStatusOption.edges.map((edge) => edge.node);
        })
      );
  }

  getTicketModeOptions(): Observable<any> {
    const query = `
    {
      ticketModeOption:AFCRefcd(filter:"moduleId=='HD';referenceGroup=='TICKET_MODE'") {
        edges {
          node {
            referenceCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._ticketModeOption.next(
            response.data.ticketModeOption.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getAttenderOptions(): Observable<any> {
    // const query = `
    // {
    //   attenderOptions:HDMAttender{
    //     edges {
    //       node {
    //         attenderId
    //         fullName
    //       }
    //     }
    //   }
    // }`;

    return this._httpClient
      .get<any>(this._ncrConfigService.getNcrService() + "/api/v1/user/get-attender/23")
      .pipe(
        map((response: any) => {
          this._attenderOptions.next(response);
          return response;
        })
      );
  }

  getServiceCategoryOptions(): Observable<any> {
    const query = `
    {
      serviceCategoryOptions:HDMServcatg(filter:"recordStatus=='1';moduleId=='ST'"){
        edges {
          node {
            categoryCode
            fullDesc
          }
        }
      }
    }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._serviceCategoryOptions.next(
            response.data.serviceCategoryOptions.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  getSpecialArrangements(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      status: "active",
      compose: {
        include: [
          {
            system: "http://terminology.hl7.org/CodeSystem/encounter-special-arrangements",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._specialArrangements.next(response);
          return response;
        })
      );
  }

  getVisitTypes(): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/visit-type-my-core",
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._visitTypes.next(response);
          return response;
        })
      );
  }

  getCompositionAttender(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/PractitionerRole?organization=" +
          this._appConfigService.getInstCode() +
          "&_include=PractitionerRole:practitioner"
      )
      .pipe(
        map((response: any) => {
          this._compositionAttender.next(response);
          return response;
        })
      );
  }

  getAllergyAsserterOption(): Observable<any> {
    let instCode = this._appConfigService.getInstCode();
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/PractitionerRole?organization=" +
          instCode +
          "&active=true"
      )
      .pipe(
        map((response: any) => {
          this._allergyAsserterOption.next(response);
          return response;
        })
      );
  }

  getFamilyRelationshipOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/relationship-my-core"
      )
      .pipe(
        map((response: any) => {
          this._familyRelationshipOption.next(response);
          return response;
        })
      );
  }

  getFamilyGenderOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/administrative-gender-my-core"
      )
      .pipe(
        map((response: any) => {
          this._familyGenderOption.next(response);
          return response;
        })
      );
  }

  getDrugCatogory(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-category-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugCategory.next(response);
          return response;
        })
      );
  }

  getDrugType(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-type-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugType.next(response);
          return response;
        })
      );
  }

  getDrugSubGroup(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-sub-group-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugSubGroup.next(response);
          return response;
        })
      );
  }

  // drug master

  getActiveIngredientMedicationOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/active-ingredient-my-core"
      )
      .pipe(
        map((response: any) => {
          this._activeIngredientMedicationOption.next(response);
          return response;
        })
      );
  }
  getDrugDoseFormOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-dose-form-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugDoseFormOption.next(response);
          return response;
        })
      );
  }
  getDrugScheduleOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-schedule-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugScheduleOption.next(response);
          return response;
        })
      );
  }
  getDrugNumeratorUnitOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-schedule-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugNumeratorUnitOption.next(response);
          return response;
        })
      );
  }
  getSpecialInstructionOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-special-instruction-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugSpecialInstructionOption.next(response);
          return response;
        })
      );
  }
  getCautionaryOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/drug-cautionary-my-core"
      )
      .pipe(
        map((response: any) => {
          this._drugCautionaryOption.next(response);
          return response;
        })
      );
  }
  getFrequencyOption(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/timing-abbreviation/$expand")
      .pipe(
        map((response: any) => {
          this._drugFrequencyOption.next(response);
          return response;
        })
      );
  }
  getRouteOption(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/route-codes/$expand")
      .pipe(
        map((response: any) => {
          this._drugRouteOption.next(response);
          return response;
        })
      );
  }
  // email validation
  validateEmail(email: string): Observable<any> {
    let myHeaders = new HttpHeaders();
    myHeaders = myHeaders.set("Authorization", "a262d915-15bc-417c-afeb-842c63b54154");
    return (
      this._httpClient
        // .get(this._ncrConfigService.getNcrService() + "/verify_email?email=" + email, {
        //   headers: myHeaders,
        // })
        .get("https://provider.qa.mhn.asia/verify_email?email=" + email, fhirHttpOptions)
        .pipe(take(1))
    );
  }

  getProcedureCategoryOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/procedure-category"
      )
      .pipe(
        map((response: any) => {
          this._procedureCategoryOption.next(response);
          return response;
        })
      );
  }
  getProcedureNameOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/procedure-code-my-core"
      )
      .pipe(
        map((response: any) => {
          this._procedureNameOption.next(response);
          return response;
        })
      );
  }
  getProcedureOutcomeOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/procedure-outcome"
      )
      .pipe(
        map((response: any) => {
          this._procedureOutcomeOption.next(response);
          return response;
        })
      );
  }
  getProcedureStatusOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/event-status"
      )
      .pipe(
        map((response: any) => {
          this._procedureStatusOption.next(response);
          return response;
        })
      );
  }
  getProcedurePerformerRoleOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://fhir.hie.moh.gov.my/ValueSet/procedure-performer-role-my-core"
      )
      .pipe(
        map((response: any) => {
          this._procedurePerformerRoleOption.next(response);
          return response;
        })
      );
  }

  //Timing abbreviation
  getTimingAbbreviation(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/timing-abbreviation/$expand")
      .pipe(
        map((response: any) => {
          this._timingAbbreviation.next(response);
          return response;
        })
      );
  }

  //Duration units
  getDurationUnits(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/duration-units/$expand")
      .pipe(
        map((response: any) => {
          this._durationUnits.next(response);
          return response;
        })
      );
  }

  //Request priority
  getRequestPriority(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/request-priority/$expand")
      .pipe(
        map((response: any) => {
          this._requestPriority.next(response);
          return response;
        })
      );
  }

  //Drug special instruction
  getDrugSpecialInstruction(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-drug-special-instruction-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._drugSpecialInstruction.next(response);
          return response;
        })
      );
  }

  //Drug cautionary
  getDrugCautionary(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-drug-cautionary-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._drugCautionary.next(response);
          return response;
        })
      );
  }

  //Drug form
  getDrugForm(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-drug-dose-form-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._drugForm.next(response);
          return response;
        })
      );
  }

  getDentition(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() + "/ValueSet/value-set-dentitian-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._dentition.next(response);
          return response;
        })
      );
  }

  getDentitionCode(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() + "/ValueSet/value-set-dental-code-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._dentitionCode.next(response);
          return response;
        })
      );
  }

  getSurface(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/value-set-surface-my-core/$expand")
      .pipe(
        map((response: any) => {
          this._surface.next(response);
          return response;
        })
      );
  }

  getSurfaceCode(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-surface-code-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._surfaceCode.next(response);
          return response;
        })
      );
  }

  //Education level form
  getEducationLevel(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-education-level-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._educationLevels.next(response);
          return response;
        })
      );
  }

  // getNationality(): Observable<any> {
  //   const query = {
  //     resourceType: "ValueSet",
  //     id: "iso3166-1-3",
  //     meta: {
  //       lastUpdated: "2019-11-01T09:29:23.356+11:00",
  //       profile: ["http://hl7.org/fhir/StructureDefinition/shareablevalueset"],
  //     },
  //     url: "http://hl7.org/fhir/ValueSet/iso3166-1-3",
  //     version: "4.0.1",
  //     name: "Iso3166-1-3",
  //     title: "Iso 3166 Part 1: 3 Letter Codes",
  //     status: "active",
  //     experimental: false,
  //     publisher: "FHIR Project team",
  //     contact: [
  //       {
  //         telecom: [
  //           {
  //             system: "url",
  //             value: "http://hl7.org/fhir",
  //           },
  //         ],
  //       },
  //     ],
  //     description: "This value set defines the ISO 3166 Part 1 3-letter codes",
  //     compose: {
  //       include: [
  //         {
  //           system: "urn:iso:std:iso:3166",
  //           filter: [
  //             {
  //               property: "code",
  //               op: "regex",
  //               value: "[A-Z]{3}",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   };

  //   return this._httpClient
  //     .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
  //     .pipe(
  //       map((response: any) => {
  //         this._nationality.next(response);
  //         return response;
  //       })
  //     );
  // }
  getReferralStatusOption(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/request-status"
      )
      .pipe(
        map((response: any) => {
          this._requestStatusOption.next(response);
          return response;
        })
      );
  }

  getLabTest(): Observable<any> {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "/ValueSet/lab-my-core/$expand")
      .pipe(
        map((response: any) => {
          this._labTest.next(response);
          return response;
        })
      );
  }

  getInterpretations(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() + "/ValueSet/observation-interpretation/$expand"
      )
      .pipe(
        map((response: any) => {
          this._interpretations.next(response);
          return response;
        })
      );
  }

  getOrderable(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-imaging-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._orderables.next(response);
          return response;
        })
      );
  }

  getModality(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-imaging-modality-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._modalitys.next(response);
          return response;
        })
      );
  }

  getRegion(): Observable<any> {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
          "/ValueSet/value-set-imaging-region-my-core/$expand"
      )
      .pipe(
        map((response: any) => {
          this._regions.next(response);
          return response;
        })
      );
  }

  getRegionByModality(modalityCode: string): Observable<any> {
    const query = {
      resourceType: "ValueSet",
      name: "imaging-region-my-core",
      status: "active",
      compose: {
        include: [
          {
            system: "http://fhir.hie.moh.gov.my/CodeSystem/imaging-region-my-core",
            filter: [
              {
                property: "modalityCode",
                op: "=",
                value: modalityCode,
              },
            ],
          },
        ],
      },
    };

    return this._httpClient
      .post<fhir.r4.ValueSet>(this._fhirConfigService.getFhirService() + "/ValueSet/$expand", query)
      .pipe(
        map((response: any) => {
          this._regions.next(response);
          return response;
        })
      );
  }
}
