import { FormsModule } from "@angular/forms";
import { MbscModule } from "@mobiscroll/angular";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from "@angular/router";
import { CadModule } from "@cad";
import { CadConfigModule } from "@cad/services/config";
import { CadMockApiModule } from "@cad/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockApiServices } from "app/mock-api";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import {
  DisplayDensityToken,
  DisplayDensity,
} from "@infragistics/igniteui-angular";
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { environment } from "environments/environment";

if (environment.environmentName === "STAGING") {
  Sentry.init({
    dsn: "https://bc42e38361f7447192b883d8cc613726@report.hie.moh.gov.my/2",
    integrations: [new BrowserTracing({ tracingOrigins: ["*"], routingInstrumentation: Sentry.routingInstrumentation, })],
    tracesSampleRate: 1.0,
  });
}

if (environment.environmentName === "QA") {
  Sentry.init({
    dsn: "https://6e781a6bb4324a6aade5dd72e11c88b1@report.hie.moh.gov.my/3",
    integrations: [new BrowserTracing({ tracingOrigins: ["*"], routingInstrumentation: Sentry.routingInstrumentation, })],
    tracesSampleRate: 1.0,
  });
}

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
};

const dbConfig: DBConfig = {
  name: 'MyDb',
  version: 3,
  objectStoresMeta: [{
    store: 'parameters',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'parameterId', keypath: 'parameterId', options: { unique: false } },
      { name: 'parameterValue', keypath: 'parameterValue', options: { unique: false } }
    ]
  }]
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Cad, CadConfig & CadMockAPI
    CadModule,
    CadConfigModule.forRoot(appConfig),
    CadMockApiModule.forRoot(mockApiServices),

    FormsModule,
    MbscModule,

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
  providers: [
    {
      provide: DisplayDensityToken,
      useValue: { displayDensity: DisplayDensity.compact },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
