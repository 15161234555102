import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, ReplaySubject, tap, BehaviorSubject } from "rxjs";
import { Navigation } from "app/core/navigation/navigation.types";
import { CadNavigationItem } from "@cad/components/navigation";
import { MenuTree } from "../model/menutree.model";
import { NcrConfigService } from "app/ncrconfig.service";
import { AppConfigService } from "app/appconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import { FhirConfigService } from "app/fhirconfig.service";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  graphqlURL: string;
  restURL: string;

  //Related Person Start
  private _relatedPersons: BehaviorSubject<fhir.r4.RelatedPerson[]> = new BehaviorSubject(null);
  private _relatedPatients: BehaviorSubject<any[]> = new BehaviorSubject(null);
  get relatedpersons$(): Observable<fhir.r4.RelatedPerson[]> {
    return this._relatedPersons.asObservable();
  }
  get relatedpatients$(): Observable<any[]> {
    return this._relatedPatients.asObservable();
  }

  getPatient(id: string): Observable<fhir.r4.Bundle> {
    return this._httpClient.get<fhir.r4.Bundle>(
      this._fhirConfigService.getFhirService() +
      "/Patient?&_tag:not=http://hapifhir.io/fhir/NamingSystem/mdm-record-status|GOLDEN_RECORD&identifier=" +
      id
    );
  }
  getRelatedPersons(id: string): Observable<fhir.r4.Bundle> {
    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
        "/RelatedPerson?active=true&patient:mdm=Patient/" +
        id
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          let relatedPersons: fhir.r4.RelatedPerson[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(RelatedPerson)"
          );
          this._relatedPersons.next(relatedPersons);
          let relateds: any[] = [];
          for (let i = 0; i < relatedPersons.length; i++) {
            let related: any = <any>{
              id: relatedPersons[i].identifier.find(
                (x) =>
                  x.system === "http://fhir.hie.moh.gov.my/sid/my-kad-no" ||
                  x.system === "http://fhir.hie.moh.gov.my/sid/passport-no" ||
                  x.system === "http://fhir.hie.moh.gov.my/sid/army-no" ||
                  x.system === "http://fhir.hie.moh.gov.my/sid/police-no" ||
                  x.system === "http://fhir.hie.moh.gov.my/sid/others-no"
              )?.value,
              dataSource: relatedPersons[i],
              name: relatedPersons[i].name[0].text,
              relationship: relatedPersons[i].relationship[0].coding[0].display,
            };
            relateds.push(related);
          }
          this._relatedPatients.next(relateds);
        })
      );
  }
  //Related Person End

  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private patientSelectedSource = new BehaviorSubject<Boolean>(false);
  private defaultLocationSelectedSource = new BehaviorSubject<Boolean>(false);
  private closeFlagSource = new BehaviorSubject<Boolean>(false);
  private institutionSelectedSource = new BehaviorSubject<string>(null);

  patientSelected = this.patientSelectedSource.asObservable();
  defaultLocationSelected = this.defaultLocationSelectedSource.asObservable();
  closeFlagSelected = this.closeFlagSource.asObservable();
  institutionSelected = this.institutionSelectedSource.asObservable();

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _appConfigService: AppConfigService,
    private _fhirPathService: FhirPathService,
    private _fhirConfigService: FhirConfigService
  ) {
    this.restURL = this._ncrConfigService.getNcrService() + "/api/v1";
    this.graphqlURL = this._ncrConfigService.getNcrService() + "/graphql/api/v1";
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    return this._httpClient.get<Navigation>("api/common/navigation").pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }

  changeDefaultLocatiSelection(defaultLocationSelected: Boolean) {
    this.defaultLocationSelectedSource.next(defaultLocationSelected);
  }

  changePatientSelection(patientSelected: Boolean) {
    this.patientSelectedSource.next(patientSelected);
  }

  closeFlag(closeFlag: Boolean) {
    this.closeFlagSource.next(closeFlag);
  }

  changeInstitution(institutionSelected: string) {
    this.institutionSelectedSource.next(institutionSelected);
  }

  getTreeMenu(): Observable<any> {
    let httpParams = new HttpParams();

    httpParams = httpParams.set("programRoleId", "HTJ-PRL-2400000002");
    return this._httpClient
      .get<any[]>(this.restURL + `/user/menu-tree-dashboard`, {
        observe: "response",
        params: httpParams,
      })
      .pipe(
        tap((res) => {
          let a: MenuTree[] = res.body;
          this._navigation.next(this.mapper(a));
        })
      );
  }

  mapper(menutree: MenuTree[]): Navigation {
    let navigationItem: CadNavigationItem[] = [];
    // if (menutree !== null) {
    menutree[0].items.forEach((element) => {
      let parent: CadNavigationItem = { type: "basic" };
      let childItem: CadNavigationItem[] = [];

      if (element.des === "EHR") {
        return;
        parent.tooltip = "Electronic Health Record";
      } else if (element.des === "CAD") {
        return;
        parent.tooltip = "Cloud Application Development";
      } else {
        parent.tooltip = element.des;
      }

      parent.id = element.opt;
      parent.title = element.des;
      parent.type = "basic";
      parent.link = "/" + element.url;
      parent.icon = element.img;
      if (element.items) {
        parent.children = childItem;
        parent.type = "collapsable";
        parent.link = null;

        element.items.forEach((childelement) => {
          let child: CadNavigationItem = { type: "basic" };
          let grandchildItem: CadNavigationItem[] = [];
          let idItemchild = parent.id + "." + childelement.opt.replace(/\s/g, "").toLowerCase();

          child.id = idItemchild;
          child.title = childelement.des;
          child.type = "basic";
          child.link = "/" + childelement.url;
          child.icon = childelement.img;
          if (childelement.items) {
            child.children = grandchildItem;
            child.type = "collapsable";
            child.link = null;

            childelement.items.forEach((grandchildElement) => {
              let grandchild: CadNavigationItem = { type: "basic" };
              let greatgrandchildItem: CadNavigationItem[] = [];
              let idItemgrandchild =
                child.id + "." + grandchildElement.opt.replace(/\s/g, "").toLowerCase();

              grandchild.id = idItemgrandchild;
              grandchild.title = grandchildElement.des;
              grandchild.type = "basic";
              grandchild.link = "/" + grandchildElement.url;
              grandchild.icon = grandchildElement.img;
              if (grandchildElement.items) {
                grandchild.children = greatgrandchildItem;
                grandchild.type = "collapsable";
                grandchild.link = null;

                grandchildElement.items.forEach((greatgrandchildElement) => {
                  let greatgrandchild: CadNavigationItem = { type: "basic" };
                  let deep1childItem: CadNavigationItem[] = [];
                  let idItemgreatgrandchild =
                    grandchild.id +
                    "." +
                    greatgrandchildElement.opt.replace(/\s/g, "").toLowerCase();
                  greatgrandchild.id = idItemgreatgrandchild;
                  greatgrandchild.title = greatgrandchildElement.des;
                  greatgrandchild.type = "basic";
                  greatgrandchild.link = "/" + greatgrandchildElement.url;
                  greatgrandchild.icon = greatgrandchildElement.img;

                  if (greatgrandchildElement.items) {
                    greatgrandchild.children = deep1childItem;
                    greatgrandchild.type = "collapsable";
                    greatgrandchild.link = null;

                    greatgrandchildElement.items.forEach((deep1childElement) => {
                      let deep1child: CadNavigationItem = { type: "basic" };
                      let deep1childID =
                        greatgrandchild.id +
                        "." +
                        deep1childElement.opt.replace(/\s/g, "").toLowerCase();
                      deep1child.id = deep1childID;
                      deep1child.title = deep1childElement.des;
                      deep1child.type = "basic";
                      deep1child.link = "/" + deep1childElement.url;
                      deep1child.icon = deep1childElement.img;
                      greatgrandchild.children.push(deep1child);
                    });
                  }
                  grandchild.children.push(greatgrandchild);
                });
              }
              child.children.push(grandchild);
            });
          }
          parent.children.push(child);
        });
      }

      navigationItem.push(parent);

      // if (this._appConfigService.getDashboardAccess() == "Y") {
      //   if (parent.id == "DASHBOARDS") {
      //     navigationItem.push(parent);
      //   }
      // } else {
      //   if (parent.id != "DASHBOARDS") {
      //     navigationItem.push(parent);
      //   }
      // }
      // if (parent.id === "DASHBOARDS") {
      //   navigationItem.push(parent);
      // }
    });
    // }
    // declare const navy: Navigation;
    const navy: Navigation = {
      compact: navigationItem,
      default: navigationItem,
      futuristic: navigationItem,
      horizontal: navigationItem,
    };
    return navy;
  }
}
